import React from "react";
import ContactUsInfo from "../components/dashboard/contact/ContactUsInfo";
const ContactUs = () => {
  return (
    <section className="body-wrap">
      <div className="contact-grid">
        <div className="container">
          <div className="banner-home_text with-h1-line text-center">
            <h1>Contact Us</h1>
          </div>
        </div>

        <div className="contact-grid_block">
          <div className="container">
            <div className="contact-info">
              <ul>
                <li>
                  <img src="/images/icons/phone-icon.svg" alt="Phome" />
                  <a href="tell:(530) 691-1055">(530) 691-1055</a>
                </li>
                <li>
                  <img src="/images/icons/fax-icon.svg" alt="Phome" />
                  <a href="fax:(530) 691-1055">(800) 644-9062</a>
                </li>
                <li>
                  <img src="/images/icons/email-icon.svg" alt="Phome" />
                  <a href="mailto:info@coolbiz.info">support@coolbiz.info</a>
                </li>
                <li>
                  <img src="/images/icons/location-icon.svg" alt="Phome" />
                  1534 Purple Way Redding, CA 96003
                </li>
              </ul>
            </div>

            <div className="contact-block">
              <div className="contact-left">
                {/* <h5>Form Title 1</h5> */}
                <ContactUsInfo/>
              </div>

              <div className="or-text">OR</div>

              {/* <div className="contact-right">
                <BookingCalendar setValue={setValue} />
              </div> */}

              <div className="contact-right">
                {/* <h5>Form Title 2</h5> */}
                <iframe
                  src="https://api.leadconnectorhq.com/widget/booking/TwTsCBXeeA81wNR3uicA"
                  title="Booking Widget"
                  style={{
                    width: "100%",
                    height: "100%",
                    border: "none",
                    overflow: "hidden"
                  }}
                  allowFullScreen
                ></iframe>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;

import React from "react";
import { Link, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { user_type } from "../utils/constant";
import Swal from "sweetalert2";

export default function CoolbizAuthNavbar({ navToggle, setNavToggle }) {
  const { logout, user } = useAuth();
  const location = useLocation()
  const FCMTOKEN=localStorage.getItem("FcmToken")
  const handleCloseNav = (type='') => {
    setNavToggle(false); // Close the side menu
    (user?.subscriptionStatus === 0 && user_type[user?.userType] != "super_admin") && type!="logout" && Swal.fire(`Activate Your Free Trial! To start your 14-day free trial, simply choose a subscription plan and add your card details. Once done, you'll be able to access the menu on the left side panel. Start now!`)
  };
  const isActive = (path) => location.pathname === path;
  return (
    <div className={navToggle ? "dashboard-left open-nav" : "dashboard-left"}>
      <button className="dashboard-nav-close" onClick={handleCloseNav}>
        <img src="/images/cross-icon-w.png" alt="cross-icon-w" />
      </button>
      <figure className="logo">
        <Link to="/dashboard" onClick={handleCloseNav}>
          <img src="/images/coobiz-logo.svg" alt="logo" />
        </Link>
      </figure>
      <header>
        <Link to="/profile" onClick={handleCloseNav}>
          <figure>
            <img
              src={!!user?.fileLink ? user?.fileLink : "/images/images-100.png"}
              alt="logo"
            />
          </figure>
        </Link>
        <aside>
          <h6>{user?.fullName ?? ""}</h6>
          <small>
            <a href={`mailto:${user?.email ?? ""}`}>{user?.email ?? ""}</a>
          </small>
        </aside>
      </header>

      <menu>
        <h2>Menu</h2>
        <ul>
          <li className={isActive(user_type[user?.userType] === "super_admin" ? "/admin/dashboard" : "/dashboard") ? "active" : ""}>
            <Link
              to={
                user_type[user?.userType] === "super_admin"
                  ? "/admin/dashboard"
                  : user?.subscriptionStatus === 0 ? "/plan-list" : "/dashboard"
              }
              onClick={handleCloseNav}
            >
              <img src="/images/icons/menu-dashboard.svg" alt="" />
              <img src="/images/icons/menu-dashboard-hov.svg" alt="" />
              Dashboard
            </Link>
          </li>
          <li className={isActive(user_type[user?.userType] === "super_admin" ? "/admin/my-chatbots" : "/my-chatbots") ? "active" : ""}>
            <Link
              to={
                user_type[user?.userType] === "super_admin"
                  ? "/admin/my-chatbots"
                  : user?.subscriptionStatus === 0 ? "/plan-list" : "/my-chatbots"
              }
              onClick={handleCloseNav}
            >
              <img src="/images/icons/menu-my-chatbots.svg" alt="" />
              <img src="/images/icons/menu-my-chatbots-hov.svg" alt="" />
              My chatbots
            </Link>
          </li>
          <li className={isActive(user_type[user?.userType] === "super_admin" ? "/admin/analytics" : "/analytics") ? "active" : ""}>
            <Link
              to={
                user_type[user?.userType] === "super_admin"
                  ? "/admin/analytics"
                  : user?.subscriptionStatus === 0 ? "/plan-list" : "/analytics"
              }
              onClick={handleCloseNav}
            >
              <img src="/images/icons/menu-chatbots-analytics.svg" alt="" />
              <img src="/images/icons/menu-chatbots-analytics-hov.svg" alt="" />
              Chatbot Analytics
            </Link>
          </li>
          <li className={isActive(user_type[user?.userType] === "super_admin" ? "/admin/chat-history" : "/chat-history") ? "active" : ""}>
            <Link
              to={
                user_type[user?.userType] === "super_admin"
                  ? "/admin/chat-history"
                  : user?.subscriptionStatus === 0 ? "/plan-list" : "/chat-history"
              }
              onClick={handleCloseNav}
            >
              <img src="/images/icons/menu-chat-history.svg" alt="" />
              <img src="/images/icons/menu-chat-history-hov.svg" alt="" />
              Chat History
            </Link>
          </li>
          {user_type[user?.userType] === "user" ? (
            <li className={isActive("/my-plan") ? "active" : ""}>
              <Link to={user?.subscriptionStatus === 0 ? "/plan-list" : "/my-plan"} onClick={handleCloseNav}>
                <img src="/images/icons/menu-my-subscription.svg" alt="" />
                <img src="/images/icons/menu-my-subscription-hov.svg" alt="" />
                My Subscription
              </Link>
            </li>
          ) : null}
          <li className={isActive("/settings") ? "active" : ""}>
            <Link to={user_type[user?.userType] === "super_admin" ? "/admin/settings" : user?.subscriptionStatus === 0 ? "/plan-list" : "/settings"} onClick={handleCloseNav}>
              <img src="/images/icons/menu-setting.svg" alt="" />
              <img src="/images/icons/menu-setting-hov.svg" alt="" />
              Settings
            </Link>
          </li>
          {user_type[user?.userType] === "super_admin" ? (
            <>
              <li className={isActive("/admin/role") ? "active" : ""}>
                <Link to="/admin/role" onClick={handleCloseNav}>
                  <img src="/images/icons/menu-customer-management.svg" alt="" />
                  <img src="/images/icons/menu-customer-management-hov.svg" alt="" />
                  Customer Management
                </Link>
              </li>
              <li className={isActive("/admin/subscription-management") ? "active" : ""}>
                <Link
                  to="/admin/subscription-management"
                  onClick={handleCloseNav}
                >
                  <img src="/images/icons/menu-customer-subscription-details.svg" alt="" />
                  <img src="/images/icons/menu-customer-subscription-details-hov.svg" alt="" />
                  Customer Subscription Details
                </Link>
              </li>
              <li className={isActive("/admin/reveneu-report") ? "active" : ""}>
                <Link to="/admin/reveneu-report" onClick={handleCloseNav}>
                  <img src="/images/icons/menu-reveneu-report.svg" alt="" />
                  <img src="/images/icons/menu-reveneu-report-hov.svg" alt="" />
                  Revenue Report
                </Link>
              </li>
              <li className={isActive("/admin/promocode-management") ? "active" : ""}>
                <Link to="/admin/promocode-management" onClick={handleCloseNav}>
                  <img src="/images/icons/menu-promocode.svg" alt="" />
                  <img src="/images/icons/menu-promocode-hov.svg" alt="" />
                  Promo Code Management
                </Link>
              </li>
              <li className={isActive("/admin/plan") ? "active" : ""}>
                <Link to="/admin/plan" onClick={handleCloseNav}>
                  <img src="/images/icons/menu-plan-management.svg" alt="" />
                  <img src="/images/icons/menu-plan-management-hov.svg" alt="" />
                  Plan Management
                </Link>
              </li>
              <li className={isActive("/admin/cms") ? "active" : ""}>
                <Link to="/admin/cms" onClick={handleCloseNav}>
                  <img src="/images/icons/menu-informative-content-management.svg" alt="" />
                  <img src="/images/icons/menu-informative-content-management-hov.svg" alt="" />
                  Informative Content Management
                </Link>
              </li>
            </>
          ) : null}
        </ul>
      </menu>

      <footer>
        <ul>
       {user_type[user?.userType] === "user" ?  <li className={isActive("/feedback") ? "active" : ""}>
            <Link to="/feedback" onClick={handleCloseNav}>
              <img src="/images/icons/menu-feedback.svg" alt="" />
              <img src="/images/icons/menu-feedback-hov.svg" alt="" />
              Feedback
            </Link>
          </li>:null}
          <li className={isActive("/help") ? "active" : ""}>
            <Link to="/help" onClick={handleCloseNav}>
              <img src="/images/icons/menu-help.svg" alt="" />
              <img src="/images/icons/menu-help-hov.svg" alt="" />
              Help
            </Link>
          </li>
          <li>
            <Link
              to="#"
              onClick={() => {
                logout({firebaseToken:FCMTOKEN},user?.userType);
                handleCloseNav("logout");
              }}
            >
              <img src="/images/icons/menu-logout.svg" alt="" />
              <img src="/images/icons/menu-logout-hov.svg" alt="" />
              Log Out
            </Link>
          </li>
        </ul>
      </footer>
    </div>
  );
}

import React, { useState, useRef } from "react";

const TableSearchFilter = ({ columns, defaultColumn, onFilter ,placeholderForSearch="",showFilterBy=true}) => {
  const [searchValue, setSearchValue] = useState("");
  const [selectedColumn, setSelectedColumn] = useState(defaultColumn); // Default selected column
  const debounceTimeout = useRef(null);

  // Local debounce implementation
  const debounce = (callback, delay) => {
    return (...args) => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      debounceTimeout.current = setTimeout(() => {
        callback(...args);
      }, delay);
    };
  };

  const debouncedFilter = debounce((value, column) => {
    onFilter(value, column);
  }, 300); // Adjust debounce delay (300ms)

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    debouncedFilter(value, selectedColumn);
  };

  const handleColumnChange = (e) => {
    const column = e.target.value;
    setSelectedColumn(column);
    // Trigger filter immediately on column change
    debouncedFilter(searchValue, column);
  };

  return (
    <div className="search-select-filter-wrap d-md-flex align-items-center">
      
      <div className="searchbox-filter">
        {/* Search Field */}
        <input
          type="text"
          placeholder={placeholderForSearch?placeholderForSearch:"Search..."}
          value={searchValue}
          onChange={handleSearch} 
          className="form-control w-180"
        />      
      </div>

     {showFilterBy&& <div class="selecFilterBy">
        {/* Dropdown for Column Selection */}
        <span>Filter By</span>
        <select
          value={selectedColumn}
          onChange={handleColumnChange}
          className="form-select w-140"
        >
          {columns.map((column) => (
            <option key={column} value={column}>
              {column}
            </option>
          ))}
        </select>
      </div>}

    </div>

    
  );
};

export default TableSearchFilter;

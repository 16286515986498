const removeSearchParams = () => {
    // Get the current URL
    const currentUrl = new URL(window.location.href);

    // Clear all search parameters
    currentUrl.search = '';

    // Update the URL without reloading the page
    window.history.replaceState({}, '', currentUrl.toString());
  };

  export default removeSearchParams;
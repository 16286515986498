import React, { useEffect, useState } from "react";
import { CallAPI } from "../../utils/apiActions";
import {
  BOT_LIST_WITH_STATUS,
  CHAT_HISTORY_LIST,
  CHAT_HISTORY_VIEW,
} from "../../utils/APIEndPoints";
import moment from "moment";
import useAuth from "../../hooks/useAuth";
import { paginationObj } from "../../utils/constant";
import { Pagination } from "@mui/material";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from "dayjs";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";

export default function ChatHistoryComponent() {
  const { user } = useAuth();
  const [chatHistoryList, setChatHistoryList] = useState(null);
  const [chatHistoryDetails, setChatHistoryDetails] = useState(null);
  const [chatListModal, setChatListModal] = useState({
    status: false,
    name: "",
  });
  const [paginationData, setPaginationData] = useState({
    totalRecords: 0,
    currentPage: 1,
    totalPages: 0,
  });
  const [chatbots, setChatbots] = useState([]);
  const [loader, setLoader] = useState(true);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedBot, setSelectedBot] = useState(0);

  useEffect(() => {
    getChatHistoryList();
  }, [paginationData.currentPage, selectedDate, selectedTime, selectedBot]);

  useEffect(() => {
    if (chatListModal.status) getChatDetails();
  }, [chatListModal.status]);

  useEffect(() => {
    fetchBotWithStatus();
  }, []);

  const fetchBotWithStatus = async () => {
    const res = await CallAPI("BOT", "GET", BOT_LIST_WITH_STATUS);
    setChatbots(res?.res?.data?.data?.data || []);
  };

  const getChatDetails = async () => {
    const response = await CallAPI(
      "CHAT",
      "GET",
      `${CHAT_HISTORY_VIEW}?id=${chatListModal.status}`
    );
    setChatHistoryDetails(response?.res?.data?.chat_details);
    setLoader(false);
  };

  const getChatHistoryList = async () => {
    let URL = `${CHAT_HISTORY_LIST}?page=${paginationData.currentPage}&pageSize=${paginationObj.item_per_page}`
    if (selectedDate) {
      URL = `${URL}&date=${moment(new Date(selectedDate)).format("YYYY-MM-DD")}`;
    }
    if (selectedTime) {
      URL = `${URL}&time=${moment(new Date(selectedTime)).format("HH:mm:ss")}`;
    }
    if (!!selectedBot) {
      URL = `${URL}&botID=${selectedBot}`;
    }
    if (user.userType !== 1) {
      URL = `${URL}&userUuid=${user.userUuid}`;
    }
    const response = await CallAPI("CHAT", "GET", URL);
    setPaginationData({
      ...paginationData,
      totalRecords: response?.res?.data?.data?.totalRecords,
      totalPages: Math.ceil(
        response?.res?.data?.data?.totalRecords / paginationObj.item_per_page
      ),
    });
    setChatHistoryList(response?.res?.data?.data?.chatList);
    setLoader(false);
  };

  const handlePaginationChange = (event, value) => {
    setPaginationData((prev) => ({ ...prev, currentPage: value }));
  };

  return (
    <div>
      {!!chatListModal.status && (
        <div className="modalOverlay modal-open">
          <div className="modal-container width-sm modal-dark-bg">
            <a
              href="javascript:void(0)"
              className="modalClose"
              onClick={() => setChatListModal({ status: false, name: "" })}
            >
              <img src="/images/icons/modal-close-icon.svg" alt="" />
            </a>
            <div className="modal-form-grid pt-3">
              <form className="form-step-2 text-start">
                {loader && chatListModal.status ? (
                  <i className="fa-solid fa-spinner fa-spin"></i>
                ) : (
                  <>
                    <h3>Chat Details ({chatListModal.name})</h3>
                    <div className="field">
                      <div className="chatbot-history-wrap">
                        <ul>
                          {chatHistoryDetails?.length > 0 &&
                            chatHistoryDetails.map((val, ind) => (
                              <React.Fragment key={ind}>
                                <li className="userChat-wrap">
                                  <figure>
                                    <img
                                      src="/images/icons/menu-my-chatbots.svg"
                                      alt=""
                                    />
                                  </figure>
                                  <article>
                                    <div className="userChatText">
                                      {val.bot.question}
                                      {val.bot?.options && (
                                        <ul className="chatflow-option">
                                          {val.bot.options.map(
                                            (option, ind) => (
                                              <span key={ind}>
                                                {val.bot?.type ===
                                                  "single-choice"
                                                  ? option.label
                                                  : option}
                                              </span>
                                            )
                                          )}
                                        </ul>
                                      )}
                                    </div>
                                    <div className="chatTime">
                                      {moment
                                        .utc(val.created_at)
                                        .local()
                                        .format("hh:mm A")}
                                    </div>
                                  </article>
                                </li>
                                {!!val.user.replay && (
                                  <li className="clientChat-wrap">
                                    <figure>
                                      <img
                                        src="/images/images-100.png"
                                        alt="images-100"
                                      />
                                    </figure>
                                    <article>
                                      <div className="clientChatText">
                                        {val.user.replay}
                                      </div>
                                      <div className="chatTime">
                                        {moment
                                          .utc(val.created_at)
                                          .local()
                                          .format("hh:mm A")}
                                      </div>
                                    </article>
                                  </li>
                                )}
                              </React.Fragment>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      )}
      <div className="chatbot-history-grid">
        <header className="d-md-flex align-items-center justify-content-between">
          <h3>My Chat History</h3>

          <div className="group-form d-md-flex">
            <LocalizationProvider dateAdapter={AdapterDayjs} >
              <DemoContainer components={['DatePicker']} >
                <DatePicker
                  className="dateAbc"
                  label="Choose date"
                  value={!!selectedDate ? dayjs(selectedDate) : null}
                  maxDate={dayjs(new Date())}
                  onAccept={(value) => {setSelectedDate(value); setPaginationData({...paginationData, currentPage: 1})}}
                  slotProps={{
                    field: { clearable: true, onClear: () => setSelectedDate(null) },
                  }} />
              </DemoContainer>
              <DemoContainer components={['TimePicker']}>
                <TimePicker
                  className="timeAbc"
                  label="Choose time"
                  value={selectedTime}
                  onAccept={(value) => {setSelectedTime(value); setPaginationData({...paginationData, currentPage: 1})}}
                  slotProps={{
                    field: { clearable: true, onClear: () => setSelectedTime(null) },
                  }} />
              </DemoContainer>
            </LocalizationProvider>
            <select className="form-select w-200" onChange={(e) => setSelectedBot(e.target.value)}>
              <option value={0} selected="">Chatbot: All</option>
              {chatbots?.length &&
                chatbots.map((bot, ind) => (
                  <option key={ind} value={bot.bot_id}>
                    {bot?.bot_name}
                  </option>
                ))}
            </select>
          </div>
        </header>

        {chatHistoryList?.length === 0 ? (
          "No data available"
        ) : (
          <div className="table-responsive">
            <table className="table align-middle">
              <thead>
                <tr>
                  <th className="text-left">Session ID / Name</th>
                  <th className="text-center">Date</th>
                  <th className="text-center">Time</th>
                  <th className="text-center">Chatbot</th>
                  <th className="text-center action">Action</th>
                </tr>
              </thead>
              {loader && chatListModal.status === false ? (
                <div className="page-loader">
                  <img src="/images/loader-bouncing-circles.svg" alt="" />
                </div>
              ) : (
                <tbody>
                  {chatHistoryList?.length > 0 &&
                    chatHistoryList.map((val, ind) => (
                      <tr key={ind}>
                        <td className="text-left">{val.username || val.id}</td>
                        <td className="text-center">
                          {moment
                            .utc(val.created_at)
                            .local()
                            .format("DD MMM YYYY")}
                        </td>
                        <td className="text-center">
                          {moment.utc(val.created_at).local().format("hh:mm A")}
                        </td>
                        <td className="text-center">{val.bot_name}</td>
                        <td className="text-center action">
                          <a
                            href="javascript:void(0)"
                            onClick={() => {
                              setChatListModal({
                                ...chatListModal,
                                status: val.id,
                                name: val.username || val.id,
                              });
                              setLoader(true);
                            }}
                            title="View Details"
                            className="mx-1"
                          >
                            <i className="fa-regular fa-eye" />
                          </a>
                        </td>
                      </tr>
                    ))}
                </tbody>
              )}
            </table>

            <div className="pagination_wrap">
              <div className="pagination-info">
                Showing {(paginationData.currentPage - 1) * 10 + 1} to{" "}
                {Math.min(
                  paginationData.currentPage * 10,
                  paginationData.totalRecords
                )}{" "}
                of {paginationData.totalRecords} entries
              </div>
              <div className="pagination-table">
                <Pagination
                  count={paginationData.totalPages}
                  page={paginationData.currentPage}
                  onChange={handlePaginationChange}
                  color="secondary"
                  shape="rounded"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

import React, { useEffect, useState } from 'react'
import BotWorkflowBuilder from './BotWorkflowBuilder';
import BotQuestionArea from './BotQuestionArea';
import { BOT_QUESTION } from '../../utils/APIEndPoints';
import { useLocation } from 'react-router-dom';
import { CallAPI } from '../../utils/apiActions';
import { showToast } from '../../helper/toastHelper';
import { LABEL } from '../../utils/constant';

export default function BotBuilder({ ...props }) {

    const [testBotPreparation, setTestBotPreparation] = useState(false);
    const [botCompletedStep, setBotCompletedStep] = useState(0);
    const [viewMode, setViewMode] = useState("Classic Builder")
    const [openBotModal, setOpenBotModal] = useState(false)
    const [isNodeSet, setIsNodeSet] = useState(false)
    const [submissionLoader, setSubmissionLoader] = useState(false)
    const [botImage, setBotImage] = useState(null)
    const [shortCode, setShortCode] = useState(null)
    const location = useLocation();
    const bot_id = (new URLSearchParams(location?.search).get("id")) || null
    const [colorSettingsArray, setColorSettingsArray] = useState([
        { key: "header_bg", lable: "Header Background", color: "#355266", elementClassName: "header-back-color", props: "background-color" },
        { key: "page_bg", lable: "Page Background", color: "#ffffff", elementClassName: "page-back-color", props: "background-color" },
        { key: "bot_tb_color", lable: "Bot Text Box Color", color: "#ffffff", elementClassName: "bot-color-box", props: "background-color" },
        { key: "bot_txt_color", lable: "Bot Text Color", color: "#000000", elementClassName: "bot-text-color", props: "color" },
        { key: "sender_tb_color", lable: "Sender Text box Color", color: "#ffffff", elementClassName: "sender-color-box", props: "background-color" },
        { key: "sender_txt_color", lable: "Sender Text Color", color: "#000000", elementClassName: "sender-text-color", props: "color" },
        { key: "link_color", lable: "Link Color", color: "#3498db", elementClassName: "link-color", props: "color" },
        { key: "btn_txt_color", lable: "Icon Color", color: "#212529bf", elementClassName: "button-text-color", props: "color" },
        { key: "option_tb_color", lable: "Option Button Color", color: "#3498db", elementClassName: "option-color", props: "background" },
        { key: "option_txt_color", lable: "Option Text Color", color: "#ffffff", elementClassName: "option-color", props: "color" },
        { key: "footer_bg", lable: "Footer Background", color: "#ffffff", elementClassName: "footer-back-color", props: "background-color" }
    ]);
    const [selectedTemplate, setSelectedTemplate] = useState("1")
    const [dataLoader, setDataLoader] = useState(true)

    useEffect(() => {
        getBotQuestionBuilder()
    }, []);

    useEffect(() => {
        console.log("colorSettingsArray--", colorSettingsArray)
        if (colorSettingsArray?.length > 0) {
            colorSettingsArray.map((settings, index) => {
                const elements = document.querySelectorAll(`.${settings.elementClassName}`);
                elements.forEach((el) => {
                    el.style.setProperty(settings.props, settings.color, 'important');
                });
            })
        }
    }, [openBotModal]);

    const getBotQuestionBuilder = async () => {
        let URL = `${BOT_QUESTION}/${bot_id}`
        const response = await CallAPI("BOT", "GET", URL);
        console.log("response bot builder--", `template#${response?.res.data?.data[0]?.template_id}`)
        if (!!response.status) {
            setSelectedTemplate(`template#${response?.res.data?.data[0]?.template_id}`)
            setBotImage(response?.res.data?.data[0]?.bot_image || "")
            setShortCode(response?.res.data?.data[0]?.shorcode || "")
            props.setFinalStep(response?.res.data?.data[0]?.completedStep || 2)
            setBotCompletedStep(response?.res.data?.data[0]?.completedStep)
            setIsNodeSet(true)
            let styleArr = [...colorSettingsArray]
            let styleObj = response?.res.data?.data[0]?.styles || JSON.parse(localStorage.getItem("botStyle"))
            setDataLoader(false)
            console.log("styleObj--", styleObj)
            for (let [key, value] of Object.entries(styleObj)) {
                var foundIndex = styleArr.findIndex(x => x.key == key);
                if (foundIndex != -1) styleArr[foundIndex].color = value;
            }
            setColorSettingsArray(styleArr)
            response?.res.data?.data[0]?.botWorkFlowNode && localStorage.setItem("botWorkFlowNode", JSON.stringify(response?.res.data.data[0]?.botWorkFlowNode))
            response?.res.data?.data[0]?.botWorkFlowEdge && localStorage.setItem("botWorkFlowEdge", JSON.stringify(response?.res.data.data[0]?.botWorkFlowEdge))
            response?.res.data?.data[0]?.questionArray && localStorage.setItem("chatElement", JSON.stringify(response?.res.data.data[0]?.questionArray))
            response?.res.data?.data[0]?.chatNode && localStorage.setItem("chatNode", JSON.stringify(response?.res.data.data[0]?.chatNode))
        }
    }

    const handleSaveStep3 = async (type = "") => {
        if (type != "bot-testing") {
            setSubmissionLoader(true)
        }
        setOpenBotModal(false)
        let sourceNodeArr = handleGetSourceNode()
        if (sourceNodeArr?.length > 1) {
            showToast("Please build all the element connections to test the chat flow.", LABEL.TOAST_TYPE.ERROR);
        }
        else {
            let body = {}
            body.bot_id = bot_id
            body.step = 3
            body.botWorkFlowEdge = JSON.parse(localStorage.getItem("botWorkFlowEdge"))
            body.botWorkFlowNode = JSON.parse(localStorage.getItem("botWorkFlowNode"))
            console.log("issue on getting", JSON.parse(localStorage.getItem("botWorkFlowNode")))
            body.questionArray = JSON.parse(localStorage.getItem("chatElement"))
            body.chatNode = JSON.parse(localStorage.getItem("chatNode"))
            console.log("body check----", body)
            const response = await CallAPI("BOT", "POST", BOT_QUESTION, body)
            console.log("question save response---", response)
            if (type != "bot-testing") {
                if (response.status) {
                    props.setFinalStep(3)
                    props.handleSetTab("4")
                    showToast(response.msg,
                        LABEL.TOAST_TYPE.SUCCESS
                    );
                    localStorage.removeItem("botWorkFlowNode")
                    localStorage.removeItem("botWorkFlowEdge")
                    localStorage.removeItem("chatElement")
                    localStorage.removeItem("chatNode")
                }
                else {
                    showToast(response.msg,
                        LABEL.TOAST_TYPE.ERROR
                    );
                }
            }
            else {
                setOpenBotModal(true)
                setTestBotPreparation(false)
            }
        }
        setSubmissionLoader(false)
    }

    const handleGetSourceNode = () => {
        let botWorkFlowEdge = localStorage.getItem("botWorkFlowEdge") ? JSON.parse(localStorage.getItem("botWorkFlowEdge")) : []
        if (botWorkFlowEdge.length > 0) {
            const sources = botWorkFlowEdge.filter((obj, index, arr) =>
                !obj.source.includes("#") && !arr.some(item => item.target === obj.source)
            );
            const uniqueSources = sources.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.source === value.source
                ))
            );
            return uniqueSources
        }
        else {
            const uniqueSources = [{ source: 1 }]
            return uniqueSources
        }
    }

    return (
        <div>
            <div className="mychatbot-grid">
                <div className="tab-content-grid">
                    <div className="tab-content active">
                        <div className="chatbot-history-grid">
                            {viewMode == "Classic Builder"
                                ? <BotQuestionArea
                                    setTestBotPreparation={setTestBotPreparation}
                                    testBotPreparation={testBotPreparation}
                                    dataLoader={dataLoader}
                                    setViewMode={setViewMode}
                                    openBotModal={openBotModal}
                                    setOpenBotModal={setOpenBotModal}
                                    selectedTemplate={selectedTemplate}
                                    handleSaveStep3={handleSaveStep3}
                                    handleGetSourceNode={handleGetSourceNode}
                                    submissionLoader={submissionLoader}
                                    colorSettingsArray={colorSettingsArray}
                                    isNodeSet={isNodeSet}
                                    botIcon={botImage}
                                    bot_id={bot_id}
                                    shortCode={shortCode} 
                                    botCompletedStep={botCompletedStep} />
                                : <BotWorkflowBuilder
                                    setTestBotPreparation={setTestBotPreparation}
                                    testBotPreparation={testBotPreparation}
                                    setViewMode={setViewMode}
                                    openBotModal={openBotModal}
                                    setOpenBotModal={setOpenBotModal}
                                    selectedTemplate={selectedTemplate}
                                    handleSaveStep3={handleSaveStep3}
                                    handleGetSourceNode={handleGetSourceNode}
                                    submissionLoader={submissionLoader}
                                    colorSettingsArray={colorSettingsArray}
                                    botIcon={botImage}
                                    bot_id={bot_id}
                                    shortCode={shortCode} 
                                    botCompletedStep={botCompletedStep} />}
                        </div>
                    </div>
                </div>
            </div>

        </div>


    )
}
import axios from "axios";
import { showToast } from "../helper/toastHelper";
import { LABEL } from "./constant";

export function CallAPI(end_point, method, apiUrl, body = '', isFile,showDefaultError=true) {
  let baseURL = handleGetEndPoint(end_point)
  let url = `${baseURL}${apiUrl}`
  let header = { headers: { 'Content-Type': isFile === "file" ? 'multipart/form-data' : 'application/json' } }
  const token = localStorage.getItem('accessToken');
// const token= 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjEsInByb21vQ29kZSI6IiIsInVzZXJVdWlkIjoiODRjZDFhY2EtM2UwYy00ZDhkLTk1YjItNTBhOWM3NzQ0NmRhIiwiZnVsbE5hbWUiOiJDb29sQml6IiwiZW1haWwiOiJjb29sYml6LmFkbWluQHlvcG1haWwuY29tIiwicGhvbmVOdW1iZXIiOiIrOTE5Njg1NzQ5Njg1Iiwib3RwIjpudWxsLCJvdHBUcnlDb3VudCI6MCwib3RwSXNWYWxpZGF0ZSI6ZmFsc2UsIm90cElzRXhwaXJlZCI6ZmFsc2UsIm90cE1vZGlmaWNhdGlvblRpbWUiOm51bGwsInJvbGUiOjIsInN1YnNjcmlwdGlvbiI6MCwic3RhdHVzIjoxLCJpc0RlbGV0ZWQiOjAsImRlbGV0ZWRCeSI6bnVsbCwiaXNUcmlhbCI6MSwib3JnYW5pemF0aW9uTmFtZSI6Ik1vemlsYSBMTEMiLCJidXNpbmVzc1VybCI6Imh0dHBzOi8vYm90LmNvb2xiaXouaW5mbyIsInBsYW5JZCI6MjksInN1YnNjcmlwdGlvblN0YXR1cyI6MSwic3Vic2NyaXB0aW9uSWQiOjEzLCJzdHJpcGVTZXNzaW9uSWQiOiJjc190ZXN0X2ExSlZ2Zmd1YUlzSXpiaFdROGc5OWhPeTR6alRFbHFLbFhpVWN2QUhtY3djSUNKM1VyUjY3bG82U2ciLCJjdXN0b21lcklkIjoiY3VzX1JIWEpnTVdkNDIzQ09PIiwic3Vic2NyaXB0aW9uU3RhcnREYXRlIjoiMjAyNC0xMS0yNVQwOTowNjoxNC4wMDBaIiwic3Vic2NyaXB0aW9uRW5kRGF0ZSI6IjIwMjQtMTItMDlUMDA6MDA6MDAuMDAwWiIsImNyZWF0ZWRBdCI6IjIwMjQtMTEtMjVUMDk6MDY6MzkuMDAwWiIsInVwZGF0ZWRBdCI6IjIwMjQtMTEtMjVUMDk6MDY6MzkuMDAwWiIsImRlbGV0ZWRBdCI6bnVsbCwiaXNVc2VkUHJvbW9Db2RlIjowLCJmaWxlTGluayI6bnVsbCwidXNlcklkIjoyMSwiaWF0IjoxNzMyNzc5MjExLCJleHAiOjE3MzI4MTUyMTF9.Hl3imNJZWGOCxl7piSzW5eN8iLgP5TdjcsxPyjbDNI-_vNcPW18_y9Qh5PTrqfzslUiFe2tYGzDpc7REK6FtFPrRQBbSiSiFyczyoZeGQvwAeN6MlwJGWAtEH9AJ_IIhyLpAPnzSikRFA-yn2OHuIuia6JlYFNz1Sp46sKlXTPmO11CMZlYrHZFuPdv4SLDQ8w8qjs6q-3HWUB_JwgcaOEGMy-PfHaNreHjwyMxcIGiYSh8M2uF93a2e73iAofmU_BVGDhLS4B7y0jK6UOWbPOsnOXAYhc2NbNc4k16qin4-NkFkGmCGyZzPeWbjhLErL67e3iZV1UF0BcRsOAKBxQ';

  // if (Cookie.getCookie('_tokenAuth')) {
  //   header = {
  //     headers: { 'Authorization': `Bearer ${Cookie.getCookie('_tokenAuth')}`, 'Content-Type': 'application/json' }
  //   }
  // }

  if (token) {
    header = {
      headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': isFile === "file" ? 'multipart/form-data' : 'application/json' }
    }
  }

  return new Promise(async (resolve, reject) => {
    try {
      if (isFile == "link") {
        const file = await fetch(url, header)
          .then((res) => res.blob())
          .then((data) => new File([data], "invoice", { type: data.type }));
        resolve({
          status: true,
          file,
        });
      }
      else {
        if (method === "POST") {
          axios
            .post(url, body, header)
            .then((response) => {
              console.log("response POST--", response)
              if (response.data.status || response.data.success) {
                resolve({ status: true, res: response, msg: response?.data?.msg || response?.data?.message });
              } else {
                resolve({ status: false, res: response, msg: response?.data?.msg || response?.data?.message });
              }
            })
            .catch((err) => {
              errHandling(err,showDefaultError)
              console.log("err POST--", err)
              resolve({ status: false, msg: err?.response?.data?.message })
            });

        }
        if (method === "PUT") {
          axios
            .put(url, body, header)
            .then((response) => {
              console.log("response PUT--", response)
              if (response.data.status) {
                resolve({ status: true, res: response, msg: response?.data?.msg || response?.data?.message });
              } else {
                resolve({ status: false, res: response, msg: response?.data?.msg || response?.data?.message});
              }
            })
            .catch((err) => {
              errHandling(err,showDefaultError)
              console.log("err PUT--", err)
              resolve({ status: false, msg: err?.response?.data?.message })
            });
        }
        if (method === "PATCH") {
          axios
            .patch(url, body, header)
            .then((response) => {
              console.log("response PATCH--", response)
              if (response.data.status) {
                resolve({ status: true, res: response, msg: response?.data?.msg || response?.data?.message});
              } else {
                resolve({ status: false, res: response, msg: response?.data?.msg || response?.data?.message});
              }
            })
            .catch((err) => {
              errHandling(err,showDefaultError)
              console.log("err PATCH--", err)
              resolve({ status: false, msg: err?.response?.data?.message })
            });
        }
        if (method === "GET") {
          axios
            .get(url, header)
            .then((response) => {
              console.log("response GET--", response)
              if (response.data.status) {
                resolve({ status: true, res: response, msg: response?.data?.msg || response?.data?.message});
              } else {
                resolve({ status: false, res: response, msg: response?.data?.msg || response?.data?.message});
              }
            })
            .catch((err) => {
              errHandling(err,showDefaultError)
              console.log("err GET--", err)
              resolve({ status: false, msg: err?.response?.data?.message })
            });
        }
        if (method === "DELETE") {
          if (body) {
            header = { ...header, data: body }
          }
          axios
            .delete(url, header)
            .then((response) => {
              console.log("response DELETE--", response)
              if (response.data.status) {
                resolve({ status: true, res: response, msg: response?.data?.msg || response?.data?.message});
              } else {
                resolve({ status: false, res: response, msg: response?.data?.msg || response?.data?.message});
              }
            })
            .catch((err) => {
              errHandling(err,showDefaultError)
              console.log("err DELETE--", err)
              resolve({ status: false, msg: err?.response?.data?.message })
            });
        }
      }
    } catch (err) {
      reject(err.response);
    }
  });
}

function handleGetEndPoint(end_point) {
  switch (end_point) {
    case 'BOT': {
      return process.env.REACT_APP_BOT_API_END_POINT
    }
    case 'USER': {
      return process.env.REACT_APP_USER_API_END_POINT
    }
    case 'WEBSITE': {
      return process.env.REACT_APP_BOT_API_END_POINT
    }
    case 'CHAT': {
      return process.env.REACT_APP_CHAT_BASE_URL
    }
    // case 'PRODUCT': {
    //   return process.env.REACT_APP_BOT_API_END_POINT
    // }
    // default: {
    //   return process.env.REACT_APP_BOT_API_END_POINT
    // }
  }
}

const errStatusCode = [401];

function errHandling(err,showDefaultError=true) {
  if (errStatusCode.includes(err.status)) {
    localStorage.removeItem('accessToken');
    localStorage.removeItem("botModification")
    localStorage.removeItem("botStyle")
    localStorage.removeItem("botWorkFlowEdge")
    localStorage.removeItem("botWorkFlowNode")
    localStorage.removeItem("chatElement")
    localStorage.removeItem("chatNode")
    setTimeout(() => {
      window.location.reload()
    }, 2000)
  }
  console.log(err)
  showDefaultError && showToast(err?.response?.data?.message || err?.response?.data?.msg || err?.response?.data?.error?.message && err?.response?.data?.error?.message[0] || "Somthing went wrong", LABEL.TOAST_TYPE.ERROR);
}
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { CallAPI } from '../../utils/apiActions';
import { BOT_LISTS } from '../../utils/APIEndPoints';
import useAuth from '../../hooks/useAuth';
import { user_type } from '../../utils/constant';

export default function MyChatboat() {
  const { user } = useAuth();
  const [botList, setBotList] = useState(null)
  const [loader, setLoader] = useState(false)
  const navigate = useNavigate();
  const botSettingsPath = user_type[user?.userType] == "super_admin" ? '/admin/bot-settings' : '/bot-settings';

  useEffect(() => {
    getAllBotList()
  }, []);

  const getAllBotList = async () => {
    let response = await CallAPI("BOT", "GET", `${BOT_LISTS}?pageSize=20`)
    console.log("response 1111---", response)
    setBotList(response?.res.data?.data?.data)
    // setBotList([])
  };

  const handleSelectChatBot = (bot) => {
    localStorage.removeItem("botWorkFlowNode")
    localStorage.removeItem("botWorkFlowEdge")
    localStorage.removeItem("chatElement")
    localStorage.removeItem("chatNode")
    navigate(`${botSettingsPath}?tab=1&id=${bot.bot_id}&temp=${bot.template_id}&botname=${bot.bot_name}`)
  }

  return (
    botList == null
      ? <div className="page-loader mychatbot-loader">
        <img src="/images/loader-bouncing-circles.svg" alt="" />
      </div>
      : <div className="mychatbot-grid">
        <header className="d-md-flex align-items-center justify-content-between">
          <h3>My Chatbot</h3>

          <div class="group-form d-md-flex">
            <a href="javascript:void(0)" className="cta-green" onClick={() => navigate(`${botSettingsPath}?tab=1`)}>
              <i className="fa-solid fa-sparkles" aria-hidden="true"></i>
              Create Chatbot Now
            </a>
          </div>
        </header>
        {!!botList && botList?.length > 0
          ? <div className="mychatbot-grid_lists d-flex justify-content-start">
            {botList?.map((bot, ind) => (
              <div className="chatbot-list border-box" onClick={() => handleSelectChatBot(bot)}>
                <figure><img src="/images/chatbot-1.svg" alt="" /></figure>
                <h5>{bot.bot_name ? bot.bot_name : `Chatbot-${ind + 1}`}</h5>
              </div>
            ))}
            {/* <div className="chatbot-list border-box" onClick={() => navigate('/bot-settings?tab=1&id=1')}>
          <figure><img src="/images/chatbot-1.svg" alt="" /></figure>
          <h5>Chatbot - 1</h5>
        </div>
        <div className="chatbot-list border-box" onClick={() => navigate('/bot-settings?tab=1&id=1')}>
          <figure><img src="/images/chatbot-2.svg" alt="" /></figure>
          <h5>Chatbot - 2</h5>
        </div>
        <div className="chatbot-list border-box" onClick={() => navigate('/bot-settings?tab=1&id=1')}>
          <figure><img src="/images/chatbot-3.svg" alt="" /></figure>
          <h5>Chatbot - 3</h5>
        </div> */}
          </div>
          : <div>No bot to show</div>}
      </div>
  )
}

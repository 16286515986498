import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

function Header() {
    const navigate = useNavigate();
    
    return (
        <header className="gbl-header">
            <div className="container">
                <Link className="brand-logo" to="/">
                    <img className="" src="/images/coobiz-logo.svg" alt="Logo" />
                </Link>

                <div className="menu-grid">
                    <nav className="site-nav">
                        <ul className="site-menu">
                            <li className=""><a href="javascript:void(0)" onClick={()=>navigate('/')}>HOME</a></li>
                            <li className=""><a href="javascript:void(0)" onClick={()=>navigate('/features')}>Features</a></li>
                            <li className=""><a href="javascript:void(0)" onClick={()=>navigate('/pricing')}>Pricing</a></li>
                            <li className=""><a href="javascript:void(0)" onClick={()=>navigate('/documentation')}>Documentation</a></li>
                            <li className=""><a href="javascript:void(0)" onClick={()=>navigate('/affiliate')}>Become an affiliate</a></li>
                            <li className=""><a href="javascript:void(0)" onClick={()=>navigate('/contact-us')}>Support</a></li>
                        </ul>
                    </nav>
                    <button className="navToggle">
                        <span className="hamburger-menu">
                            <span className="bar"></span>
                        </span>
                    </button>

                    {/* Mobile Nav Header  */}
                    <nav className="mobnav-grid">
                        <a href="javascript:void(0)" className="mobnav-close navToggle">
                            <img src="/images/cross-icon-w.png" alt="cross-icon-white" />
                        </a>
                        <ul>
                            <li className=""><a href="javascript:void(0)">HOME</a></li>
                            <li className=""><a href="javascript:void(0)">Pricing</a></li>
                            <li className=""><a href="javascript:void(0)">Suppor</a></li>
                            <li className=""><a href="javascript:void(0)">Documentation</a></li>
                            <li className=""><a href="javascript:void(0)">Features</a></li>
                        </ul>
                    </nav>
                </div>

                <div className="group-user-cta">
                    <a href="javascript:void(0)" className="cta-border" onClick={()=>navigate('/login')}>Sign In</a>
                    <a href="javascript:void(0)" className="cta-border" onClick={()=>navigate('/signup')}>Signup Now</a>
                </div>
            </div>
        </header>
    )
}

export default Header

import React, { useCallback, useEffect, useState } from 'react';
import {
  ReactFlow,
  addEdge,
  useNodesState,
  useEdgesState,
  MiniMap,
  Controls,
} from '@xyflow/react';

import '@xyflow/react/dist/style.css';
import CustomEdge from '../../utils/CustomEdge';
import { useNavigate } from 'react-router-dom';
import Templates from './Templates/Templates';
import { showToast } from '../../helper/toastHelper';
import { LABEL } from '../../utils/constant';
import LiveChat from '../live-chat/LiveChat';

const initialNodes = [
  {
    "id": "1",
    "data": {
      "label": "Please select all the options that apply"
    },
    "position": {
      "x": 0,
      "y": 0
    }
  },
  // {
  //   "id": "2",
  //   "data": {
  //     "label": "Welcome to CoolBiz"
  //   },
  //   "position": {
  //     "x": 0,
  //     "y": 100
  //   }
  // },
  // {
  //   "id": "3",
  //   "data": {
  //     "label": "Please type an answer"
  //   },
  //   "position": {
  //     "x": 0,
  //     "y": 200
  //   }
  // },
  // {
  //   "id": "4",
  //   "data": {
  //     "label": "Please select one from the given options"
  //   },
  //   "position": {
  //     "x": 0,
  //     "y": 300
  //   }
  // },
  // {
  //   "id": "4#a",
  //   "data": {
  //     "label": "option 1"
  //   },
  //   "position": {
  //     "x": -200,
  //     "y": 400
  //   }
  // },
  // {
  //   "id": "4#b",
  //   "data": {
  //     "label": "option 2"
  //   },
  //   "position": {
  //     "x": 299,
  //     "y": 400
  //   }
  // },
  // {
  //   "id": "5",
  //   "data": {
  //     "label": "Please provide your email"
  //   },
  //   "position": {
  //     "x": 0,
  //     "y": 500
  //   }
  // },
  // {
  //   "id": "6",
  //   "data": {
  //     "label": "Please provide your phone number"
  //   },
  //   "position": {
  //     "x": 0,
  //     "y": 600
  //   }
  // },
  // {
  //   "id": "7",
  //   "data": {
  //     "label": "Please provide your name"
  //   },
  //   "position": {
  //     "x": 0,
  //     "y": 700
  //   }
  // }
]

const initialEdges = [
  // {
  //   "id": "1->2",
  //   "source": "1",
  //   "target": "2",
  //   "type": "custom-edge",
  //   "questionType": "multi-choice",
  //   "question": "Please select all the options that apply",
  //   "options": [
  //     "option 1",
  //     "option 2"
  //   ]
  // },
  // {
  //   "id": "2->3",
  //   "source": "2",
  //   "target": "3",
  //   "type": "custom-edge",
  //   "questionType": "text-question",
  //   "question": "Welcome to CoolBiz"
  // },
  // {
  //   "id": "3->4",
  //   "source": "3",
  //   "target": "4",
  //   "type": "custom-edge",
  //   "questionType": "text-question",
  //   "question": "Please type an answer"
  // },
  // {
  //   "id": "4->4#a",
  //   "source": "4",
  //   "target": "4#a",
  //   "questionType": "single-choice",
  //   "question": "Please select one from the given options",
  //   "options": [
  //     "option 1",
  //     "option 2"
  //   ]
  // },
  // {
  //   "id": "4#a->5",
  //   "source": "4#a",
  //   "target": "5",
  //   "type": "custom-edge",
  //   "questionType": "single-choice",
  //   "question": "Please select one from the given options",
  //   "options": [
  //     "option 1",
  //     "option 2"
  //   ]
  // },
  // {
  //   "id": "4->4#b",
  //   "source": "4",
  //   "target": "4#b",
  //   "questionType": "single-choice",
  //   "question": "Please select one from the given options",
  //   "options": [
  //     "option 1",
  //     "option 2"
  //   ]
  // },
  // {
  //   "id": "5->6",
  //   "source": "5",
  //   "target": "6",
  //   "type": "custom-edge",
  //   "questionType": "email",
  //   "question": "Please provide your email"
  // },
  // {
  //   "id": "6->7",
  //   "source": "6",
  //   "target": "7",
  //   "type": "custom-edge",
  //   "questionType": "phone",
  //   "question": "Please provide your phone number"
  // },
  // {
  //   "id": "7->8",
  //   "source": "7",
  //   "target": "8",
  //   "type": "custom-edge",
  //   "questionType": "name",
  //   "question": "Please provide your name"
  // }
]

const edgeTypes = {
  'custom-edge': CustomEdge,
};

export default function BotWorkflowBuilder({ ...props }) {
  const navigate = useNavigate();
  const [nodes, setNodes] = useNodesState();
  const [edges, setEdges, onEdgesChange] = useEdgesState();

  useEffect(() => {
    setNodes(JSON.parse(localStorage.getItem("botWorkFlowNode")))
    setEdges(JSON.parse(localStorage.getItem("botWorkFlowEdge")))
    // setNodes(initialNodes)
    // setEdges(initialEdges)
  }, [localStorage.getItem("botWorkFlowNode"), localStorage.getItem("botWorkFlowEdge")]);

  const onConnect = useCallback(
    (connection) => {
      const edge = { ...connection, type: 'custom-edge', id: `${connection.source}->${connection.target}` };
      console.log("edge--", connection, edges, nodes)
      setEdges((eds) => addEdge(edge, eds));
    },
    [setEdges],
  );

  const onNodeDragStop = useCallback((event, node) => {
    console.log('Updated node:', node);  // Inspect the node's updated position
    setNodes(prevNodes =>
      prevNodes.map(n =>
        n.id === node.id ? { ...n, position: node.position } : n
      )
    );
  }, [setNodes]);

  const handleSetClassicBuilder = (type = "") => {
    props?.setOpenBotModal(false)
    localStorage.setItem("botWorkFlowNode", JSON.stringify(nodes))
    localStorage.setItem("botWorkFlowEdge", JSON.stringify(edges))
    if (type == "toggle-view") {
      buildClassicalFlow()
      props.setViewMode("Classic Builder")
    }
  }

  const buildClassicalFlow = () => {
    let sourcePos = props.handleGetSourceNode().map(item => item.source);
    let targetArr = [...sourcePos]
    let sno = 0
    let laEdges = [...edges]
    let laElements = [...JSON.parse(localStorage.getItem("chatNode"))]
    while (laEdges.length > 0 && targetArr.length > 0) {
      console.log("targetArr---", targetArr)
      let sourceArr = [...targetArr]
      targetArr = []
      if (sourceArr.length > 0) {
        sourceArr.map((item, ind) => {
          const elemIndex = laElements.findIndex(elem => elem.id == item)
          if (elemIndex !== -1) {
            laElements[elemIndex].sno = ++sno;
          }
          let identifyEdges = laEdges.filter(edge => edge.source == item)
          if (identifyEdges.length > 0) {
            identifyEdges.map((idenEdge, index) => {
              if (idenEdge.target.includes('#')) {
                let child = laEdges.filter(childEdge => childEdge.source == idenEdge.target)
                if (child?.length > 0) {
                  targetArr.push(child[0].target)
                }
              }
              else {
                targetArr.push(idenEdge.target)
              }
              let idenEdgeind = laEdges.findIndex(edg => edg.id == idenEdge.id)
              laEdges.splice(idenEdgeind, 1);
            }
            )
          }
        })
      }
    }
    laElements.sort((a, b) => a.sno - b.sno);
    let updatedChatFlow = laElements
    updatedChatFlow.forEach(item => {
      item.id = item?.sno?.toString() || ++sno;
    });
    localStorage.setItem("chatNode", JSON.stringify(laElements))
  }

  const handleTestBot = () => {
    props.setTestBotPreparation(true)
    handleSetClassicBuilder("bot-testing")
    let sourceNodeArr = props.handleGetSourceNode()
    console.log("sourceNodeArr---", sourceNodeArr)
    if (sourceNodeArr.length > 1) {
      showToast("Please build all the element connections to test the chat flow.", LABEL.TOAST_TYPE.ERROR);
    }
    else {
      updateWorkFlow("bot-testing")
      localStorage.setItem("chatSource", sourceNodeArr[0].source)
    }
  }

  const updateWorkFlow = async (type = "") => {
    localStorage.setItem("botWorkFlowNode", JSON.stringify(nodes))
    localStorage.setItem("botWorkFlowEdge", JSON.stringify(edges))
    buildClassicalFlow()
    props.handleSaveStep3(type || "visual")
  }

  return (
    <>
      {props.openBotModal &&
        // <Templates
        //   type={"test-bot"}
        //   elementFlow={JSON.parse(localStorage.getItem("chatElement"))}
        //   selectedTemplate={props.selectedTemplate}
        //   flowDirection={JSON.parse(localStorage.getItem("botWorkFlowEdge"))}
        //   setOpenBotModal={props.setOpenBotModal}
        //   colorSettingsArray={props.colorSettingsArray}
        //   botIcon={props.botIcon}
        // />
        // <Templates
        //   type={"live-bot"}
        //   selectedTemplate={props.selectedTemplate}
        //   setOpenBotModal={props.setOpenBotModal}
        //   botBubbleIcon={props.botIcon || "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"}
        //   colorSettingsArray={props.colorSettingsArray}
        //   openBotStyle={{
        //     top: '395px',
        //     left: '1085px',
        //     height: '315px',
        //     width: '220px'
        //   }}
        // />
        <LiveChat
          connectionType={"platform"}
          bot_id={props.bot_id}
          selectedTemplate={props.selectedTemplate}
          setOpenBotModal={props.setOpenBotModal}
          openBotModal={props.openBotModal}
          botBubbleIcon={props.botIcon || "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"}
          colorSettingsArray={props.colorSettingsArray} 
          shortCode={props.shortCode}
          botCompletedStep={props.botCompletedStep}/>}
      <div>
        <div class="modalOverlay modal-open none">
          <div class="modal-container width-sm modal-dark-bg">
            <a href="javascript:void(0)" class="modalClose"><img src="/images/icons/modal-close-icon.svg" alt="" /></a>
            <div class="modal-form-grid pt-3">

              <form class="form-step-1">
                <div class="field">
                  <label for="txtDocumentName">Message</label>
                  <input type="text" class="form-control" id="txtMessage" name="txtMessage" placeholder="Please provide your name" />
                </div>
                <div class="field">
                  <label for="txtNextMessage">Go to next message</label>
                  <input type="text" class="form-control" id="txtNextMessage" name="txtNextMessage" placeholder="What is your email address?" />
                </div>
                <div class="field mb-0 text-center">
                  <button class="cta-green">Save</button>
                </div>
              </form>

            </div>
          </div>
        </div>

        <header className="d-md-flex align-items-center justify-content-between">
          <h3>Bot Workflow Builder</h3>

          <div className="group-form d-md-flex">
            <a href="javascript:void(0)" class="cta-border-w" onClick={() => handleSetClassicBuilder("toggle-view")}>Classic Builder</a>
            <a href="javascript:void(0)" class="cta-border-w with-icon" onClick={() => handleTestBot()}>
              <img src="/images/icons/eye-icon-green.png" alt="" />Test your bot
            </a>
            <button disabled={props.submissionLoader} className="cta-green" onClick={() => { updateWorkFlow() }}>
              {props.submissionLoader && <i class="fa-solid fa-spinner fa-spin"></i>}Save and continue</button>
          </div>
        </header>



        <div className="appearance-grid">

          <div className="dark-bg2 box-full p-5">
            <div class="workflow-builder" style={{ height: "500px" }}>
              {nodes && nodes.length > 0 && <ReactFlow
                nodes={nodes}
                edges={edges}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                onNodeDragStop={onNodeDragStop}
                edgeTypes={edgeTypes}
                fitView
              >
                <MiniMap />
                <Controls />
              </ReactFlow>}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// Define validation schema using yup
const schema = yup.object({
  dataRetentionDays: yup
    .number()
    .required('Data Retention Day is required')
    .min(1, 'Minimum value is 1')
    .typeError('Data Retention Day must be a number'),
}).required();

const GlobalSettings = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues:{
     dataRetentionDays:30
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    console.log('Form Data:', data);
   
  };

  return (
    <div className="change-pass_form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="field">
          <label htmlFor="dataRetentionDays">Data Retention Day</label>
      
            <input
              type="number"
              className="form-control"
              id="dataRetentionDays"
              placeholder="Enter number of days"
              {...register('dataRetentionDays')}
            />
        
          {errors.dataRetentionDays && (
            <span className="error-message">{errors.dataRetentionDays.message}</span>
          )}
        </div>
        <div className="field text-center">
          <button type="submit" className="cta-green">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default GlobalSettings;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import MySubscription from "../components/my-subscription/MySubscription";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useDashboard from "../hooks/useDashboard";
import useAuth from "../hooks/useAuth";
import moment from "moment";
import { showToast } from "../helper/toastHelper";
import { LABEL, replaceActionCallback, SUBSCRIPTION_TABLE_CONSTANT } from "../utils/constant";
import CommonTable from "../components/UI/Table";
import Swal from "sweetalert2";
import { CallAPI } from "../utils/apiActions";
import { CARD_DEFAULT_SETUP, CARD_UPDATE } from "../utils/APIEndPoints";
import removeSearchParams from "../utils/removeSearchParams";

const SubscriptionPlans = () => {
  const {
    isLoading,
    getUserActivePlan,
    activePlan,
    cancelSubscription,
    getUsersPaymentHistory,
    paymentHistory,
    getInvoiceList,
    invoiceList,
    downloadInvoice: downloadInvoiceApi,
  } = useDashboard();
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const session_id = useState(new URLSearchParams(location?.search).get("session_id"))
  const [isInvoiceListModalOpen, setIsInvoiceListModalOpen] = useState(false)

  useEffect(() => {
    if (!!session_id[0]) {
      handleCardDefaultSetup()
    }

    else
    {
      getUserActivePlan({
        userId: user.id,
      });
    }

    getUsersPaymentHistory(user.id, 1);
  }, []);

  function handleSubscriptionListPagination(page) {
    getUsersPaymentHistory(user.id, page);
  }

  const subscriptionListPagination = useMemo(() => {
    return {
      current: paymentHistory.currentPage,
      pageSize: paymentHistory.pageSize,
      total: paymentHistory.totalRecords,
      onChange: handleSubscriptionListPagination,
    };
  }, [
    paymentHistory.currentPage,
    paymentHistory.pageSize,
    paymentHistory.totalRecords,
    getUsersPaymentHistory,
  ]);

  function handleInvoiceListPagination(page) {
    getInvoiceList(isInvoiceListModalOpen, page)
  }

  const invoiceListPagination = useMemo(() => {
    return {
      current: paymentHistory.currentPage,
      pageSize: paymentHistory.pageSize,
      total: paymentHistory.totalRecords,
      onChange: handleInvoiceListPagination,
    };
  }, [
    paymentHistory.currentPage,
    paymentHistory.pageSize,
    paymentHistory.totalRecords,
    getUsersPaymentHistory,
  ]);

  const handleCardDefaultSetup = async (page) => {
    let response = await CallAPI("USER", "POST", CARD_DEFAULT_SETUP, { sessionId: session_id[0] });
    getUserActivePlan({
      userId: user.id,
    });
    showToast(response.msg, LABEL.TOAST_TYPE.SUCCESS);
    removeSearchParams()
  }

  const afterSuccess = (msg) => {
    getUserActivePlan({
      userId: user.id,
    });

    return showToast(msg, LABEL.TOAST_TYPE.SUCCESS);
  };

  const handleUpdatePayment = async () => {
    let body =
    {
      userId: user.id,
      returnUrl: `${process.env.REACT_APP_FRONTEND_BASE_URL}my-plan`,
      // returnUrl: 'http://localhost:3000/my-plan',
    }
    const updateCardResponse = await CallAPI("USER", "POST", CARD_UPDATE, body);

    console.log("updateCardResponse---", updateCardResponse.res.data?.data)
    const redirectUrl = updateCardResponse.res.data?.data;

    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  }

  const cancelSubscriptionCb = (id) => {
    Swal.fire({
      title: "Are you sure you want to Cancel Plan?",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          const payload = { subscriptionId: id };
          cancelSubscription(payload, afterSuccess);
        } catch (err) {
          showToast(err.msg, LABEL.TOAST_TYPE.ERROR);
        }
      }
    });
  }

  const downloadInvoice = async (payload) => {
    const invoiceDownloadUrl = await downloadInvoiceApi(payload);

    if (invoiceDownloadUrl) {
      const link = document.createElement("a");
      link.href = invoiceDownloadUrl;
      link.download = "";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const downloadInvoiceFn = useCallback((item) => {
    downloadInvoice({ stripeInvoiceId: item.stripeInvoiceId });
  }, []);

  const handleShowInvoice = (stripeSubscriptionId) => {
    getInvoiceList(stripeSubscriptionId, 1)
    setIsInvoiceListModalOpen(stripeSubscriptionId)
  }

  let subscriptionListActions =
    [{
      label: 'View Invoice',
      icon: "/images/icons/eye-icon-white.svg",
      onClick: (item) => handleShowInvoice(item.stripeSubscriptionId),
    }]

  let invoiceListActions = SUBSCRIPTION_TABLE_CONSTANT.usersManagementactions;

  invoiceListActions = replaceActionCallback(
    invoiceListActions,
    LABEL.TABLE_COLUMN.ACTION.DOWNLOAD.LABEL,
    downloadInvoiceFn
  );

  return (
    <div>
      {isInvoiceListModalOpen && <div class="modalOverlay modal-open">
        <div class="modal-container width-lg modal-dark-bg">
          <a href="javascript:void(0)" class="modalClose" onClick={() => setIsInvoiceListModalOpen(false)}><img src="/images/icons/modal-close-icon.svg" alt="" /></a>
          <div class="modal-form-grid pt-3">
            <form class="form-step-2 text-start">
              <h3>Invoice List</h3>
              <div class="field">
                <CommonTable
                  data={invoiceList?.invoiceList || []}
                  columns={SUBSCRIPTION_TABLE_CONSTANT.usersPaymentInvoiceColumns}
                  pagination={invoiceListPagination}
                  actions={invoiceListActions}
                  isPagination={true}
                  loading={isLoading}
                />
              </div>
            </form>
          </div>
        </div>
      </div>}

      <div class="subscription-plan">
        <header class="d-md-flex align-items-center justify-content-between with-tooltips">
          <h3>My Subscription

            <div className="tooltips">
              <Link href="javascript:void(0)" className="tooltips_icon"><i class="fa-solid fa-circle-info"></i></Link>
              <div className="tooltips_text">
                <p>Your subscription will be auto-deducted after the 14-day trial period unless canceled.</p>
              </div>
            </div>
          </h3>

        </header>

        {activePlan?.activeSubscription ? (
          <div class="subscription-plan-info">
            <ul>
              {activePlan?.activeSubscription?.plan?.isFreePlan == 1
                && <li class="annual-agency-plan">
                  <div class="subscription-plan_left">
                    {activePlan?.activeSubscription?.plan?.isFreePlan == 1 ? "Free Trial" : ""}
                    {/* {activePlan?.activeSubscription?.plan?.planName || ""}{'  '}{!activePlan?.activeSubscription?.amountReceived ? `(Free Plan)` : ''} */}
                  </div>
                  <div class="subscription-plan_right">
                    Expires:{" "}
                    {`${moment(
                      activePlan?.activeSubscription?.subscriptionEndDate
                    ).format("DD MMMM YYYY")}`}
                     <div className="cta-purple-with-text">
                      {activePlan?.activeSubscription?.status
                        ? "Active"
                        : "Inactive"}
                    </div>
                  </div>
                </li>}
              <li class="annual-agency-plan">
                <div class="subscription-plan_left">
                  {activePlan?.activeSubscription?.plan?.planName}
                  {/* {activePlan?.activeSubscription?.plan?.isFreePlan == 1 ? `${activePlan?.activeSubscription?.plan?.planName} (Free)` : activePlan?.activeSubscription?.plan?.planName} */}
                  {/* {activePlan?.activeSubscription?.plan?.planName || ""}{'  '}{!activePlan?.activeSubscription?.amountReceived ? `(Free Plan)` : ''} */}
                </div>
                <div class="subscription-plan_right">
                  Expires:{" "}
                  {activePlan?.activeSubscription?.plan?.isFreePlan == 1
                    ? ""
                    : <>
                      {`${moment(
                        activePlan?.activeSubscription?.subscriptionEndDate
                      ).format("DD MMMM YYYY")}`}
                      <a href="javascript:void(0)" class="cta-green">
                        {activePlan?.activeSubscription?.status
                          ? "Active"
                          : "Inactive"}
                      </a>
                    </>}
                </div>
              </li>
              <li>
                <div class="subscription-plan_left">
                  {`$${activePlan?.activeSubscription?.plan?.price}/${activePlan?.activeSubscription?.plan?.planType == 1 ? "mo" : "yr"}`}
                </div>
                <div class="subscription-plan_right">
                  {`Start date: ${activePlan?.activeSubscription?.plan?.isFreePlan == 1
                    ? activePlan?.activeSubscription?.isCancel == 0
                      ? moment(
                        activePlan?.activeSubscription?.subscriptionEndDate
                      ).add(1, 'days').format("DD MMMM YYYY")
                      : ""
                    : moment(
                      activePlan?.activeSubscription?.subscriptionStartDate
                    ).add(activePlan?.activeSubscription?.plan?.noOfDay, 'days').format("DD MMMM YYYY")}`}
                </div>
              </li>
              <li>
                <div class="subscription-plan_left">Last payment</div>
                <div class="subscription-plan_right">
                  {`${moment(activePlan?.activeSubscription?.createdAt).format(
                    "DD MMMM YYYY"
                  )}`}
                </div>
              </li>
              <li>
                <div class="subscription-plan_left">Next payment date</div>
                {activePlan?.activeSubscription?.isCancel == 1
                  ? <></>
                  : <div class="subscription-plan_right">
                    {`${moment(
                      activePlan?.activeSubscription?.subscriptionEndDate
                    ).add(1, 'days').format("DD MMMM YYYY")}`}
                  </div>}
              </li>
              <li>
                <div class="subscription-plan_left">Payment method</div>
                <div class="subscription-plan_right">
                  Visa XXXX{activePlan?.cardDetails?.last4}
                </div>
              </li>
              {activePlan?.activeSubscription?.cancelDate ? (
                <li>
                  <div class="subscription-plan_left">cancellation Date</div>
                  <div class="subscription-plan_right">
                    <strong>{`${moment(
                      activePlan?.activeSubscription?.cancelDate
                    ).format("DD MMMM YYYY hh:mm A")}`}</strong>
                  </div>
                </li>
              ) : (
                <li class="cancel-subscription">
                  <div class="subscription-plan_left">
                    <Link
                      to={"#"}
                      onClick={() =>
                        cancelSubscriptionCb(activePlan?.activeSubscription?.id)
                      }
                      class="cta-green"
                    >
                      <strong>Cancel Subscription</strong>
                    </Link>
                  </div>
                  <div class="subscription-plan_right">
                    <Link
                      to={"#"}
                      onClick={() =>
                        handleUpdatePayment(activePlan?.activeSubscription?.id)
                      }
                      class="cta-green"
                    >
                      <strong>Update Payment Method</strong>
                    </Link>
                  </div>
                </li>
              )}
            </ul>
          </div>
        ) :
          <div>
            <p>
              No Plan Available
            </p>
            <div class="group-form d-md-flex">
              <Link to='/plan-list'>
                <button class="cta-border-w rounded-30 w-180 text-center">Choose a Plan</button>
              </Link>
            </div>
          </div>
        }
      </div>

      <div class="chatbot-history-grid">
        <header class="d-md-flex align-items-center justify-content-between">
          <h3>Payment History</h3>

          {/* <div class="group-form d-md-flex">
            <input type="date" class="form-control w-180" id="" name="" />
            <button class="cta-border-w rounded-30 w-180 text-center">Download History</button>
          </div> */}
        </header>

        <React.Fragment>
          <CommonTable
            data={paymentHistory?.subscriptionList || []}
            columns={SUBSCRIPTION_TABLE_CONSTANT.usersManagementcolumns}
            pagination={subscriptionListPagination}
            actions={subscriptionListActions}
            isPagination={true}
            loading={isLoading}
          />
        </React.Fragment>
      </div>
    </div>
  );
};

export default SubscriptionPlans;

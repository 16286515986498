import React from 'react'
import useAuth from '../../hooks/useAuth';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

export function AdminGuard({ children }) {
  const { role, isAuthenticated, user } = useAuth();
  const location = useLocation();

  if (location.pathname != "/admin/bot-settings") {
    localStorage.removeItem("chatSource")
    localStorage.removeItem("botStyle")
    localStorage.removeItem("botWorkFlowEdge")
    localStorage.removeItem("botWorkFlowNode")
    localStorage.removeItem("chatElement")
    localStorage.removeItem("chatNode")
  }

  if (!isAuthenticated && user === null) {
    return <Navigate to="/" />;
  }

  // if (user && user.userType === 1) {
  //   return <Navigate to="/dashboard" />;
  // }

  // if (user && user.userType === 1) {
  //   return <Navigate to="/agency/dashboard" />;
  // }

  // if (user && user.userType === 1) {
  //   return <Navigate to="/affiliate/dashboard" />;
  // }

  return (
    <>
      {children}
    </>
  );
}

AdminGuard.propTypes = {
  children: PropTypes.node.isRequired,
};
export default AdminGuard;


import { MuiTelInput } from "mui-tel-input";
import { Controller, useForm } from "react-hook-form";
import FileUpload from "../UI/file-upload";
import { yupResolver } from "@hookform/resolvers/yup";
import { defaultValuesForFeedback, LABEL } from "../../utils/constant";
import { feedbackSchema } from "../../utils/validationSchema";
import { useRef, useState } from "react";
import { CallAPI } from "../../utils/apiActions";
import { showToast } from "../../helper/toastHelper";
const createFormData = (data) => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    formData.append(key, value);
  });
  return formData;
};
const FeedbackForm = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    reset,
  } = useForm({
    defaultValues: defaultValuesForFeedback,
    resolver: yupResolver(feedbackSchema),
  });
  const fileUploadRef = useRef();
  const [loading,setLoading]=useState(false)
  const onSubmit = async (value) => {
    setLoading(true)
    const payloadResponse = createFormData(value);
    console.log(payloadResponse)
    try {
      const response = await CallAPI(
        "WEBSITE",
        "POST",
        "contact-us/feedback",
        payloadResponse,
        "file"
      );
      if (response?.res?.status === 200) {
        showToast(response.res.data.message, LABEL.TOAST_TYPE.SUCCESS);
        reset({
          subject: "",
          message: "",
          files: null,
        });
        fileUploadRef.current.resetFileInput();
      }
    } catch (err) {
      console.log("err", err);
    }
    finally{
      setLoading(false)
    }
  };
  return (
    <div className="mychatbot-grid">
      <div className="tab-content-grid">
        <div className="tab-content active">
          <div className="chatbot-history-grid">
            <header className="mb-0">
              <div>
                <h3>{"Feedback"}</h3>
                <small className="text-grey2">
                  You can submit your feedback by filling this form.
                </small>
              </div>
            </header>

            <div className="chatSettings-grid">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="field">
                      <label for="txtOrganizationName">Subject</label>
                      <input
                        type="text"
                        className="form-control"
                        id="txtOrganizationName"
                        name="username"
                        placeholder="Enter Your Subject"
                        {...register("subject")}
                      />
                      {errors.subject && (
                        <span className="invalid-feedback">
                          {errors.subject.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="field">
                      <label for="txtBusinessURL">Message</label>
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows="5"
                        placeholder="Enter the message about your feedback"
                        {...register("message")}
                      ></textarea>
                      {errors.message && (
                        <span className="invalid-feedback">
                          {errors.message.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-12">
                    <div class="field">
                      {/* Image Upload */}
                      <div className="file-upload-container">
                        <Controller
                          name="files"
                          control={control}
                          render={({ field }) => (
                            <FileUpload
                              label="File Upload"
                              {...field}
                              ref={fileUploadRef}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <button className="cta-green w-140" type="submit" id disabled={!!loading}>
                    {!!loading && (
                                <i class="fa-solid fa-spinner fa-spin"></i>
                              )}
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FeedbackForm;

import React, { useEffect, useState } from 'react'
import { json, useLocation, useNavigate } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Swal from 'sweetalert2';
import Templates from './Templates/Templates';
import { showToast } from '../../helper/toastHelper';
import { LABEL } from '../../utils/constant';
import { delay } from '../../utils/delay';
import LiveChat from '../live-chat/LiveChat';

export default function BotQuestionArea({ ...props }) {
  const [chatNode, setCahtNode] = useState(!!localStorage.getItem("chatNode") ? JSON.parse(localStorage.getItem("chatNode")) : [{ id: "1", type: "text-question", question: "Welcome to CoolBiz. I am here to help you.", answer: "User Reply", uniqueId: (Date.now() + Math.random()).toString(36) }])
  const [hoverNode, setHoverNode] = useState(null)
  const elements = [
    { type: "message", label: "Message", icon: "images/icons/message-icon-w.svg", question: "Welcome to CoolBiz. I am here to help you." },
    { type: "name", label: "Name", icon: "images/icons/user-name-icon-w.svg", question: "Please provide your name", answer: "User Reply" },
    { type: "phone", label: "Phone Number", icon: "images/icons/message-icon-w.svg", question: "Please provide your phone number", answer: "User Reply" },
    { type: "email", label: "Email", icon: "images/icons/email-icon-w.svg", question: "Please provide your email", answer: "User Reply" },
    { type: "single-choice", label: "Single Choice", icon: "images/icons/single-choice-icon-w.svg", question: "Please select one from the given options", options: ["option 1", "option 2"] },
    { type: "multi-choice", label: "Multiple Choice", icon: "images/icons/multiple-choice-icon-w.svg", question: "Please select all the options that apply", options: ["option 1", "option 2"] },
    { type: "text-question", label: "Text Question", icon: "images/icons/message-icon-w.svg", question: "Please type an answer", answer: "User Reply" },
    { type: "others", label: "Others", icon: "images/icons/other-icon-w.svg", question: "Welcome to CoolBiz. I am here to help you.", answer: "User Reply" }
  ]
  const [nodeAddedPosition, setNodeAddedPosition] = useState(0)
  const navigate = useNavigate();
  const [openNodeModal, setOpenNodeModal] = useState(false)
  const [blankErrField, setBlankErrField] = useState("")
  const [isNodeUpdated, setIsNodeUpdated] = useState(false)
  const [selectedNode, setSelectedNode] = useState({ ...chatNode[0], index: 0 })

  useEffect(() => {
    setNodeAddedPosition(chatNode?.length)
    // if (selectedNode == null || !!props.isNodeSet) {
    //   setSelectedNode({ ...chatNode[0], index: 0 })
    // }
  }, [chatNode, props.isNodeSet]);

  useEffect(() => {
    setCahtNode(!!localStorage.getItem("chatNode") ? JSON.parse(localStorage.getItem("chatNode")) : [{ id: "1", type: "text-question", question: "Welcome to CoolBiz", answer: "User Reply", uniqueId: (Date.now() + Math.random()).toString(36) }])
  }, [localStorage.getItem("chatNode")]);

  useEffect(() => {
    setBlankErrField("")
  }, [selectedNode?.id]);


  const handleAddNode = (index) => {
    setOpenNodeModal(index + 1)
  }

  const handleAddElement = (element) => {
    let newNodeAddPosition = openNodeModal || nodeAddedPosition
    let laChatNode = [...chatNode]
    laChatNode.map(function (nodes) {
      nodes.isActive = false;
      return nodes
    });
    let loNodeObj =
    {
      id: chatNode?.length > 0 ? (parseInt(chatNode?.length) + 1).toString() : "1",
      type: element?.type,
      question: element.question,
      isActive: true,
      uniqueId: (Date.now() + Math.random()).toString(36)
    }
    if (!!element.answer) {
      loNodeObj.answer = element.answer
      setSelectedNode({ ...loNodeObj, index: newNodeAddPosition, answer: element.answer })
    }
    else if (!!element.options) {
      setSelectedNode({ ...loNodeObj, index: newNodeAddPosition, options: [...element.options] })
      loNodeObj.options = [...element.options]
    }
    laChatNode.splice(newNodeAddPosition, 0, loNodeObj)
    setCahtNode(laChatNode)
    setIsNodeUpdated(true)
    setOpenNodeModal(false)
  }

  const handleSetFocusedNode = async (node, index, type = "") => {
    if (type == "select") {
      let divElement = document.getElementById(`msg${selectedNode?.index}`)
      divElement.blur()
      if (selectedNode?.options?.length > 0) {
        selectedNode?.options.map((option, index) => {
          let optionElement = document.getElementById(`option${index}`)
          optionElement.blur()
        })
      }
    }
    if (index != selectedNode?.index) {
      if (!!node?.option) {
        setSelectedNode({ ...node, index: index, options: [...node.options] })
      }
      else {
        setSelectedNode({ ...node, index: index })
      }
    }
  }

  const handleUpdateSelectedNode = (e, index) => {
    if (index + 1) {
      let laOptions = [...selectedNode.options]
      laOptions[index] = !!(e.target.value.trim()) ? e.target.value : ''
      setSelectedNode({ ...selectedNode, options: [...laOptions] })
    }
    else {
      setSelectedNode({ ...selectedNode, question: !!(e.target.value.trim()) ? e.target.value : '' })
    }
    setIsNodeUpdated(true)
  }

  const handleUpdateNodeArray = (type = "", ind) => {
    let laBlankErrField = [...blankErrField]
    let laChatNode = [...chatNode]
    if (type == "question") {
      if (!!selectedNode.question) {
        laChatNode[selectedNode.index] = { ...selectedNode, index: undefined }
      }
      else {
        laBlankErrField.push("question")
        setBlankErrField(laBlankErrField)
      }
    }
    else {
      if (!!selectedNode.options[ind]) {
        laChatNode[selectedNode.index] = { ...selectedNode, index: undefined, options: [...selectedNode.options] }
      }
      else {
        laBlankErrField.push(`options#${ind}`)
        setBlankErrField(laBlankErrField)
      }
    }
    setCahtNode(laChatNode)
    setIsNodeUpdated(true)
  }

  const handleAppOption = () => {
    let currentSelectedNode = {...selectedNode}
    let chatNodeActualvalue = chatNode[selectedNode.index]
    if (selectedNode.question == '' || selectedNode.question == undefined || selectedNode.question == null) {
      currentSelectedNode.question = chatNodeActualvalue.question
    }
    selectedNode.options.forEach((option, ind) => {
      if (option == '' || option == undefined || option == null) {
        currentSelectedNode.options[ind] = chatNodeActualvalue.options[ind]
      }
    })
    setBlankErrField("")
    let laChatNode = [...chatNode]
    let laOptions = currentSelectedNode.options
    laOptions.push(`Option ${currentSelectedNode.options.length + 1}`)
    setSelectedNode({ ...currentSelectedNode, options: laOptions })
    laChatNode[selectedNode.index] = { ...currentSelectedNode, index: undefined, options: [...currentSelectedNode.options] }
    setCahtNode(laChatNode)
    setIsNodeUpdated(true)
  }

  const handleDeleteOption = (index) => {
    let laOptions = selectedNode.options
    laOptions.splice(index, 1);
    setSelectedNode({ ...selectedNode, options: laOptions })
    setIsNodeUpdated(true)
  }

  const handleOnDragEnd = (result) => {
    console.log("result--", chatNode)
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    const reorderedItems = [...chatNode];
    const [removed] = reorderedItems.splice(source.index, 1);
    reorderedItems.splice(destination.index, 0, removed);
    setCahtNode(reorderedItems);
    setSelectedNode({ ...removed, index: destination.index })
    setIsNodeUpdated(true)
  }

  const handleDeleteChatNode = (index) => {
    Swal.fire({
      title: "Are you sure you want to delete this question from the bot flow?",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        const reorderedItems = [...chatNode];
        reorderedItems.splice(index, 1);
        setCahtNode(reorderedItems);
        if (reorderedItems.length > 0) {
          handleSetFocusedNode(reorderedItems[index], index)
        }
        else {
          setSelectedNode({})
        }
      }
    });
    setIsNodeUpdated(true)
  }

  const handleSetVisualizeBuilder = (type = "") => {
    let prevBotWorkFlowNode = JSON.parse(localStorage.getItem("botWorkFlowNode")) || []
    if (isNodeUpdated || !localStorage.getItem("botWorkFlowEdge")) {
      props?.setOpenBotModal(false)
      let asciiVal = 97
      let flowChatNode = []
      let flowChatEdge = []
      let primaryFlow = []
      let yPosition = 0
      chatNode.forEach((chat, index) => {
        // let existingNode = prevBotWorkFlowNode?.find(x => x.uniqueId == chat.uniqueId)
        // if (!!existingNode) {
        //   flowChatNode.push({ id: `${index + 1}`, data: { label: chat.question }, position: existingNode.position, uniqueId: chat.uniqueId })
        // }
        // else {
        flowChatNode.push({ id: `${index + 1}`, data: { label: chat.question }, position: { x: 0, y: parseInt(yPosition) }, uniqueId: chat.uniqueId })
        // }
        yPosition += 100
        if (chat?.type == "single-choice" && !!chat.options && chat.options.length >= 2) {
          let optionArr = []
          let xOptionDistance = 1000 / chat.options.length - 1
          let xPosition = -200
          chat.options.forEach((option, ind) => {
            optionArr.push({ value: `${index + 1}#${String.fromCharCode(asciiVal + ind)}`, label: option })
            flowChatNode.push({ id: `${index + 1}#${String.fromCharCode(asciiVal + ind)}`, data: { label: option }, position: { x: parseInt(xPosition), y: parseInt(yPosition) }, uniqueId: chat.uniqueId })
            xPosition += xOptionDistance
            flowChatEdge.push({ id: `${index + 1}->${index + 1}#${String.fromCharCode(asciiVal + ind)}`, source: `${index + 1}`, target: `${index + 1}#${String.fromCharCode(asciiVal + ind)}` })
            if (ind == 0) {
              flowChatEdge.push({ id: `${index + 1}#${String.fromCharCode(asciiVal + ind)}->${index + 2}`, source: `${index + 1}#${String.fromCharCode(asciiVal + ind)}`, target: `${index + 2}`, type: 'custom-edge' })
            }
          })
          primaryFlow.push({ ...chat, id: index + 1, options: optionArr })
          console.log("optionArr--", primaryFlow)
          yPosition += 100
        }
        else {
          primaryFlow.push({ ...chat, id: index + 1 })
          if (index != chatNode.length - 1) {
            flowChatEdge.push({ id: `${index + 1}->${index + 2}`, source: `${index + 1}`, target: `${index + 2}`, type: 'custom-edge' })
          }
        }
      })
      localStorage.setItem("botWorkFlowNode", JSON.stringify(flowChatNode))
      localStorage.setItem("botWorkFlowEdge", JSON.stringify(flowChatEdge))
      localStorage.setItem("chatElement", JSON.stringify(primaryFlow))
      localStorage.setItem("chatNode", JSON.stringify(chatNode))
    }
    if (type == "toggle-view") {
      props.setViewMode("Visualize Flow")
    }
  }

  const handleTestBot = () => {
    props.setTestBotPreparation(true)
    handleSetVisualizeBuilder("bot-testing")
    let sourceNodeArr = props.handleGetSourceNode()
    console.log("sourceNodeArr---", sourceNodeArr)
    if (sourceNodeArr.length > 1) {
      showToast("Please build all the element connections to test the chat flow.", LABEL.TOAST_TYPE.ERROR);
    }
    else {
      props.handleSaveStep3("bot-testing")
      localStorage.setItem("chatSource", sourceNodeArr[0].source)
    }
  }

  return (
    <>
      {props.openBotModal &&
        // <Templates
        //   type={"test-bot"}
        //   elementFlow={JSON.parse(localStorage.getItem("chatElement"))}
        //   selectedTemplate={props.selectedTemplate}
        //   flowDirection={JSON.parse(localStorage.getItem("botWorkFlowEdge"))}
        //   setOpenBotModal={props.setOpenBotModal}
        //   colorSettingsArray={props.colorSettingsArray}
        //   botIcon={props.botIcon}
        // />
        // <Templates
        //   type={"live-bot"}
        //   selectedTemplate={props.selectedTemplate}
        //   setOpenBotModal={props.setOpenBotModal}
        //   botBubbleIcon={props.botIcon || "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"}
        //   colorSettingsArray={props.colorSettingsArray}
        //   openBotStyle={{
        //     top: '395px',
        //     left: '1085px',
        //     height: '315px',
        //     width: '220px'
        //   }}
        // />
        <LiveChat
          connectionType={"platform"}
          bot_id={props.bot_id}
          selectedTemplate={props.selectedTemplate}
          setOpenBotModal={props.setOpenBotModal}
          openBotModal={props.openBotModal}
          botBubbleIcon={props.botIcon || "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"}
          colorSettingsArray={props.colorSettingsArray}
          shortCode={props.shortCode}
          botCompletedStep={props.botCompletedStep} />}
      <div>
        {!!openNodeModal && <div class="modalOverlay modal-open">
          <div class="modal-container width-small modal-dark-bg rounded-4 p-4">
            <a href="javascript:void(0)" class="modalClose" onClick={() => setOpenNodeModal(false)}><img src="/images/icons/modal-close-icon.svg" alt="" /></a>
            <div class="modal-form-grid pt-3">
              <h3></h3>
              <div class="button-group">
                {elements?.length > 0 && elements.map((element, index) => (
                  element.label != "Others" &&
                  <button class="cta-border-w full with-icon" onClick={() => { setOpenNodeModal(false); handleAddElement(element) }}>
                    <img src={element.icon} alt="" />{element.label}
                  </button>
                ))}
              </div>

            </div>
          </div>
        </div>}

        <header className="d-md-flex align-items-center justify-content-between">
          <h3>Bot Question Area</h3>

          <div className="group-form d-md-flex">
            <a href="javascript:void(0)" class="cta-border-w" onClick={() => handleSetVisualizeBuilder("toggle-view")}>Visualize Flow</a>
            <button disabled={!!props.testBotPreparation} class="cta-border-w with-icon" onClick={() => handleTestBot()}>
              {!!props.testBotPreparation
                ? <><i class="fa-solid fa-spinner fa-spin" />Preparing bot</>
                : <><img src="/images/icons/eye-icon-green.png" alt="" />Test your bot</>}
            </button>
            <button disabled={props.submissionLoader} className="cta-green" onClick={() => { handleSetVisualizeBuilder(); props.handleSaveStep3("classical") }}>
              {props.submissionLoader && <i class="fa-solid fa-spinner fa-spin"></i>}
              Save and continue
            </button>
          </div>
        </header>

        {props.dataLoader
          ? <div className="page-loader">
            <img src="/images/loader-bouncing-circles.svg" alt="" />
          </div>
          : <div className="appearance-grid">

            <div className="dark-bg2 box-24 pt-4 p-3">
              <header class="text-center">
                <h5>Add Chat Component</h5>
              </header>
              <div class="button-group">

                {elements?.length > 0 && elements.map((element, index) => (
                  element.label != "Others" &&
                  <button class="cta-border-w full with-icon" onClick={() => handleAddElement(element)}>
                    <img src={element.icon} alt="" />{element.label}
                  </button>
                ))}
              </div>
            </div>

            <div className="create-reorder-chatflow dark-bg2 box-38 p-4 pb-5 verticle_scroll">
              <header class="text-center">
                <h5>Create/Reorder Chat Flow</h5>
              </header>

              {chatNode?.length > 0 ?
                <div onMouseLeave={() => setHoverNode(null)}>
                  <DragDropContext onDragEnd={(result) => handleOnDragEnd(result)} onDragStart={() => setHoverNode(null)}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {chatNode.map((node, index) => (
                            <Draggable key={node.id} draggableId={node.id} index={index}>
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}>
                                  <div class={selectedNode?.index == index ? "chatflow-grid chatflow-option-grid" : "chatflow-grid"}
                                    onClick={() => handleSetFocusedNode(node, index, "select")}
                                    onMouseEnter={() => setHoverNode(index)}>
                                    <a href="javascript:void(0)" class="delete-item">
                                      <img src="/images/icons/delete-icon.svg" alt="" onClick={() => handleDeleteChatNode(index)} />
                                    </a>
                                    <ul>
                                      <li class="user-chatflow">
                                        <figure><img src="/images/icons/chatbot-robot-icon.svg" alt="" /></figure>
                                        <aside>{node.question}</aside>
                                      </li>
                                      {!!node.answer
                                        ?
                                        <li class="user-chatflow-reply">
                                          <div class="chat-box">{node.answer}</div>
                                        </li>
                                        :
                                        <li class="chatflow-option">
                                          {node?.options?.length > 0 && node.options.map((option, ind) => (
                                            <a href="javascript:void(0)" class="cta-border-w">{option}</a>
                                          ))}
                                        </li>}
                                    </ul>
                                  </div>

                                  {(index == hoverNode - 1 || index == hoverNode) && <div class="generate-new-chat text-center" onClick={() => handleAddNode(index)}>
                                    <a href="javascript:void(0)"><img src="/images/icons/chatflow-plus-icon.png" alt="" /></a>
                                  </div>}
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
                : <span onClick={() => handleAddNode(0)}>Add chat component</span>}



              {/* <div class="chatflow-grid chatflow-option-grid">
                  <ul>
                    <li class="user-chatflow">
                      <figure><img src="/images/icons/chatbot-robot-icon.svg" alt="" /></figure>
                      <aside>Please select all the options that apply</aside>
                    </li>
                    <li class="chatflow-option">
                      <a href="javascript:void(0)" class="cta-border-w">Option 1</a>
                      <a href="javascript:void(0)" class="cta-border-w">Option 2</a>
                    </li>
                  </ul>
                </div> */}

              {/* <div class="generate-new-chat text-center">
                  <a href="javascript:void(0)"><img src="/images/icons/chatflow-plus-icon.png" alt="" /></a>
                </div>

                <div class="chatflow-grid">
                  <ul>
                    <li class="user-chatflow">
                      <figure><img src="/images/icons/chatbot-robot-icon.svg" alt="" /></figure>
                      <aside>Please Provide your name</aside>
                    </li>
                    <li class="user-chatflow-reply">
                      <div class="chat-box">User Reply</div>
                    </li>
                  </ul>
                </div> */}

            </div>

            <div className="dark-bg2 box-38 p-4 pb-5">
              <header class="text-center">
                <h5>Customize Bot Messages</h5>
              </header>
              {!!selectedNode && Object.keys(selectedNode).length > 0 && <div class="form-group customize-messages">
                <div class="field">
                  <label for='txtMessagebox'>Message</label>
                  <input
                    type="text"
                    value={selectedNode?.question}
                    class="form-control"
                    id={`msg${selectedNode.index}`}
                    name="txtMessage"
                    placeholder="Please select all the options that apply"
                    onChange={(e) => handleUpdateSelectedNode(e)}
                    onBlur={() => handleUpdateNodeArray("question")} />
                  {blankErrField.includes("question") && <span>Can't left this field blank</span>}
                </div>


                {!!selectedNode.options &&
                  <>
                    {selectedNode.options.map((option, index) => (
                      <>
                        <div class="field">
                          <label for="txtOption1">{`Option ${index + 1}`}</label>
                          <div class="with-icon">
                            <input
                              type="text"
                              value={option}
                              class="form-control"
                              id={`option${index}`}
                              name={`option${index}`}
                              placeholder="Type..."
                              onChange={(e) => handleUpdateSelectedNode(e, index)}
                              onBlur={() => handleUpdateNodeArray("option", index)} />
                            <a href="javascript:void(0)">
                              {selectedNode.options.length > 2 &&
                                <img src="/images/icons/text-remove-icon.png" alt="" onClick={() => handleDeleteOption(index)} />}
                            </a>
                          </div>
                        </div>
                        {blankErrField.includes(`options#${index}`) && <span>Can't left this field blank</span>}
                      </>
                    ))}

                    <div class="field text-right">
                      <button class="cta-green" onClick={() => handleAppOption()}>+ Add Option</button>
                    </div>
                  </>
                }

              </div>}
            </div>
          </div>}

      </div>
    </>
  )
}
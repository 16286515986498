import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import FileUpload from '../../UI/file-upload'
import { defaultValuesForContactUs, LABEL } from '../../../utils/constant'
import { MuiTelInput } from 'mui-tel-input'
import { useNavigate } from 'react-router-dom'
import useDashboard from '../../../hooks/useDashboard'
import { yupResolver } from '@hookform/resolvers/yup'
import { contactUsSchema } from '../../../utils/validationSchema'
import { showToast } from '../../../helper/toastHelper'
import { useRef } from 'react'
const createFormData = (data) => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    formData.append(key, value);
  });
  return formData;
};
const ContactUsInfo = () => {
  const navigate = useNavigate();
  const fileUploadRef=useRef()
  const { contactUsRecordCreate } = useDashboard()
  const {
    handleSubmit,
    control,
    register,
    watch,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(contactUsSchema),
  defaultValues: defaultValuesForContactUs,
  });

  const afterSuccess = (msg) => {
    setTimeout(() => {
      navigate('/')
    }, 1000)
    return showToast(msg, LABEL.TOAST_TYPE.SUCCESS);
  };

  const onSubmit = (data) => {
    // if (!data.slot) return showToast("Please Select A Time Slote", LABEL.TOAST_TYPE.ERROR);

    const payloadResponse = createFormData(data);
    contactUsRecordCreate(payloadResponse, afterSuccess, 'file')
  };
  return (
    <div className="contact-form box-border">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-xl-12">
            <div className="field">
              <label
                for="txtName">Name</label>

              <input
                type="text"
                className="form-control"
                id="txtName"
                placeholder="Enter Your Name"
                {...register("name")}
              />
              {errors.name && (
                <span className="invalid-feedback">
                  {errors.name.message}
                </span>
              )}

            </div>
            <div className="field">
              <label
                for="txtEmail">Email</label>
              <input
                type="email"
                className="form-control"
                id="txtEmail"
                placeholder="Enter Your Email"
                {...register("email")}
              />
              {errors.email && (
                <span className="invalid-feedback">
                  {errors.email.message}
                </span>
              )}
            </div>
            <div className="field">
              <label
                for="txtPhoneNumber">Phone
                Number</label>
              <Controller
                name="phone"
                control={control}
                rules={{ required: "Phone number is required" }}
                render={({ field }) => (
                  <MuiTelInput
                    {...field}
                    id="full-width-text-field-mobile"
                    fullWidth={true}
                    variant="outlined"
                    forceCallingCode
                    enableLongNumbers={true}
                    autoFormat={false}
                    disableAreaCodes={true}
                    countryCodeEditable={false}
                    autoComplete="off"
                    className="customtel"
                    error={!!errors.phone}
                    helperText={errors.phone?.message}
                    placeholder="Enter Your Phone Number"
                  />
                )}
              />
            </div>

            <div className="field">
              <label
                for="txtOrganizationName">Subject</label>
              <input
                type="text"
                className="form-control"
                id="txtOrganizationName"
                name="username"
                placeholder="Enter Your Subject"
                {...register("subject")}

              />
              {errors.subject && (
                <span className="invalid-feedback">
                  {errors.subject.message}
                </span>
              )}
            </div>
            <div className="field">
              <label
                for="txtBusinessURL">Message</label>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="5"
                {...register("message")}
              ></textarea>
              {errors.message && (
                <span className="invalid-feedback">
                  {errors.message.message}
                </span>
              )}
            </div>
            <div class="field">
              {/* Image Upload */}
              <div className="file-upload-container">
                <Controller
                  name="file"
                  control={control}
                  render={({ field }) => (
                    <FileUpload
                      label="File Upload"
                      {...field}
                      ref={fileUploadRef}
                    />
                  )}
                />
              </div>
            </div>
          </div>

          <div className="form-check mb--10">
            <label
              className="form-checkbox">
              <input type="checkbox"
                name="checkbox"
                className="checkbox" />
              I Agree
            </label>
          </div>

          <div className="field">
            <small>
              By checking this box, I
              accept CoolBiz's <a
                href="javascript:void(0)">Terms
                and Conditions</a>
              and <a
                href="javascript:void(0)">Privacy Policy</a>,
              and consent to receive
              text and emails for
              communication and
              promotions. I can
              opt-out anytime by
              replying STOP to
              texts and clicking
              UNSUBSCRIBE in emails.
            </small>
          </div>

          <div className="col-12 text-center">
            <button className="cta-dark"
              type="submit" id>Submit
              Form</button>
          </div>

        </div>
      </form>
    </div>
  )
}
export default ContactUsInfo
import React, { useCallback, useEffect, useMemo } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Dropdown from "../../UI/dropdown";
import ToggleSwitch from "../../UI/toggle-switch";
import {
  currencyOptions,
  defaultValuesForPlan,
  LABEL,
  mapToLabelAndValues,
  subscriptionTypeOptions,
  transformBooleanToNumber,
} from "../../../utils/constant";
import { planSchema } from "../../../utils/validationSchema";
import useAuth from "../../../hooks/useAuth";
import { showToast } from "../../../helper/toastHelper";
import useCmsManagement from "../../../hooks/useCmsManagement";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import CustomSelect from "../../UI/dropdown/CustomSelect";
import moment from "moment";

const CreateOrUpdatePlan = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useAuth();
  const { createPlan, planDescriptionList, getAllPlanDescription, getPlanById } =
    useCmsManagement();

  const memoizedPlanDescriptionOptions = useMemo(() => {
    return mapToLabelAndValues(planDescriptionList, "id", "description");
  }, [planDescriptionList]);

  useEffect(() => {
    if (!user.id) {
      return showToast(LABEL.USER.USER_ID_NOT_FOUND, LABEL.TOAST_TYPE.ERROR);
    }

    fetchAllPlansDescriptions();
  }, []);

  const fetchPlanById = useCallback(async () => {
    if (!id) return;
    const result = await getPlanById(id);
    resetFilds(result ? result[0] : []);
  }, [id, getPlanById]);

  // Trigger fetch on ID change
  useEffect(() => {
    fetchPlanById();
  }, [id]);

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    watch,
    reset,
    setValue
  } = useForm({
    resolver: yupResolver(planSchema),
    defaultValues: defaultValuesForPlan,
  });

  const { isFreePlan, module, subscriptionType, allowPromotionCode, autoCollectTax } = watch();

  useEffect(() => {
    if (isFreePlan) {
      setValue('noOfDay', 14)
    } else {
      setValue('noOfDay', '')
    }
  }, [isFreePlan])

  const afterSuccess = (msg) => {
    navigate("/admin/plan");
    return showToast(msg, LABEL.TOAST_TYPE.SUCCESS);
  };

  function resetFilds(data) {
    
    const {
      planName,
      userId,
      userType,
      isFreePlan,
      noOfDay,
      planType,
      price,
      allowPromotionCode,
      autoCollectTax,
      currency,
      module
    } = data;

    reset({
      planId: id,
      planName,
      userId,
      userType,
      isFreePlan: Boolean(isFreePlan),
      noOfDay,
      subscriptionType: planType,
      price,
      allowPromotionCode: Boolean(allowPromotionCode),
      autoCollectTax: Boolean(autoCollectTax),
      currency,
      module:module.length!==0? module.map((item,index)=>{return({
        value:item.moduleDescription.id,label:item.moduleDescription.description
      })}) : [],
    });

  }

  function fetchAllPlansDescriptions() {
    const payload = {
      userId: user.id,
    };

    getAllPlanDescription(payload);
  }

  const onSubmit = (data) => {
    if (!user.id) {
      return showToast(LABEL.USER.USER_ID_NOT_FOUND, LABEL.TOAST_TYPE.ERROR);
    }
   
    let {
      isFreePlan,
      allowPromotionCode,
      autoCollectTax,
      subscriptionType,
      noOfDay,
      ...nonTransformableValues
    } = data;
    noOfDay=noOfDay??0
    const transformableValues = {
      isFreePlan,
      allowPromotionCode,
      autoCollectTax,
      // subscriptionType,
    };

    const transformBooleanToNumberResult =
      transformBooleanToNumber(transformableValues);

    const payload = {
      ...nonTransformableValues,
      ...transformBooleanToNumberResult,
      subscriptionType: Number(subscriptionType),
      noOfDay:noOfDay,
      userId: user.id,
      userType: user.role,
    };
    console.log("non",payload)
    createPlan(payload, afterSuccess, id);
  };
  
  return (
    <div className="mychatbot-grid">
      <div className="tab-content-grid">
        <div className="tab-content active">
          <div className="chatbot-history-grid">
            <header className="d-md-flex align-items-center justify-content-between">
              <h3>{id ? LABEL.PLAN_MANAGEMENT.TITLE_VIEW : LABEL.PLAN_MANAGEMENT.TITLE}</h3>

              <span class="text-grey">{` Last Update:  ${moment().format("DD MMMM YYYY hh:mm A")}`}</span>
            </header>

            <div className="chatSettings-grid">
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="coupon-form-grid"
              >
                <div class="row">
                  <div class="col-md-12 col-lg-12">
                    <div class="row">
                      {/* Plan Name */}

                      <div className="col-md-12">
                        <div className="field">
                          <label>{LABEL.PLAN_MANAGEMENT.PLAN_NAME}</label>
                          <input
                            {...register("planName")}
                            className={`form-control ${errors.planName ? "is-invalid" : ""
                              }`}
                            placeholder="Enter Plan name"
                            // disabled={!!id}
                          />
                          {errors.planName && (
                            <span className="invalid-feedback">
                              {errors.planName.message}
                            </span>
                          )}
                        </div>
                      </div>

                      {/* Price */}

                      <div className="col-md-6">
                        <div className="field">
                          <label>{LABEL.PLAN_MANAGEMENT.PRICE}</label>
                          <input
                            type="number"
                            {...register("price")}
                            className={`form-control ${errors.price ? "is-invalid" : ""
                              }`}
                            placeholder="Enter price"
                            // disabled={!!id}
                          />
                          {errors.price && (
                            <span className="invalid-feedback">
                              {errors.price.message}
                            </span>
                          )}
                        </div>
                      </div>

                      {/* Currency */}

                      <div className="col-md-6">
                        <Controller
                          name="currency"
                          control={control}
                          render={({ field }) => (
                            <Dropdown
                              label={LABEL.PLAN_MANAGEMENT.CURRENCY}
                              options={currencyOptions}
                              error={errors.currency}
                              // disabled={!!id}
                              {...field}
                            />
                          )}
                        />
                      </div>

                      {/* Plan Discription */}
                      <div className="col-md-12">
                        {
                          // id ? <p>{module?.map(e => e.label)?.join(',')}</p>
                          //   :

                            <Controller
                              name="module"
                              control={control}
                              render={({ field }) => (
                                <CustomSelect
                                  label={LABEL.PLAN_MANAGEMENT.PLAN_DESCRIPTION}
                                  options={memoizedPlanDescriptionOptions ?? []}
                                  error={errors.module}
                                  isMulti={true}
                                  {...field}
                                />
                              )}
                            />
                        }
                      </div>


                      <div className="col-md-12">
                        {/* is Free Plan */}

                        <div className="col-md-6">
                          {
                          // id ?
                          //   <p>Allow Trial: {isFreePlan ? "YES" : "NO"}</p>
                          //   :
                            <>
                              <div className="d-flex align-items-center gap-3">
                                <Controller
                                  name="isFreePlan"
                                  control={control}
                                  defaultValue={false}
                                  render={({ field }) => (
                                    <ToggleSwitch
                                      label={LABEL.PLAN_MANAGEMENT.FOR_TRILE}
                                      {...field}
                                    />
                                  )}
                                />
                              </div>
                              {isFreePlan?<span class="text-grey">
                                After disable user will not get the free trails
                              </span>:<span class="text-grey">
                                After enable user will get the free trails
                              </span>}
                            </>}
                        </div>

                        {/* No of days */}

                        {isFreePlan ? (
                          <div className="col-md-6">
                            <div className="field">
                              <label>{LABEL.PLAN_MANAGEMENT.NO_OF_DAYS}</label>
                              <input
                                type="number"
                                {...register("noOfDay")}
                                className={`form-control ${errors.noOfDay ? "is-invalid" : ""
                                  }`}
                                min={1}
                                placeholder="Enter number of day"
                              />
                              {errors.noOfDay && (
                                <span className="invalid-feedback">
                                  {errors.noOfDay.message}
                                </span>
                              )}
                            </div>
                          </div>
                        ) : null}
                      </div>

                      {/* subscription Type */}

                      <div className="col-md-12">
                        <div className="d-flex align-items-center gap-3">
                          {
                            // id ?
                            //   <p>Allow For Subscription Type: {subscriptionType ? "YES" : "NO"}</p>
                            //   :
                              <Controller
                                name="subscriptionType"
                                control={control}
                                defaultValue={false}
                                render={({ field }) => (
                                  <Dropdown
                                    label={LABEL.PLAN_MANAGEMENT.ALLOW_SUBSCRIPTION}
                                    options={subscriptionTypeOptions}
                                    error={errors.subscriptionType}
                                    {...field}
                                  />
                                )}
                              />
                          }
                        </div>
                      </div>

                      {/* allow Promotion Code */}

                      <div className="col-md-4">
                        <div className="d-flex align-items-center gap-3">
                          {
                            // id ?
                            //   <p>Allow Promotion code: {subscriptionType ? "YES" : "NO"}</p>
                            //   :
                              <Controller
                                name="allowPromotionCode"
                                control={control}
                                defaultValue={false}
                                render={({ field }) => (
                                  <ToggleSwitch
                                    label={
                                      LABEL.PLAN_MANAGEMENT.ALLOW_PORMOTION_CODE
                                    }
                                    {...field}
                                  />
                                )}
                              />
                          }
                        </div>
                      </div>

                      {/* auto Collect Tax */}

                      <div className="col-md-4">
                        <div className="d-flex align-items-center gap-3">
                          {
                            // id ?
                            //   <p>Allow To Collect TAX: {subscriptionType ? "YES" : "NO"}</p>
                            //   :
                              <Controller
                                name="autoCollectTax"
                                control={control}
                                defaultValue={true}
                                render={({ field }) => (
                                  <ToggleSwitch
                                    label={LABEL.PLAN_MANAGEMENT.ALLOW_TAX}
                                    value={true}
                                    disabled={true}
                                  />
                                )}
                              />
                          }
                        </div>
                      </div>

                      {/* subscription Type */}

                      {/* <div className="col-md-4">
                        <div className="d-flex align-items-center gap-3">
                          <Controller
                            name="subscriptionType"
                            control={control}
                            defaultValue={false}
                            render={({ field }) => (
                              <ToggleSwitch
                                label={LABEL.PLAN_MANAGEMENT.ALLOW_SUBSCRIPTION}
                                {...field}
                              />
                            )}
                          />
                        </div>
                      </div> */}

                      <div class="col-md-12">
                        <div class="field">
                          <div class="group-form d-flex align-items-center gap-3">
                            {/* Submit Button */}
                            {
                              // id ?
                              //   <Link to="/admin/plan" className="cta-green mt-3">
                              //     Go Back
                              //   </Link>
                              //   :
                                <button type="submit" className="cta-green mt-3">
                                  {LABEL.PLAN_MANAGEMENT.SAVE_AND_CONTINUE}
                                </button>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateOrUpdatePlan;

import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { CallAPI } from '../../utils/apiActions';
import { BOT_DELETE, BOT_LIST_WITH_STATUS, DASHBOARD_CHAT_DETAILS } from '../../utils/APIEndPoints';
import { LABEL, paginationObj, user_type } from '../../utils/constant';
import { showToast } from '../../helper/toastHelper';
import Swal from 'sweetalert2';
// import { Area, AreaChart, CartesianGrid, Label, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import useAuth from '../../hooks/useAuth';
import useDashboard from '../../hooks/useDashboard';
import CardStattics from './CardStattics';
import GraphStatistics from './GraphStatistics';
import BotListStatistics from './BotListStatistics';

export default function DashboardMain() {
  const { user } = useAuth();
  const [botList, setBotList] = useState(null)
  const { getDashboardAndAnalyticsData, dashbordAndAnalyticsData, isLoading ,saveFcmToken} = useDashboard()
  const [cardDetails, setCardDetails] = useState([])
  const [selectedBot, setSelectedBot] = useState(null)
  const [messageGraph, setMessageGraph] = useState(null)
  const [paginationData, SetPaginationData] = useState({ totalRecords: 0, currentPage: 1, totalPages: 0 })
  const FCMTOKEN=localStorage.getItem("FcmToken")

  useEffect(() => {
    if (!!dashbordAndAnalyticsData)
      getDashboardChatDetails()
  }, [dashbordAndAnalyticsData, selectedBot]);

  useEffect(() => {
    getAllBotList()
  }, [paginationData.currentPage]);

 useEffect(()=>{
  if(FCMTOKEN){
    saveFcmToken({firebaseToken:FCMTOKEN})
  }
 },[FCMTOKEN])

  useEffect(() => { 
    getDashboardAndAnalyticsData()
  }, [botList]);

  const getAllBotList = async () => {
    let url = `${BOT_LIST_WITH_STATUS}?page=${paginationData.currentPage}&pageSize=${paginationObj.item_per_page}`
    let response = await CallAPI("BOT", "GET", url)
    setBotList(response?.res.data?.data?.data)
    SetPaginationData({ ...paginationData, totalRecords: response?.res.data?.data?.totalRecords, totalPages: Math.ceil(response?.res.data?.data?.totalRecords / paginationObj.item_per_page) })
    // setBotList([])
  };

  const getDashboardChatDetails = async () => {
    let URL = `${DASHBOARD_CHAT_DETAILS}/${user.userUuid}/`
    if (!!selectedBot && selectedBot != 0) {
      URL = `${URL}?botID=${selectedBot}`
    }
    let response = await CallAPI("CHAT", "GET", URL)
    console.log("DashboardChatDetails---", response.res, dashbordAndAnalyticsData)
    setMessageGraph(response.res.data.total_incoming_chat)
    let loCardDetails = [{ label: "Total Bots", count: user_type[user?.userType] == "super_admin" ? dashbordAndAnalyticsData.adminTotalChatBot : dashbordAndAnalyticsData.totalChatBots }, { label: "Total Active Bots", count: user_type[user?.userType] == "super_admin" ? dashbordAndAnalyticsData.adminTotalActiveChatBotsCurrent : dashbordAndAnalyticsData.totalActiveChatBots }]
    if (user_type[user?.userType] == "super_admin") {
      loCardDetails.push({ label: "Incomming Chats", count: response.res.data.chat_summary.total_incoming_chats }, { label: "Total Transaction", count: dashbordAndAnalyticsData.totalTransactions })
    }
    else {
      loCardDetails.push({ label: "Incomming Chats", count: response.res.data.chat_summary.total_incoming_chats }, { label: "Total Active Chats", count: response.res.data.chat_summary.active_chats })
    }
    setCardDetails(loCardDetails)
  }

  const handlePaginationChange = (event, value) => {
    SetPaginationData((prev) => ({ ...prev, currentPage: value }));
  };

  return (
    <React.Fragment>
      <CardStattics cardDetails={cardDetails} />
      {messageGraph && <GraphStatistics botList={botList} setSelectedBot={setSelectedBot} messageGraph={messageGraph} />}
      <BotListStatistics getAllBotList={getAllBotList} botList={botList} paginationData={paginationData} handlePaginationChange={handlePaginationChange}/>
    </React.Fragment>
  )
}

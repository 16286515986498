import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

function Footer() {
  const navigate = useNavigate();

  return (
    <>
      <section className="body-wrap">
        <div className="chatbot-robot gradient">
          <div className="container">
            <div className="chatbot-robot_grid">
              <div className="chatbot-robot_grid_info">
                <h3>Customize Your Chatbot with Advanced AI Training and Prompting for Tailored
                  Conversations</h3>
                <p>CoolBiz AI Chatbot brings the future of customer interaction to your business. Our
                  platform enables you to design,
                  customize, and Manage Proprietary AI-Powered Chatbots that are tailored to your
                  industry’s needs. Whether you're
                  looking to streamline customer service, enhance engagement, or provide 24/7 support, our
                  easy-to-use platform ensures
                  your chatbot is up and running in no time.</p>

                <Link to="/signup" className="cta-green"><i className="fa-solid fa-sparkles"
                  aria-hidden="true"></i> Create Chatbot Now</Link>
                <Link to="/documentation#tutorialsVideo" className="cta-dark"><i className="fa-solid fa-play"></i> How to
                  create</Link>
              </div>
              <div className="chatbot-robot_grid_image">
                <img src="/images/Bot-AvatarGreenXparent.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="footer-gbl">
        <div className="container">
          <div className="footer-block">

            <div className="footer-block_logo">
              <figure className="mb-4">
                <Link to="/">
                  <img src="/images/coobiz-logo.svg" alt="Deadalus Logo" />
                </Link>
              </figure>
              <h5>TRANSFORM IDEAS INTO STUNNING AI CHATBOT</h5>
            </div>

            <div className="footer-block_link">
              <ul>
                <li><a href="javascript:void(0)" onClick={() => navigate('/')}>Home</a></li>
                <li><a href="javascript:void(0)" onClick={() => navigate('/features')}>Features</a></li>
                <li><a href="javascript:void(0)" onClick={() => navigate('/pricing')}>Pricing</a></li>
                <li><a href="javascript:void(0)" onClick={() => navigate('/documentation')}>Documentation</a></li>
                <li><a href="javascript:void(0)" onClick={() => navigate('/contact-us')}>Support</a></li>
              </ul>
            </div>

            <div className="footer-block_link">
              <ul>
                <li><a href="https://bot.coolbiz.info/terms-of-service" target="_blank">TERMS OF SERVICE</a></li>
                <li><a href="https://bot.coolbiz.info/privacy-policy" target="_blank">PRIVACY POLICY</a></li>
                <li><a href="https://bot.coolbiz.info/refund-policy" target="_blank">REFUND POLICY</a></li>
                <li><a href="https://bot.coolbiz.info/affiliate" target="_blank">BECOME AN AFFILIATE</a></li>
              </ul>
            </div>

            <div className="footer-block_visitus">
              <p>COOLBIZ WEB DESIGN</p>
              <a href="https://coolbiz.info/" target='_blank' className="cta-green">coolbiz.info</a>
            </div>

            <div className="footer-block_contact">
              <ul>
                <li>1534 Purple Way, Redding, CA 96003</li>
                <li><span>Phone: </span><a href="javascript:void(0)">(530) 691-1055</a></li>
                <li><a href="javascript:void(0)">sales@coolbiz.info</a></li>
                <li><a href="javascript:void(0)">support@coolbiz.info</a></li>
                <li><a href="javascript:void(0)">billing@coolbiz.info</a></li>
              </ul>
            </div>

          </div>
        </div>

        <div className="footer-bottom">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-md-6">
                <div className="small-txt">
                  <p>CoolBiz Web Design © All Rights Reserved</p>
                </div>
              </div>
              <div className="col-md-6 social-media">
                <ul>
                  <li><a href="javascript:void(0)" target="_blank"><i className="fab fa-youtube"></i></a></li>
                  <li><a href="javascript:void(0)" target="_blank"><i className="fab fa-facebook-f"></i></a>
                  </li>
                  <li><a href="javascript:void(0)" target="_blank"><i
                    className="fa-brands fa-instagram"></i></a></li>
                  <li><a href="javascript:void(0)" target="_blank"><i className="fab fa-linkedin-in"></i></a>
                  </li>
                  <li><a href="javascript:void(0)" target="_blank"><i className="fa-brands fa-tiktok"></i></a>
                  </li>
                  <li><a href="javascript:void(0)" target="_blank"><i
                    className="fa-brands fa-discord"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}


export default Footer
import React from "react";
import { Link, useNavigate } from "react-router-dom";

const LogInBG = () => {
    return (
        <div className="form-grid_right"
            style={{ backgroundImage: "url('images/login-bg.jpg')" }}
        // style="background-image: url(images/login-bg.svg);"
        >
            <div className="form-right-img-block login">
                <div className="btn-grid">
                    <Link
                        className="cta-white"
                        to="/signup"
                    >
                        Sign Up
                    </Link>
                    <Link
                        className="cta-white"
                        to="/admin/login"
                    >
                        Administrator Sign In
                    </Link>
                </div>
                <header>
                    <h2>
                        <span>Unlock</span> the power of
                    </h2>
                    <h3>CoolBiz AI Chatbot</h3>
                </header>
                <figure>
                    <img src="/images/Bot-AvatarGreenXparent.png" alt />
                </figure>
            </div>
        </div>
    )
}
export default LogInBG
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { HexColorPicker } from "react-colorful";
import Templates from './Templates/Templates';
import { CallAPI } from '../../utils/apiActions';
import { BOT_APPEARANCE, BOT_APPEARANCE_DETAILS, BOT_CHAT_ICON, BOT_CHAT_ICON_REMOVE, BOT_LISTS } from '../../utils/APIEndPoints';
import { showToast } from '../../helper/toastHelper';
import { LABEL } from '../../utils/constant';
import { SketchPicker } from 'react-color';
// import '../templates'
// import { BotTemplate1, BotTemplate2 } from '../templates';

export default function BotAppearance({ ...props }) {

  let colorArrInitialState = [
    { key: "header_bg", lable: "Header Background", color: "#355266", elementClassName: "header-back-color", props: "background-color" },
    { key: "page_bg", lable: "Page Background", color: "#ffffff", elementClassName: "page-back-color", props: "background-color" },
    { key: "bot_tb_color", lable: "Bot Text Box Color", color: "#ffffff", elementClassName: "bot-color-box", props: "background-color" },
    { key: "bot_txt_color", lable: "Bot Text Color", color: "#000000", elementClassName: "bot-text-color", props: "color" },
    { key: "sender_tb_color", lable: "Sender Text box Color", color: "#ffffff", elementClassName: "sender-color-box", props: "background-color" },
    { key: "sender_txt_color", lable: "Sender Text Color", color: "#000000", elementClassName: "sender-text-color", props: "color" },
    { key: "link_color", lable: "Link Color", color: "#3498db", elementClassName: "link-color", props: "color" },
    { key: "btn_txt_color", lable: "Icon Color", color: "#212529bf", elementClassName: "button-text-color", props: "color" },
    { key: "option_tb_color", lable: "Option Button Color", color: "#3498db", elementClassName: "option-color", props: "background" },
    { key: "option_txt_color", lable: "Option Text Color", color: "#ffffff", elementClassName: "option-color", props: "color" },
    { key: "footer_bg", lable: "Footer Background", color: "#ffffff", elementClassName: "footer-back-color", props: "background-color" }
  ]
  const [colorSettingsArray, setColorSettingsArray] = useState(colorArrInitialState);
  const [showIndex, setShowIndex] = useState(null)
  const location = useLocation();
  const bot_id = (new URLSearchParams(location?.search).get("id")) || null
  const [selectedTemplate, setSelectedTemplate] = useState(!!new URLSearchParams(location?.search).get("temp") ? `template#${(new URLSearchParams(location?.search).get("temp"))}` : null)
  const [loader, setLoader] = useState(false)
  const [botNameFields, setBotNameFields] = useState({ isUpdate: false, name: null, err: null })
  const [botImageFields, setBotImageFields] = useState({ icon: null, err: null })
  const [botLoad, setBotLoad] = useState(false)
  const inputRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    colorSettingsArray?.map((settings, index) => {
      const elements = document.querySelectorAll(`.${settings.elementClassName}`);
      elements.forEach((el) => {
        el.style.setProperty(settings.props, settings.color, 'important');
      });
    })
  }, [colorSettingsArray]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [botNameFields]);

  useEffect(() => {
    if (!!selectedTemplate && !!bot_id) {
      getBotStyle()
    }
    if (selectedTemplate == null) {
      setColorSettingsArray(colorArrInitialState)
      setBotNameFields({ ...botNameFields, err: null })
      setBotImageFields({ ...botImageFields, err: null })
    }
  }, [selectedTemplate]);

  const getBotStyle = async () => {
    let styleArr = [...colorSettingsArray]
    let body = {
      bot_id: bot_id,
    }
    const response = await CallAPI("BOT", "POST", BOT_APPEARANCE_DETAILS, body);
    props.setFinalStep(response?.res.data?.data[0].completedStep)
    setBotNameFields({ ...botNameFields, name: response?.res.data?.data[0].bot_name || "test" })
    setBotImageFields({ ...botImageFields, icon: response?.res.data?.data[0]?.bot_image || "" })
    setBotLoad(true)
    localStorage.setItem("botStyle", JSON.stringify(response?.res.data?.data[0].styles))
    for (let [key, value] of Object.entries(response?.res.data?.data[0].styles)) {
      var foundIndex = styleArr.findIndex(x => x.key == key);
      if (foundIndex != -1) styleArr[foundIndex].color = value;
    }
    setColorSettingsArray(styleArr)
  };

  const rgbaToHex = (r, g, b, a) => {
    r = Math.min(255, Math.max(0, r));
    g = Math.min(255, Math.max(0, g));
    b = Math.min(255, Math.max(0, b));
    a = Math.min(1, Math.max(0, a));

    const hex = (x) => x.toString(16).padStart(2, '0');
    const alphaHex = Math.round(a * 255).toString(16).padStart(2, '0');

    return `#${hex(r)}${hex(g)}${hex(b)}${alphaHex}`;
  }

  const handleSetColor = (rgba, key) => {
    const hexColor = rgbaToHex(rgba.r, rgba.g, rgba.b, rgba.a);
    let laColorSettings = [...colorSettingsArray]

    var foundIndex = laColorSettings.findIndex(x => x.key == key);
    laColorSettings[foundIndex].color = hexColor;

    setColorSettingsArray(laColorSettings)
  }

  const handleSaveStep1 = async () => {
    setLoader(true)
    if (!!botNameFields.name) {
      let body = {}
      body.step = 1
      body.bot_name = botNameFields.name
      body.styles = {}
      colorSettingsArray.map((colorObj, ind) => {
        body.styles[colorObj.key] = colorObj.color
      })
      if (!!bot_id) {
        body.bot_id = bot_id
      }
      else {
        props.setFinalStep(1)
      }

      body.template_id = selectedTemplate.split("#")[1]
      body.template_name = selectedTemplate

      localStorage.setItem("botStyle", JSON.stringify(body.styles))
      const response = await CallAPI("BOT", "POST", BOT_APPEARANCE, body)
      console.log("response-----", response)
      if (response.status) {
        if (!!botImageFields.icon && typeof (botImageFields.icon) == "object") {
          const formData = new FormData();
          formData.append('files', botImageFields.icon);
          formData.append('bot_id', response.res.data.bot_id);
          await CallAPI("BOT", "POST", BOT_CHAT_ICON, formData, "file")
        }
        else {
          let url = `${BOT_CHAT_ICON_REMOVE}/${response.res.data.bot_id}`
          await CallAPI("BOT", "POST", url, {})
        }
        if (!bot_id) {
          appendQueryParam({ id: response?.res.data.bot_id, temp: selectedTemplate.split("#")[1] })
        }
        props.handleSetTab("2", botNameFields.name)
        showToast(response.msg,
          LABEL.TOAST_TYPE.SUCCESS
        );
      }
    }
    else if (!botNameFields.name) {
      setBotNameFields({ ...botNameFields, err: "Please enter a bot name" })
    }
    setLoader(false)
  }

  const resetIcon = async () => {
    setBotImageFields({ icon: null, err: null })
  }

  const appendQueryParam = (params) => {
    const searchParams = new URLSearchParams(location.search);
    for (const [key, value] of Object.entries(params)) {
      searchParams.set(key, value);
    }
    navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
  };

  const handleUplaodBotImage = (e) => {
    let file = e.target.files[0]
    if (file?.type == 'image/bmp' || file?.type == 'image/jpeg' || file?.type == 'image/png' || file?.type == 'image/gif' || file?.type == 'image/webp') {
      setBotImageFields({ ...botImageFields, icon: file, err: null })
    }
    else {
      setBotImageFields({ ...botImageFields, err: "Only .jpg, .jpeg, .gif, .webp and .png is supported" })
    }
  }


  return (
    (!!bot_id && !botLoad)
      ? <div className="page-loader">
        <img src="/images/loader-bouncing-circles.svg" alt="" />
      </div>
      :
      <div className="tab-content-grid" >
        <div className="tab-content active">
          <div className="chatbot-history-grid">
            <header className="d-md-flex align-items-center justify-content-between">
              <h3>Appearance</h3>
              <div className="group-form d-md-flex">
                {!!selectedTemplate && <button onClick={() => setSelectedTemplate(null)} className="cta-green"><i class="fa-solid fa-angles-left fa-fade"></i> Back to Chatbot Templates</button>}
                {!selectedTemplate && !!bot_id && <button onClick={() => setSelectedTemplate(new URLSearchParams(location?.search).get("temp"))} className="cta-green">Continue Editing</button>}
                {!!selectedTemplate && <button disabled={!!loader} className="cta-green" onClick={() => handleSaveStep1()}>{!!loader && <i class="fa-solid fa-spinner fa-spin"></i>} Save and continue</button>}
              </div>
            </header>

            <div className="appearance-grid">
              {/* {Object.keys(components).map((key) => {
              const Component = components[key];
              return (
                <div className="dark-bg2 box-38">
                  <Component type={"appearance"}/>
                </div>
              );
            })} */}
              <Templates type={"appearance"} selectedTemplate={selectedTemplate} setSelectedTemplate={setSelectedTemplate} botIcon={botImageFields.icon} />
              {!!selectedTemplate &&
                <div className="appearance-select-color dark-bg2 box-24" >
                  <h3>Style</h3>
                  {colorSettingsArray?.length > 0 && colorSettingsArray.map((settings, index) => (
                    <div className="field">
                      <label>{settings.lable}</label>
                      <div className="form-select" style={{ backgroundColor: settings.color }} onClick={() => { setShowIndex(index) }} />
                      {showIndex == index &&
                        <div className="choose-color_style">
                          {/* onBlur={() => setShowIndex(null)} */}
                          <img alt="avatar" src="/images/icons/modal-close-icon14x14.png" class="choose-color_style_modal" onClick={() => setShowIndex(null)} />
                          <SketchPicker
                            color={settings?.color} // Set the initial color
                            onChange={(value) => handleSetColor(value.rgb, settings.key)} // Handle color change
                          />
                        </div>
                      }
                    </div>
                  ))}

                </div>}
              {!!selectedTemplate &&
                <div className="appearance-select-color dark-bg2 box-24" >
                  <div className="botname_block border rounded">
                    <h3>Bot Name *</h3>
                    <div className={!botNameFields.isUpdate ? "botname-block-form active" : "botname-block-form"}>
                      <input ref={inputRef} value={botNameFields.name} type="text" disabled={!botNameFields.isUpdate}
                        onChange={(e) => { e.preventDefault(); setBotNameFields({ ...botNameFields, name: !!(e.target.value.trim()) ? e.target.value : '', err: null }); }}
                        onBlur={() => setBotNameFields({ ...botNameFields, isUpdate: false })}
                      />
                      {!botNameFields.isUpdate && <img src="/images/icons/edit-icon.svg" alt="" onClick={() => setBotNameFields({ ...botNameFields, isUpdate: true })} />}
                    </div>
                    {!!botNameFields.err && <span>{botNameFields.err}</span>}
                  </div>
                  <div className="botname_block border rounded">
                    <h3>Bot Icon *</h3>
                    {!!botImageFields.icon && <button onClick={() => resetIcon()} class="cta-green">Reset Icon</button>}
                    <div className={!botNameFields.isUpdate ? "botname-block-form active" : "botname-block-form"}>
                      <div className="profile-photo_block">
                        <figure><img
                          alt="avatar"
                          src={!!botImageFields.icon && typeof (botImageFields.icon) == "object"
                            ? URL.createObjectURL(botImageFields.icon)
                            : !!botImageFields.icon
                              ? botImageFields.icon
                              : "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"} /></figure>
                        <div class="file-upload-grid">
                          <img
                            alt="avatar"
                            src="/images/camera-solid.svg" />
                          <input
                            type="file"
                            id="file-upload"
                            class="file-upload-input"
                            accept="image/*"
                            onClick={e => (e.target.value = null)}
                            onChange={(e) => handleUplaodBotImage(e)} />
                        </div>
                      </div>
                      {!!botImageFields.err && <span>{botImageFields.err}</span>}
                    </div>
                  </div>
                </div>}
            </div>

          </div>
        </div>
      </div>
  )
}

import { CircularProgress, LinearProgress } from "@mui/material";
import React from "react";
const TopChatBot = ({ data = [], isLoading = false }) => {
  const getColor = (index) => {
    const colors = ["#ff5c8d", "#75ff36", "#28aef3", "#903fd7"];
    return colors[index % colors.length];
  };
  return (
    <div className="chart-block dark-bg">
      <header>
        <h6>Top Active Bots</h6>
      </header>
      <div className="table-responsive">
        {data.length ? (
          <table className="activeBotsTable">
            <thead>
              <tr>
                <th className="text-left">#</th>
                <th className="text-left">Name</th>
                <th className="text-left">Popularity</th>
              </tr>
            </thead>
            <tbody>
              {data.map((chatbot, index) => (
                <tr key={chatbot.bot_id}>
                  <td className="text-left">
                    {String(index + 1).padStart(2, "0")}
                  </td>
                  <td className="text-left">{chatbot.bot_name}</td>
                  <td className="text-left">
                    <LinearProgress
                      variant="determinate"
                      value={parseInt(chatbot.popularity.split("%")[0])}
                      sx={{
                        "& .MuiLinearProgress-bar": {
                          backgroundColor: getColor(index), // Replace with your custom color
                        },
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No records for this year range.</p>
        )}
      </div>
    </div>
  );
};
export default TopChatBot;

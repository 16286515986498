import React from 'react';
import { MuiTelInput } from "mui-tel-input"
import styled from 'styled-components';
import PhoneNumber from "awesome-phonenumber"
import moment from 'moment';

const ChatTemplate = styled.div`
  z-index: 100;
  position: fixed;
  border-radius: 16px;
  overflow: hidden;
  font-family: "Poppins", arial, sans-serif;
  font-size: 16px;

  *, ::after, ::before {
    box-sizing: border-box;
    }

    .d-flex {display: flex;}
    .justify-content-between {justify-content: space-between;}
    .justify-content-end {justify-content: flex-end;}
    .justify-content-start {justify-content: flex-start;}
    .align-items-center {align-items: center;} 
    .flex-row {flex-direction: row;}
    .rounded-3 {border-radius: 0.5rem;}
    .small{font-size: .875em;}

    .text-white{color: #ffffff;}
    .p-3{padding: 1rem;}
    .ms-3 {margin-left: 1rem;}
    .mb-4 {margin-bottom: 1.5rem;}

    .cta-border-w{
        display: inline-block; text-align: center;
        text-decoration: none; position: relative;
        border: 0px; outline: none;
        cursor: pointer; padding: 10px 15px; margin-right:3px;
        line-height: 1.2; letter-spacing: 0.5px;
        white-space: nowrap; font-weight: 400;
        border-radius: 30px; font-size: 14px;
        border: 1px solid #ffffff;
        color:#ffffff; font-size: 14px;
        background: transparent;
    }

    p{margin: 0px; line-height: 1.2; font-size: 16px; font-family: "Poppins", arial, sans-serif;}
    h5{margin-bottom:0; font-size: 16px; font-weight: 700; font-family: "Poppins", arial, sans-serif;}

    .card-header{
        p{font-weight: 700; line-height: 1.85;}

        .live-chat-close{max-width: 14px; cursor: pointer;}
    }
    .card-body {
        flex: 1 1 auto;
        padding: 1rem;
    }

    &.live-chat-first {
        .left-live-chat{
            border: 1px solid #dee2e6; border-bottom-left-radius: 0 !important;
        }
        .right-live-chat{
            border: 1px solid #dee2e6; border-bottom-right-radius: 0 !important;
        }
        .right-live-chat .chatTime{
            text-align: right; display: block;
        }
        .chatflow-option{
            margin-top:5px;
            display: flex;
            gap: 5px;

            .cta-border-w{
                border-radius: 5px;
                padding: 5px 10px;
                font-size: 12px;
            }
        }
    }
`;

const LiveChatboxGrid = styled.div`
    position: relative;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &.live-chatbox_grid{
        background-color: #fff;

        .card-body{
            height: calc(100% - 128px) !important;
        }
    }
    .chatbot-process_message_one,
    .chatbot-process_message_two{
        font-size: 12px;
        text-align: center;
        padding: 10px;
        color: #ffffff;
        background-color:rgba(0, 0, 0, 0.4);
        box-shadow: 0 2px 4px 0px rgba(255, 255, 255, 0.3);
        z-index: 9;
    }    
    .Welcome_message_one p,
    .Welcome_message_two p{
        font-size: 14px;
        line-height: 1.4;
    }
    .Welcome_message_one,
    .Welcome_message_two{        
        background-color:rgba(0, 0, 0, 0.2);       
        border-radius: 5px;
        padding: 10px;
        font-size: 14px;
        font-family: "Poppins", arial, sans-serif;
    }   
    
    .cta-link{
        border: 1px solid #fff; 
        color: #ffffff;      
        border-radius: 50px;
        padding: 5px 10px;
        font-size: 12px;
        outline: none;
        min-width: 70px;
        margin: 10px 5px 0 0;
        font-family: "Poppins", arial, sans-serif;
    }
`;

const LiveChatboxMessage = styled.div`
  height: calc(100% - 120px);
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow-y: auto;
  margin-bottom: 78px;
`;

const LiveChatboxTime = styled.span`
  font-size: 11px; 
  margin-top: 5px; 
  color: #355266;
  display: block;
`;
const LiveChatboxTime2 = styled.span`
  font-size: 11px; 
  margin-top: 5px; 
  color: #355266;
  display: block;
  text-align: right;
`;

const MessageBox = styled.div`
  align-items: flex-start;
`;

const LiveChatboxFooter = styled.div`
    bottom: 0;
    position: absolute;
    padding: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #fff;

    &.live-chat_one {background: #f8f8f8;}

    &.live-chat_two {background: #f8f8f8;}

    .w-100 {width: 100%;}
    .form-control-lg {border-radius: 0.5rem;}
    .paper-plane_icon{background: transparent; padding: 0px; outline: none; border: 0px;}
    .paper-plane_icon img{width: 20px;}

    /* Add New Css for live chat (03-01-2025) */
    &.chatBot_live_one { 
        .MuiInputBase-root.MuiOutlinedInput-root{padding-left:2px;}
        .MuiFormControl-root{background-color: #6c757d; border-radius: 0.5rem; border: 0px;}
        .MuiFormControl-root input.MuiInputBase-input{color: #ffffff !important; padding: 12px 10px !important;}
        .MuiTypography-body1{color: #ffffff !important;}
        .MuiOutlinedInput-notchedOutline {border: 0px !important; border-radius: 0px !important;}
        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{border: 0px;}

        .form-control-lg{
            min-height: 43px;
        }
    }
    &.chatBot_live_two {
        background: #f8f8f8;
        .bg-white{
            background: #ffffff !important;
        }
        .pr-10 {
            padding-right: 10px;
        }
        .form-control-lg{
            border: 0; width: 100%; font-size: 14px; display: block;
            padding: 12px 10px 12px 10px; background: #ffffff; min-height: 43px;
            color: #000000; font-family: "Poppins", arial, sans-serif;

            &:focus {border: 0px; outline: none;}
            &::-webkit-input-placeholder {color: #000000;}
            &::-moz-placeholder {color: #000000;}
            &::-ms-placeholder {color: #000000;}
            &::placeholder {color: #000000;}
        }
        .MuiFormControl-root input.MuiInputBase-input{color: #000000 !important; padding: 12px 10px 12px 10px !important;}
        .MuiTypography-body1{color: #000000 !important;}
        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{border: 0px;}                
    }
`;

const FormControlLg = styled.input`
    border: 0;
    width: 100%;
    font-size: 14px;
    display: block;
    padding: 12px 10px 12px 10px;
    background: #6c757d;
    color: #ffffff;

    &.live-chat_one::-webkit-input-placeholder {color: #fff;}
    &.live-chat_one::-moz-placeholder {color: #fff;}
    &.live-chat_one::-ms-placeholder {color: #fff;}
    &.live-chat_one::placeholder {color: #fff;}
    &.form-control-lg:focus {border: 0px; outline: none;}

    &.live-chat_two {
        background: #fff;
    }  
`;

const BotColorBox = styled.div`
  border-radius: 15px;
  background-color: rgba(57, 192, 237, 0.2);
  padding: 10px;
`;

const SenderColorBox = styled.div`
  border-radius: 15px;
  padding: 10px;
  background-color: #e9ecef;
`;

const AvatarImage = styled.img`
  width: 45px;
`;



export function BotAppearance1({ ...props }) {

    console.log("props?.messageList1--", props?.messageList, props?.type, props?.openBotStyle)

    return (
        <div >
            <ChatTemplate className="col-md-12 col-lg-12 col-xl-12 live-chat-first" style={props?.openBotStyle}>
                <LiveChatboxGrid className="card" id="chat1" style={{ borderRadius: '15px' }}>
                    <div
                        className="card-header header-back-color d-flex justify-content-between align-items-center p-3 bg-info text-white border-bottom-0"
                        style={{ borderTopLeftRadius: '15px', borderTopRightRadius: '15px' }}
                    >
                        <p className="mb-0 fw-bold" style={{ fontFamily: props.fontStyle, fontSize: props.fontSize }}>Live Chat</p>
                        <img src="https://coolbizz-dev.s3.us-east-1.amazonaws.com/public/close.png" className="live-chat-close" onClick={() => props?.setOpenBotTestModal(false)}></img>

                    </div>
                    <div className="page-back-color bot-text-color chatbot-process_message_one">This chatbot processes queries to identify key topics and may anonymize data to train AI models.</div>
                    {/* {(!!props?.consentParams?.chatHistory && !!props?.consentParams?.trustedDevice)
                        ?
                        <> */}
                    <LiveChatboxMessage ref={props.chatContainerRef} className="card-body page-back-color">
                        {props?.messageList?.length > 0 && props?.messageList?.map((msg, index) => (
                            <>
                                {msg.directionType == "receive"
                                    ? <MessageBox className="d-flex flex-row justify-content-start mb-4 message_box align-items-start">
                                        <AvatarImage src={props?.botBubbleIcon} alt="avatar 1" />
                                        <div className="ms-3">
                                            <BotColorBox className="p-3 bot-color-box left-live-chat"
                                                style={{ borderRadius: '15px', backgroundColor: 'rgba(57, 192, 237,.2)' }}>
                                                <p className="small mb-0 bot-text-color" style={{ fontFamily: props.fontStyle, fontSize: props.fontSize }}>{msg?.question}</p>
                                                {msg?.options &&
                                                    <ul className="chatflow-option">
                                                        {msg?.options?.length > 0 && msg.options.map((option, ind) =>
                                                        (msg?.type == "single-choice"
                                                            ? <button disabled={msg.isAnswered} className="cta-border-w option-color" style={{ fontFamily: props.fontStyle, fontSize: props.fontSize }} onClick={() => props.handleReply(option, "single-choice", msg?.consentType)}>{option.label}</button>
                                                            : < >
                                                                <input type="checkbox"
                                                                    disabled={msg.isAnswered}
                                                                    value={option}
                                                                    onClick={() => props.handleSetInputvalue(option, "multi-choice")}
                                                                    onKeyDown={(e) => props.handleKeypress(e, props?.type)} />
                                                                <label for="vehicle1">{option}</label><br />
                                                            </>)
                                                        )}
                                                    </ul>}
                                                <LiveChatboxTime>{moment.utc(msg.datetime).local().format('hh:mm A')}</LiveChatboxTime>
                                            </BotColorBox>

                                        </div>
                                    </MessageBox>
                                    : <MessageBox className="d-flex flex-row justify-content-end mb-4">
                                        <div className="me-3">
                                            <SenderColorBox className="p-3 border bg-body-tertiary sender-color-box right-live-chat" style={{ borderRadius: '15px' }}>
                                                <p className="small mb-0 sender-text-color" style={{ fontFamily: props.fontStyle, fontSize: props.fontSize }}>{msg.user_message}</p>
                                                <LiveChatboxTime2>{moment.utc(msg.datetime).local().format('hh:mm A')}</LiveChatboxTime2>
                                            </SenderColorBox>
                                        </div>
                                    </MessageBox>}
                            </>
                        )
                        )}
                        {props.responseDelay &&
                            <MessageBox className="d-flex flex-row justify-content-end mb-4 message_box align-items-start">
                                <AvatarImage src={props?.botBubbleIcon} alt="avatar 1" />
                                <div>
                                    <p className="small mb-0 bot-text-color" style={{ fontFamily: props.fontStyle, fontSize: props.fontSize }}>...</p>
                                </div>
                            </MessageBox>}
                    </LiveChatboxMessage>

                    <LiveChatboxFooter className="card-footer footer-back-color live-chatbox_footer live-chat_one chatBot_live_one text-muted d-flex justify-content-start align-items-center p-3">
                        <div className="card-footer_box d-flex align-items-center w-100">
                            {props?.type == "phone"
                                ? <MuiTelInput
                                    name="mobile"
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            fontFamily: { fontFamily: props.fontStyle },
                                            fontSize: { fontSize: props.fontSize }// Change font-family here
                                        },
                                        '& .MuiTypography-body1  ': {
                                            fontFamily: { fontFamily: `${props.fontStyle} !important` },
                                            fontSize: { fontSize: `${props.fontSize} !important` }// Change font-family for the country code options in the dropdown
                                        },
                                    }}
                                    value={props.inputValue}
                                    id="input-control-id"
                                    fullWidth={true}
                                    variant="outlined"
                                    forceCallingCode
                                    onChange={(e) => props.handleSetInputvalue(e, "phone")}
                                    onKeyDown={(e) => props.handleKeypress(e, props?.type)}
                                    enableLongNumbers={true}
                                    autoFormat={false}
                                    disableAreaCodes={true}
                                    countryCodeEditable={false}
                                    autoComplete="off"
                                    className="customtel"
                                />
                                : <FormControlLg
                                    id="input-control-id"
                                    value={props.inputValue}
                                    disabled={props?.type == "single-choice"}
                                    type="text"
                                    className="form-control-lg live-chat_one"
                                    placeholder="Type your message"
                                    style={{ fontFamily: props.fontStyle, fontSize: props.fontSize }}
                                    onChange={(e) => props.handleSetInputvalue(e)}
                                    onKeyDown={(e) => props.handleKeypress(e, props?.type)}
                                />}


                            <button className="ms-3 paper-plane_icon" disabled={props.inputValue == "" ? true : false} onClick={(e) => props.handleReply(e, props?.type)}>
                                <img src="https://coolbizz-dev.s3.us-east-1.amazonaws.com/public/paper-plane-icon.svg" alt=""></img>
                            </button>

                        </div>
                    </LiveChatboxFooter>
                    {/* </>
                        :
                        <div className="card-body page-back-color bot-text-color">
                           <div className="Welcome_message_one">
                                <p>
                                    {!!props?.consentParams?.trustedDevice
                                    ? "Welcome! Would you like to save your chat history for future reference? Type YES to set up secure access, or type NO to continue without saving. Note: If YES, your chat history will be saved for 30 days. If NO, your chat history will be automatically purged when the chat ends. "
                                    : "Welcome! Trust This Device for 30 Days? "}
                                </p>
                                <button className="cta-link header-back-color" onClick={() => props.handleChatbotConsent(!!props?.consentParams?.trustedDevice ? "chatHistory" : "trustedDevice", "yes")}>Yes</button>
                                <button className="cta-link header-back-color" onClick={() => props.handleChatbotConsent(!!props?.consentParams?.trustedDevice ? "chatHistory" : "trustedDevice", "no")}>No</button>
                            </div>
                        </div>} */}
                </LiveChatboxGrid>
            </ChatTemplate>
        </div>
    )
}

export function BotAppearance2({ ...props }) {

    console.log("props?.messageList2--", props?.messageList, props?.type, props?.openBotStyle)

    return (
        <div>
            <ChatTemplate className="col-md-12 col-lg-12 col-xl-12" style={props?.openBotStyle}>
                <LiveChatboxGrid className="card live-chatbox_grid" id="chat2">
                    <div className="card-header header-back-color d-flex justify-content-between align-items-center text-white p-3">
                        <h5 className="mb-0" style={{ fontFamily: props.fontStyle, fontSize: props.fontSize }}>Live Chat</h5>
                        <img src="https://coolbizz-dev.s3.us-east-1.amazonaws.com/public/close.png" className="live-chat-close" onClick={() => props?.setOpenBotTestModal(false)}></img>
                    </div>
                    <div className="page-back-color bot-text-color chatbot-process_message_two">This chatbot processes queries to identify key topics and may anonymize data to train AI models.</div>
                    {/* {(!!props?.consentParams?.chatHistory && !!props?.consentParams?.trustedDevice)
                        ?
                        <> */}
                    <LiveChatboxMessage
                        ref={props.chatContainerRef}
                        className="card-body page-back-color perfect-scrollbar ps ps--active-y"
                        style={{ position: 'relative', height: '100%' }}
                        data-mdb-perfect-scrollbar-init
                    >
                        {props?.messageList?.length > 0 && props?.messageList?.map((msg, index) => (
                            <>
                                {msg.directionType == "receive"
                                    ? <MessageBox className="d-flex flex-row justify-content-start">
                                        <AvatarImage src={props?.botBubbleIcon} alt="avatar 1" />
                                        <div className="ms-3">
                                            <p className="small p-3 mb-2 rounded-3 bg-body-tertiary bot-color-box bot-text-color" style={{ fontFamily: props.fontStyle, fontSize: props.fontSize }}>{msg?.question}</p>
                                            {msg?.options &&
                                                <ul className="chatflow-option">
                                                    {msg?.options?.length > 0 && msg.options.map((option, ind) =>
                                                    (msg?.type == "single-choice"
                                                        ? <button disabled={msg.isAnswered} className="cta-border-w option-color" style={{ fontFamily: props.fontStyle, fontSize: props.fontSize }} onClick={() => props.handleReply(option, "single-choice", msg?.consentType)}>{option.label}</button>
                                                        : < >
                                                            <input type="checkbox"
                                                                value={option}
                                                                disabled={msg.isAnswered}
                                                                onClick={() => props.handleSetInputvalue(option, "multi-choice")}
                                                                onKeyDown={(e) => props.handleKeypress(e, props?.type)} />
                                                            <label for="vehicle1">{option}</label><br />
                                                        </>)
                                                    )}
                                                </ul>}
                                            <LiveChatboxTime>{moment.utc(msg.datetime).local().format('hh:mm A')}</LiveChatboxTime>
                                        </div>
                                    </MessageBox>
                                    : <div className="d-flex flex-row justify-content-end mb-4 pt-1">
                                        <div className="me-3">
                                            <p className="small p-3 mb-1 text-white rounded-3 bg-secondary sender-color-box sender-text-color" style={{ fontFamily: props.fontStyle, fontSize: props.fontSize }}>{msg.user_message}</p>
                                            <LiveChatboxTime>{moment.utc(msg.datetime).local().format('hh:mm A')}</LiveChatboxTime>
                                        </div>
                                    </div>}
                            </>
                        )
                        )}
                        {props.responseDelay &&
                            <MessageBox className="d-flex flex-row justify-content-start message_box align-items-start">
                                <AvatarImage src={props?.botBubbleIcon} alt="avatar 1" />
                                <div>
                                    <p className="small p-2 ms-3 mb-1 rounded-3 bg-body-tertiary bot-color-box bot-text-color" style={{ fontFamily: props.fontStyle, fontSize: props.fontSize }}>...</p>
                                </div>
                            </MessageBox>}
                    </LiveChatboxMessage>

                    <LiveChatboxFooter className="card-footer footer-back-color live-chatbox_footer live-chat_two chatBot_live_two text-muted d-flex justify-content-start align-items-center p-3">
                        <div className="card-footer_box rounded-3 pr-10 border d-flex align-items-center w-100 bg-white">

                            {props?.type == "phone"
                                ? <MuiTelInput
                                    name="mobile"
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            fontFamily: { fontFamily: props.fontStyle },
                                            fontSize: { fontSize: props.fontSize } // Change font-family here
                                        },
                                        '& .MuiTypography-body1  ': {
                                            fontFamily: { fontFamily: `${props.fontStyle} !important` },
                                            fontSize: { fontSize: `${props.fontSize} !important` } // Change font-family for the country code options in the dropdown
                                        },
                                    }}
                                    value={props.inputValue}
                                    id="input-control-id"
                                    fullWidth={true}
                                    variant="outlined"
                                    forceCallingCode
                                    onChange={(e) => props.handleSetInputvalue(e, "phone")}
                                    onKeyDown={(e) => props.handleKeypress(e, props?.type)}
                                    enableLongNumbers={true}
                                    autoFormat={false}
                                    disableAreaCodes={true}
                                    countryCodeEditable={false}
                                    autoComplete="off"
                                    className="customtel"
                                />
                                : <FormControlLg
                                    id="input-control-id"
                                    value={props.inputValue}
                                    disabled={props?.type == "single-choice"}
                                    type="text"
                                    className="form-control-lg"
                                    placeholder="Type your message"
                                    style={{ fontFamily: props.fontStyle, fontSize: props.fontSize }}
                                    onChange={(e) => props.handleSetInputvalue(e)}
                                    onKeyDown={(e) => props.handleKeypress(e, props?.type)}
                                />}
                            {/* {props?.type == "phone" && !new PhoneNumber(`${props.inputValue}`).isValid()
                                ? <i className="fas fa-paper-plane button-text-color"></i>
                                : <> */}

                            <button className="ms-3 paper-plane_icon" disabled={props.inputValue == "" ? true : false} onClick={(e) => props.handleReply(e, props?.type)}>
                                <img src="https://coolbizz-dev.s3.us-east-1.amazonaws.com/public/paper-plane-icon.svg" alt=""></img>
                            </button>
                            {/* </>} */}
                        </div>
                    </LiveChatboxFooter>
                    {/* </>
                        :
                        <div className="card-body page-back-color bot-text-color">
                           <div className="Welcome_message_two">
                                <p>
                                {!!props?.consentParams?.trustedDevice
                                    ? "Welcome! Would you like to save your chat history for future reference? Type YES to set up secure access, or type NO to continue without saving. Note: If YES, your chat history will be saved for 30 days. If NO, your chat history will be automatically purged when the chat ends. "
                                    : "Welcome! Trust This Device for 30 Days? "}
                                </p>
                                <button className="cta-link header-back-color" onClick={() => props.handleChatbotConsent(!!props?.consentParams?.trustedDevice ? "chatHistory" : "trustedDevice", "yes")}>Yes</button>
                                <button className="cta-link header-back-color" onClick={() => props.handleChatbotConsent(!!props?.consentParams?.trustedDevice ? "chatHistory" : "trustedDevice", "no")}>No</button>
                            </div>
                        </div>} */}
                </LiveChatboxGrid>
            </ChatTemplate>
        </div>
    )
}
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { user_type } from '../utils/constant';
import { toast } from 'react-toastify';
import MessageNotification from '../components/MessageNotification';

export default function CoolbizAuthHeader({...props}) {

  const { user } = useAuth();
  // const { switchUserForDemoPerpos } = useAuth();
  // const [checked, setChecked] = useState(false);

  // const handleChange = () => {
  //   setChecked(!checked);
  //   switchUserForDemoPerpos(checked ? 'user' : 'admin');
  // };

  return (
    <div className="top-head">
      <button class="navToggle" onClick={()=>props.setNavToggle(true)}>
          <span class="hamburger-menu">
              <span class="bar"></span>                    
          </span>        
      </button>

      <div className="top-head_left">
        <form>
          {/* <div className="page-search">
            <i class="fa-solid fa-magnifying-glass"></i>
            <input type="text" className="form-control" id="txtSearch" name="txtSearch"
              placeholder="Search" />
          </div> */}
        </form>
      </div>
      <div className="top-head_right">
        <ul className="user-notification">
          {/* <li className="notification">

            Need to remove after api 
            <Form>
              <Form.Check
                type="switch"
                id="custom-switch"
                label={checked ? 'Admin View' : 'User view'}
                checked={checked}
                onChange={handleChange}
              />
            </Form>
          </li> */}

          {/* ********************* */}
          {user?.role==1 && <li className="notification">
            <div className="cta-purple-with-text">
              {user?.isTrial==1 ? "Free Trial - Active" : `${user?.userSubscription.plan.planName} - Active`}
            </div>
          </li>}
          <li className="notification">
            <Link to={user_type[user?.userType] === "super_admin"
                  ? "/admin/notifications":"/notifications"}>
              {/*<i className="fa-sharp fa-light fa-bell"></i>*/}
              <img src="/images/icons/bell-icon.svg" alt="bell-icon" />
              {/* <spna>2</spna> */}
            </Link>
          </li>
          <li className="login-user">
            <Link to="/profile">
              <span><img src={!!user?.fileLink ? user?.fileLink : "/images/images-100.png"} alt="images-100" /></span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

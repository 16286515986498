import React, { useEffect, useState } from "react";
import useDashboard from "../../hooks/useDashboard";
import { formatTimestamp } from "../../utils/constant";
import SlashScreen from "../SplashScreen";
import Pagination from "@mui/material/Pagination";

const Notifications = () => {
  const { getNotification, allNotification, isLoading } = useDashboard();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getNotification(currentPage); 
  }, [currentPage]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <>
      <header className="d-md-flex align-items-center justify-content-between">
        <h2>Notifications</h2>
      </header>

      {isLoading ? (
        <SlashScreen />
      ) : (
        <div className="notification_wrap">
          {allNotification?.notifications.length === 0 ? (
            <span>No records found!</span>
          ) : (
            <>
              {allNotification.notifications.map((item, index) => (
                <a
                  href="javascript:void(0)"
                  className={
                    true ? "notification_item" : "notification_item disabled"
                  }
                  key={index}
                >
                  <figure>
                    <img src="/images/icons/bell-icon.svg" alt="bell-icon" />
                  </figure>
                  <h5>{item.notificationTitle}</h5>
                  <p>{item.notificationBody}</p>
                  <span className="notificationTime">
                    {formatTimestamp(item.createdAt)}
                  </span>
                </a>
              ))}

              {/* Pagination */}
              <div className="pagination_wrap">
          <div className="pagination-info">
            Showing {(allNotification.currentPage - 1) * 10 + 1} to{" "}
            {Math.min(
              allNotification.currentPage * 10,
              allNotification.totalRecords
            )}{" "}
            of {allNotification.totalRecords} entries
          </div>
              <div className="pagination-table">
                <Pagination
                  count={allNotification.totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="secondary"
                  shape="rounded"
                />
              </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Notifications;

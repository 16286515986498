import { useEffect, useRef, useState } from "react";
import { BotTemplate1 } from "../components/my-chatboat/Templates/TemplateCollection.jsx";

const ResizableDiv = ({ ...props }) => {
  // Initial size of the div
  const [size, setSize] = useState({ width: 360, height: 425 });

  // References to store initial mouse position and div size
  const resizerRef = useRef(null);
  const initialMousePos = useRef({ x: 0, y: 0 });
  const initialSize = useRef({ width: size.width, height: size.height });

  useEffect(() => {
    setSize({ width: props.editContentData.width, height: props.editContentData.height })
  }, [props.editContentData.width, props.editContentData.height]);

  // Start resizing handler
  const handleMouseDown = (e) => {
    // Store initial mouse position and div size
    initialMousePos.current = { x: e.clientX, y: e.clientY };
    initialSize.current = { width: size.width, height: size.height };

    // Add mouse move and mouse up event listeners
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  // Mouse move handler
  const handleMouseMove = (e) => {
    // Calculate the new width and height based on mouse movement
    const dx = e.clientX - initialMousePos.current.x;
    const dy = e.clientY - initialMousePos.current.y;

    props.setEditContentData({ ...props.editContentData, width: Math.max(initialSize.current.width + dx, 220), height: Math.max(initialSize.current.height + dy, 315), })
    setSize({
      width: Math.max(initialSize.current.width + dx, 220), // Minimum width of 50px
      height: Math.max(initialSize.current.height + dy, 315), // Minimum height of 50px
    });
  };

  // Mouse up handler (end resize)
  const handleMouseUp = () => {

    // Remove the event listeners
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  return (
    <div
      style={{
        position: 'fixed',
        top: `${props.resizableDivPos.y}px`,
        left: `${props.resizableDivPos.x}px`,
      }}>
      <div
        style={{
          width: `${size.width}px`,
          height: `${size.height}px`,
          background: "#903fd7",
          opacity: "90%",
          position: 'fixed',
          userSelect: 'none',
          borderRadius: '15px',
          padding: '20px',
          border: "dotted"
        }}
      >

      <img src="https://coolbizz-dev.s3.us-east-1.amazonaws.com/public/close.png" 
      onClick={() => props?.setOpenBotTestModal(false)}
        style={{
          width: `14px`,
          position: 'absolute',
          right: '10px',
          top: '10px',
          cursor: 'pointer',
        }}
        />
       <p
        style={{
          lineHeight: '22px',
          margin: '20px 0 0',
        }}
        >
        dragging the right buttom cornner you can resize the bot window
       </p> 
        
        <div
          ref={resizerRef}
          onMouseDown={handleMouseDown}
          style={{
            width: '10px',
            height: '10px',
            backgroundColor: 'darkblue',
            borderRadius: '100%',
            position: 'absolute',
            bottom: 0,
            right: 0,
            cursor: 'se-resize', // Resize cursor style
          }}
        />
      </div>
    </div>
  );
};

export default ResizableDiv;
import React from 'react';

const CardStattics = ({ ...props }) => {
    return (
        <div className="dashboard-feature d-flex justify-content-between">
            {props.cardDetails?.length > 0 && props.cardDetails.map((card, index) => (
                <div className="cards total-chatbot">
                    <img src="/images/feature-curve-bg.svg" className="feature-curve-bg" alt="" />
                    <div className="cards_feature">
                        <figure><img src="/images/icons/chatbot-icon.svg" alt="" /></figure>
                        <aside>
                            <h4>{card.label}</h4>
                            <span>{card.count}</span>
                        </aside>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default CardStattics;
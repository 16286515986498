import moment from "moment";
import React from "react";

const ExportCSV = (csvString,fileName_props="",forUserDetails=false) => {
  try {
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const formattedDate = moment().format("DD_MMM_YYYY");
      const fileName =fileName_props? fileName_props.split('filename="')[1].split('"')[0]||`Exported_List_${formattedDate}.csv`:forUserDetails?`User_Details_${formattedDate}.csv`:`Reveneu_Report_${formattedDate}.csv`;
      const url = URL.createObjectURL(blob); 
      link.setAttribute("href", url);
      link.setAttribute("download", fileName); 
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click(); 
      document.body.removeChild(link); 
    }
  } catch (error) {
    console.error("Error exporting CSV:", error);
  }
};

export default ExportCSV;


import React, { useCallback, useEffect, useMemo, useState } from "react";
import useCmsManagement from "../../hooks/useCmsManagement";
import CommonTable from "../UI/Table";
import {
  LABEL,
  replaceActionCallback,
  TABLE_CONSTANT,
} from "../../utils/constant";
import ConfirmationModal from "../UI/ConfirmationModal";
import { showToast } from "../../helper/toastHelper";
import { Link, useNavigate } from "react-router-dom";
import TableSearchFilter from "../UI/TableSearchFilter";
import UpdateViewModal from "./users/UpdateViewModal";
const getkeyforFilter = (key) => {
  switch (key) {
    case "name":
      return "fullName";
    case "email":
      return "email";
    case "phone number":
      return "phoneNumber";
    case "plan":
      return "planName";
    case "promo":
      return "promoCode";
    default:
      return ""
  }
};
export default function UserManagement() {
  const navigate = useNavigate();
  const { getAllUsers, usersList, isLoading, deleteUsers,exportCSV,isCSVLoading } = useCmsManagement();
  const userStructeredList =
    usersList?.users || [].length
      ? usersList?.users.map((item) => {
          return {
            ...item,
            planName: item.planId ? item?.userSubscription?.plan?.planName : "",
          };
        })
      : [];
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [filterData, setFilterData] = useState({
    search: "",
    filter: "",
  });
  const [selectIdsForExport, setSelectedForExport] = useState([]);
  const [modal, setModal] = useState({
    show: false,
    data: {},
  });
  const fetchUsers = (page = 1) => {
    const la_filterData={...filterData,filter:filterData.search.trim()?getkeyforFilter(filterData.filter):""}
   
    getAllUsers(page, la_filterData);
  };
  const handlePagination = (page = 1) => {
    fetchUsers(page);
  };
  const pagination = useMemo(() => {
    return {
      current: usersList.currentPage,
      pageSize: usersList.pageSize,
      total: usersList.totalRecords,
      onChange: handlePagination,
    };
  }, [usersList.currentPage, usersList.pageSize, usersList.totalRecords]);

  useEffect(() => {
    fetchUsers();
  }, [filterData.search]);
  const afterSuccess = useCallback(
    (msg) => {
      fetchUsers();
      return showToast(msg, LABEL.TOAST_TYPE.SUCCESS);
    },
    [fetchUsers]
  );
  const handleDelete = () => {
    if (selectedItem) {
      deleteUsers(selectedItem.id, afterSuccess);
    }
    cancelDelete();
  };
  const cancelDelete = () => {
    setDeleteModalVisible(false);
    setSelectedItem(null);
  };

  const deletePlanFn = useCallback((item) => {
    console.log(item);
    setSelectedItem(item);
    setDeleteModalVisible(true);
  }, []);

  // const goForEdit = (id) => navigate(`/admin/role/edit/${id}`)
  const editPlanFn = useCallback((item) => {
    setModal({ show: true, data: item });
    // goForEdit(item.id)
  }, []);
  let modifiedActions = TABLE_CONSTANT.usersManagementactions;

  modifiedActions = replaceActionCallback(
    modifiedActions,
    LABEL.TABLE_COLUMN.ACTION.DELETE.LABEL,
    deletePlanFn
  );

  modifiedActions = replaceActionCallback(
    modifiedActions,
    LABEL.TABLE_COLUMN.ACTION.EDIT.LABEL,
    editPlanFn
  );
  const handleFilter = (searchValue, selectedColumns) => {
    console.log("Search Value:", searchValue);
    console.log("Selected Columns:", selectedColumns.toLowerCase());
    setFilterData({
      search: searchValue,
      filter: selectedColumns.toLowerCase(),
    });
  };
  const onClose = () => {
    setModal({ data: {}, show: false });
  }; 
  const handleExportCSV=()=>{
    const la_filterData={...filterData,filter:filterData.search.trim()?getkeyforFilter(filterData.filter):""}
    console.log(selectIdsForExport,"ss")
    exportCSV(la_filterData,selectIdsForExport)
  }

  return (
    <React.Fragment>
      <div className="all-chatboat">
        <header className="d-lg-flex align-items-center justify-content-between">
          <h3 class="mb-md-2">Customer Management</h3>

          <div className="group-form d-md-flex">
            <Link to="/admin/role/create" className="cta-green">
              <i className="fa-solid fa-sparkles" aria-hidden="true"></i>
              New Customer
            </Link>
            <button type="submit" className="cta-green" disabled={!!isCSVLoading} onClick={handleExportCSV}>
              {isCSVLoading ? <i class="fa-solid fa-spinner fa-spin"></i>: <i class="fa-solid fa-download"></i>}
              Export CSV
            </button>
            {/* <div className="cta-green" onClick={handleExportCSV}>
              <i className="fa-solid fa-sparkles" aria-hidden="true"></i>
              Export CSV
            </div> */}
            <TableSearchFilter
              defaultColumn={"NAME"}
              columns={["NAME", "EMAIL", "PHONE NUMBER", "PLAN", "PROMO"]}
              onFilter={handleFilter}
            />
          </div>
          
        </header>
        <CommonTable
          data={userStructeredList}
          columns={TABLE_CONSTANT.usersManagementcolumns}
          actions={modifiedActions}
          pagination={pagination}
          isPagination={true}
          isLoading={isLoading}
          onRowSelectionChange={(data) => setSelectedForExport(data || [])}
          isSelectedRowFeature={true}
        />
      </div>
      {deleteModalVisible && (
        <ConfirmationModal
          handleDelete={handleDelete}
          cancelDelete={cancelDelete}
        ></ConfirmationModal>
      )}
      {modal.show && (
        <UpdateViewModal
          onClose={onClose}
          item={modal.data}
          fetchUsers={fetchUsers}
        />
      )}
    </React.Fragment>
  );
}

import React, { useState, useRef, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

const YearSelector = ({ selectedYear, setSelectedYear }) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const calendarRef = useRef(null);
  const currentYear = new Date().getFullYear();

  const handleYearClick = (date) => {
    const year = date.getFullYear();
    setSelectedYear(year);
    setShowCalendar(false);
  };

  const handleOutsideClick = (e) => {
    if (calendarRef.current && !calendarRef.current.contains(e.target)) {
      setShowCalendar(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, []);

  const displaySelectedYear = () => {
    return selectedYear || "Select Year";
  };

  return (
    <div className="form-select with-dateSelect">
      <button
        onClick={() => setShowCalendar((prev) => !prev)}
        style={{ padding: "10px 15px", cursor: "pointer" }}
      >
        {displaySelectedYear()}
      </button>
      {showCalendar && (
        <div
          ref={calendarRef}
          style={{
            position: "absolute",
            top: "50px",
            right: "0px",
            zIndex: 100,
            backgroundColor: "#21222d",            
          }}
        >
          <Calendar
            view="decade"
            onClickYear={handleYearClick}
            tileDisabled={({ date }) => date.getFullYear() > currentYear + 1}
            tileClassName={({ date }) => {
              return date.getFullYear() === selectedYear ? "selected-year" : null;
            }}
          />
        </div>
      )}
      <style>
        {`
          .react-calendar {
            font-family: Arial, sans-serif;
            width: 300px;
            background-color: #21222d;
            border-radius: 10px;
            overflow: hidden;
          }

          .react-calendar__tile {
            height: 30px !important;
            width: 50px !important;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.9rem;
          }

          .react-calendar__tile:hover {
            background-color: rgb(215, 170, 255) !important;
            cursor: pointer;
          }

          .react-calendar__tile:disabled {
            background-color: #1F2645;
            color: #747474;
          }

          .react-calendar__navigation button:enabled:hover{
            background-color: transparent;
            color: #903fd7 !important;
          }
          .react-calendar__navigation button:enabled:focus {
            background-color: transparent;
            color: #903fd7 !important;
          }

          .react-calendar__navigation {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 40px;
          }

          .react-calendar__navigation button {
            background: none;
            border: none;
            color: #fff;
            font-size: 1.5rem;
            cursor: pointer;
          }

          .react-calendar__tile--now{
            background-color: rgb(215, 170, 255) !important;
          }

          .selected-year {
            background-color: #903fd7 !important;
            color: white !important;
          }
        `}
      </style>
    </div>
  );
};

export default YearSelector;

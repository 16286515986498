import React, { useState } from "react";
import ToggleSwitch from "../../UI/toggle-switch";
import { CallAPI } from "../../../utils/apiActions";
import { showToast } from "../../../helper/toastHelper";
import { LABEL } from "../../../utils/constant";
import { CMS_BASE_URL } from "../../../utils/APIEndPoints";

const UpdateViewModal = ({ onClose = () => {}, item = {},fetchUsers=()=>{} }) => {
  const [status, setStatus] = useState(item.status ? true : false);
  const [loading,setLoading]=useState(false)
  const updateStatus = async () => {
    try {
      const res =await CallAPI(
        "WEBSITE",
        "PUT",
        `${CMS_BASE_URL.UPDATE_USERS_STATUS_BY_ADMIN}/${item.id}`,
        {}
      );
      console.log("res_", res);
      onClose();
      showToast(res?.msg||"Successfully updated!",LABEL.TOAST_TYPE.SUCCESS);
      fetchUsers()
    } catch (err) {
      console.log(err);
    }
  };
  const handleChange = (val) => {
    setStatus(val);
    updateStatus();
  };
  
  const restoreAccount=async()=>{
    setLoading(true)
    try {
      const res =await CallAPI(
        "WEBSITE",
        "PUT",
        `${CMS_BASE_URL.RESTORE_USER_BY_ADMIN}/${item.id}`,
        {}
      );
      onClose();
      showToast(res?.msg||"Successfully Restored!",LABEL.TOAST_TYPE.SUCCESS);
      fetchUsers()
    } catch (err) {
      console.log(err);
    }
    finally{
      setLoading(false)
    }
  }
  return (
    <div class="modalOverlay modal-open">
      <div class="modal-container width-sm modal-dark-bg rounded-4 p-4">
        
        <a href="javascript:void(0)" class="modalClose" onClick={onClose}>
          <img src="/images/icons/modal-close-icon.svg" alt="" />
        </a>

        <div class="user-details-form modal-form-grid pt-3 form-step-2">         
          <h3>User Details</h3>

          <div className="field text-left">
            <label>User Name</label>
            <input value={item?.fullName || ""} class="form-control" placeholder="User Name" disabled />
          </div>
          <div className="field text-left">
            <label>Email</label>
            <input value={item?.email || ""} class="form-control" placeholder="Email" disabled  />
          </div>
          <div className="field text-left">
            <label>Phone Number</label>
            <input value={item?.phoneNumber || ""} class="form-control" placeholder="Phone Number" disabled  />
          </div>

          <div class="d-lg-flex align-items-center justify-content-between">
            <div className="statusToggle">
              <label className="mb-2 text-12">Status</label>
              <div class="d-flex align-items-center gap-3 text-14 mb-4">
                <label>In-Active</label>
                <ToggleSwitch value={status} onChange={handleChange} />
                <label>Active</label>
              </div>
            </div>

           {item?.isDeleted? <button class="cta-green small" onClick={restoreAccount} disabled={!!loading}>
              {loading ? <i class="fa-solid fa-spinner fa-spin"></i>: <i class="fa fa-retweet" aria-hidden="true"></i>} Restore Account
            </button>:<></>}
          </div>
          
        </div>
      </div>
      
    </div>
  );
};

export default UpdateViewModal;

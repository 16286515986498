import React, { useEffect, useReducer, useRef, useState } from "react";
import useAuth from "../../hooks/useAuth";
import validationUtility from "../../utils/Validation";
import { MuiTelInput } from "mui-tel-input";
import {
  EMAIL_VERIFICATION,
  EMAIL_VERIFICATION_OTP,
  EXPORT_USER_DATA,
  PHONE_VERIFICATION,
  PHONE_VERIFICATION_OTP,
  USER_UPDATE,
} from "../../utils/APIEndPoints";
import { LABEL, user_type } from "../../utils/constant";
import { CallAPI } from "../../utils/apiActions";
import { showToast } from "../../helper/toastHelper";
import PhoneNumber from "awesome-phonenumber";
import ChangePasswordSection from "../ChangePasswordSection";
import ResetPassword from "../signin-signup/ResetPassword";
import OtpView from "../OtpView";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import ConfirmationModal from "../UI/ConfirmationModal";
import ExportCSV from "../../utils/ExportCSV";

export default function ProfileComponent({ ...props }) {
  const { user, updateUser,deleteAccount,loader:deleteLoader } = useAuth();
  const [editedData, setEditedData] = useState(user);
  const [otpField, setOtpField] = useState({ otp: "", otpErr: "" });
  const [editedDataErr, setEditedDataErr] = useState({});
  const [editedField, setEditedField] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [loader, setLoader] = useState(false);
  const [loaderExport, setLoaderExport] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const inputRefs = useRef([]);
  inputRefs.current = new Array(5)
    .fill()
    .map((_, i) => inputRefs.current[i] ?? React.createRef());

  useEffect(() => {
    (!!editedField || editedField == 0) &&
      inputRefs.current[editedField].current.focus();
  }, [editedField]);

  const handleEditProfileData = (e, type = "") => {
    if (type == "phone") {
      setEditedData({ ...editedData, phoneNumber: e });
      setEditedDataErr({ ...editedDataErr, phoneNumber: null });
    } else {
      setEditedData({ ...editedData, [e.target.name]: e.target.value });
      setEditedDataErr({ ...editedDataErr, [e.target.name]: null });
    }
  };

  const handleSaveUserProfile = async () => {
    setLoader(true);
    console.log("profileImage--", profileImage);
    let loEditedDataErr = {};
    let flag = true;
    if (user.email != editedData.email || !user.isEmailVerified) {
      flag = false;
      loEditedDataErr.email = "You need to verify your new mail.";
    }
    if (
      user.phoneNumber != editedData.phoneNumber ||
      !user.isPhoneNumberVerified
    ) {
      flag = false;
      loEditedDataErr.phoneNumber = "You need to verify your new phone number.";
    }
    let requiredField = [
      { key: "fullName", value: "Full Name" },
      { key: "email", value: "Email" },
      { key: "phoneNumber", value: "Phone Number" },
    ];
    console.log(
      "phvalid--",
      !new PhoneNumber(`${editedData.phoneNumber}`).isValid()
    );
    requiredField.map((field, ind) => {
      if (
        field.key == "phoneNumber" &&
        !new PhoneNumber(`${editedData.phoneNumber}`).isValid()
      ) {
        flag = false;
        loEditedDataErr[field.key] = "Pnone Number is not valid";
      }
      if (field.key == "email") {
        if (!validationUtility.email(editedData.email)) {
          flag = false;
          loEditedDataErr[field.key] = "Email is not valid";
        } else if (editedData.email.length > 50) {
          flag = false;
          loEditedDataErr[field.key] = "Email can be maximum of 50 characters";
        }
      }
      if (
        editedData[field.key] == null ||
        editedData[field.key] == "" ||
        editedData[field.key] == undefined
      ) {
        flag = false;
        loEditedDataErr[field.key] = `${field.value} is required`;
      }
    });
    if (
      !!editedData.businessUrl &&
      !validationUtility.validUrl(editedData.businessUrl)
    ) {
      flag = false;
      loEditedDataErr.businessUrl = "Not a valid URL";
    }
    console.log("loEditedDataErr--", loEditedDataErr);
    setEditedDataErr({ ...loEditedDataErr });
    if (!!flag) {
      const formData = new FormData();
      profileImage && formData.append("file", profileImage);
      formData.append("fullName", editedData.fullName);
      formData.append("email", editedData.email);
      formData.append("phoneNumber", editedData.phoneNumber);
      !!editedData.organizationName &&
        formData.append("organizationName", editedData.organizationName);
      !!editedData.businessUrl &&
        formData.append("businessUrl", editedData.businessUrl);
      let response = await CallAPI(
        "USER",
        "PUT",
        USER_UPDATE,
        formData,
        "file"
      );
      console.log("response----", response);
      if (response.status) {
        showToast(response.msg, LABEL.TOAST_TYPE.SUCCESS);
        updateUser({ ...editedData, ...response.res.data.data });
      } else {
        showToast(response.msg, LABEL.TOAST_TYPE.ERROR);
      }
    }
    setLoader(false);
  };

  const handleUpdateFile = (e) => {
    let file = e.target.files[0];
    if (
      file?.type == "image/bmp" ||
      file?.type == "image/jpeg" ||
      file?.type == "image/png" ||
      file?.type == "image/gif" ||
      file?.type == "image/webp"
    ) {
      setProfileImage(file);
      setEditedDataErr({ ...editedDataErr, profileImage: null });
    } else {
      setEditedDataErr({
        ...editedDataErr,
        profileImage: "Only .jpg, .jpeg, .gif, .webp and .png is supported",
      });
    }
  };

  const ReSendOtp = () => {
    handleVerify(openModal);
  };

  const handleVerify = async (type = "") => {
    setOtpField({ ...otpField, otp: "", otpErr: "" });
    let URL = type == "email" ? EMAIL_VERIFICATION_OTP : PHONE_VERIFICATION_OTP;
    let body =
      type == "email"
        ? { email: editedData.email, actionType: "email_update" }
        : { phoneNumber: editedData.phoneNumber };
    let response = await CallAPI("USER", "POST", URL, body);
    if (response.status) {
      setOpenModal(type);
    } else {
      showToast(response.msg, LABEL.TOAST_TYPE.ERROR);
    }
  };

  const handleSetOtp = async (value) => {
    setOtpField({ ...otpField, otp: value, otpErr: "" });
    if (value.length == 6) {
      let URL = openModal == "email" ? EMAIL_VERIFICATION : PHONE_VERIFICATION;
      let body =
        openModal == "email"
          ? { email: editedData.email, otp: value, actionType: "email_update" }
          : { phoneNumber: editedData.phoneNumber, otp: value };
      let response = await CallAPI("USER", "POST", URL, body);
      if (response.status) {
        if (openModal == "email") {
          updateUser({
            ...user,
            email: editedData.email,
            isEmailVerified: true,
          });
          setEditedDataErr({ ...editedDataErr, email: null });
        } else {
          updateUser({
            ...user,
            phoneNumber: editedData.phoneNumber,
            isPhoneNumberVerified: true,
          });
          setEditedDataErr({ ...editedDataErr, phoneNumber: null });
        }
        showToast(response.msg, LABEL.TOAST_TYPE.SUCCESS);
        setOpenModal(false);
      } else {
        setOtpField({ ...otpField, otp: "", otpErr: response.msg });
      }
    }
  };
  const handleDeleteAccount = async () => {
    setDeleteModalVisible(!deleteModalVisible)
  }

  const handleDelete=async()=>{
    await deleteAccount()
  }
  const handleExportData=async()=>{
    setLoaderExport(true)
   try{
    const response = await CallAPI("WEBSITE", "GET", `${EXPORT_USER_DATA}`);
    if (response.res.status) {
      // const fileName = response?.res?.headers?.get('Content-Disposition')||""
      // console.log("filename",fileName,response.res.data)
      ExportCSV(response.res.data,"",true)
    }
   }
   catch(err){
    console.log("err",err)
   }
   finally{
    setLoaderExport(false)
   }
  }
  // console.log("editedDataErr---", editedDataErr)

  return (
    <>
      {openModal && (
        <div class="modalOverlay modal-open">
          <div class="modal-container width-small modal-dark-bg rounded-4 p-4">
            <a
              href="javascript:void(0)"
              class="modalClose"
              onClick={() => setOpenModal(false)}
            >
              <img src="/images/icons/modal-close-icon.svg" alt="" />
            </a>
            <div className="loginForm">
              <OtpView
                EmailOrPhone={
                  openModal == "email"
                    ? editedData.email
                    : editedData.phoneNumber
                }
                handleSetOtp={handleSetOtp}
                otp={otpField.otp}
                ReSendOtp={ReSendOtp}
                otpErr={otpField.otpErr}
              />
            </div>
          </div>
        </div>
      )}
      <div className="my-profile-grid">
        <header className="d-md-flex align-items-center justify-content-between">
          <h3>My Profile</h3>

          <div className="group-form d-md-flex">
            {/*
            {user_type[user?.userType] !== "super_admin"&&<Link to="/ghl/form" className="cta-green">
              Connect to Go High Level
            </Link>}*/}

           { user_type[user?.userType] !== "super_admin"&& <button  className="cta-green-border small" onClick={handleExportData} disabled={!!loaderExport}>
             {!!loaderExport?<i class="fa-solid fa-spinner fa-spin"></i>: <img src="images/icons/download-icon-g.svg" alt="" />} Export Data
            </button>}
            {user_type[user?.userType] !== "super_admin"&& <button  className="cta-red-border small" onClick={handleDeleteAccount}>
              <img src="images/icons/delete-icon-red.svg" alt="" /> Delete Account
            </button>}
          </div>
        </header>

        <div className="myprofile-block">
          <ul>
            <li>
              <div className="info_left">
                <p>Photo</p>
                {/* <span>This will be displayed on your profile.</span> */}
              </div>
              <div className="info_right">
                <div className="profile-photo">
                  <figure>
                    <img
                      alt="avatar"
                      src={
                        !!profileImage
                          ? URL.createObjectURL(profileImage)
                          : !!editedData?.fileLink
                          ? editedData?.fileLink
                          : "/images/images-100.png"
                      }
                      sx={{ width: 56, height: 56 }}
                    />
                  </figure>
                  <div className="file-upload-grid">
                    <img alt="avatar" src="/images/camera-solid.svg" />
                    <input
                      type="file"
                      id="file-upload"
                      class="file-upload-input"
                      onChange={(e) => handleUpdateFile(e)}
                    />
                    {!!editedDataErr.profileImage && (
                      <span className="errMsg">
                        {editedDataErr.profileImage}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="info_left">
                <p>Full name</p>
                {/* <span>This will be displayed on your profile.</span> */}
              </div>
              <div className="info_right">
                <div className="edit-text-block">
                  <input
                    name="fullName"
                    ref={inputRefs.current[0]}
                    value={editedData.fullName}
                    type="text"
                    disabled={
                      editedField == 0 || !!editedDataErr.fullName
                        ? false
                        : true
                    }
                    onChange={(e) => handleEditProfileData(e)}
                    onBlur={() => setEditedField(null)}
                  />
                  <a
                    href="javascript:void(0)"
                    className="text-link_purple bold"
                    onClick={() => setEditedField(0)}
                  >
                    Edit
                  </a>                  
                </div>
                {!!editedDataErr.fullName && (
                  <span className="errMsg mt-2">{editedDataErr.fullName}</span>
                )}
              </div>
            </li>
            <li>
              <div className="info_left">
                <p>Email ID</p>
                {/* <span>This will be displayed on your profile.</span> */}
              </div>
              <div className="info_right verify-email_block">
                <div className="edit-text-block">
                  <input
                    name="email"
                    ref={inputRefs.current[1]}
                    value={editedData.email}
                    type="text"
                    disabled={
                      editedField == 1 || !!editedDataErr.email ? false : true
                    }
                    onChange={(e) => handleEditProfileData(e)}
                    onBlur={() => setEditedField(null)}
                  />

                  {/* for email-phone verification need to add By Sharmishtha */}
                  {user.isEmailVerified && user.email == editedData.email ? (
                    <div className="cta-purple-with-text verified-tag">
                      Verified
                    </div>
                  ) : (
                    <a
                      href="javascript:void(0)"
                      class="cta-green verified-tag"
                      onClick={() => handleVerify("email")}
                    >
                      Verify Email
                    </a>
                  )}
                  <a
                    href="javascript:void(0)"
                    className="text-link_purple bold"
                    onClick={() => setEditedField(1)}
                  >
                    Edit
                  </a>                  
                </div>
                {!!editedDataErr.email && (
                  <span className="errMsg mt-2">{editedDataErr.email}</span>
                )} 
              </div>
            </li>
            <li>
              <div className="info_left">
                <p>Phone Number</p>
                {/* <span>your contact number.</span> */}
              </div>
              <div className="info_right verify-phonenumber_block">
                <div className="edit-text-block">
                  <MuiTelInput
                    name="mobile"
                    value={editedData.phoneNumber}
                    inputRef={inputRefs.current[2]}
                    disabled={
                      editedField == 2 || !!editedDataErr.phoneNumber
                        ? false
                        : true
                    }
                    id="full-width-text-field-mobile"
                    fullWidth={true}
                    variant="outlined"
                    forceCallingCode
                    onChange={(e) => handleEditProfileData(e, "phone")}
                    // onBlur={() => setEditedField(null)}
                    enableLongNumbers={true}
                    autoFormat={false}
                    disableAreaCodes={true}
                    countryCodeEditable={false}
                    autoComplete="off"
                    className="customtel"
                    // renderInput={(props) => (
                    //   <input
                    //     {...props}
                    //     ref={inputRefs.current[2]} />)}
                  />

                  {/* for email-phone verification need to add By Sharmishtha */}
                  {user.isPhoneNumberVerified &&
                  user.phoneNumber == editedData.phoneNumber ? (
                    <div className="cta-purple-with-text verified-tag">
                      Verified
                    </div>
                  ) : (
                    <a
                      href="javascript:void(0)"
                      class="cta-green verified-tag"
                      onClick={() => handleVerify("phone")}
                    >
                      Verify Pnone Number
                    </a>
                  )}
                  <a
                    href="javascript:void(0)"
                    className="text-link_purple bold"
                    onClick={() => setEditedField(2)}
                  >
                    Edit
                  </a>                  
                </div>
                {!!editedDataErr.phoneNumber && (
                  <span className="errMsg mt-2">{editedDataErr.phoneNumber}</span>
                )}
              </div>
            </li>

            {props.userType !=
              "affiliate" /*this section condition will be re[placed by authUser user. By Sharmishtha */ && (
              <>
                <li>
                  <div className="info_left">
                    <p>Organization name</p>
                    {/* <span>This will be displayed on your profile.</span> */}
                  </div>
                  <div className="info_right">
                    <div className="edit-text-block">
                      <input
                        name="organizationName"
                        ref={inputRefs.current[3]}
                        value={editedData.organizationName}
                        type="text"
                        disabled={editedField == 3 ? false : true}
                        onChange={(e) => handleEditProfileData(e)}
                        onBlur={() => setEditedField(null)}
                      />
                      <a
                        href="javascript:void(0)"
                        className="text-link_purple bold"
                        onClick={() => setEditedField(3)}
                      >
                        Edit
                      </a>
                      {!!editedDataErr.organizationName && (
                        <span className="errMsg">
                          {editedDataErr.organizationName}
                        </span>
                      )}
                    </div>
                  </div>
                </li>
                <li>
                  <div className="info_left">
                    <p>Business URL</p>
                    {/* <span>This will be displayed on your profile.</span> */}
                  </div>
                  <div className="info_right">
                    <div className="edit-text-block">
                      <input
                        name="businessUrl"
                        ref={inputRefs.current[4]}
                        value={editedData.businessUrl}
                        type="text"
                        disabled={editedField == 4 ? false : true}
                        onChange={(e) => handleEditProfileData(e)}
                        onBlur={() => setEditedField(null)}
                      />
                      <a
                        href="javascript:void(0)"
                        className="text-link_purple bold"
                        onClick={() => setEditedField(4)}
                      >
                        Edit
                      </a>
                      {!!editedDataErr.businessUrl && (
                        <span className="errMsg">
                          {editedDataErr.businessUrl}
                        </span>
                      )}
                    </div>
                  </div>
                </li>
              </>
            )}
          </ul>

          {props.userType ==
            "affiliate" /*this section condition will be re[placed by authUser user. By Sharmishtha */ && (
            <>
              <ChangePasswordSection />
              <hr className="mt-5" />
            </>
          )}

          <div className="mt-4 d-md-flex align-items-center justify-content-end">
            {/*{user_type[user?.userType] !== "super_admin"&& <button  className="cta-green" onClick={handleDeleteAccount}>
              Delete Account
            </button>}*/}

            {user_type[user?.userType] !== "super_admin"&&<Link to="/ghl/form" className="cta-green med">
              Connect to Go High Level
            </Link>}
          </div>
          <div className="mt-5 mb-4">
            <button
              disabled={!!loader}
              className="cta-green"
              onClick={() => handleSaveUserProfile()}
            >
              {!!loader && <i class="fa-solid fa-spinner fa-spin"></i>}Save And
              Continue
            </button>
          </div>
        </div>
      </div>
      {deleteModalVisible && (
        <ConfirmationModal
          handleDelete={handleDelete}
          cancelDelete={handleDeleteAccount}
        ></ConfirmationModal>
      )}
    </>
  );
}

import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { KNOWLEDGE_CATAGORY, KNOWLEDGE_SOURCE, KNOWLEDGE_SOURCE_DOCUMENT } from '../../utils/APIEndPoints';
import { CallAPI } from '../../utils/apiActions';
import validationUtility from '../../utils/Validation';
import Swal from 'sweetalert2';
import { LABEL, paginationObj } from '../../utils/constant';
import { showToast } from '../../helper/toastHelper';
import { Pagination } from '@mui/material';


export default function BotKnowledgeSource({ ...props }) {
  const navigate = useNavigate();
  const location = useLocation();
  const bot_id = (new URLSearchParams(location?.search).get("id")) || null
  const [modalOpen, setModalOpen] = useState(false)
  const [docTypeToUpload, setDocTypeToUpload] = useState(1)
  const [docTypeToFilter, setDocTypeToFilter] = useState(0)
  const [docNameField, setDocNameField] = useState({ data: null, errMsg: "" })
  const [calendarLinkArr, setCalendarLinkArr] = useState([{ name: '', link: '', sno: 1 }])
  const [socialLinkArr, setSocialLinkArr] = useState([{ name: '', link: '', sno: 1 }])
  const [errorMsgArr, setErrorMsgArr] = useState([])
  const [emptyLinkErrMsg, setEmptyLinkErrMsg] = useState(null)
  const [docErrorMsg, setDocErrorMsg] = useState("")
  const [knowlwdgeList, setKnowlwdgeList] = useState(null);
  const [attachmentList, setAttachmentList] = useState([])
  const [loader, setLoader] = useState(true)
  const [editedElement, setEditedElement] = useState(null)
  const [categoryList, setCategoryList] = useState(null)
  const [submissionLoader, setSubmissionLoader] = useState(false)
  const [paginationData, SetPaginationData] = useState({ totalRecords: 0, currentPage: 1, totalPages: 0 })

  useEffect(() => {
    categoryList == null && getCategories()
    getAllBotKnowledge()
  }, [docTypeToFilter, paginationData.currentPage]);

  const getCategories = async () => {
    let response = await CallAPI("BOT", "GET", KNOWLEDGE_CATAGORY)
    setCategoryList(response?.res.data?.data)
  }

  const getAllBotKnowledge = async () => {
    let url = `${KNOWLEDGE_SOURCE_DOCUMENT}/${bot_id}?page=${paginationData.currentPage}&pageSize=${paginationObj.item_per_page}&cate_id=${docTypeToFilter}`
    let response = await CallAPI("BOT", "GET", url)
    console.log("knowledge list", response?.res.data?.data?.data)
    SetPaginationData({ ...paginationData, totalRecords: response?.res.data?.data?.totalRecords, totalPages: Math.ceil(response?.res.data?.data?.totalRecords / paginationObj.item_per_page) })
    setKnowlwdgeList(response?.res.data?.data?.data)
    props.setFinalStep(response?.res.data?.data?.completedStep || 1)
    setLoader(false)
  };

  const addElement = () => {
    if (docTypeToUpload == 3) {
      let laCalendarLinkArr = [...calendarLinkArr]
      laCalendarLinkArr.push({ name: '', link: '', sno: calendarLinkArr.length + 1 })
      setCalendarLinkArr(laCalendarLinkArr)
      console.log("laCalendarLinkArr testARR--", laCalendarLinkArr)
    }
    else if (docTypeToUpload == 2) {
      let laSocialLinkArr = [...socialLinkArr]
      laSocialLinkArr.push({ name: '', link: '', sno: socialLinkArr.length + 1 })
      setSocialLinkArr(laSocialLinkArr)
      console.log("laSocialLinkArr testARR--", laSocialLinkArr)
    }
  }

  const handleRemoveElement = (ind) => {
    if (docTypeToUpload == 3) {
      const calendarItems = [...calendarLinkArr];
      calendarItems.splice(ind, 1);
      setCalendarLinkArr(calendarItems);
    }
    else if (docTypeToUpload == 2) {
      const linkItems = [...socialLinkArr];
      linkItems.splice(ind, 1);
      setSocialLinkArr(linkItems);
    }
  }

  const handleSetFieldValue = (e, ind) => {
    if (docTypeToUpload == 3) {
      const calendarItems = [...calendarLinkArr];
      calendarItems[ind] = {
        ...calendarItems[ind],
        [e.target.name]: e.target.value
      };
      setCalendarLinkArr([...calendarItems])
    }
    else if (docTypeToUpload == 2) {
      const linkItems = [...socialLinkArr];
      linkItems[ind] = {
        ...linkItems[ind],
        [e.target.name]: e.target.value
      };
      console.log("event", e.target.value, docTypeToUpload, ind, linkItems)
      setSocialLinkArr([...linkItems])
    }
  }

  const handleAddKnowledge = async (type = "") => {
    setSubmissionLoader(true)
    let flag = true
    let handleResponse = false
    let body = { step: 2, bot_id: bot_id, cate_id: docTypeToUpload }
    if (docTypeToUpload == 1) {
      if (docNameField.data == "" || docNameField.data == null || docNameField.data == undefined) {
        setDocNameField({ ...docNameField, errMsg: "Please enter your doc name" })
      }
      if (attachmentList.length > 0 && attachmentList.length <= 5) {
        let APImethod = (type == "update" ? "PUT" : "POST")
        const formData = new FormData();
        attachmentList.forEach((file, ind) => {
          formData.append('files', file.document);
        });
        formData.append("bot_id", bot_id)
        formData.append("cate_id", editedElement?.cate_id || docTypeToUpload)
        if (type == "update") {
          formData.append("ks_id", editedElement.ks_id)
          formData.append("slugs", editedElement.slugs)
        }
        else {
          formData.append("step", 2)
          !!docNameField.data && formData.append("source_name", docNameField.data)
        }
        const response = await CallAPI("BOT", APImethod, KNOWLEDGE_SOURCE_DOCUMENT, formData, "file");
        if (response.status) {
          handleResponse = true
          showToast(response.msg, LABEL.TOAST_TYPE.SUCCESS);
        } else {
          showToast(response.msg, LABEL.TOAST_TYPE.ERROR);
        }
      }
      else if (attachmentList.length == 0) {
        setDocErrorMsg("Plesae upload file(s) to add knowledge.")
      }
      else if (attachmentList.length > 5) {
        setDocErrorMsg("Upload maximum 5 files at a time.")
      }
    }
    else if (docTypeToUpload == 3) {
      let calenderErrorMsgArr = []
      let laCalenderArr = []
      calendarLinkArr.map((calendar, ind) => {
        if (!!calendar.name && !!calendar.link && validationUtility.validUrl(calendar.link)) {
          laCalenderArr.push(calendar)
        }
        else if (!calendar.name && !calendar.link) {
          setEmptyLinkErrMsg("Plesae enter details to uplaod")
        }
        else {
          flag = false
          let errObj = { index: ind }
          if (calendar.name == '') {
            errObj.name = "Please enter title"
          }
          if (calendar.link == '') {
            errObj.link = "Please enter link"
          }
          if (!validationUtility.validUrl(calendar.link)) {
            errObj.link = "Please enter an URL"
          }
          calenderErrorMsgArr.push(errObj)
        }
      })
      setErrorMsgArr(calenderErrorMsgArr)
      if (flag) {
        body.arr = laCalenderArr
        console.log("laCalenderArr--", body)
      }
    }
    else {
      let socialErrorMsgArr = []
      let laSocialArr = []
      socialLinkArr.map((social, ind) => {
        if (!!social.name && !!social.link && validationUtility.validUrl(social.link)) {
          laSocialArr.push(social)
        }
        else if (social.name == '' && social.link == '') {
          setEmptyLinkErrMsg("Plesae enter details to uplaod")
        }
        else {
          flag = false
          let errObj = { index: ind }
          if (social.name == '') {
            errObj.name = "enter title"
          }
          if (social.link == '') {
            errObj.link = "enter link"
          }
          if (!validationUtility.validUrl(social.link)) {
            errObj.link = "Please enter an URL"
          }
          socialErrorMsgArr.push(errObj)
        }
      })
      setErrorMsgArr(socialErrorMsgArr)
      if (flag) {
        body.arr = laSocialArr
        console.log("socialErrorMsgArr--", body)
      }
    }
    if ((docTypeToUpload == 2 || docTypeToUpload == 3) && !!flag && body.arr.length>0) {
      const response = await CallAPI("BOT", "POST", KNOWLEDGE_SOURCE, body);
      if (response.status) {
        showToast(response.msg, LABEL.TOAST_TYPE.SUCCESS);
        handleResponse = true
      }
      else {
        showToast(response.msg, LABEL.TOAST_TYPE.ERROR);
      }
    }
    if (handleResponse) {
      getAllBotKnowledge()
      setModalOpen(false)
      setCalendarLinkArr([{ name: '', link: '', sno: 1 }])
      setSocialLinkArr([{ name: '', link: '', sno: 1 }])
      setErrorMsgArr([])
      setDocErrorMsg("")
      setAttachmentList([])
    }
    setSubmissionLoader(false)
  }

  function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  const handleAddAttachments = async (event, type = "") => {
    const selectedFiles = event.target.files ? Array.from(event.target.files) : [];
    let attachmentListsArr = [...attachmentList]
    let flag = true
    if (selectedFiles?.length > 0) {
      selectedFiles.map((file) => {
        if (file?.type == 'application/msword' || file?.type == 'application/pdf' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
          flag = false
          const reader = new FileReader();
          reader.onloadend = () => {
            attachmentListsArr.push({
              document: file,
              filetype: file?.type,
              fileName: file.name,
              documentpreview: reader.result
            })
            console.log("attachmentLists--", attachmentListsArr)
          };
          reader.readAsDataURL(file); // Convert file to base64 string
        }
      })
      if (!!flag) {
        setDocErrorMsg("Only .doc and .pdf is allowed")
      }
    }
    await delay(50);
    setAttachmentList(attachmentListsArr)
  };

  const handleUpdateFile = (e) => {
    const selectedFiles = e.target.files[0]
    if (selectedFiles?.type == 'application/msword' || selectedFiles?.type == 'application/pdf' || selectedFiles.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      let loEditedElement = {}
      loEditedElement.filetype = selectedFiles?.type
      loEditedElement.fileName = selectedFiles.name
      setEditedElement({ ...editedElement, ...loEditedElement, docErr: "" })
      setAttachmentList([{
        document: selectedFiles,
        filetype: selectedFiles?.type,
        fileName: selectedFiles.name,
      }])
    }
    else {
      setEditedElement({ ...editedElement, docErr: "Only .doc and .pdf is allowed" })
    }
    console.log("selectedFiles---", selectedFiles)
  }

  const handleUpdateKnowledge = async () => {
    if (!!editedElement.source_name && !!editedElement.slugs && validationUtility.validUrl(editedElement.slugs)) {
      let body = {
        bot_id: bot_id,
        ks_id: editedElement.ks_id,
        cate_id: editedElement.cate_id,
        name: editedElement.source_name,
        link: editedElement.slugs
      }
      const response = await CallAPI("BOT", "PUT", KNOWLEDGE_SOURCE, body);
      if (response.status) {
        showToast(response.msg, LABEL.TOAST_TYPE.SUCCESS);
        getAllBotKnowledge()
      }
      else {
        showToast(response.msg, LABEL.TOAST_TYPE.ERROR);
      }
      setModalOpen(false)
    }
    else {
      let editedElementErr = {}
      if (editedElement.source_name == null || editedElement.source_name == "" || editedElement.source_name == undefined) {
        editedElementErr.nameErr = "Please enter a name"
      }
      if (!!editedElement.slugs && !validationUtility.validUrl(editedElement.slugs)) {
        editedElementErr.linkErr = "Please enter a valid link"
      }
      if (editedElement.slugs == null || editedElement.slugs == "" || editedElement.slugs == undefined) {
        editedElementErr.linkErr = "Please enter a link"
      }
      setEditedElement({ ...editedElement, ...editedElementErr })
    }
  }

  const RemoveAttachment = (ind) => {
    let attachmentLists = [...attachmentList]
    attachmentLists.splice(ind, 1);
    setAttachmentList(attachmentLists)
  }

  const handleOnModalClose = () => {
    setModalOpen(false)
    setEditedElement(null)
    setErrorMsgArr([])
    setDocErrorMsg("")
  }

  const handleOpenEditModal = (knowledge) => {
    setEditedElement(knowledge)
    setModalOpen(true)
    setEmptyLinkErrMsg(null)
  }

  const handleDeleteKnowlwdge = async (ks_id) => {
    Swal.fire({
      title: "Are you sure you want to delete this knowledge source?",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          let delete_ks_url = `${KNOWLEDGE_SOURCE_DOCUMENT}/${ks_id}`
          let response = await CallAPI("BOT", "DELETE", delete_ks_url);
          if (response.status) {
            getAllBotKnowledge()
            showToast(response.msg, LABEL.TOAST_TYPE.SUCCESS);

          } else {
            showToast(response.msg, LABEL.TOAST_TYPE.ERROR);
          }
        } catch (err) {
          showToast(err.msg, LABEL.TOAST_TYPE.ERROR);
        }
      }
    });
  }

  const handlePaginationChange = (event, value) => {
    SetPaginationData((prev) => ({ ...prev, currentPage: value }));
  };
  
  return (
    <div>
      {modalOpen &&
        (editedElement?.cate_id == 1
          ? <div class="modalOverlay modal-open">
            <div class="modal-container width-sm modal-dark-bg">
              <a href="javascript:void(0)" class="modalClose" onClick={() => handleOnModalClose()}><img src="/images/icons/modal-close-icon.svg" alt="" /></a>
              <div class="modal-form-grid pt-3">
                <form class="form-step-2 text-center">
                  <h3>Update Document</h3>
                  <div class="field">
                    <div class="file-upload-container">
                      <div>
                        <label class="file-upload-img" for="file-upload">
                          {!!editedElement.filetype
                            ? <img src={editedElement.filetype == "application/pdf" ? "images/icons/pdf-icon2.svg" : "images/icons/doc-file2.svg"} alt="" className="docfile-icon" />
                            : <img src="/images/icons/file-upload-icon.png" alt="" />}
                        </label>
                        {!!editedElement
                          ? <label class="file-upload-label" for="file-upload">{!!editedElement.fileName ? editedElement.fileName : editedElement.slugs}</label>
                          : <label class="file-upload-label" for="file-upload">Upload Your Document</label>}
                        <label class="file-upload-label2" for="file-upload">Drag & Drop or <span>Click Here</span> to Update Document</label>
                        <input type="file" id="file-upload" class="file-upload-input" onChange={(e) => handleUpdateFile(e)} />
                        <small>You can upload 5 PDF(s) or Word Document(s) at a time.</small>
                      </div>
                    </div>
                    {editedElement.docErr && <span>{editedElement.docErr}</span>}
                  </div>
                  {/* <div class="document-list-grid text-left">
                    <ul>
                      {attachmentList?.length > 0 && attachmentList.map((attachment, index) => (
                        <li key={index}>
                          <figure onClick={() => RemoveAttachment(index)}>
                            <a href="javascript:void(0)" class="remove-file"><img src="/images/icons/remove-document-icon.png" alt="" /></a>
                            <img src={attachment.filetype == "application/pdf" ? "images/icons/pdf-icon2" : "images/icons/doc-file2.svg"} alt="" />
                          </figure>
                          <span>{attachment.fileName}</span>
                        </li>
                      ))}
                    </ul>
                  </div> */}
                  {!!docErrorMsg && <span>{docErrorMsg}</span>}
                  {/* <div class="field text-left">
                    <label for="txtDocumentName">Document Name</label>
                    <input type="text" class="form-control" id="txtDocumentName" name="txtDocumentName" placeholder="Enter Name" onChange={(e) => setDocNameField({ ...docNameField, data: e.target.value })} />
                  </div> */}
                  <div class="field  mb-0">
                    <button class="cta-green" type="button" onClick={() => handleAddKnowledge("update")}>Update Knowledge</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          : editedElement?.cate_id == 3 || editedElement?.cate_id == 2
            ? <div class="modalOverlay modal-open">
              <div class="modal-container width-sm modal-dark-bg">
                <a href="javascript:void(0)" class="modalClose" onClick={() => { setEditedElement(null); setModalOpen(false) }}><img src="/images/icons/modal-close-icon.svg" alt="" /></a>
                <div class="modal-form-grid pt-3 mt-3">
                  <div class="border-box-grey p-4">
                    <form class="form-step-2 text-center">
                      <h3>Update Knowledge</h3>
                      <div className="field text-left">
                        <input type="text" value={editedElement.source_name} class="form-control" id="" name="name" placeholder="Name" onChange={(e) => setEditedElement({ ...editedElement, source_name: e.target.value, nameErr: "", linkErr: "" })} />
                        {editedElement?.nameErr && <span>{editedElement?.nameErr}</span>}
                      </div>
                      <div className="field text-left">
                        <input type="text" value={editedElement.slugs} class="form-control" id="" name="link" placeholder="https/sitename/knowledge..." onChange={(e) => setEditedElement({ ...editedElement, slugs: e.target.value, linkErr: "", nameErr: "" })} />
                        {editedElement?.linkErr && <span>{editedElement?.linkErr}</span>}
                      </div>

                      {calendarLinkArr?.length > 1 && <a href="javascript:void(0)" onClick={() => handleRemoveElement()}>Remove</a>}
                      <div class="field  mb-0">
                        <button class="cta-green" type="button" onClick={() => handleUpdateKnowledge()}>Update Knowledge</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            : <div class="modalOverlay modal-open">
              <div class="modal-container width-sm modal-dark-bg">
                <a href="javascript:void(0)" class="modalClose" onClick={() => handleOnModalClose()}><img src="/images/icons/modal-close-icon.svg" alt="" /></a>
                <div class="modal-form-grid pt-3">
                  <form class="form-step-2 text-center">
                    <h3>Upload New Document</h3>
                    <div class="field">
                      <select className="form-select" onChange={(e) => setDocTypeToUpload(e.target.value)}>
                        {categoryList?.length > 0 && categoryList.map((docType, ind) => (
                          <option value={docType.id}>{docType.cat_name}</option>
                        ))}
                      </select>
                    </div>
                    {docTypeToUpload == 1
                      ?
                      <>
                        <div class="field">
                          <div class="file-upload-container">
                            <div>
                              <label class="file-upload-img" for="file-upload"><img src="/images/icons/file-upload-icon.png" alt="" /></label>
                              <label class="file-upload-label" for="file-upload">Upload Your Document</label>
                              <label class="file-upload-label2" for="file-upload">Drag & Drop or <span>Click Here</span> to Upload Document</label>
                              <input type="file" id="file-upload" class="file-upload-input" multiple onChange={(e) => handleAddAttachments(e)} />
                              <small>You can upload 5 PDF(s) or Word Document(s) at a time.</small>
                            </div>
                          </div>
                          {!!docErrorMsg && <span className="text-left">{docErrorMsg}</span>}
                        </div>
                        <div class="document-list-grid text-left">
                          <ul>
                            {attachmentList?.length > 0 && attachmentList.map((attachment, index) => (
                              <li key={index}>
                                <figure onClick={() => RemoveAttachment(index)}>
                                  <a href="javascript:void(0)" class="remove-file"><img src="/images/icons/remove-document-icon.png" alt="" /></a>
                                  <img className="docfile-icon" src={attachment.filetype == "application/pdf" ? "images/icons/pdf-icon2.svg" : "images/icons/doc-file2.svg"} alt="" />
                                </figure>
                                {/* <span>{attachment.document}</span> */}
                              </li>
                            ))}
                          </ul>
                        </div>

                        <div class="field text-left">
                          <label for="txtDocumentName">Document Name</label>
                          <input type="text" class="form-control" id="txtDocumentName" name="txtDocumentName" placeholder="Enter Name" onChange={(e) => setDocNameField({ ...docNameField, data: e.target.value })} />
                        </div>
                      </>
                      :
                      <>
                        {docTypeToUpload == 2 && socialLinkArr?.length > 0 && socialLinkArr.map((socialLink, ind) => (
                          <div class="border-box-grey">
                            {socialLinkArr?.length > 1 && <a href="javascript:void(0)" className="remove-box" onClick={() => handleRemoveElement(ind)}><img src="/images/icons/modal-close-icon.svg" alt="" /></a>}
                            <div className="field text-left">
                              <input type="text" value={socialLink.name} class="form-control" id="" name="name" placeholder="Name" onChange={(e) => handleSetFieldValue(e, ind)} />
                              {errorMsgArr?.length > 0 && errorMsgArr.find(({ index }) => index == ind)?.name && <span>{errorMsgArr.find(({ index }) => index == ind)?.name}</span>}
                            </div>
                            <div className="field text-left">
                              <input type="text" value={socialLink.link} class="form-control" id="" name="link" placeholder="https/sitename/knowledge..." onChange={(e) => handleSetFieldValue(e, ind)} />
                              {errorMsgArr?.length > 0 && errorMsgArr.find(({ index }) => index == ind)?.link && <span>{errorMsgArr.find(({ index }) => index == ind)?.link}</span>}
                            </div>
                          </div>
                        ))}
                        {docTypeToUpload == 3 && calendarLinkArr?.length > 0 && calendarLinkArr.map((calendarLink, ind) => (
                          <div class="border-box-grey">
                            <div className="field text-left">
                              <input type="text" value={calendarLink.name} class="form-control" id="" name="name" placeholder="Name" onChange={(e) => handleSetFieldValue(e, ind)} />
                              {errorMsgArr?.length > 0 && errorMsgArr.find(({ index }) => index == ind)?.name && <span>{errorMsgArr.find(({ index }) => index == ind)?.name}</span>}
                            </div>
                            <div className="field text-left">
                              <input type="text" value={calendarLink.link} class="form-control" id="" name="link" placeholder="https/sitename/knowledge..." onChange={(e) => handleSetFieldValue(e, ind)} />
                              {errorMsgArr?.length > 0 && errorMsgArr.find(({ index }) => index == ind)?.link && <span>{errorMsgArr.find(({ index }) => index == ind)?.link}</span>}
                            </div>
                            {calendarLinkArr?.length > 1 && <a href="javascript:void(0)" className="remove-box" onClick={() => handleRemoveElement(ind)}><img src="/images/icons/modal-close-icon.svg" alt="" /></a>}
                          </div>
                        ))}
                        <div class="field text-right">
                          <a href="javascript:void(0)" class="cta-white" onClick={() => addElement()}>+ Add</a>
                        </div>
                      </>}
                      {emptyLinkErrMsg && <span>{emptyLinkErrMsg}</span>}
                    <div class="field  mb-0">
                      <button class="cta-green" type="button" disabled={submissionLoader} onClick={() => handleAddKnowledge()}>
                        {submissionLoader && <i class="fa-solid fa-spinner fa-spin"></i>}Add Knowledge</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>)
      }

      <div className="tab-content-grid">
        <div className="tab-content active">
          <div className="chatbot-history-grid">
            <header className="d-md-flex align-items-center justify-content-between">
              <h3>Knowledge Sources</h3>

              <div className="group-form d-md-flex">
                <select className="form-select w-180" onChange={(e) => { setDocTypeToFilter(e.target.value); SetPaginationData({ ...paginationData, currentPage: 1 }) }}>
                  <option value="" disabled>Filter by Category</option>
                  <option value="0">All</option>
                  {categoryList?.length > 0 && categoryList.map((docType, ind) => (
                    <option value={docType.id}>{docType.cat_name}</option>
                  ))}
                </select>
                <a href="javascript:void(0)" className="cta-green" onClick={() => { setEmptyLinkErrMsg(null); setDocTypeToUpload(1); setModalOpen(true); setAttachmentList([]) }}>
                  <i className="fa-solid fa-sparkles" aria-hidden="true"></i>
                  UPLOAD NEW DOCUMENT
                </a>
                {props.finalStep >= 2 && <a href="javascript:void(0)" className="cta-green" onClick={() => props.handleSetTab("3")}>continue</a>}
              </div>
            </header>
            {knowlwdgeList == null
              ? <div className="page-loader">
                <img src="/images/loader-bouncing-circles.svg" alt="" />
              </div>
              : knowlwdgeList?.length > 0
                ? <div className="table-responsive">
                  <table className="table align-middle">
                    <thead>
                      <tr>
                        <th className="text-left">Date</th>
                        <th className="text-left">{docTypeToFilter == 1 ? "Document Name" : "Link"}</th>
                        <th className="text-left">Source Name</th>
                        <th className="text-left">Category</th>
                        <th className="text-center action">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {knowlwdgeList.map((knowledge, ind) => (
                        <tr>
                          <td className="text-left with-icon"><i className="fa-light fa-calendar-days"></i>{new Date(knowledge.createdAt).toLocaleString()}</td>
                          <td className="text-left with-link">
                            {knowledge.cate_id == 1 ?
                              <>
                                <img src={knowledge.filetype == "application/pdf" ? "images/icons/pdf-icon2.svg" : "images/icons/doc-file2.svg"} alt="pdf-icon" className="docfile-icon" />
                                <a href={knowledge.slugs}>{knowledge.slugs.split(".com")[1]}</a>
                              </>
                              : knowledge.slugs}
                          </td>
                          <td className="text-left with-user-img">{!!knowledge.source_name ? knowledge.source_name :'N/A'}</td>
                          <td className="text-left">{knowledge.cate_id == 1 ? "Document" : knowledge.cate_id == 2 ? "URL" : "Calender URL"}</td>
                          <td className="text-center action">
                            <a href="javascript:void(0)"><img src="/images/icons/edit-icon.svg" onClick={() => handleOpenEditModal(knowledge)} /></a>
                            <a href="javascript:void(0)"><img src="/images/icons/delete-icon.svg" alt="" onClick={() => handleDeleteKnowlwdge(knowledge.ks_id)} /></a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {/* <div class="pagination-table">
                    <button disabled={paginationData.currentPage==1} onClick={() => SetPaginationData({ ...paginationData, currentPage: paginationData.currentPage - 1 })}>&laquo;</button>
                    {[...Array(paginationData.totalPages)].map((item, ind) => (
                      <button onClick={() => SetPaginationData({ ...paginationData, currentPage: ind + 1 })}>{ind + 1}</button>
                    ))}
                    <button disabled={paginationData.currentPage===paginationData.totalPages} onClick={() => SetPaginationData({ ...paginationData, currentPage: paginationData.currentPage + 1 })}>&raquo;</button>
                  </div> */}
                  <div className="pagination_wrap">
                    <div className="pagination-info">
                      Showing {(paginationData.currentPage - 1) * paginationObj.item_per_page + 1} to{" "}
                      {Math.min(
                        paginationData.currentPage * paginationObj.item_per_page,
                        paginationData.totalRecords
                      )}{" "}
                      of {paginationData.totalRecords} entries
                    </div>
                    <div className="pagination-table">
                      <Pagination
                        count={paginationData.totalPages}
                        page={paginationData.currentPage}
                        onChange={handlePaginationChange}
                        color="secondary"
                        shape="rounded"
                      />
                    </div>
                  </div>
                </div>
                
                : <span>There is no knowledge source to show. Plesae upload knowledge to train your bot.</span>}
          </div>
        </div>
        <div className="tab-content none" id="">Content for Tab 2</div>
        <div className="tab-content none" id="">Content for Tab 3</div>
        <div className="tab-content none" id="">Content for Tab 4</div>
      </div>
    </div>
  )
}

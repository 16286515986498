import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useDashboard from '../../hooks/useDashboard';
import useAuth from '../../hooks/useAuth';
import { LABEL } from '../../utils/constant';
import { showToast } from '../../helper/toastHelper';
import { CallAPI } from '../../utils/apiActions';
import { CMS_BASE_URL } from '../../utils/APIEndPoints';

const PlanList = () => {
  const {
    isLoading,
    getUserActivePlan,
    activePlan,
    cancelSubscription,
    getUsersPaymentHistory,
    paymentHistory,
    downloadInvoice: downloadInvoiceApi,
    planList, getAllPlan,
    saveFcmToken
  } = useDashboard();
  const FCMTOKEN=localStorage.getItem("FcmToken")
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if(FCMTOKEN) saveFcmToken({firebaseToken:FCMTOKEN})
    
      getAllPlan()
  }, []);


  const handleGetPlan = async (planPurchase) => {

    if (!user.id) {
      return showToast(LABEL.USER.USER_ID_NOT_FOUND, LABEL.TOAST_TYPE.ERROR);
    }

    const createSubscriptionPayload = {
      userId: user.id,
      planId: planPurchase?.id,
      promotionCode: user?.promoCode && !user?.isUsedPromoCode ? user.promoCode : "",
      returnUrl: `${process.env.REACT_APP_FRONTEND_BASE_URL}payment-status`,
      // returnUrl: 'http://localhost:3000/payment-status',
    }

    const createSubscriptionResponse = await CallAPI("WEBSITE", "POST", CMS_BASE_URL.DASHBOARD.USER_SUBSCRIPTION, createSubscriptionPayload);

    const redirectUrl = createSubscriptionResponse.res.data?.data.url;

    if (redirectUrl) {
      window.location.href = redirectUrl;
    }

    // getUserActivePlan({
    //   userId: user.id,
    // });

    // getUsersPaymentHistory({
    //   userId: user.id,
    // });
  }

  return (
    <section className="body-wrap">

      <div className="pricing-grid">
        <div className="container sum-med">
          <div className="banner-home_text with-h1-line text-center">
            <h1>14-day <span>free trial</span></h1>
            <h2>for any plan.</h2>
            <a href="javascript:void(0)" className="cta-green"><i
              className="fa-solid fa-sparkles"></i> Free
              Trial: Try Any Plan for 14 Days!</a>
          </div>

          <div className="pricing-grid_list">
            <header>
              <h3>Power Up Your Business with Our Plans</h3>
            </header>

            <div className="row">
              {
                planList?.map((plan) => (
                  <div key={plan.id} className="col-md-4 mb--30">
                    <div className="pricing-plan businessPlan">
                      <div className="top-header">
                        <h5>{plan.planName || ""}</h5>
                      </div>
                      <div
                        className="plan-price">$<span>{plan.price}</span>/mo</div>
                      <div className="plan-lists">
                        <ul>
                          {
                            plan?.module?.map((module) => (<li key={module.id}>{module.moduleDescription.description || ""}</li>))
                          }
                        </ul>
                      </div>
                      <div className="get-it-button">
                        <button
                          onClick={() => handleGetPlan(plan)}
                          className="cta-dark">{user?"Select":"GET IT NOW"}</button>
                      </div>
                    </div>
                  </div>))
              }

            </div>
          </div>
        </div>
      </div>

    </section>
  )
}

export default PlanList;

import moment from "moment";

// Validation messages for fields
export const validationMessages = {
  fullName: "Name is required",
  emailRequired: "Email is required",
  emailInvalid: "Invalid email",
  phoneRequired: "Phone number is required",
  phoneInvalid: "Please enter a valid phone number",
  passwordRequired: "Password is required",
  passwordErr: "Password must 8 characters log and contain at least one uppercase letter, one lowercase letter, one number, and one symbol",
  confirmPasswordRequired: "Confirm password is required",
  confirmPasswordMismatch: "Confirm password does not match with password",
  validUrl: "Enter valid URL",
  orgNameMin: "Organization name must be at least 3 characters long",
  promoCodePattern: "Promo code can only contain uppercase letters and numbers",
};

export const regex = {
  promoCodePattern: /^[A-Z0-9]+$/,
};

export const defaultValues = {
  fullName: "",
  email: "",
  phoneNumber: "",
  password: "",
  confirmPassword: "",
  businessUrl: "",
  organizationName: "",
  promoCode: "",
  isAgree:false
};

export const defaultValuesLogin = {
  email: "",
  password: "",
};

export const validationMessagesForBotChatSettings = {
  chatbotDescription: "Chatbot description is required",
  toneRequired: "Tone is required",
  languageRequired: "Language is required",
  fontHeaderRequired: "Font Header is required",
  fontBodyRequired: "Font Body is required",
  responseLengthRequired: "Response Length is required",
  domainLinkRequired: "Domain link is required",
  domainLinkInvalid: "Must be a valid URL",
};

export const defaultValuesForBotChatSettings = {
  chatbotDescription: "",
  widgetToggle: false,
  tone: "",
  language: "",
  fontHeader: "",
  fontBody: "",
  responseLength: "",
  chatBubbleIcon: null,
  uploadChatbotAvatar: null,
  domainLink: "",
};

export const validationMessagesForCms = {
  title: "Title is reqired",
  subTitle: "Subtitle is reqired",
  description: "Description is reqired",
  cmsType: "CMS type is reqired",
};

export const defaultValuesForCms = {
  title: "",
  subTitle: "",
  description: "",
  cmsType: "",
  files: null,
};
export const validationMessagesForUser = {
  name: "Full Name is reqired",
  email: "Email is reqired",
  emailInValid:"Email is not valid",
  phoneNumber: "Phone Number is reqired",
};
export const defaultValuesForUsers = {
  fullName: "",
  email: "",
  phoneNumber:"",
};

export const defaultValuesForContactUs = {
  name: "",
  email: "",
  phone: "",
  subject: "",
  message: "",
  file: null,
  // agree: false,
};
export const defaultValuesForFeedback = {
  subject: "",
  message: "",
  files: null,
};
export const validationMessagesForCoupon = {
  expiresAtRequired: "Expiry date is required",
  couponNameRequired: "Coupon name is required",
  maxRedemptionsRequired: "Max redemptions are required",
  maxRedemptionsPositive: "Must be greater than zero",
  maxRedemptionsInteger: "Must be an integer",
  couponDurationRequired: "Coupon duration is required",
  couponDurationInvalid: "Invalid duration",
  discountTypeRequired: "Discount type is required",
  discountTypeInvalid: "Invalid discount type",
  couponAmountRequired: "Coupon amount is required",
  couponAmountPositive: "Must be greater than zero",
  currencyRequired: "Currency is required",
  isEligibleRequired: "Eligibility is required",
};

export const defaultValuesForCoupon = {
  expiresAt: null,
  couponName: "",
  // max_redemptions: 1,
  couponDuration: "",
  discountType: "",
  couponAmount: "",
  currency: "",
  // isEligibleForFirstTime: false,
}

export const validationMessagesForPlan = {
  planNameRequired: "Plan name is required",
  priceRequired: "Price is required",
  pricePositive: "Price must be greater than zero",
  currencyRequired: "Please choose a valid currency type.",
  noOfDayRequired: "No of days required",
  noOfDayMustBeNumber: "No of days must be greater that or eqaul to 1",
  planDescriptionRequired: "Plan description are required",
  planDescriptionMustBeOne: "Please choose at least one plan description",
  planSubscriptionType:"Please choose a subscription type"
};

export const defaultValuesForPlan = {
  planName: "",
  price: "",
  currency: "",
  module: [],
  isFreePlan: true,
  noOfDay: "",
  allowPromotionCode: false,
  autoCollectTax: false,
  subscriptionType: "",
}

export const LABEL = {
  TOAST_TYPE: {
    SUCCESS: "success",
    ERROR: "error",
    INFO: "info",
    WARN: "warn",
  },
  SIGNUP: {
    ERROR_MESSAGE: {
      FAILED: "Registration Failed",
    },
  },
  USER_MANAGEMENT: {
    TITLE: {
      NAME: "Full Name",
      EMAIL: "Email",
      ORGANIZATION: "Organization Name",
      TRILE: "Trial User",
    },
    KEY: {
      NAME: "fullName",
      EMAIL: "email",
      ORGANIZATION: "organizationName",
      TRILE: "isTrial",
    },
  },
  TABLE_COLUMN: {
    POSSITION: {
      LEFT: "left",
      RIGHT: "right",
      CENTER: "center",
      WORD_BREAK: "break-word",
    },
    ACTION: {
      EDIT: {
        LABEL: "Edit",
        ICON: "/images/icons/edit-icon.svg",
      },
      DELETE: {
        LABEL: "Delete",
        ICON: "/images/icons/delete-icon.svg",
      },
      VIEW: {
        LABEL: 'View Invoice',
        ICON: "/images/icons/eye-icon-green.png",
      },
      DOWNLOAD: {
        LABEL: 'Download Invoice',
        ICON: "/images/icons/fax-icon.svg",
      },
    },
  },
  BOT_CHAT_SETTINGS: {
    TITLE: "Bot Chat Settings",
    SAVE_AND_CONTINUE: "Save and continue",
    CHATBOT_DESCRIPTION: "Chatbot Description",
    DOMAIN_LINK: "Domain Link",
    UPLOAD_WIDGET: "Upload widget",
    CHAT_BUBBLE_ICON: "Chat Bubble Icon",
    UPLOAD_AVATAR: "Upload Chatbot Avatar",
  },
  COUPON_SETTINGS: {
    TITLE: "Create Coupon",
    COUPON_NAME: "Coupon Name",
    MAX_REDEMPTIONS: "Max Redemptions",
    COUPON_DURATION: "Coupon Duration",
    DISCOUNT_TYPE: "Discount Type",
    COUPON_AMOUNT: "Coupon Amount",
    CURRENCY: "Currency",
    ELIGIBLE_FOR_FIRST_TIME: "Eligible for First-Time Users",
    SAVE_AND_CONTINUE: "Save and Continue",
    EXPIRES_AT: "Expiry Date",

    TABLE_COLUMN: {
      TITLE: {
        COUPON_NAME: "Coupon Name",
        COUPON_CODE: "Coupon Code",
        COUPON_AMOUNT: "Coupon Amount",
        COUPON_DURATION: "Coupon Duration",
        COUPON_EXPIRE: "Expires At",
        COUPON_STATUS: "Status",
      },
      KEY: {
        COUPON_NAME: "couponName",
        COUPON_CODE: "couponCode",
        COUPON_AMOUNT: "couponAmount",
        COUPON_DURATION: "couponDuration",
        COUPON_EXPIRE: "expiresAt",
        COUPON_STATUS: "status",
      },
    },
  },

  PLAN_MANAGEMENT: {
    TITLE: "Create Plan",
    TITLE_EDIT: "View Plan",
    TITLE_VIEW:"Plan Details",
    PLAN_NAME: "Plan Name",
    PRICE: "Price",
    CURRENCY: "Currency",
    FOR_TRILE: "Allow Trial",
    NO_OF_DAYS: "Trial Days",
    ALLOW_PORMOTION_CODE: "Allow Promotion code",
    ALLOW_TAX: "Allow To Collect TAX",
    ALLOW_SUBSCRIPTION: "Allow For Subscription Type",
    PLAN_DESCRIPTION: "Select Plan Description",
    SAVE_AND_CONTINUE: "Save and Continue",
    PLAN_MANAGEMENT: "Plan Management",
    TABLE_COLUMN: {
      TITLE: {
        PLAN_NAME: "Plan Name",
        PLAN_TRILE: "Available With Trial",
        TRAIL_DAYS: "Free Trial Days",
        PLAN_CURRENCY: "Currency",
        PLAN_PRICE: "Plan Price",
        PLAN_EXPIRE: "Expires At",
        ALLOW_PROMO: "Allow Promotioncode",
        PLAN_STATUS: "Status",
      },
      KEY: {
        PLAN_NAME: "planName",
        PLAN_TRILE: "isFreePlan",
        TRAIL_DAYS: "noOfDay",
        PLAN_CURRENCY: "currency",
        PLAN_PRICE: "price",
        PLAN_EXPIRE: "expiresAt",
        ALLOW_PROMO: "allowPromotionCode",
        PLAN_STATUS: "status",
      },
    },
  },
  SUBSCRIPTION_MANAGEMENT: {
    TITLE: "Create Subscription",
    TITLE_EDIT: "Edit Plan",
    SUBSCRIPTION_NAME: "Plan Name",
    PRICE: "Price",
    CURRENCY: "Currency",
    FOR_TRILE: "Allow Trile",
    NO_OF_DAYS: "Trile Days",
    ALLOW_PORMOTION_CODE: "Allow Promotion code",
    ALLOW_TAX: "Allow To Collect TAX",
    ALLOW_SUBSCRIPTION: "Allow For Subscription Type",
    SUBSCRIPTION_DESCRIPTION: "Select Plan Description",
    SAVE_AND_CONTINUE: "Save and Continue",
    HEADLINE: "Customer Subscription Details",
    TABLE_COLUMN: {
      TITLE: {
        PLAN_NAME: "Plan Name",
        PLAN_TRILE: "Available With Trial",
        TRAIL_DAYS: "Free Trial Days",
        PLAN_CURRENCY: "Currency",
        PLAN_PRICE: "Plan Price",
        PLAN_EXPIRE: "Expires At",
        ALLOW_PROMO: "Allow Promotioncode",
        PLAN_STATUS: "Status",
      },
      KEY: {
        PLAN_NAME: "planName",
        PLAN_TRILE: "isFreePlan",
        TRAIL_DAYS: "noOfDay",
        PLAN_CURRENCY: "currency",
        PLAN_PRICE: "price",
        PLAN_EXPIRE: "expiresAt",
        ALLOW_PROMO: "allowPromotionCode",
        PLAN_STATUS: "status",
      },
    },
  },
  USER: {
    USER_ID_NOT_FOUND: "Please reload the page or Login Again!",
    EDIT_HEADER:"Edit Customer Details",
    HEADER: "Add New Customer",
    NAME:"Full Name",
    EMAIL:"Email",
    PHONE:"Phone Number",
    STATUS:"Status"
  },
  CMS: {
    HEADER: "Add New Content",
    EDIT_HEADER: "Edit Content",
    SAVE_AND_CONTINUE: "Save and continue",
    TITLE: "Title",
    SUBTITLE: "Subtitle",
    DESCRIPTION: "Description",
    UPLOAD_WIDGET: "Upload widget",
    CHAT_BUBBLE_ICON: "Upload Icon",
    UPLOAD_VIDEO: "Upload Video",
    UPLOAD_AVATAR: "Upload Chatbot Avatar",
  },
  REVENEU:{
    HEADLINE:"Revenue Report",
    TOTAL_TRANSACTION:"Total Transactions :$"
  }
};

const UMK = LABEL.USER_MANAGEMENT.KEY;
const UMT = LABEL.USER_MANAGEMENT.TITLE;
const TCP = LABEL.TABLE_COLUMN.POSSITION;
const TCA = LABEL.TABLE_COLUMN.ACTION;

const CMTCK = LABEL.COUPON_SETTINGS.TABLE_COLUMN.KEY;
const CMTCT = LABEL.COUPON_SETTINGS.TABLE_COLUMN.TITLE;

const PMTCK = LABEL.PLAN_MANAGEMENT.TABLE_COLUMN.KEY;
const PMTCT = LABEL.PLAN_MANAGEMENT.TABLE_COLUMN.TITLE;

export const TABLE_CONSTANT = {
  usersManagementcolumns: [
    { key: UMK.NAME, title: UMT.NAME, align: TCP.LEFT },
    { key: UMK.EMAIL, title: UMT.EMAIL, align: TCP.LEFT },
    { key: "phoneNumber", title: "Phone", align: TCP.LEFT },
    { key: "planName", title: "Plan", align: TCP.CENTER ,render: (value) => {
      return <span style={{ textTransform: "uppercase" }}>{value ? value : "NA"}</span>
    }},
    { key: "promoCode", title: "Promocode", align: TCP.CENTER,
      render: (value) => {
      return <span style={{ textTransform: "uppercase" }}>{value ? value : "NA"}</span>
    } },
    { key: "status", title: "Status", align: TCP.CENTER,render: (value) => {
      return <span style={{ textTransform: "uppercase" }}>{value?"active":"in-active" }</span>
    } },
    { key: "isDeleted", title: "Is Deleted?", align: TCP.CENTER,render: (value) => {
      return <span style={{ textTransform: "uppercase" }}>{value?"Yes":"No" }</span>
    } },
    {
      key: UMK.TRILE,
      title: UMT.TRILE,
      align: TCP.CENTER,
      wordBreak: TCP.WORD_BREAK,
      render: (value, item) => {
        return <span style={{ textTransform: "uppercase" }}>{value ? 'Free Trial' : 'Trial Expired'}</span>
      }
    },
  ],

  usersManagementactions: [
    {
      label: TCA.EDIT.LABEL,
      icon: TCA.EDIT.ICON,
      onClick: (item) => alert(`Edit ${item[UMK.NAME]}`),
    },
    // {
    //   label: TCA.DELETE.LABEL,
    //   icon: TCA.DELETE.ICON,
    //   onClick: (item) => alert(`Delete ${item[UMK.NAME]}`),
    // },
  ],
};

const fontHeaderOptions = [
  { label: "Poppins Bold", value: "Poppins-Bold" },
  { label: "Montserrat Extra Bold", value: "Montserrat-ExtraBold" },
  { label: "Open Sans Extra Bold", value: "OpenSans-ExtraBold" },
  { label: "Roboto Bold", value: "Roboto-Bold" },
  { label: "Bebas Neue", value: "BebasNeue" },
  { label: "Playfair Display Bold", value: "PlayfairDisplay-Bold" },
];

const fontBodyOptions = [
  { label: "Poppins Regular", value: "Poppins-Regular" },
  { label: "Open Sans Regular", value: "OpenSans-Regular" },
  { label: "Lato Regular", value: "Lato-Regular" },
  { label: "Roboto Regular", value: "Roboto-Regular" },
  { label: "Georgia Regular", value: "Georgia-Regular" },
  { label: "Merriweather Regular", value: "Merriweather-Regular" },
];

const responseLengthOptions = [
  { label: "Small", value: "small" },
  { label: "Medium", value: "medium" },
  { label: "Large", value: "large" },
];

const languageOptions = [
  { label: "English (US)", value: "en-us" },
  { label: "English (UK)", value: "en-uk" },
  { label: "Spanish (Español)", value: "es" },
  { label: "French (Français)", value: "fr" },
  { label: "German (Deutsch)", value: "de" },
  { label: "Hindi (हिंदी)", value: "hi" },
  { label: "Mandarin Chinese (中文)", value: "zh" },
  { label: "Japanese (日本語)", value: "ja" },
  { label: "Arabic (العربية)", value: "ar" },
  { label: "Swahili (Kiswahili)", value: "sw" },
];

const toneOptions = [
  { label: "Informative", value: "informative" },
  { label: "Professional", value: "professional" },
  { label: "Humorous", value: "humorous" },
  { label: "Friendly", value: "friendly" },
  { label: "Persuasive", value: "persuasive" },
  { label: "Casual", value: "casual" },
  { label: "Empathetic", value: "empathetic" },
  { label: "Inspirational", value: "inspirational" },
];

export const dropdownConfigs = [
  {
    name: "tone",
    label: "Tone",
    options: toneOptions,
  },
  {
    name: "language",
    label: "Language",
    options: languageOptions,
  },
  {
    name: "fontHeader",
    label: "Font Header",
    options: fontHeaderOptions,
  },
  {
    name: "fontBody",
    label: "Font Body",
    options: fontBodyOptions,
  },
  {
    name: "responseLength",
    label: "Response Length",
    options: responseLengthOptions,
  },
];

export const currencyOptions = [
  { value: "usd", label: "$ - USD" }, // US Dollar
  { value: "eur", label: "€ - EUR" }, // Euro
  { value: "inr", label: "₹ - INR" }, // Indian Rupee
  { value: "gbp", label: "£ - GBP" }, // British Pound Sterling
  { value: "jpy", label: "¥ - JPY" }, // Japanese Yen
  { value: "aud", label: "A$ - AUD" }, // Australian Dollar
  { value: "cad", label: "C$ - CAD" }, // Canadian Dollar
  { value: "chf", label: "CHF - CHF" }, // Swiss Franc
  { value: "cny", label: "¥ - CNY" }, // Chinese Yuan
  { value: "rub", label: "₽ - RUB" }, // Russian Ruble
  { value: "brl", label: "R$ - BRL" }, // Brazilian Real
  { value: "zar", label: "R - ZAR" }, // South African Rand
  { value: "mxn", label: "$ - MXN" }, // Mexican Peso
  { value: "nzd", label: "NZ$ - NZD" }, // New Zealand Dollar
  { value: "krw", label: "₩ - KRW" }, // South Korean Won
  { value: "sgd", label: "S$ - SGD" }, // Singapore Dollar
  { value: "hkd", label: "HK$ - HKD" }, // Hong Kong Dollar
  { value: "sek", label: "kr - SEK" }, // Swedish Krona
  { value: "nok", label: "kr - NOK" }, // Norwegian Krone
  { value: "dkk", label: "kr - DKK" }, // Danish Krone
  { value: "pln", label: "zł - PLN" }, // Polish Zloty
  { value: "try", label: "₺ - TRY" }, // Turkish Lira
  { value: "thb", label: "฿ - THB" }, // Thai Baht
  { value: "idr", label: "Rp - IDR" }, // Indonesian Rupiah
];

export const subscriptionTypeOptions = [
  { value: 1, label: "Monthly" },
  { value: 2, label: "Yearly" },
];

export const discountTypeOptions = [
  { value: 1, label: "Percent" },
  { value: 2, label: "Fixed" },
];

export const couponDurationOptions = [
  { value: "once", label: "One Time" },
  { value: "repeating", label: "Recurring" },
  { value: "forever", label: "Lifetime" },
];

// One Time, Recurring, Lifetime
// export const couponDurationOptions = [
//   { value: "one_time", label: "One Time" },
//   { value: "recurring", label: "Recurring" },
//   { value: "lifetime", label: "Lifetime" },
// ];

export const couponDropdownConfigs = [
  {
    name: "discountType",
    label: "Discount Type",
    options: discountTypeOptions,
  },
  {
    name: "couponDuration",
    label: "Coupon Duration",
    options: couponDurationOptions,
  },
  {
    name: "couponDuration",
    label: "Coupon Duration",
    options: currencyOptions,
  },
];

export const COUPON_TABLE_CONSTANT = {
  usersManagementcolumns: [
    { key: CMTCK.COUPON_NAME, title: CMTCT.COUPON_NAME, align: TCP.LEFT },
    { key: CMTCK.COUPON_CODE, title: CMTCT.COUPON_CODE, align: TCP.CENTER },
    { key: CMTCK.COUPON_AMOUNT, title: CMTCT.COUPON_AMOUNT, align: TCP.CENTER },
    { key: CMTCK.COUPON_DURATION, title: CMTCT.COUPON_DURATION, align: TCP.CENTER },
    {
      key: CMTCK.COUPON_EXPIRE, title: CMTCT.COUPON_EXPIRE, align: TCP.CENTER,
      render: (value) => {
        return <span style={{ textTransform: "uppercase" }}>{value ? `${moment(value).format("DD MMMM YYYY")}` : "NA"}</span>
      }
    },
    {
      key: CMTCK.COUPON_STATUS,
      title: CMTCT.COUPON_STATUS,
      align: TCP.CENTER,
      wordBreak: TCP.WORD_BREAK,
      render: (value, item) => {
        return <span style={{ textTransform: "uppercase" }}>{value === 1 ? "Active" : "Inactive"}</span>
      }
    },
  ],

  usersManagementactions: [
    // {
    //   label: TCA.EDIT.LABEL,
    //   icon: TCA.EDIT.ICON,
    //   onClick: (item) => alert(`Edit ${item[UMK.NAME]}`),
    // },
    {
      label: TCA.DELETE.LABEL,
      icon: TCA.DELETE.ICON,
      onClick: (item) => alert(`Delete ${item[UMK.NAME]}`),
    },
  ],
};

export const PLAN_TABLE_CONSTANT = {
  usersManagementcolumns: [
    { key: PMTCK.PLAN_NAME, title: PMTCT.PLAN_NAME, align: TCP.LEFT },
    {
      key: PMTCK.PLAN_TRILE, title: PMTCT.PLAN_TRILE, align: TCP.CENTER, render: (value) => {
        return <span style={{ textTransform: "uppercase" }}>{value === 1 ? "YES" : "NO"}</span>
      }
    },
    {
      key: PMTCK.TRAIL_DAYS, title: PMTCT.TRAIL_DAYS, align: TCP.CENTER, render: (value) => {
        return <span>{value ?? "NA"}</span>
      }
    },
    {
      key: PMTCK.PLAN_CURRENCY,
      title: PMTCT.PLAN_CURRENCY,
      align: TCP.CENTER,
      render: (value, item) => {
        return <span style={{ textTransform: "uppercase" }}>{value}</span>
      }
    },
    {
      key: PMTCK.PLAN_PRICE,
      title: PMTCT.PLAN_PRICE,
      align: TCP.CENTER,
    },
    {
      key: PMTCK.ALLOW_PROMO,
      title: PMTCT.ALLOW_PROMO,
      align: TCP.CENTER,
      render: (value, item) => {
        return <span style={{ textTransform: "uppercase" }}>{value === 1 ? "YES" : "NO"}</span>
      }
    },
    {
      key: PMTCK.PLAN_STATUS,
      title: PMTCT.PLAN_STATUS,
      align: TCP.CENTER,
      wordBreak: TCP.WORD_BREAK,
      render: (value, item) => {
        return <span style={{ textTransform: "uppercase" }}>{value === 1 ? "Active" : "Inactive"}</span>
      }
    },
  ],

  usersManagementactions: [
    {
      label: "View Plan",
      icon: '/images/icons/eye-icon-white.svg',
      onClick: (item) => alert(`Edit ${item[UMK.NAME]}`),
    },
    // {
    //   label: "Edit Plan",
    //   icon: TCA.EDIT.ICON,
    //   onClick: (item) => alert(`Edit ${item[UMK.NAME]}`),
    // },
    // {
    //   label: TCA.DELETE.LABEL,
    //   icon: TCA.DELETE.ICON,
    //   onClick: (item) => alert(`Delete ${item[UMK.NAME]}`),
    // },
  ],
};

export const MANAGE_SUBSCRIBERS_TABLE_CONSTANT = {
  usersManagementcolumns: [
    {
      key: 'email',
      title: 'Email',
      align: TCP.LEFT
    },
    {
      key: 'subscriptionStartDate',
      title: 'Payment Status',
      align: TCP.CENTER,
      render: (value, item) => {
        return <span>{item?.activeSubscription?.paymentStatusMessage ?? "NA"}</span>
      }
    },
    {
      key: 'subscriptionStartDate',
      title: 'Payment Type',
      align: TCP.CENTER,
      render: (value, item) => {
        return <span>{item?.activeSubscription?.paymentType ?? "NA"}</span>
      }
    },
    {
      key: 'activeSubscription.subscriptionStartDate',
      title: 'Subscription Start',
      align: TCP.CENTER,
      render: (value, item) => {
        return <span style={{ textTransform: "uppercase" }}>{`${moment(item?.activeSubscription?.subscriptionStartDate).format("DD MMMM YYYY")}`}</span>
      }
    },
    {
      key: 'activeSubscription.subscriptionEndDate',
      title: 'Subscription End',
      align: TCP.CENTER,
      render: (value, item) => {
        return <span style={{ textTransform: "uppercase" }}>{`${moment(item?.activeSubscription?.subscriptionEndDate).format("DD MMMM YYYY")}`}</span>
      }
    },
  ],

};
export const MANAGE_REVENEU_TABLE_CONSTANT={
  usersManagementcolumns:[
    {
      key: 'fullName',
      title: 'User Name',
      align: TCP.LEFT
    },
    {
      key: 'email',
      title: 'Email',
      align: TCP.LEFT
    },
    {
      key: 'taxAmount',
      title: 'Tax Amount',
      align: TCP.LEFT,
      render: (value) => {
        return <span>{value===null || value===undefined? "NA":value}</span>;
      },
    },
    {
      key: 'subTotal',
      title: 'Sub Total',
      align: TCP.LEFT,
      render: (value) => {
        return <span>{value===null || value===undefined? "NA":value}</span>;
      },
    },
    {
      key: 'totalAmountReceived',
      title: 'Total Amount',
      align: TCP.LEFT,
      render: (value) => {
        return <span>{value===null || value===undefined? "NA":value}</span>;
      },
    },

  ],
  usersManagementactions: [
    {
      label: "View",
      icon: '/images/icons/eye-icon-white.svg',
      onClick: () => {},
    },
  ],
}
export const replaceActionCallback = (actions, label, newCallback) => {
  return actions.map((action) =>
    action.label === label ? { ...action, onClick: newCallback } : action
  );
};

// export const mapToLabelAndValues = (list = [], value = 'id', label = 'description') => {
//   return list.map((plan) => ({
//     value: plan[value],
//     label: plan[label],
//   }));
// };

export const mapToLabelAndValues = (
  list = [],
  valueKey = 'id',
  labelKey = 'description'
) => {
  return list.map((item) => ({
    value: item[valueKey],
    label: labelKey.includes('.') 
      ? labelKey.split('.').reduce((acc, key) => acc?.[key], item) 
      : item[labelKey],
  }));
};

export function transformBooleanToNumber(payload) {
  const transformedPayload = Object.keys(payload).reduce((acc, key) => {
    const value = payload[key];

    if (typeof value === "boolean") {
      acc[key] = value ? 1 : 0;
    } else {
      acc[key] = value;
    }

    return acc;
  }, {});

  return transformedPayload;
}

export const user_type = {
  1: "super_admin",
  2: "user",
  3: "user",
  4: "user",
  5: "user"
}

export const paginationObj = {
  item_per_page: 10
}

export const cmsTypeOptions = [
  { label: "Chatbot Features", value: 4 },
  { label: "Chatbot Integration Guides", value: 1 },
  { label: "FAQs", value: 2 },
  { label: "Onboarding Tutorials", value: 3 },
  { label: "Terms of Service", value: 7 },
  { label: "Privacy Policy", value: 6 },
  { label: "Refund Policy", value: 5 },
]

export const cmsTypeObj = {
  1: "Chatbot Integration Guides",
  2: "FAQs",
  3: "Onboarding Tutorials",
  4: "Chatbot Features",
  5: "Refund Policy",
  6: "Privacy Policy",
  7: "Terms of Service"
}

function truncateString(str, maxLength = 50) {
  if (str.length > maxLength) {
    return str.slice(0, maxLength) + '...';
  }
  return str;
}

export const CMS_TABLE_CONSTANT = {
  usersManagementcolumns: [
    {
      key: "title",
      title: "Title",
      align: TCP.LEFT,
      render: (value) => {
        return <span>{truncateString(value, 25) || "NA"}</span>;
      },
    },
    {
      key: "subTitle",
      title: "Subtitle",
      align: TCP.CENTER,
      render: (value) => {
        return <span>{truncateString(value, 25) || "NA"}</span>;
      },
    },
    {
      key: "description",
      title: "Description",
      align: TCP.CENTER,
      render: (value) => {
        return <span>{truncateString(value, 40) || "NA"}</span>;
      },
    },
    {
      key: "cmsType",
      title: "CMS Type",
      align: TCP.CENTER,
      render: (value) => {
        return <span>{cmsTypeObj[value]}</span>;
      },
    },
  ],

  usersManagementactions: [
    {
      label: TCA.EDIT.LABEL,
      icon: TCA.EDIT.ICON,
      onClick: (item) => alert(`Edit ${item[UMK.NAME]}`),
    },
    {
      label: TCA.DELETE.LABEL,
      icon: TCA.DELETE.ICON,
      onClick: (item) => alert(`Delete ${item[UMK.NAME]}`),
    },
  ],
};

export const SUBSCRIPTION_TABLE_CONSTANT = {
  usersManagementcolumns: [
    // { key: "stripeInvoiceId", title: "Invoice Id", align: TCP.LEFT },
    {
      key: 'plan.planName',
      title: 'Plan Name',
      align: TCP.CENTER,
      render: (value, item) => {
        return <span>{item?.plan?.planName ?? "NA"}</span>;
      },
    },
    {
      key: 'plan.isFreePlan',
      title: 'Is Trial Included?',
      align: TCP.CENTER,
      render: (value, item) => {
        return <span>{item?.plan?.isFreePlan ? 'Yes' :'No'}</span>;
      },
    },
    {
      key: "paymentStatusMessage",
      title: "payment Status",
      align: TCP.CENTER,
      render: (value) => {
        return <span>{value || "NA"}</span>;
      },
    },
    {
      key: "paymentType",
      title: "Payment Type",
      align: TCP.CENTER,
      render: (value) => {
        return <span>{value || "NA"}</span>;
      },
    },
  ],

  usersPaymentInvoiceColumns: [
    { key: "stripeInvoiceId", title: "Invoice Id", align: TCP.LEFT },
    {
      key: 'subscriptionStartDate',
      title: 'Subscription Start',
      align: TCP.CENTER,
      render: (value, item) => {
        return <span style={{ textTransform: "uppercase" }}>{`${moment(value).format("DD MMMM YYYY")}`}</span>
      }
    },
    {
      key: 'subscriptionEndDate',
      title: 'Subscription End',
      align: TCP.CENTER,
      render: (value, item) => {
        return <span style={{ textTransform: "uppercase" }}>{`${moment(value).format("DD MMMM YYYY")}`}</span>
      }
    },
    {
      key: "paymentStatusMessage",
      title: "payment Status",
      align: TCP.CENTER,
      render: (value) => {
        return <span>{value || "NA"}</span>;
      },
    },
    {
      key: "paymentType",
      title: "Payment Type",
      align: TCP.CENTER,
      render: (value) => {
        return <span>{value || "NA"}</span>;
      },
    },
  ],
  usersPaymentInvoiceColumnsInAdmin: [
    { key: "stripeInvoiceId", title: "Invoice Id", align: TCP.LEFT },
    {
      key: 'subscriptionStartDate',
      title: 'Subscription Start',
      align: TCP.CENTER,
      render: (value, item) => {
        return <span style={{ textTransform: "uppercase" }}>{`${moment(value).format("DD MMMM YYYY")}`}</span>
      }
    },
    {
      key: 'subscriptionEndDate',
      title: 'Subscription End',
      align: TCP.CENTER,
      render: (value, item) => {
        return <span style={{ textTransform: "uppercase" }}>{`${moment(value).format("DD MMMM YYYY")}`}</span>
      }
    },
    {
      key: "paymentStatusMessage",
      title: "payment Status",
      align: TCP.CENTER,
      render: (value) => {
        return <span>{value || "NA"}</span>;
      },
    },
    {
      key: "plan",
      title: "PlanName",
      align: TCP.CENTER,
      render: (value) => {
        return <span>{value?.planName || "NA"}</span>;
      },
    },
    {
      key: "paymentType",
      title: "Payment Type",
      align: TCP.CENTER,
      render: (value) => {
        return <span>{value || "NA"}</span>;
      },
    },
    {
      key: "amountReceived",
      title: "Amount",
      align: TCP.CENTER,
      render: (value) => {
        return <span>{value}</span>;
      },
    },
  ],
  usersManagementactions: [
    {
      label: TCA.DOWNLOAD.LABEL,
      icon: TCA.DOWNLOAD.ICON,
      onClick: (item) => alert(`Download ${item[UMK.NAME]}`),
    },
  ],
};


export const CONTACT_US_TABLE_CONSTANT = {
  usersManagementcolumns: [
    {
      key: 'name',
      title: 'Name',
      align: TCP.LEFT
    },
    {
      key: 'email',
      title: 'Email',
      align: TCP.LEFT
    },
    {
      key: 'phone',
      title: 'Phone',
      align: TCP.LEFT
    },
    {
      key: 'message',
      title: 'Message',
      align: TCP.CENTER,
      render: (value, item) => {
        return <span>{value ?? "NA"}</span>
      }
    },
    {
      key: 'slot',
      title: 'Booked Slot',
      align: TCP.CENTER,
      render: (value, item) => {
        return <span style={{ textTransform: "uppercase" }}>{`${moment(value).format("DD MMMM YYYY hh:mm A")}`}</span>
      }
    },
    // {
    //   key: 'fileLink',
    //   title: 'View File',
    //   align: TCP.CENTER,
    //   render: (value, item) => {
    //     return <span style={{ textTransform: "uppercase" }}>{value ? window.location.href = value : ''}</span>
    //   }
    // },
  ],

};

export const formatDateTime = (selectedDate, selectedTime) => {
  const combinedDateTime = `${moment(selectedDate).format('YYYY-MM-DD')} ${selectedTime}`;
  const formattedDateTime = moment(combinedDateTime, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DD HH:mm:ss');
  return formattedDateTime;
};
export function formatTimestamp(timestamp) {
  const date = new Date(timestamp);
  const now = new Date();
  const diffInSeconds = Math.floor((now - date) / 1000);

  if (diffInSeconds < 60) {
      return 'Just now';
  } else if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  } else if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  } else if (diffInSeconds < 172800) {
      return 'Yesterday';
  } else if (diffInSeconds < 604800) {
      const days = Math.floor(diffInSeconds / 86400);
      return `${days} day${days > 1 ? 's' : ''} ago`;
  } else if (diffInSeconds < 31536000) {
      const weeks = Math.floor(diffInSeconds / 604800);
      return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
  } else {
      const years = Math.floor(diffInSeconds / 31536000);
      return `${years} year${years > 1 ? 's' : ''} ago`;
  }
}
export function roundPercentageString(input="") {
  // Remove the % sign, parse to float, round to 2 decimals, and reattach the %
  if (input.endsWith("%")) {
    let numericValue = parseFloat(input.replace('%', ''));
    return numericValue.toFixed(2) + "%";
  }
  else {
    return input
  }
}
export const scopes= [
  { label: "businesses.readonly", value: "businesses.readonly" },
  { label: "businesses.write", value: "businesses.write" },
  { label: "calendars.write", value: "calendars.write" },
  { label: "calendars.readonly", value: "calendars.readonly" },
  { label: "calendars/groups.readonly", value: "calendars/groups.readonly" },
  { label: "calendars/groups.write", value: "calendars/groups.write" },
  { label: "calendars/resources.readonly", value: "calendars/resources.readonly" },
  { label: "calendars/resources.write", value: "calendars/resources.write" },
  { label: "calendars/events.readonly", value: "calendars/events.readonly" },
  { label: "calendars/events.write", value: "calendars/events.write" },
  { label: "campaigns.readonly", value: "campaigns.readonly" },
  { label: "contacts.readonly", value: "contacts.readonly" },
  { label: "contacts.write", value: "contacts.write" },
  { label: "objects/schema.readonly", value: "objects/schema.readonly" },
  { label: "objects/schema.write", value: "objects/schema.write" },
  { label: "objects/record.readonly", value: "objects/record.readonly" },
  { label: "objects/record.write", value: "objects/record.write" },
  { label: "conversations.readonly", value: "conversations.readonly" },
  { label: "conversations.write", value: "conversations.write" },
  { label: "conversations/message.readonly", value: "conversations/message.readonly" },
  { label: "conversations/message.write", value: "conversations/message.write" },
  { label: "conversations/livechat.write", value: "conversations/livechat.write" },
  { label: "forms.readonly", value: "forms.readonly" },
  { label: "invoices.readonly", value: "invoices.readonly" },
  { label: "invoices.write", value: "invoices.write" },
  { label: "invoices/schedule.readonly", value: "invoices/schedule.readonly" },
  { label: "invoices/schedule.write", value: "invoices/schedule.write" },
  { label: "invoices/template.readonly", value: "invoices/template.readonly" },
  { label: "invoices/template.write", value: "invoices/template.write" },
  { label: "links.readonly", value: "links.readonly" },
  { label: "links.write", value: "links.write" },
  { label: "locations.readonly", value: "locations.readonly" },
  { label: "LocationUpdate", value: "LocationUpdate" },
  { label: "locations.write", value: "locations.write" },
  { label: "locations/customValues.readonly", value: "locations/customValues.readonly" },
  { label: "locations/customValues.write", value: "locations/customValues.write" },
  { label: "locations/customFields.readonly", value: "locations/customFields.readonly" },
  { label: "locations/customFields.write", value: "locations/customFields.write" },
  { label: "locations/tags.readonly", value: "locations/tags.readonly" },
  { label: "locations/tags.write", value: "locations/tags.write" },
  { label: "locations/templates.readonly", value: "locations/templates.readonly" },
  { label: "locations/tasks.readonly", value: "locations/tasks.readonly" },
  { label: "medias.readonly", value: "medias.readonly" },
  { label: "medias.write", value: "medias.write" },
  { label: "funnels/redirect.readonly", value: "funnels/redirect.readonly" },
  { label: "funnels/redirect.write", value: "funnels/redirect.write" },
  { label: "funnels/page.readonly", value: "funnels/page.readonly" },
  { label: "funnels/funnel.readonly", value: "funnels/funnel.readonly" },
  { label: "funnels/pagecount.readonly", value: "funnels/pagecount.readonly" },
  { label: "opportunities.readonly", value: "opportunities.readonly" },
  { label: "opportunities.write", value: "opportunities.write" },
  { label: "payments/integration.readonly", value: "payments/integration.readonly" },
  { label: "payments/integration.write", value: "payments/integration.write" },
  { label: "payments/orders.readonly", value: "payments/orders.readonly" },
  { label: "payments/orders.write", value: "payments/orders.write" },
];

//All module and menu items
// export const menuItems = {
//  "super_admin":[ { path: "/admin/dashboard", key: "dashboard", label: "Dashboard", icon: "menu-dashboard" },
//   { path: "/admin/my-chatbots", key: "myChatbots", label: "My Chatbots", icon: "menu-my-chatbots"  },
//   { path: "/admin/analytics", key: "chatbotAnalytics", label: "Chatbot Analytics", icon: "menu-chatbots-analytics"  },
//   { path: "/admin/chat-history", key: "chatHistory", label: "Chat History", icon: "menu-chat-history"  },
//   { path: "/admin/settings", key: "settings", label: "Settings", icon: "menu-setting"  },
//   { path: "/admin/role", key: "userManagement", label: "Customer Management", icon: "menu-setting"  },
//   { path: "/admin/subscription-management", key: "subscriptionManagement", label: "Customer Subscription Details", icon: "menu-setting" },
//   { path: "/admin/promocode-management", key: "promocodeManagement", label: "Promo Code Management", icon: "menu-setting" },
//   { path: "/admin/plan", key: "planManagement", label: "Plan Management", icon: "menu-setting" },
//   { 
//     path: "/admin/cms", 
//     key: "cmsManagement", 
//     label: "Informative Content Management", 
//     icon: "menu-setting",
  
//   }],
//   "user":[
//   { path: "/dashboard", key: "dashboard", label: "Dashboard", icon: "menu-dashboard" },
//   { path: "/my-chatbots", key: "myChatbots", label: "My Chatbots", icon: "menu-my-chatbots"  },
//   { path: "/analytics", key: "chatbotAnalytics", label: "Chatbot Analytics", icon: "menu-chatbots-analytics"  },
//   { path: "/chat-history", key: "chatHistory", label: "Chat History", icon: "menu-chat-history"  },
//   { path: "/my-plan", key: "myPlan", label: "My Subscription", icon: "menu-my-subscription"  },
//   { path: "/settings", key: "settings", label: "Settings", icon: "menu-setting"  },
//   ]
// };



import React, { createContext, useEffect, useState } from 'react';
import { CHAT_HISTORY_DETAILS, CMS_BASE_URL, lOGIN, ME, NOTIFICATION, SAVE_TOKEN, SIGN_UP, USER_INVOICE_LIST, USER_SUBSCRIPTION_LIST } from '../utils/APIEndPoints';
import { CallAPI } from '../utils/apiActions';
import { useNavigate } from 'react-router-dom';
import { showToast } from '../helper/toastHelper';
import { LABEL } from '../utils/constant';

const coolBizDasboardState = {
  planList: [],
  activePlan: [],
  allSubscribers: [],
  paymentHistory: [],
  invoiceList: [],
  allCMS: [],
  planPurchase: null,
  isLoading: false,
  dashbordAndAnalyticsData:{},
  chatDashbordAndAnalyticsData:{},
  allNotification:{notifications:[]}
};

const DashboardContext = createContext({
  ...coolBizDasboardState
});

export const DasboardProvider = ({ children }) => {
  const navigate = useNavigate();
  const [state, dispatch] = useState(coolBizDasboardState);

  const manageDasboardState = (key, value) => dispatch((prev) => ({ ...prev, [key]: value }))

  const getAllPlan = async (payload) => {
    manageDasboardState('isLoading', true);
    const response = await CallAPI("WEBSITE", "POST", CMS_BASE_URL.DASHBOARD.GET_ALL, {});
    manageDasboardState('isLoading', false);
    if (response?.res?.status === 200) {
     
      manageDasboardState('planList', response?.res?.data?.data || []);
    }
  }

  const getUserActivePlan = async (payload) => {
    manageDasboardState('isLoading', true);
    const response = await CallAPI("WEBSITE", "POST", CMS_BASE_URL.DASHBOARD.ACTIVE_PLAN, payload);
    manageDasboardState('isLoading', false);
    if (response?.res?.status === 200) {
    
      manageDasboardState('activePlan', response?.res?.data?.data || []);
    }
  }

  const getUsersActivePlan = async (_,page=1) => {
    manageDasboardState('isLoading', true);
    const response = await CallAPI("WEBSITE", "GET", `${CMS_BASE_URL.DASHBOARD.ALL_ACTIVE_SUBSCRIBED_USER}?page=${page}`);
    manageDasboardState('isLoading', false);
    if (response?.res?.status === 200) {
      manageDasboardState('allSubscribers', response?.res?.data?.data ||{});
    }
  }

  const getUsersPaymentHistory = async (userId, page) => {
    manageDasboardState('isLoading', true);
    const response = await CallAPI("WEBSITE", "GET", `${USER_SUBSCRIPTION_LIST}?userId=${userId}&page=${page}`);
    manageDasboardState('isLoading', false);
    if (response?.res?.status === 200) {
      manageDasboardState('paymentHistory', response?.res?.data?.data || []);
    }
  }

  const getInvoiceList = async (subscriptionId, page=1,viauserId=false) => {
    manageDasboardState('isLoading', true);
    const response = await CallAPI("WEBSITE", "GET", `${USER_INVOICE_LIST}?${viauserId?"userId":"subscriptionId"}=${subscriptionId}&page=${page}`);
    manageDasboardState('isLoading', false);
    if (response?.res?.status === 200) {
      manageDasboardState('invoiceList', response?.res?.data?.data || []);
    }
  }

  const downloadInvoice = async (payload) => {
    // manageDasboardState('isLoading', true);
    const response = await CallAPI("WEBSITE", "POST", CMS_BASE_URL.DASHBOARD.DOWNLOAD_INVOICE, payload);
    // manageDasboardState('isLoading', false);
    if (response?.res?.status === 200) {
      return response?.res?.data?.data || null;
    }
  }

  const cancelSubscription = async (payload, cb) => {
    manageDasboardState('isLoading', true);
    const response = await CallAPI("WEBSITE", "POST", CMS_BASE_URL.DASHBOARD.CANCEL_PLAN, payload);
    manageDasboardState('isLoading', false);
    if (response?.res?.status === 200) {
      cb(response.res.data.message)
    }
  }

  const contactUsRecordCreate = async (payload, cb, type) => {
    manageDasboardState('isLoading', true);
    const response = await CallAPI("WEBSITE", "POST", 'contact-us', payload, type);
    manageDasboardState('isLoading', false);
    if (response?.res?.status === 200) {
      cb(response.res.data.message)
    }
  }

  const getCmsByCmsType = async (type = 1) => {
    manageDasboardState('isLoading', true);
    const response = await CallAPI("WEBSITE", "GET", `${CMS_BASE_URL.CMS.GET_ALL}${type}`);
    manageDasboardState('isLoading', false);
    if (response?.res?.status === 200) {
      manageDasboardState('allCMS', response?.res?.data?.data || []);
    }
  }

  const getCmsDocument = async () => {
    manageDasboardState('isLoading', true);
    try {
      const [type1Data, type2Data, type3Data] = await Promise.all([
        CallAPI("WEBSITE", "GET", `${CMS_BASE_URL.CMS.GET_ALL}1`),
        CallAPI("WEBSITE", "GET", `${CMS_BASE_URL.CMS.GET_ALL}2`),
        CallAPI("WEBSITE", "GET", `${CMS_BASE_URL.CMS.GET_ALL}3`)
      ]);

      manageDasboardState('isLoading', false);
      return {
        cig: type1Data?.res?.status === 200 ? type1Data?.res?.data?.data?.cmsData : [],
        faq: type2Data?.res?.status === 200 ? type2Data?.res?.data?.data?.cmsData  : [],
        ot: type3Data?.res?.status === 200 ? type3Data?.res?.data?.data?.cmsData  : [],
      };
    } catch (error) {
      manageDasboardState('isLoading', false);
      console.error("Error fetching CMS documents:", error);
      return null;
    }
  }

  const checkPromocode = async (payload) => {
    manageDasboardState('isLoading', true);
    const response = await CallAPI("WEBSITE", "POST", 'subscription/check-promoCode', payload);
    manageDasboardState('isLoading', false);
    if (response?.res?.status === 200) {
      return response.res.data;
    }
  }

  const getDashboardAndAnalyticsData = async (dateRange="",userId) => {
    try {
      manageDasboardState('isLoading', true);
      const buildUrlWithDateRange = (baseUrl, dateRange) => {
        let url=baseUrl
        if (dateRange&&baseUrl===CHAT_HISTORY_DETAILS) {
          url= `${baseUrl}?startYear=${dateRange}`;
        }
        if(dateRange&&baseUrl===CMS_BASE_URL.DASHBOARD.DASHBOARD_ANALYTICS){
          url= `${baseUrl}?year=${dateRange}`
        }
        if(userId&&baseUrl===CHAT_HISTORY_DETAILS){
          url=`${url}&userId=${userId}`
        }
        return url;
      };
  
      const URL1 = buildUrlWithDateRange(CMS_BASE_URL.DASHBOARD.DASHBOARD_ANALYTICS, dateRange);
      const apiCalls = [ { type: "WEBSITE", method: "GET", url: URL1 },];
     if(dateRange){
      const URL2 = buildUrlWithDateRange(CHAT_HISTORY_DETAILS, dateRange);
      apiCalls.push({ type: "CHAT", method: "GET", url: URL2 })
     }
      const fetchApi = async ({ type, method, url }) => {
        try {
          return await CallAPI(type, method, url);
        } catch (error) {
          console.error(`Error fetching data from ${url}`, error);
          return null;
        }
      };
      const [response, response2] = await Promise.all(apiCalls.map(fetchApi));
      if (response?.res?.status === 200) {
        manageDasboardState('dashbordAndAnalyticsData', response.res.data?.data || {});
      }
      if (response2?.res?.status === 200) {
        manageDasboardState('chatDashbordAndAnalyticsData', response2.res.data || {});
      }
    } catch (error) {
      console.error('Error in getDashboardAndAnalyticsData:', error);
    } finally {
      manageDasboardState('isLoading', false);
    }
  };
  const setPlanPurchase = async (value) => {
    manageDasboardState('planPurchase', value);
  }
  const saveFcmToken = async (payload) => {
    const response = await CallAPI("WEBSITE", "POST", SAVE_TOKEN, payload,"",false);
    if (response?.res?.status === 200) {
     console.warn("saved")
    }
  }
  const getNotification = async (page=1) => {
    manageDasboardState('isLoading', true);
    const response = await CallAPI("WEBSITE", "GET", `${NOTIFICATION}?page=${page}`);
    manageDasboardState('isLoading', false);
    if (response?.res?.status === 200) {
      manageDasboardState('allNotification', response?.res?.data?.data?.response || {notifications:[]});
    }
  }

  const coolBizDasboardValues = {
    ...state,
    getAllPlan,
    setPlanPurchase,
    getUsersActivePlan,
    cancelSubscription,
    getUserActivePlan,
    getUsersPaymentHistory,
    getInvoiceList,
    downloadInvoice,
    contactUsRecordCreate,
    getCmsByCmsType,
    getCmsDocument,
    checkPromocode,
    getDashboardAndAnalyticsData,
    saveFcmToken,
    getNotification
  }

  return (
    <DashboardContext.Provider
      value={coolBizDasboardValues}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardContext;

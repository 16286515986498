export const createLinkText = (text) => {
    const regex = /(https?:\/\/[^\s]+)/g;  // Simple regex to detect URLs in the text
    const parts = text.split(regex);  // Split the text by the URLs

    return parts.map((part, index) => {
      if (part.match(regex)) {
        return <a style={{color:'blue'}} key={index} href={part} target="_blank" rel="noopener noreferrer">{part}</a>;
      } else {
        return part; // If it's not a URL, just return the text
      }
    });
  };
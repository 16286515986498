import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CallAPI } from "../../utils/apiActions";
import { showToast } from "../../helper/toastHelper";
import { LABEL } from "../../utils/constant";
import { GO_HIGH_LEVEL } from "../../utils/APIEndPoints";

const ConnectGoHighLevelCode = () => {
  const query = new URLSearchParams(useLocation().search);
  const initialCode = query.get("code") || "";
  const [code, setCode] = useState(initialCode);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const saveCode = async (e) => {
    e.preventDefault();
    if (!code.trim()) {
      return showToast("Please provide a valid code", LABEL.TOAST_TYPE.ERROR);
    }
    setIsLoading(true);
    try {
      const response = await CallAPI("WEBSITE", "POST", GO_HIGH_LEVEL.CALLBACK, {
        code,
      });
      if (response?.res?.data?.msg) {
        showToast(response.res.data.msg, LABEL.TOAST_TYPE.SUCCESS);
        navigate("/profile");
      }
    } catch (error) {
      showToast(
        "An error occurred while saving the code",
        LABEL.TOAST_TYPE.ERROR
      );
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="user-details-form modal-form-grid pt-3 form-step-2">
      <form onSubmit={saveCode}>
        <div class="field">
          <label for="textareaChatbotDescription">
            Enter your code
            <span style={{ color: "red" }}>*</span>
          </label>
          <input
            className={`form-control`}
            type="text"
            name="code"
            value={code}
            placeholder="Enter the code"
            onChange={(e) => setCode(e.target.value)}
          />
        </div>
        <button type="submit" className="cta-green" disabled={isLoading}>
          {isLoading ? <i className="fa-solid fa-spinner fa-spin"></i> : "Connect"}
        </button>
      </form>
    </div>
  );
};

export default ConnectGoHighLevelCode;

import { CircularProgress } from "@mui/material"
import React from "react"
import { Area, AreaChart, ResponsiveContainer, XAxis, YAxis } from "recharts"
const MessageGraph=({data=[]})=>{
    return(
        <div className="chart-container">
          <div className="chart-block dark-bg flex-grow12">
            <header>
              <h6>Total Incoming Chat</h6>
            </header>
         <ResponsiveContainer width="100%" height={200}>
              <AreaChart data={data}>
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#75FF36" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#75FF36" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis dataKey="month" />
                <YAxis />
                {/* <Tooltip content={<CustomTooltip />} /> */}
                <Area
                  type="monotone"
                  dataKey="uv"
                  stroke="#75FF36"
                  fillOpacity={1}
                  fill="url(#colorUv)"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
    )
}
export default MessageGraph
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  LABEL,
  MANAGE_REVENEU_TABLE_CONSTANT,
  replaceActionCallback,
  SUBSCRIPTION_TABLE_CONSTANT,
} from "../../utils/constant";
import CommonTable from "../UI/Table";
import TableSearchFilter from "../UI/TableSearchFilter";
import useCmsManagement from "../../hooks/useCmsManagement";
import useDashboard from "../../hooks/useDashboard";
const ReveneuReport = () => {
  const { getReveneuList, reveneuList, isLoading,handleExportReveneuCSV,isCSVLoading } = useCmsManagement();
  const {
    isLoading: isLoadingInvoice,
    getInvoiceList,
    invoiceList,
    downloadInvoice:downloadInvoiceApi,
 
  } = useDashboard();
  const [isInvoiceListModalOpen_id, setIsInvoiceListModalOpen_id] =
    useState("");
  const onClose = () => {
    setIsInvoiceListModalOpen_id("");
  };
  const getReveneuApi = (page = 1, name = "") => {
    console.log("e",name)
    getReveneuList(page, name);
  };
  const pagination = useMemo(() => {
    return {
      current: reveneuList.currentPage,
      pageSize: reveneuList.pageSize,
      total: reveneuList.totalRecords,
      onChange: getReveneuApi,
    };
  }, [reveneuList.currentPage, reveneuList.pageSize, reveneuList.totalRecords]);
  useEffect(() => {
    getReveneuApi();
  }, []);
  const handleViewInvoiceList = (item) => {
    getInvoiceList(item.id, 1,true);
    setIsInvoiceListModalOpen_id(item?.id)
  };
  function handleInvoiceListPagination(page = 1) {
    getInvoiceList(isInvoiceListModalOpen_id, page,true);
  }

  const invoiceListPagination = useMemo(() => {
    return {
      current: invoiceList.currentPage,
      pageSize: invoiceList.pageSize,
      total: invoiceList.totalRecords,
      onChange: handleInvoiceListPagination,
    };
  }, [
    invoiceList.currentPage,
    invoiceList.pageSize,
    invoiceList.totalRecords,
    getInvoiceList,
  ]);
  const downloadInvoice = async (payload) => {
    const invoiceDownloadUrl = await downloadInvoiceApi(payload);

    if (invoiceDownloadUrl) {
      const link = document.createElement("a");
      link.href = invoiceDownloadUrl;
      link.download = "";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  const downloadInvoiceFn = useCallback((item) => {
    console.log("itete",item?.stripeInvoiceId)
    downloadInvoice({ stripeInvoiceId: item.stripeInvoiceId });
  }, []);
  let modifiedActions = MANAGE_REVENEU_TABLE_CONSTANT.usersManagementactions;

  modifiedActions = replaceActionCallback(
    modifiedActions,
    "View",
    handleViewInvoiceList
  );
  let invoiceListActions = SUBSCRIPTION_TABLE_CONSTANT.usersManagementactions;

  invoiceListActions = replaceActionCallback(
    invoiceListActions,
    LABEL.TABLE_COLUMN.ACTION.DOWNLOAD.LABEL,
    downloadInvoiceFn
  );
  return (
    <React.Fragment>
      <div className="all-chatboat">
        <header className="d-md-flex align-items-center justify-content-between">
          <h3>{LABEL.REVENEU.HEADLINE}</h3>
          <div className="group-form d-md-flex">
            <h3>
              {LABEL.REVENEU.TOTAL_TRANSACTION}
              {parseFloat(reveneuList?.totalRevenue||0).toFixed(2)|| ""}
            </h3>
            <button type="submit" className="cta-green" disabled={!!isCSVLoading} onClick={handleExportReveneuCSV}>
              {isCSVLoading ? <i class="fa-solid fa-spinner fa-spin"></i>: <i class="fa-solid fa-download"></i>}
              Export CSV
            </button>
            <TableSearchFilter
              placeholderForSearch="Search by name"
              showFilterBy={false}
              onFilter={(val) => getReveneuApi(1, val)}
            />
          </div>
        </header>
        <CommonTable
          data={reveneuList?.users || []}
          columns={MANAGE_REVENEU_TABLE_CONSTANT.usersManagementcolumns}
          actions={modifiedActions}
          pagination={pagination}
          isLoading={isLoading}
          isPagination={true}
        />
      </div>
      {isInvoiceListModalOpen_id && (
        <div class="modalOverlay modal-open">
          <div class="modal-container width-lg modal-dark-bg">
            <a href="javascript:void(0)" class="modalClose" onClick={onClose}>
              <img src="/images/icons/modal-close-icon.svg" alt="" />
            </a>
            <div class="modal-form-grid pt-3">
              <form class="form-step-2 text-start">
                <h3>Invoice List</h3>
                <div class="field">
                  <CommonTable
                    data={invoiceList?.invoiceList || []}
                    columns={
                      SUBSCRIPTION_TABLE_CONSTANT.usersPaymentInvoiceColumnsInAdmin
                    }
                    pagination={invoiceListPagination}
                    actions={invoiceListActions}
                    isPagination={true}
                    loading={isLoadingInvoice}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
export default ReveneuReport;

import { useEffect, useRef } from "react";
import useChatBot from "../hooks/useChatBot";
import { v4 as uuidv4 } from 'uuid';

export default function ChatSocketInitialization({ ...props }) {
    const { chatHistoryConsent, trustedDeviceConsent, updateConnectedShortCode } = useChatBot();
    const audioRef = useRef(null);

    useEffect(() => {
        if (!!props.bot_id && props.botCompletedStep != 4) {
            updateConnectedShortCode({ shortCode: '', domainURL: '', domainPath: '', BotID: props.bot_id, env: props?.connectionType == "outside" ? "live" : "test" })
        }
        else {
            updateConnectedShortCode({ shortCode: props.shortCode || "951644555488", domainURL: props.domainURL, domainPath: props.domainPath, BotID: '', env: props?.connectionType == "outside" ? "live" : "test" })
            if (chatHistoryConsent == "yes" && trustedDeviceConsent == "yes" && props?.connectionType == "outside") {
                localStorage.setItem("browser_unique_id", uuidv4())
            }
        }
    }, [props?.shortCode, props.domainURL, props.domainPath, props.bot_id, props.botCompletedStep]);

    // useEffect(() => {
    //     if (audioRef.current && !!receivedMessage) {
    //         audioRef.current.play()
    //             .catch((error) => {
    //                 console.error("Error playing sound:", error);
    //             });
    //     }
    // }, [receivedMessage]);


    //   

    return (
        <audio ref={audioRef} src="/sound/new-notification-7-210334.mp3" />
    )
}
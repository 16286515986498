import React, { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { user_type } from "../../utils/constant";
import YearRangeSelector from "../UI/YearRangeSelector";
import UserEngagement from "./UserEngagement";
import useDashboard from "../../hooks/useDashboard";
import TopChatBot from "./TopChatBot";
import MessageGraph from "./MessageGraph";
import UsersSummaryCard from "./UsersSummaryCard";
import AdminSummaryCard from "./AdminSummaryCard";
import SlashScreen from "../SplashScreen";
import YearSelector from "../UI/YearRangeSelector";

export default function ChatboatAnalytics() {
  const { user } = useAuth();
  const {
    getDashboardAndAnalyticsData,
    dashbordAndAnalyticsData,
    chatDashbordAndAnalyticsData,
    isLoading,
  } = useDashboard();

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const handleYearChange = (year) => {
    setSelectedYear(year);
  };
  useEffect(() => {
      getDashboardAndAnalyticsData(selectedYear,user_type[user?.userType] == "super_admin"?"":user.userUuid||"");
  }, [selectedYear]);

  return (
    <div className="chatbot-analytics-grid">
      <header className="">
        <h3>Chatbot Analytics</h3>
      </header>
      {isLoading ? (
        <SlashScreen />
      ) : (
        <>
          <div className="chart-container">
            <div className="chart-block dark-bg">
              <header className="with-selectbox">
                <h6>Chatbot Analytics</h6>
                <span>Chat Summary</span>
                <YearSelector selectedYear={selectedYear} setSelectedYear={handleYearChange} />
              </header>

              {user_type[user?.userType] === "user" && (
                <UsersSummaryCard
                  data={{
                    ...dashbordAndAnalyticsData,
                    total_incoming_chats:
                      chatDashbordAndAnalyticsData?.chat_summary
                        ?.total_incoming_chats || 0,
                    total_incoming_chats_change:
                      chatDashbordAndAnalyticsData?.chat_summary
                        ?.total_incoming_chats_change || "",
                    active_chats:
                      chatDashbordAndAnalyticsData?.chat_summary
                        ?.active_chats || 0,
                    active_chats_change:
                      chatDashbordAndAnalyticsData?.chat_summary
                        ?.active_chats_change || "",
                  }}
                />
              )}

              {user_type[user?.userType] === "super_admin" && (
                <AdminSummaryCard
                  data={{
                    ...dashbordAndAnalyticsData,
                    total_incoming_chats:
                      chatDashbordAndAnalyticsData?.chat_summary
                        ?.total_incoming_chats || 0,
                    total_incoming_chats_change:
                      chatDashbordAndAnalyticsData?.chat_summary
                        ?.total_incoming_chats_change || "",
                    active_chats:
                      chatDashbordAndAnalyticsData?.chat_summary
                        ?.active_chats || 0,
                    active_chats_change:
                      chatDashbordAndAnalyticsData?.chat_summary
                        ?.active_chats_change || "",
                  }}
                />
              )}
            </div>
          </div>
          <MessageGraph
            data={chatDashbordAndAnalyticsData?.total_incoming_chat || []}
          />
          <div className="chart-container">
            <UserEngagement
              data={chatDashbordAndAnalyticsData.user_engagement}
            />
            <TopChatBot data={chatDashbordAndAnalyticsData.top_chatbots} />
          </div>
        </>
      )}
    </div>
  );
}

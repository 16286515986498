import React, { useCallback, useEffect, useMemo, useState } from "react";
import useCmsManagement from "../../hooks/useCmsManagement";
import CommonTable from "../UI/Table";
import { PLAN_TABLE_CONSTANT, LABEL, replaceActionCallback } from "../../utils/constant";
import { showToast } from "../../helper/toastHelper";
import useAuth from "../../hooks/useAuth";
import { Link, useNavigate } from "react-router-dom";
import ViewPlanModal from "./PlanModal/ViewPlanModal";


export default function PlanManagement() {
  const navigate = useNavigate();
  const { getAllPlan, planList, deleteCoupon, isLoading } = useCmsManagement();
  const { user } = useAuth();
  const [modalContent,setModalContent]=useState({
    show:false,
    data:{}
  })
  useEffect(() => {

    if (!user.id) {
      return showToast(LABEL.USER.USER_ID_NOT_FOUND, LABEL.TOAST_TYPE.ERROR);
    }

    fetchAllPlans();

  }, []);

  function fetchAllPlans(page=1) {
    const payload = {
      userId: user.id
    }

    getAllPlan(payload,page);
  }

  const afterSuccess = (msg) => {
    fetchAllPlans();

    return showToast(msg, LABEL.TOAST_TYPE.SUCCESS);
  }

  const deletePlanFn = useCallback(
    (item) => {
      const confirmDelete = window.confirm(`Are you sure you want to delete ${item.couponName}?`);
      if (confirmDelete) {
        const payload = { couponId: item.id };
        // deleteCoupon(payload, afterSuccess)
      }
    },
    [deleteCoupon, getAllPlan, user.id]
  );

  const goForEdit = (id) => navigate(`/admin/plan/edit/${id}`)

  // Custom edit function
  const viewPlanFn = useCallback(
    (item) => {
      // goForEdit(item.id)
      setModalContent({
        show:true,
        data:item
      })
    },
    []
  );
  const editPlanFn = useCallback(
    (item) => {
      goForEdit(item.id)
    },
    []
  );
  const onClose=()=>{
    setModalContent({...modalContent,show:false})
  }
  const handlePagination=(page)=>{
    fetchAllPlans(page)
  }
  const pagination = useMemo(() => {
    return {
      current: planList.currentPage,
      pageSize: planList.pageSize,
      total: planList.totalRecords,
      onChange: handlePagination,
    };
  }, [planList.currentPage, planList.pageSize, planList.totalRecords, getAllPlan]);

  let modifiedActions = PLAN_TABLE_CONSTANT.usersManagementactions;

  modifiedActions = replaceActionCallback(modifiedActions, LABEL.TABLE_COLUMN.ACTION.DELETE.LABEL, deletePlanFn);

  modifiedActions = replaceActionCallback(modifiedActions, "Edit Plan", editPlanFn);
  modifiedActions = replaceActionCallback(modifiedActions, "View Plan", viewPlanFn);

  return (
    <React.Fragment>
      <div className="all-chatboat">
        <header className="d-md-flex align-items-center justify-content-between">
          <h3>{LABEL.PLAN_MANAGEMENT.PLAN_MANAGEMENT}</h3>

          <Link to="/admin/plan/create" className="cta-green">
            <i className="fa-solid fa-sparkles" aria-hidden="true"></i>
            {LABEL.PLAN_MANAGEMENT.TITLE}
          </Link>
        </header>
        <CommonTable
          data={planList?.planList||[]}
          columns={PLAN_TABLE_CONSTANT.usersManagementcolumns}
          actions={modifiedActions}
          pagination={pagination}
          isPagination={true}
          isLoading={isLoading}
        />
      </div>
      {modalContent.show&&<ViewPlanModal onClose={onClose} item={modalContent.data}/>}
    </React.Fragment>
  );
}

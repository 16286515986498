/* AUTH APIS */
export const lOGIN = "users/login";
export const SUPER_LOGIN = "super-admin/login";
export const SIGN_UP = "users/register"
export const ME = "me"
export const LOGOUT_USER = "users/logout"
export const LOGOUT_ADMIN="super-admin/logout"

/* BOT SETTINGS */
export const BOT_APPEARANCE = "bot/appearance"
export const BOT_LISTS = "bot/list"
export const BOT_APPEARANCE_DETAILS = "bot/appearance-details"
export const BOT_QUESTION = "bot/question"
export const KNOWLEDGE_CATAGORY = "bot/ks-category"
export const GENERATE_EMBED = "bot/settings/shortcode"
export const KNOWLEDGE_SOURCE_DOCUMENT = "bot/ks-upload-fs"
export const BOT_SETTINGS = "bot/settings"
export const BOT_SETTINGS_PROPS = "bot/settings-prop"
export const BOT_SETTINGS_PROPS_CHAT = "language-list/"
export const KNOWLEDGE_SOURCE = "bot/ks-upload"
export const BOT_CHAT_ICON = "bot/appearance-img"
export const BOT_CHAT_ICON_REMOVE = "bot/remove-bot-appearance-img"

export const BOT_EMBED = "bot/embed"
export const BOT_DELETE = "bot/archive"
export const BOT_CHAT_HISTORY = "chat-bot-history-browser"
export const TRANSLATED_OUTPUT="translated-output/"
/* SETTINGS FOR BOT */
export const BOT_STATUS_UPDATE = "bot/update-bot-status"
export const BOT_LIST_WITH_STATUS = "bot/all-status"

/* USER SETTINGS */
export const USER_UPDATE = "users/update"
export const FORGET_PASSWORD = "users/forget_password"
export const GET_OTP = "users/get_otp"
export const VALIDATE_OTP = "users/validate_otp"
export const CHANGE_PASSWORD = "users/reset_password"
export const EMAIL_VERIFICATION_OTP = "users/email_verification_otp"
export const EMAIL_VERIFICATION = "users/email_otp_validate"
export const PHONE_VERIFICATION_OTP = "users/phone_verification_otp"
export const PHONE_VERIFICATION = "users/phone_otp_validate"
export const CARD_DEFAULT_SETUP = "subscription/card-default-setup"
export const CARD_UPDATE = "subscription/card-update"
export const USER_SUBSCRIPTION_LIST = "subscription/user-subscription-list"
export const USER_INVOICE_LIST = "subscription/user-invoice-list"
export const TOTAL_SUBSCRIBERS = "users/total_subscribers"

export const CHAT_HISTORY_LIST = "chat-bot-history-list/"
export const CHAT_HISTORY_DETAILS = "chat-bot-analytics/"
export const CHAT_HISTORY_VIEW = "chat-bot-history-view/"
export const DASHBOARD_CHAT_DETAILS="chat-bot-history-details-user"

/*ADMIN USER SETTINGS */
export const ADMIN_FORGET_PASSWORD = "super-admin/forget_password"
export const ADMIN_GET_OTP = "super-admin/get_otp"
export const ADMIN_VALIDATE_OTP = "super-admin/validate_otp"
export const ADMIN_CHANGE_PASSWORD = "super-admin/reset_password"
export const ADMIN_REVENEU_URL = "subscription/get-user-revenue"
export const NOTIFICATION="users/notification"
export const SAVE_TOKEN="fcm/token/subscribe"
export const SEND_NOTIFICATION= "fcm/send-notification"
export const EXPORT_USER_DATA="users/download_user_details"
export const CMS_BASE_URL = {
    ALL_USERS: "super-admin/user_lists",
    CREATE_USERS_BY_ADMIN: "super-admin/create_new_user",
    DELETE_USERS_BY_ADMIN: "super-admin/delete_user",
    UPDATE_USERS_STATUS_BY_ADMIN:"super-admin/status_toggle",
    RESTORE_USER_BY_ADMIN:"super-admin/retrieve_user",
    DOWNLOAD_USERS_BY_ADMIN:"super-admin/download_user_lists",
    DOWNLOAD_REVENEU_REPORT:"subscription/downLoad-user-revenue",
    COUPON: {
        CREATE: "subscription/create-coupon",
        GET_ALL: "subscription/coupon-list",
        DELETE: "subscription/delete-coupon",
    },
    PLAN_DESCRIPTION: {
        CREATE: "subscription/create-plan",
        UPDATE: "subscription/update-plan",
        GET_ALL: "subscription/plan-description-list",
        DELETE: "subscription/delete-coupon",
    },
    PLAN: {
        CREATE: "subscription/create-plan",
        UPDATE: "subscription/update-plan",
        GET_ALL: "subscription/plan-list",
        DELETE: "subscription/delete-coupon",
        GET_BY_ID: "subscription/edit-plan/",
    },
    DASHBOARD: {
        GET_ALL: "subscription/user-plan-list",
        USER_SUBSCRIPTION: "subscription/create-subscription",
        ACTIVE_PLAN: "subscription/user-active-subscription",
        CANCEL_PLAN: "subscription/cancel-subscription",
        ALL_ACTIVE_SUBSCRIBED_USER: "subscription/superadmin-user-subscription-list",
        DOWNLOAD_INVOICE: "subscription/download-invoice",
        DASHBOARD_ANALYTICS:"users/dashboard_analytics",
    },
    CMS: {
        CREATE: "cms",
        UPDATE: "cms/",
        GET_ALL: "cms/type/",
        DELETE: "cms/",
        GET_BY_ID: "cms/",
    },
}
export const GO_HIGH_LEVEL={
    CONFIG:"oauth/ghl/config",
    AUTHORIZE:"oauth/authorize/",
    CALLBACK:"oauth/callback"
}

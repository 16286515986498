import React, { createContext, useEffect, useReducer } from 'react';
import { createLinkText } from '../utils/common';

const initialAuthState = {
    receivedQuestionType: '',
    connectedShortCode: '',
    connectedBotID: '',
    connectedDomainURL: '',
    connectedDomainPath: '',
    globalSocket: null,
    messageList: [],
    receivedMessage: null,
    responseDelay: false,
    chatHistoryConsent: '',
    trustedDeviceConsent: '',
    env:'',
};

const reducer = (state, action) => {
    console.log("action---", action)
    switch (action.type) {
        case 'GLOBAL_SOCKET_CONNECTION': {
            return {
                ...state,
                globalSocket: action.payload,
            };
        }
        case 'MSG_LIST_UPDATE': {
            return {
                ...state,
                messageList: action.payload,
            };
        }
        case 'RECEIVED_MSG_UPDATE': {
            return {
                ...state,
                receivedMessage: action.payload,
            };
        }
        case 'RECEIVED_QUESTION_TYPE': {
            return {
                ...state,
                receivedQuestionType: action?.payload,
            };
        }
        case 'SET_CONNECTED_PARAMETERS': {
            return {
                ...state,
                connectedShortCode: action.payload.shortCode,
                connectedDomainURL: action.payload.domainURL,
                connectedDomainPath: action.payload.domainPath,
                connectedBotID: action.payload.BotID,
                env: action.payload.env
            };
        }
        case 'SET_CHAT_CONSENT': {
            return {
                ...state,
                chatHistoryConsent: action?.payload,
            };
        }

        case 'SET_TRUSTED_DEVICE': {
            return {
                ...state,
                trustedDeviceConsent: action?.payload,
            };
        }

        case 'DELAY_UPDATE': {
            return {
                ...state,
                responseDelay: action.payload,
            };
        }
        default: {
            return { ...state };
        }
    }
};

const ChatBotContext = createContext({
    ...initialAuthState,
    updateDelay: () => Promise.resolve(),
    setGlobalSocket: () => Promise.resolve(),
    updateReceivedMsg: () => Promise.resolve(),
    updateMsgList: () => Promise.resolve(),
    updateConnectedShortCode: () => Promise.resolve(),
    handleSetChatConsent: () => Promise.resolve(),
    updateReceivedQuestionType: () => Promise.resolve(),
});

export const ChatBotProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialAuthState);

    useEffect(() => {
        console.log("WS URL 1111", `${process.env.REACT_APP_EXTERNAL_SOCKET_URL}${state.connectedShortCode}/?domain=${state.connectedDomainURL}&path=${state.connectedDomainPath}&env=${state.env}`)
        if ((!!state.connectedShortCode && !!state.connectedDomainURL && !!state.connectedDomainPath) || !!state.connectedBotID) {
            let ws = ''
            if (!!state.connectedBotID) {
                console.log("socket by botid")
                ws = new WebSocket(`${process.env.REACT_APP_TEST_SOCKET_URL}${state.connectedBotID}/`);
            }
            else {
                console.log("socket by shortcode")
                let lsChatHistoryConsent = state.chatHistoryConsent
                let lsTrustedDeviceConsents = state.trustedDeviceConsents
                if (!!localStorage.getItem("browser_unique_id")) {
                    lsChatHistoryConsent = "yes"
                    lsTrustedDeviceConsents = "yes"
                }
                ws = new WebSocket(`${process.env.REACT_APP_EXTERNAL_SOCKET_URL}${state.connectedShortCode}/?domain=${state.connectedDomainURL}&path=${state.connectedDomainPath}&consent=${lsChatHistoryConsent}&browser_unique_id=${localStorage.getItem("browser_unique_id")}&deviceTrust=${lsTrustedDeviceConsents}&env=${state.env}`);
            }
            ws.onopen = () => {
                console.log('WebSocket connected');
            };
            setGlobalSocket(ws)

            ws.onmessage = (data) => {
                updateDelay(false)
                console.log('WebSocket onmessage', data, JSON.parse(data.data));
                updateReceivedMsg({ ...JSON.parse(data?.data).message?.bot, directionType: "receive", isAnswered: false });
                updateReceivedQuestionType(JSON.parse(data?.data).message?.bot.type)
            };

            // ws.send(JSON.stringify({"message":{"id":"1","user_message":"hi"}}))

            // ws.onclose = () => {
            //     console.log('WebSocket disconnected');
            // };

            // ws.onerror = (error) => {
            //     console.error('WebSocket error:', error);
            // };

            // ws.receive = (data) => {
            //     console.log('WebSocket Receive', data);
            // };

            return () => {
                ws.close();;
            };
        }
    }, [state.connectedShortCode, state.connectedDomainURL, state.connectedDomainPath, state.connectedBotID]);

    useEffect(() => {
        if (!!state.receivedMessage) {
            let laMsgList = [...state.messageList]
            laMsgList.push({ ...state.receivedMessage, question: createLinkText(state.receivedMessage.question) })
            console.log("laMsgList---", laMsgList)
            updateMsgList([...laMsgList])
        }
        updateReceivedMsg(null)
    }, [state.receivedMessage]);

    const updateDelay = (value) => {
        dispatch({ type: 'DELAY_UPDATE', payload: value })
    }

    const updateReceivedMsg = (value) => {
        dispatch({ type: 'RECEIVED_MSG_UPDATE', payload: value })
    }

    const updateReceivedQuestionType = (value) => {
        dispatch({ type: 'RECEIVED_QUESTION_TYPE', payload: value })
    }

    const updateMsgList = (value) => {
        dispatch({ type: 'MSG_LIST_UPDATE', payload: value })
    }

    const setGlobalSocket = (value) => {
        dispatch({ type: 'GLOBAL_SOCKET_CONNECTION', payload: value })
    }

    const updateConnectedShortCode = (value) => {
        dispatch({ type: 'SET_CONNECTED_PARAMETERS', payload: value })
    }

    const handleSetChatConsent = (type, value) => {
        if (type == "chatHistory") {
            dispatch({ type: 'SET_CHAT_CONSENT', payload: value })
        }
        else if (type == "trustedDevice") {
            dispatch({ type: 'SET_TRUSTED_DEVICE', payload: value })
        }
    }


    return (
        <ChatBotContext.Provider
            value={{
                ...state,
                updateDelay,
                setGlobalSocket,
                updateReceivedMsg,
                updateMsgList,
                updateConnectedShortCode,
                handleSetChatConsent,
                updateReceivedQuestionType
            }}
        >
            {children}
        </ChatBotContext.Provider>
    );
};

export default ChatBotContext;

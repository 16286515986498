import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import CustomSelect from "../UI/dropdown/CustomSelect";
import { scopes } from "../../utils/constant";
import { CallAPI } from "../../utils/apiActions";
import { useNavigate } from "react-router-dom";
import SlashScreen from "../SplashScreen";
import { GO_HIGH_LEVEL } from "../../utils/APIEndPoints";
import { Button } from "@mui/material";
const schema = yup.object().shape({
  client_id: yup.string().required("Client ID is required"),
  client_scerets: yup.string().required("Client Secret is required"),
  lid: yup.string().required("Location Id is required"),
  scopes: yup.array().min(1).required(),
});
const genaral = [
  "Create an App by selecting App type as 'Private', Distribution Type as 'Agency' and 'Sub-Account', Listing Type as 'White-label'.",
  "Go to 'Advance Settings' -> Auth -> select Scopes as 'contact.readonly, contact.write'.",
  "Add this URL: https://api.coolbiz.info/oauth/callback to the Redirect URLs box and press Add. Save the settings and select/check the redirect URL.",
  "Generate client key -> press on add button and generate client_id, client_secret. Save the settings.",
];
const feilds = [
  {
    title: "Location Id",
    id: "lid",
    placeholder: "Enter the location id",
    visit: "https://app.leadconnectorhq.com/agency_dashboard/",
    link: [
      "Press on 'click here to switch' from the left side menu, select a location/account.",
      "Press on 'Settings' at the bottom of the left side menu.",
      "Location ID will be visible in the 'General Information' section.",
    ],
  },
  {
    title: "Client Id",
    id: "client_id",
    placeholder: "Enter the client id",
    visit:"https://marketplace.gohighlevel.com/apps",
    link: genaral,
  },
  {
    title: "Client Scerets",
    id: "client_scerets",
    placeholder: "Enter the client secrets",
    visit:"https://marketplace.gohighlevel.com/apps",
    link: genaral,
  },
  { title: "Scopes", id: "scopes", placeholder: "Enter the scopes", link: [] },
];
const ConnectGoHighLevelForm = ({}) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      scopes: [
        { label: "contacts.readonly", value: "contacts.readonly" },
        { label: "contacts.write", value: "contacts.write" },
      ],
    },
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState({
    client_id:false,
    client_scerets:false,
    lid:false
  });

  const handleClick = (id) => {
    setAnchorEl({
      ...anchorEl,
      [id]:!anchorEl[id]
    });
  };

  const getDetailsOfGhl = async () => {
    setLoading(true);
    try {
      const res = await CallAPI("WEBSITE", "GET", GO_HIGH_LEVEL.CONFIG);
      const la_data = res?.res?.data?.data;
      reset({
        client_id: la_data?.cid || "",
        client_scerets: la_data?.cs || "",
        lid: la_data?.lid || "",
        scopes: la_data?.scopes
          ? la_data.scopes.split(" ").map((item) => {
              return { label: item, value: item };
            })
          : [
              { label: "contacts.readonly", value: "contacts.readonly" },
              { label: "contacts.write", value: "contacts.write" },
            ],
      });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getDetailsOfGhl();
  }, []);
  const onSubmit = async (val) => {
    try {
      const res = await CallAPI("WEBSITE", "POST", GO_HIGH_LEVEL.AUTHORIZE, {
        cid: val.client_id,
        cs: val.client_scerets,
        lid: val.lid,
        scopes: val.scopes.map((item) => item.value),
      });
      if (res?.res?.data?.authorizationUrl) {
        navigate("/profile");
        window.open(res?.res?.data?.authorizationUrl, "_blank");
      }
    } catch (err) {
      console.log("Erer", err);
    }
  };

  return loading ? (
    <SlashScreen />
  ) : (
    <div>
      <div className="user-details-form modal-form-grid pt-3 form-step-2">
        <form onSubmit={handleSubmit(onSubmit)}>
          {feilds.map((item, index) => {
            return (
              <div className="field" key={index}>
                <label for="textareaChatbotDescription">
                  {item.title}

                  <span style={{ color: "red" }}>*</span>
                  <span className="popover_wrpa">
                    {" "}
                    {item.link.length ? (
                      <>
                        <Button
                          className="popover_icon"
                          variant="contained"
                          onClick={()=>handleClick(item.id)}
                        >
                          <i className="fa-solid fa-circle-info"></i>
                        </Button>
                       {anchorEl[item.id] && 
                          <div className="popover_info">
                            <button class="popover_close" onClick={()=>handleClick(item.id)}>
                              <img src="../images/cross-icon-w.png" alt="cross-icon-w" />
                            </button>
                            <span>Steps to find {item.title}</span>
                            <ol>
                              <li> Visit :<a href={item.visit} target="_blank"> {item.visit}</a></li>
                              {item.link.map((item, index) => {
                                return <li key={index}>{item}</li>;
                              })}
                            </ol>
                          </div>}
                      </>
                    ) : (
                      <></>
                    )}
                  </span>
                </label>
                {item.id === "scopes" ? (
                  <Controller
                    name="scopes"
                    control={control}
                    render={({ field }) => (
                      <CustomSelect
                        label={""}
                        options={scopes}
                        defaultValue={[
                          {
                            label: "contacts.readonly",
                            value: "contacts.readonly",
                          },
                          { label: "contacts.write", value: "contacts.write" },
                        ]}
                        error={errors.scopes}
                        isMulti={true}
                        {...field}
                      />
                    )}
                  />
                ) : (
                  <input
                    {...register(item.id)}
                    className={`form-control ${
                      errors[item.id] ? "is-invalid" : ""
                    }`}
                    placeholder={item.placeholder}
                  />
                )}
                {errors[item.id] && (
                  <span className="invalid-feedback">
                    {errors[item.id].message}
                  </span>
                )}
              </div>
            );
          })}

          <button type="submit" className="cta-green">
           Authorize
          </button>
        </form>
      </div>
    </div>
  );
};

export default ConnectGoHighLevelForm;

import React from "react";

const MessageNotification = ({ notification }) => {
  return (
    <div className="notification-grid">
      {/* {notification.image && ( */}
        <figure>
          <img src="/images/icons/bell-icon.svg" alt="Notification" />
        </figure>
      {/* )} */}
      <div className="notification_info">
        <h5>{notification.title}</h5>
        <p>{notification.body}</p>
      </div>
    </div>
  );
};

export default MessageNotification;

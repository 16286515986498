import React, { useReducer, useState } from 'react';
import OTPInput from 'react-otp-input';
import Reducer from '../../utils/Reducer';
import { ADMIN_FORGET_PASSWORD, ADMIN_GET_OTP, ADMIN_VALIDATE_OTP, EMAIL_VERIFICATION, EMAIL_VERIFICATION_OTP, FORGET_PASSWORD, GET_OTP, VALIDATE_OTP } from '../../utils/APIEndPoints';
import { CallAPI } from '../../utils/apiActions';
import validationUtility from '../../utils/Validation';
import { LABEL } from '../../utils/constant';
import { showToast } from '../../helper/toastHelper';
import { useNavigate } from 'react-router-dom';
import useDashboard from '../../hooks/useDashboard';
import OtpView from '../OtpView';
import useAuth from '../../hooks/useAuth';

const initState = {
    varifivationStatus: false,
    otp: "",
    password: "",
    confirmPassword: "",

    otpErr: "",
    passwordErr: "",
    confirmPasswordErr: "",

    passEyeShow: false,
    confirmPassEyeShow: false,

    loading: false,
};

function ResetPassword({ ...props }) {
    const navigate = useNavigate();
    const { isEmailVerified, updateEmailVerified } = useAuth();
    const { planPurchase } = useDashboard();
    // const { planPurchase, setPlanPurchase } = useDashboard(); //emai_verification -----> By Sharmishtha
    const [state, dispatch] = useReducer(Reducer, initState);
    const [verification, setVerification] = useState({email:false, phone:false});
    const email = localStorage.getItem("email-verify") || localStorage.getItem("reset-verify-mail") || localStorage.getItem("admin-reset-verify-mail") || ""
    const VALIDATE_OTP_URL = !isEmailVerified ? EMAIL_VERIFICATION : props.userType == "user" ? VALIDATE_OTP : ADMIN_VALIDATE_OTP
    const GET_OTP_URL = props.userType == "user" ? GET_OTP : ADMIN_GET_OTP
    const FORGET_PASSWORD_URL = props.userType == "user" ? FORGET_PASSWORD : ADMIN_FORGET_PASSWORD
    const EMAIL_VERIFICATION_RESET_PASSWORD = EMAIL_VERIFICATION_OTP
    // const otp = localStorage.getItem("reset-pass-otp") || ""

    console.log("registerdata--", props.data, isEmailVerified)

    const handleFormInput = (e) => {
        if (e.target.value == null || e.target.value == '' || e.target.value == undefined) {
            dispatch({ type: "SETDATA", payload: { "name": e.target.name + 'Err', "value": `${e.target.name} is required` } })
        }
        else {
            dispatch({ type: "SETDATA", payload: { "name": e.target.name + 'Err', "value": '' } })
        }
        dispatch({ type: "SETDATA", payload: { "name": e.target.name, "value": e.target.value } })
    };

    const handleSetOtp = async (value) => {
        dispatch({ type: "SETDATA", payload: { "name": "otp", "value": value } })
        dispatch({ type: "SETDATA", payload: { "name": "otpErr", "value": "" } })
        if (value.length == 6) {
            let body = { email: email, otp: value }
            if (!isEmailVerified) {
                body.actionType = localStorage.getItem("action-type")
            }
            let response = await CallAPI("USER", "POST", VALIDATE_OTP_URL, body)
            if (response.status) {
                if (!isEmailVerified) {
                    let action_type = localStorage.getItem("action-type")
                    localStorage.removeItem("email-verify")
                    localStorage.removeItem("action-type")
                    if (action_type == "post_signup" || !!planPurchase?.id) {
                        updateEmailVerified(true)
                    }
                    else if (action_type == "signup") {
                        showToast(response.msg, LABEL.TOAST_TYPE.SUCCESS);
                        navigate('/login')
                    }
                }
                if (props.type == "emailVerification") {
                    // if (planPurchase?.id) {
                    //     let loginBody = { email: props.body?.email, password: props.body?.password }
                    //     await login(loginBody, "with-plan")
                    //     showToast(response.msg,
                    //         LABEL.TOAST_TYPE.SUCCESS
                    //     );
                    //     const user = response?.res?.data?.data;
                    //     const createSubscriptionPayload = {
                    //         userId: user.userId,
                    //         planId: planPurchase?.id,
                    //         promotionCode: !!payload?.promoCode ? payload?.promoCode : "",
                    //         returnUrl: `${process.env.REACT_APP_FRONTEND_BASE_URL}payment-status`,
                    //         // returnUrl: 'http://localhost:3000/payment-status',
                    //     }

                    //     const createSubscriptionResponse = await CallAPI("WEBSITE", "POST", CMS_BASE_URL.DASHBOARD.USER_SUBSCRIPTION, createSubscriptionPayload);

                    //     const redirectUrl = createSubscriptionResponse.res.data?.data.url;
                    //     setPlanPurchase(null)
                    //     if (redirectUrl) {
                    //         window.location.href = redirectUrl;
                    //     }
                    //     await me()

                    // } else {
                    //     showToast(response.msg,
                    //         LABEL.TOAST_TYPE.SUCCESS
                    //     );
                    //     navigate('/login')
                    // }
                    showToast(response.msg, LABEL.TOAST_TYPE.SUCCESS);
                    navigate('/login')
                }
                else {
                    dispatch({ type: "SETDATA", payload: { "name": "varifivationStatus", "value": true } })
                    // localStorage.setItem("reset-pass-otp",value)
                }
            }
            else {
                dispatch({ type: "SETDATA", payload: { "name": "otpErr", "value": response.msg } })
            }
        }
    }

    const handleResetPassword = async () => {
        let flag = true
        if (!!state.password) {
            if (!validationUtility.passwordCheck(state.password)) {
                flag = false
                dispatch({ type: "SETDATA", payload: { "name": "passwordErr", "value": "Password must 8 characters log and contain at least one uppercase letter, one lowercase letter, one number, and one symbol" } })
            }
        }
        else {
            flag = false
            dispatch({ type: "SETDATA", payload: { "name": "passwordErr", "value": "Password is required" } })
        }

        if (!!state.confirmPassword) {
            if (!validationUtility.passwordConfirmCheck(state.password, state.confirmPassword)) {
                flag = false
                dispatch({ type: "SETDATA", payload: { "name": "confirmPasswordErr", "value": "Confirm password does not match with password" } })
            }
        }
        else {
            flag = false
            dispatch({ type: "SETDATA", payload: { "name": "confirmPasswordErr", "value": "Confirm password is required" } })
        }
        if (!!flag) {
            dispatch({ type: "SETDATA", payload: { "name": "loading", "value": true } })
            let body = { email: email, otp: state.otp, password: state.password, confirmPassword: state.confirmPassword }
            let response = await CallAPI("USER", "POST", FORGET_PASSWORD_URL, body)
            if (response.status) {
                showToast(response.msg, LABEL.TOAST_TYPE.SUCCESS);
                props.userType == "user" ? navigate('/') : navigate('/admin/login')
            }
            else {
                showToast(response.msg, LABEL.TOAST_TYPE.ERROR);
            }
            dispatch({ type: "SETDATA", payload: { "name": "loading", "value": false } })
        }
    }

    const ReSendOtp = async () => {
        let URL = !isEmailVerified ? EMAIL_VERIFICATION_OTP : GET_OTP_URL
        let body = { email: email }
        if (!isEmailVerified) {
            body.actionType = "post_signup"
        }
        let response = await CallAPI("USER", "POST", URL, body)
        if (response.status) {
            showToast("OTP resend successfully", LABEL.TOAST_TYPE.SUCCESS);
        }
        else {
            showToast(response.msg, LABEL.TOAST_TYPE.ERROR);
        }
    }

    return (
        <div className="form-grid_left">
            <div className="form-grid_gredient">
                <div className="form-grid_block">
                    <figure>
                        <img src={props.userType == "user" ? "images/coobiz-logo.svg" : "../images/coobiz-logo.svg"} alt />
                    </figure>
                    <div className="loginForm">
                        <header>
                            <h3>{props.type == "resetPass" ? "Reset Password" : "Please verify your mail to login"}</h3>
                        </header>
                        <div className="setting-block">
                            <div className="change-pass">

                                <div className="change-pass_form">
                                    {!!state.varifivationStatus ?
                                        props.type == "resetPass" &&
                                        <>
                                            <div className="field">
                                                <label for="txtNewPassword">New Password</label>
                                                <div className="changePass-grid">
                                                    <input
                                                        type={state.passEyeShow ? "text" : "password"}
                                                        className="form-control"
                                                        name="password"
                                                        value={state.password}
                                                        placeholder="Enter Your Password"
                                                        onChange={(e) => handleFormInput(e)}
                                                    />
                                                    <span className="toggle-password" data-target="txtNewPassword"
                                                        onClick={() => dispatch({ type: "SETDATA", payload: { "name": "passEyeShow", "value": !state.passEyeShow } })}>
                                                        <i className={state.passEyeShow ? "fa-regular fa-eye" : "fa-regular fa-eye-slash"}></i></span>
                                                </div>
                                                {state.passwordErr && <span>{state.passwordErr}</span>}
                                            </div>
                                            <div className="field">
                                                <label for="txtConfirmNewPassword">Confirm New Password</label>
                                                <div className="changePass-grid">
                                                    <input
                                                        type={state.confirmPassEyeShow ? "text" : "password"}
                                                        value={state.confirmPassword}
                                                        className="form-control"
                                                        name="confirmPassword"
                                                        placeholder="Enter Your Password"
                                                        onChange={(e) => handleFormInput(e)}
                                                    />
                                                    <span className="toggle-password" data-target="txtConfirmNewPassword"
                                                        onClick={() => dispatch({ type: "SETDATA", payload: { "name": "confirmPassEyeShow", "value": !state.confirmPassEyeShow } })}>
                                                        <i className={state.confirmPassEyeShow ? "fa-regular fa-eye" : "fa-regular fa-eye-slash"}></i></span>
                                                    {state.confirmPasswordErr && <span>{state.confirmPasswordErr}</span>}
                                                </div>
                                            </div>
                                            <div className="field text-center">
                                                <a href="javascript:void(0)" disabled={!!state.loading} className="cta-green" onClick={() => handleResetPassword()}>
                                                    {!!state.loading && <i class="fa-solid fa-spinner fa-spin"></i>}Reset Password</a>
                                            </div>
                                        </>
                                        : <OtpView
                                            EmailOrPhone={email}
                                            handleSetOtp={handleSetOtp}
                                            ReSendOtp={ReSendOtp}
                                            otp={state.otp}
                                            otpErr={state.otpErr} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CallAPI } from '../utils/apiActions';
import { TOTAL_SUBSCRIBERS } from '../utils/APIEndPoints';

const Home = () => {
	const navigate = useNavigate();
	const [count, setCount] = useState(0);

	useEffect(() => {
		getTotalSubscriberCount()
	}, []);

	const getTotalSubscriberCount = async () => {
		let response = await CallAPI('USER', 'GET', TOTAL_SUBSCRIBERS)
		setCount(response.res.data.data)
	}
	// const target = 5500;

	// useEffect(() => {
	// 	const interval = setInterval(() => {
	// 		setCount(prevCount => {
	// 			if (prevCount >= target) {
	// 				clearInterval(interval);
	// 				return target;
	// 			}
	// 			return prevCount + 10; // Increment by 1 for smoother transition
	// 		});
	// 	}, 1); // 10ms for smoother animation

	// 	return () => clearInterval(interval);
	// }, [target]);

	return (
		<section className="body-wrap">
			<div className="banner-home text-center">
				<div className="container">
					<div className="banner-home_text with-h1-line">
						<h1>Welcome to CoolBiz AI Chatbot</h1>
						<h2>
							Empower your business with secure, barrier-free chatbots
							HIPAA-friendly with GDPR and CCPA-compliant encryption
						</h2>
					</div>
				</div>
			</div>
			<div className="freetrial-grid upper">
				<div className="container med">
					<div className="freetrial-grid_gredient">
						<div className="freetrial-grid_header">
							<h5>14-day free trial for any plan</h5>
							<div className='cta-green-with-text'><i className="fa-solid fa-sparkles"></i> Free
								Trial: Try Any Plan for 14 Days!</div>
						</div>
						<div className="freetrial-grid_image">
							<img src="/images/header-img.png" alt="" />
						</div>
					</div>
				</div>
			</div>


			<div className="subscriber gbl">
				<div className="subscriber_block">
					<div className="subscriber-text"><img src="/images/icons/user-icon.svg" alt="User" /> Subscriber</div>
					<div className="counter-item">
						<div className="counter" data-count="12345678" data-speed="1000">{count}</div>
					</div>
				</div>
			</div>

			<div className="group-img-grid gbl">
				<div className="container larg">
					<div className="group-img-grid_block">
						<div className="ctaBlock with-h2-line">
							<header>
								<h2>Unlock the power of</h2>
								<h2><span>CoolBiz AI Chatbot</span></h2>
								<h5>CCPA/GDPR/HIPAA Compliance</h5>
							</header>
							<div className="short-info text-center">
								<p>CoolBiz AI Chatbot Brings The Future of customer interaction to your business. Our
									platform enables you to design,
									Customeize, And Manage Proprietary AI-Powered Chatbot Widgets That Are tailored to
									your industry’s needs. Whether you're
									looking to streamline customer service, enhance engagement, or provide 24/7 support,
									our easy-to-use platform ensures your
									chatbot is up and running in no time.</p>

								<a href="javascript:void(0)" className="cta-light" onClick={() => navigate('/login')}>Sign In</a>
								<a href="javascript:void(0)" className="cta-gradient" onClick={() => navigate('/signup')}>Sign up</a>
							</div>
						</div>
						<ul className="img-list">
							<li><img src="/images/mask-img/mask-group.png" alt="mask image" /></li>
							<li><img src="/images/mask-img/mask-group-1.png" alt="mask image" /></li>
							<li><img src="/images/mask-img/mask-group-2.png" alt="mask image" /></li>
							<li><img src="/images/mask-img/mask-group-3.png" alt="mask image" /></li>
							<li><img src="/images/mask-img/mask-group-4.png" alt="mask image" /></li>
							<li><img src="/images/mask-img/mask-group-9.png" alt="mask image" /></li>
							<li><img src="/images/mask-img/mask-group-8.png" alt="mask image" /></li>
							<li><img src="/images/mask-img/mask-group-7.png" alt="mask image" /></li>
							<li><img src="/images/mask-img/mask-group-6.png" alt="mask image" /></li>
							<li><img src="/images/mask-img/mask-group-5.png" alt="mask image" /></li>
						</ul>
					</div>
				</div>
			</div>


			<div className="feature-list gbl text-center">
				<div className="container">
					<header>
						<h2>Feature List</h2>
					</header>
					<div className="feature-list_grid">
						<div className="feature-list_block">
							<figure><img src="/images/icons/customizable-icon.svg" alt="" /></figure>
							<h5><a href="javascript:void(0)">Customizable<br /> Proprietary AI Models</a></h5>
						</div>

						<div className="feature-list_connector one"><img src="/images/connector/feature-list-connector.svg"
							alt="" /></div>

						<div className="feature-list_block">
							<figure><img src="/images/icons/downloadable-icon.svg" alt="" /></figure>
							<h5>Downloadable<br /> Chat History</h5>
						</div>

						<div className="feature-list_connector two"><img src="/images/connector/feature-list-connector.svg"
							alt="" /></div>

						<div className="feature-list_block">
							<figure><img src="/images/icons/multilingual-language-icon.svg" alt="" /></figure>
							<h5>Supports Multiple<br /> Languages</h5>
						</div>

						<div className="feature-list_connector three"><img src="/images/connector/feature-list-connector.svg"
							alt="" /></div>

						<div className="feature-list_block">
							<figure><img src="/images/icons/user-privacy-icon.svg" alt="" /></figure>
							<h5>User Privacy<br /> Controls</h5>
						</div>
					</div>
					<div className="text-center mt--30">
						<a href="javascript:void(0)" className="cta-green" onClick={() => navigate('/features')}>VIEW ALL FEATURES</a>
					</div>
				</div>
			</div>
			<section className="chatbot-features gbl">
				<div className="container sum-med">
					<div className="custom-ai-solutions gbl-100">
						<figure><img src="/images/customizable-ai-solutions.png" alt="Customizable AI Solutions" />
						</figure>
						<article>
							<h3>Customizable AI Solutions</h3>
							<p>Tailor chatbots to meet your business's unique requirements.</p>

							<div className="button-grid">
								<a href="javascript:void(0)" className="cta-green">Get Started</a>
							</div>
						</article>
					</div>

					<div className="seamless-integration gbl-100">
						<figure><img src="/images/seamless-integration.png" alt="Seamless Integration" /></figure>
						<article>
							<h3>Seamless Integration</h3>
							<p>Built on a leading cloud computing platform and enhanced with Go High Level for effective
								customer service managment (CRM).</p>

							<div className="button-grid">
								<a href="javascript:void(0)" className="cta-green">Get Started</a>
							</div>
						</article>
					</div>

					<div className="subscriber-dashboard gbl-100">
						<figure><img src="/images/user-frendly-subscriber-dashboard.png"
							alt="User-Frendly Subscriber Dashboard" /></figure>
						<article>
							<h3>User-Frendly Subscriber Dashboard</h3>
							<p>Manage chatbot settings, Billing, Analytics, And Interactions from one place.</p>

							<div className="button-grid">
								<a href="javascript:void(0)" className="cta-green">Get Started</a>
							</div>
						</article>
					</div>

					<div className="industry-specific gbl-100">
						<figure><img src="/images/industry-specific-chatbot-models.png"
							alt="Industry-Specific Customizable AI Chatbot Models" /></figure>
						<article>
							<h3>Industry-Specific Customizable AI Chatbot Models</h3>
							<p>Choose From Advance Proprietary AI Models Designed for Healthcare, Finance, Insurance,
								Real Estate, And More.</p>

							<div className="button-grid">
								<a href="javascript:void(0)" className="cta-green">Get Started</a>
							</div>
						</article>
					</div>
				</div>
			</section>

			{/* <div className="chatbot-robot gradient">
                <div className="container">
                    <div className="chatbot-robot_grid">
                        <div className="chatbot-robot_grid_info">
                            <h3>Customize Your Chatbot with Advanced AI Training and Prompting for Tailored
                                Conversations</h3>
                            <p>CoolBiz AI Chatbot brings the future of customer interaction to your business. Our
                                platform enables you to design,
                                customize, and Manage Proprietary AI-Powered Chatbots that are tailored to your
                                industry’s needs. Whether you're
                                looking to streamline customer service, enhance engagement, or provide 24/7 support, our
                                easy-to-use platform ensures
                                your chatbot is up and running in no time.</p>

                            <a href="javascript:void(0)" className="cta-green"><i className="fa-solid fa-sparkles"
                                    aria-hidden="true"></i> Create Chatbot Now</a>
                            <a href="javascript:void(0)" className="cta-dark"><i className="fa-solid fa-play"></i> How to
                                create</a>
                        </div>
                        <div className="chatbot-robot_grid_image">
                            <img src="/images/Bot-AvatarGreenXparent.png" alt="" />
                        </div>
                    </div>
                </div>
            </div> */}

		</section>
	)
}

export default Home;
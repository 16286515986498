import React from "react";
import Select from "react-select";

const CustomSelect = ({
  label,
  options,
  value,
  onChange,
  error,
  isMulti = false,
  defaultValue = null, // Add defaultValue prop
}) => {
  const handleChange = (selectedOptions) => {
    if (isMulti) {
      const selectedValues = selectedOptions
        ? selectedOptions.map((option) => option.value)
        : [];
      onChange(selectedValues);
    } else {
      onChange(selectedOptions?.value || "");
    }
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#010214" // Background color for selected option
        : state.isFocused
        ? "#1F2645" // Dropdown Background color on hover
        : "#010214", // Default Dropdown background color
      color: state.isSelected ? "#010214" : "#ffffff", // Text color
      cursor: "pointer",
    }),
    control: (provided) => ({
      ...provided,
      borderColor: error ? "red" : "#ccc",
      "&:hover": {
        borderColor: error ? "red" : "#888", // Border color on hover
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#ffffff", // Icon color
      "&:hover": {
        color: "#ffffff", // Icon hover color
      },
    }),
  };

  return (
    <div className="field">
      {label && <label>{label}</label>}
      <Select
        isMulti={isMulti}
        options={options}
        value={
          isMulti
            ? options.filter((option) => value?.includes(option.value))
                .length !== 0
              ? options.filter((option) => value?.includes(option.value))
              : defaultValue
            : options.find((option) => option.value === value) || defaultValue
        }
        onChange={handleChange}
        placeholder={
          isMulti ? "Select multiple options..." : "Select an option..."
        }
        noOptionsMessage={() => "No options available"}
        classNamePrefix="custom-select"
        defaultValue={defaultValue} // Use defaultValue
        closeMenuOnSelect={!isMulti}
        styles={customStyles} // Apply custom styles
      />
      {error && <span className="invalid-feedback">{error.message}</span>}
    </div>
  );
};

export default CustomSelect;

import React, { useEffect, useMemo } from "react";
import CommonTable from "../UI/Table";
import {
  LABEL,
  MANAGE_SUBSCRIBERS_TABLE_CONSTANT,
} from "../../utils/constant";
import { showToast } from "../../helper/toastHelper";
import useAuth from "../../hooks/useAuth";
import useDashboard from "../../hooks/useDashboard";

export default function SubscriptionManagement() {
  const { user } = useAuth();
  const { getUsersActivePlan, allSubscribers, isLoading } = useDashboard();
  const allSubscriberList = useMemo(
    () =>
      allSubscribers?.users||[].filter(
        (subscriber) => subscriber.activeSubscription
      ) || [],
    [getUsersActivePlan, allSubscribers]
  );

  useEffect(() => {
    if (!user.id) {
      return showToast(LABEL.USER.USER_ID_NOT_FOUND, LABEL.TOAST_TYPE.ERROR);
    }
    fetchAllPlans();
  }, []);

  function fetchAllPlans(page = 1) {
    getUsersActivePlan({}, page);
  }
  const handlePagination = (page) => {
    fetchAllPlans(page);
  };
  const pagination = useMemo(() => {
    return {
      current: allSubscribers.currentPage,
      pageSize: allSubscribers.pageSize,
      total: allSubscribers.totalRecords,
      onChange: handlePagination,
    };
  }, [
    allSubscribers.currentPage,
    allSubscribers.pageSize,
    allSubscribers.totalRecords,
  ]);

  return (
    <React.Fragment>
      <div className="all-chatboat">
        <header className="d-md-flex align-items-center justify-content-between">
          <h3>{LABEL.SUBSCRIPTION_MANAGEMENT.HEADLINE}</h3>
        </header>
        <CommonTable
          data={allSubscriberList}
          columns={MANAGE_SUBSCRIBERS_TABLE_CONSTANT.usersManagementcolumns}
          actions={[]}
          pagination={pagination}
          isLoading={isLoading}
          isPagination={true}
        />
      </div>
    </React.Fragment>
  );
}

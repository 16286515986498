import React, { useState } from 'react';
import CoolbizAuthHeader from './CoolbizAuthHeader';
import CoolbizAuthNavbar from './CoolbizAuthNavbar';

const AuthLayout = ({ children }) => {
  const [navToggle, setNavToggle] = useState(false)

  return (
    <React.Fragment>
      <div id="window">
        <section className="body-wrap">
          <div className="container larg">
            <section className="dashboard-wrap">
              <CoolbizAuthNavbar navToggle={navToggle} setNavToggle={setNavToggle} />
              <div className="dashboard-right" onClick={()=> navToggle && setNavToggle(false)}>
                <CoolbizAuthHeader setNavToggle={setNavToggle}/>
                {children}
              </div>
            </section>
          </div>
        </section>
      </div>
    </React.Fragment>
  )
}

export default AuthLayout
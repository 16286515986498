import React from "react";
import { roundPercentageString } from "../../utils/constant";
const UsersSummaryCard = ({ data = {} }) => {
  return (
    <div className="chart-grid chat-summary-block column-4 mb-4">
      <div className="chart-grid_card analytics-total-chatbot">
          <figure>
            <img
              src="/images/icons/analytics-total-chatbot-icon.svg"
              alt=""
            />
          </figure>
          <h6>{data?.totalChatBots||0}</h6>
          <p className="text-10">Total Chatbot</p>
          <span className="text-8">{roundPercentageString(data?.chatbotPercentageChange)||"0% from last year"}</span>
        </div>
        <div className="chart-grid_card analytics-active">
          <figure>
            <img src="/images/icons/analytics-active-icon.svg" alt="" />
          </figure>
          <h6>{data?.totalActiveChatBots||0}</h6>
          <p className="text-10">Total Active Chatbot</p>
          <span className="text-8">{roundPercentageString(data?.activeChatbotPercentageChange)||"0% from last year"} </span>
        </div>
      <div className="chart-grid_card analytics-incoming">
        <figure>
          <img src="/images/icons/analytics-incoming-icon.svg" alt="" />
        </figure>
        <h6>{data?.total_incoming_chats}</h6>
          <p className="text-10">Total Incoming Chats</p>
          <span className="text-8">{roundPercentageString(data?.total_incoming_chats_change)} from last year</span>
      </div>
      <div className="chart-grid_card analytics-reply">
        <figure>
          <img src="/images/icons/analytics-reply-icon.svg" alt="" />
        </figure>
        <h6>{data?.active_chats}</h6>
          <p className="text-10">Active Chats</p>
          <span className="text-8">{roundPercentageString(data?.active_chats_change)} from last year</span>
      </div>
     
    </div>
  );
};
export default UsersSummaryCard;

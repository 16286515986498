import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CallAPI } from "../../utils/apiActions";
import { BOT_SETTINGS, BOT_SETTINGS_PROPS, BOT_SETTINGS_PROPS_CHAT, GENERATE_EMBED } from "../../utils/APIEndPoints";
import validationUtility from "../../utils/Validation";
import { LABEL } from "../../utils/constant";
import { showToast } from "../../helper/toastHelper";
import AsyncSelect from 'react-select';
import ResizableDiv from "../../utils/ResizableDiv.jsx";
import { Tooltip } from "@mui/material";

export default function BotChatSettings({ ...props }) {
  const navigate = useNavigate();
  const location = useLocation();
  const bot_id = (new URLSearchParams(location?.search).get("id")) || null
  const [submissionLoader, setSubmissionLoader] = useState(false)
  const [shortCodeLoader, setShortCodeLoader] = useState(false)
  const [botSettingsProps, setBotSettingProps] = useState({})
  const [isCopied, setIsCopied] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [showPreviewDesc, setShowPreviewDesc] = useState(true);
  const [openBotTestModal, setOpenBotTestModal] = useState(false);
  const [descBoxPos, setDescBoxPos] = useState({ x: 1425, y: 650 });
  const [resizableDivPos, setResizableDivPos] = useState({ x: 1085, y: 395 });
  const [descBoxHeight, setDescBoxHeight] = useState(60);
  const [editContentData, setEditContentData] = useState({
    chat_bbl_desc: "",
    isDescAllow: false,
    tone: "Simple",
    language: "English",
    fontHeader: 1,
    fontBody: 1,
    responseLength: "Medium",
    widgetCheck: 0,
    files: "",
    filesWidget: "",
    filesAvatar: "",
    domain_link: "",
    short_code: "",
    width: 360,
    height: 425,
    desc_box_x: 1425,
    desc_box_y: 650,
    chat_box_x: 1085,
    chat_box_y: 395,
    viewport_x: 1425,
    viewport_y: 650,
  })
  const [stateForErr, setStateForErr] = useState({
    chat_bbl_descErr: "",
    toneErr: "",
    languageErr: "",
    fontHeaderErr: "",
    fontBodyErr: "",
    responseLengthErr: "",
    widgetCheckErr: "",
    filesWidgetErr: "",
    filesAvatarErr: "",
    domain_linkErr: "",
    short_codeErr: "",
    filesTypeErr: null,
    completedStep: ""
  })
  const [dataLoader, setDataLoader] = useState(true)
  const [isCustomPos, setIsCustomPos] = useState(false)
  const [position, setPosition] = useState({ x: 1425, y: 650 });
  const dragRef = useRef(null);
  const initialMousePos = useRef({ x: 0, y: 0 });
  const initialDivPos = useRef({ x: 0, y: 0 });
  const positions = [
    { x: 20, y: 20 },
    { x: 1425, y: 20 },
    { x: 20, y: 650 },
    { x: 1425, y: 650 }
  ];

  useEffect(() => {
    getBotSettingsProps()
    getBotSettings()
  }, []);


  useEffect(() => {
    let loDescBoxPos = rePositionDescBox()
    setEditContentData({ ...editContentData, desc_box_x: loDescBoxPos.x, desc_box_y: loDescBoxPos.y })
  }, [editContentData.chat_bbl_desc]);

  useEffect(() => {
    let loResizableDivPos = reSizeChatElement()
    setEditContentData({ ...editContentData, chat_box_x: loResizableDivPos.x, chat_box_y: loResizableDivPos.y })
  }, [openBotTestModal, editContentData.height, editContentData.width]);

  useEffect(() => {
    effectAfterPositionChange()
    checkPositionExists(position)
  }, [position]);

  const reSizeChatElement = () => {
    let loResizableDivPos = { ...resizableDivPos }
    if (position.y + editContentData.height > window.innerHeight) {
      loResizableDivPos.y = ((position.y + descBoxHeight) - editContentData.height)
    }
    else {
      loResizableDivPos.y = position.y
    }
    if (position.x + editContentData.width + 60 > window.innerWidth) {
      loResizableDivPos.x = (position.x + 60) - editContentData.width
    }
    else {
      loResizableDivPos.x = position.x
    }
    setResizableDivPos({ ...loResizableDivPos });
    return loResizableDivPos
  }

  const rePositionDescBox = () => {
    if (!!editContentData.chat_bbl_desc) {
      setShowPreviewDesc(true)
    }
    let descElement = document.getElementById('chat_desc_box')
    if (descElement) {
      let loDescBoxPos = { ...descBoxPos }
      if (position.y + descElement.offsetHeight + 60 > window.innerHeight) {
        loDescBoxPos.y = position.y - descElement.offsetHeight
        setDescBoxHeight(60)
      }
      else {
        loDescBoxPos.y = position.y + 60
        setDescBoxHeight(descElement.offsetHeight + 60)
      }
      if (position.x + descElement.offsetWidth + 60 > window.innerWidth) {
        loDescBoxPos.x = (position.x + 60) - descElement.offsetWidth
      }
      else {
        loDescBoxPos.x = position.x
      }
      setDescBoxPos({ ...loDescBoxPos });
      return loDescBoxPos
    }
    return { ...descBoxPos }
  }

  const effectAfterPositionChange = () => {
    let loDescBoxPos = rePositionDescBox()
    let loResizableDivPos = reSizeChatElement()
    setEditContentData({ ...editContentData, desc_box_x: loDescBoxPos.x, desc_box_y: loDescBoxPos.y, chat_box_x: loResizableDivPos.x, chat_box_y: loResizableDivPos.y })
    return loDescBoxPos
  }

  const checkPositionExists = (pos) => {
    let defaultPos = positions.findIndex(position => position.x === pos.x && position.y === pos.y);
    console.log("defaultPos", defaultPos, positions, pos)
    if (defaultPos >= 0) {
      setIsCustomPos(defaultPos)
    }
    else {
      setIsCustomPos(null)
    }
  }

  const getListFormat = (listFromApi, labelName) => {
    let list = []
    let obj = {}
    if (labelName == "pname") {
      listFromApi && listFromApi.length > 0 && listFromApi.map((data) => {
        obj = {
          id: data.pid,
          label: data[labelName],
          value: data.pid
        }
        list.push(obj)
      })
    }
    else {
      Object.entries(listFromApi).forEach(([key, value]) => {
        if (labelName == "language") {
          obj = {
            id: key,
            label: key,
            value: key
          }
        }
        else {
          obj = {
            id: value,
            label: value,
            value: value
          }
        }
        list.push(obj)
      });
    }
    return list
  }

  useEffect(() => {
    if (editContentData.widgetCheck == 1) {
      setPreviewImage(editContentData.filesAvatar)
    }
    else {
      setPreviewImage(editContentData.filesWidget)
    }
  }, [editContentData.widgetCheck, editContentData.filesWidget, editContentData.filesAvatar]);

  const getBotSettings = async () => {
    let loEditContentData = { ...editContentData }
    let response = await CallAPI("BOT", "GET", `${BOT_SETTINGS}/${bot_id}`)
    console.log("response.res.data.data set--", response?.res.data?.data)
    if (response.status) {
      let contentData = response?.res.data?.data
      setDataLoader(false)
      if (contentData?.widgetCheck == 0) {
        contentData.filesWidget = response?.res.data?.data.files
      }
      else {
        contentData.filesAvatar = response?.res.data?.data.files
      }
      props.setFinalStep(response?.res.data?.data.completedStep || 3)

      for (let key in contentData) {
        if (contentData[key] !== null && key != "original_x" && key != "original_y") {
          loEditContentData[key] = contentData[key];
        }
      }
      setEditContentData({ ...editContentData, ...loEditContentData })
      if (!!contentData.viewport_x && !!contentData.viewport_y) {
        setPosition({ x: contentData.viewport_x, y: contentData.viewport_y })
      }
      if (!!contentData.desc_box_x && !!contentData.desc_box_y) {
        setDescBoxPos({ x: contentData.desc_box_x, y: contentData.desc_box_y })
      }
      if (!!contentData.chat_box_x && !!contentData.chat_box_y) {
        setResizableDivPos({ x: contentData.chat_box_x, y: contentData.chat_box_y })
      }
    }
    else {
      showToast(response.msg, LABEL.TOAST_TYPE.ERROR);
    }
  }

  const getBotSettingsProps = async () => {
    let response = await CallAPI("BOT", "GET", `${BOT_SETTINGS_PROPS}/${bot_id}`)
    let obj = {
      fontBody: getListFormat(response?.res.data?.data?.fontBody, "pname"),
      fontHeader: getListFormat(response?.res.data?.data?.fontHeader, "pname"),
    }
    let res = await CallAPI("CHAT", "GET", `${BOT_SETTINGS_PROPS_CHAT}`)
    obj.language = getListFormat(res?.res.data?.all_language, "language")
    obj.responseLength = getListFormat(res?.res.data?.all_response_lenght, "response")
    obj.tone = getListFormat(res?.res.data?.all_tone, "tone")
    console.log("BotSettingProps--", obj)
    setBotSettingProps(obj)
  }

  const handleFormInput = (e, type = '', name = '') => {
    console.log("event----", e)
    let loEditedContent = { ...editContentData }
    let loStateForErr = { ...stateForErr }
    if (type == 'file') {
      let file = e.target.files[0]
      if (file?.type == 'image/png') {
        if (!editContentData.widgetCheck) {
          loEditedContent.filesWidget = file
          loStateForErr.filesWidgetErr = ''
        }
        else {
          loEditedContent.filesAvatar = file
          loStateForErr.filesAvatarErr = ''
        }
      }
      else {
        if (!editContentData.widgetCheck) {
          loStateForErr.filesWidgetErr = 'Only .png is supported'
        }
        else {
          loStateForErr.filesAvatarErr = 'Only .png is supported'
        }
      }
    }
    else if (type == 'check') {
      loEditedContent.widgetCheck = e.target.checked ? 1 : 0
      loStateForErr.widgetCheckErr = ''
      loStateForErr.filesWidgetErr = ''
      loStateForErr.filesAvatarErr = ''
    }
    else if (type == 'descCheck') {
      loEditedContent.isDescAllow = e.target.checked ? 1 : 0
    }
    else if (type == "select") {
      loEditedContent[name] = e
      loStateForErr[name + 'Err'] = ''
    }
    else {
      loEditedContent[e.target.name] = e.target.value
      loStateForErr[e.target.name + 'Err'] = ''
    }
    setEditContentData({ ...editContentData, ...loEditedContent })
    setStateForErr({ ...stateForErr, ...loStateForErr })
  }

  const handleSaveStep4 = async () => {
    setSubmissionLoader(true)
    let mandetoryField = [{ key: "files", value: "Files" }, { key: "domain_link", value: "Domain Link" }, { key: "short_code", value: "Short Code" }]
    let loStateForErr = { ...stateForErr }
    let flag = true
    mandetoryField.map((field, ind) => {
      if (field.key == "files") {
        if (editContentData.widgetCheck == 0 && (editContentData.filesWidget == null || editContentData.filesWidget == undefined || editContentData.filesWidget == "")) {
          flag = false
          loStateForErr.filesWidgetErr = "Please upload a widget"
          loStateForErr.filesTypeErr = "widget"
        }
        else if (editContentData.widgetCheck == 1 && (editContentData.filesAvatar == null || editContentData.filesAvatar == undefined || editContentData.filesAvatar == "")) {
          flag = false
          loStateForErr.filesAvatarErr = "Please upload a avatar"
          loStateForErr.filesTypeErr = "avatar"
        }
      }
      if (field.key == "domain_link" && !!editContentData.domain_link && !validationUtility.validUrl(editContentData.domain_link)) {
        flag = false
        loStateForErr.domain_linkErr = "Not a valid domain link"
      }
      if ((field.key != "files" && (editContentData[field.key] == null || editContentData[field.key] == "" || editContentData[field.key] == undefined))) {
        flag = false
        loStateForErr[field.key + 'Err'] = field.key == "short_code" ? "Please generate your short code to complete bot configuration." : `${field.value} is required`
      }
    })
    if(!!editContentData.isDescAllow && (editContentData.chat_bbl_desc==null || editContentData.chat_bbl_desc.trim()=='' || editContentData.chat_bbl_desc==undefined))
    {
      flag = false
      loStateForErr.chat_bbl_descErr = "Add chat bubble description or uncheck the option"
    }
    console.log("loStateForErr---", loStateForErr)
    setStateForErr(loStateForErr)
    if (!!flag) {
      let method = editContentData.completedStep == 4 ? "PUT" : "POST"
      const formData = new FormData();
      let files = editContentData.widgetCheck == 0 ? editContentData.filesWidget : editContentData.filesAvatar
      formData.append("files", files);
      Object.entries(editContentData).forEach(([key, value]) => {
        if ((key != "filesWidget" && key != "filesAvatar" && key != "files")) {
          formData.append(key, value);
        }
      });
      formData.append("step", 4)
      formData.append("original_x", window.innerWidth)
      formData.append("original_y", window.innerHeight)
      if (method == "POST") {
        formData.append("bot_id", bot_id)
      }
      let response = await CallAPI("BOT", method, BOT_SETTINGS, formData, "file")
      if (response.status) {
        showToast(response.msg, LABEL.TOAST_TYPE.SUCCESS);
        navigate('/my-chatbots')
      }
      else {
        showToast(response.msg, LABEL.TOAST_TYPE.ERROR);
      }
    }
    setSubmissionLoader(false)
  }

  const handleGenerateShortCode = async () => {
    setShortCodeLoader(true)
    const response = await CallAPI("BOT", "POST", GENERATE_EMBED, { bot_id: bot_id });
    console.log("response---", response)
    setEditContentData({ ...editContentData, short_code: response.res.data.short_code })
    setShortCodeLoader(false)
    setStateForErr({ ...stateForErr, short_codeErr: "" })
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(`${process.env.REACT_APP_SHORT_CODE_BUILD_PATH}?id=${editContentData.short_code}`).then(() => {
      setIsCopied(true); // Show the "link copied" message
      setTimeout(() => {
        setIsCopied(false); // Hide the message after 3 seconds
      }, 2000);
      // alert('Text copied to clipboard!');
    }).catch((err) => {
      console.error('Error copying text: ', err);
    });
  };

  const handleMouseDown = (e) => {
    initialMousePos.current = { x: e.clientX, y: e.clientY };
    initialDivPos.current = { x: position.x, y: position.y };
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e) => {
    const dx = e.clientX - initialMousePos.current.x;
    const dy = e.clientY - initialMousePos.current.y;

    console.log("to get position---", e.clientX, e.clientY, initialDivPos.current.x + dx, initialDivPos.current.y + dy)
    setEditContentData({ ...editContentData, viewport_x: initialDivPos.current.x + dx, viewport_y: initialDivPos.current.y + dy })
    setPosition({
      x: initialDivPos.current.x + dx,
      y: initialDivPos.current.y + dy,
    });
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  const handleopenBotTestModal = () => {
    if (!openBotTestModal)
      setOpenBotTestModal(true)
  }

  const handleSetPosition = (value) => {
    switch (value) {
      case 'top-left':
        setPosition({
          x: 20 * window.innerWidth / 1536,
          y: 20 * window.innerHeight / 730
        })
        break;
      case 'top-right':
        setPosition({
          x: 1425 * window.innerWidth / 1536,
          y: 20 * window.innerHeight / 730
        })
        break;
      case 'bottom-left':
        setPosition({
          x: 20 * window.innerWidth / 1536,
          y: 650 * window.innerHeight / 730
        })
        break;
      case 'bottom-right':
        setPosition({
          x: 1425 * window.innerWidth / 1536,
          y: 650 * window.innerHeight / 730
        })
        break;
    }
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#2684ff' : state.isFocused ? '#2684ff' : '#1F2645',
      color: state.isSelected ? '#ffffff' : '#ffffff',
      padding: 10,
           
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: '#1F2645',
      borderRadius: 10,
    }),  
  };

  return (
    <div className="mychatbot-grid">
      <div className="tab-content-grid">
        <div className="tab-content active">
          <div className="chatbot-history-grid">
            <header className="d-md-flex align-items-center justify-content-between">
              <h3>Bot Chat Settings</h3>
              <div className="group-form d-md-flex">
                <button disabled={submissionLoader} className="cta-green" onClick={() => handleSaveStep4()}>
                  {submissionLoader && <i class="fa-solid fa-spinner fa-spin"></i>}
                  Save and continue
                </button>
              </div>
            </header>
            {dataLoader
              ? <div className="page-loader">
                <img src="/images/loader-bouncing-circles.svg" alt="" />
              </div>
              : <div className="chatSettings-grid">
                <form>
                  <div class="row">
                    <div class="col-md-10 col-lg-8">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="field">
                            <div class="description-with-toggle">
                              <label for="textareaChatbotDescription">Chatbot Description *</label>
                              <label class="toggle-switch">
                                <input type="checkbox" name="isDescAllow" checked={editContentData.isDescAllow == 1 || false} onChange={(e) => handleFormInput(e, "descCheck")} />
                                <span class="toggleSlider"></span>
                              </label>
                            </div>
                            
                            <textarea
                              class="form-control"
                              name="chat_bbl_desc"
                              id="textareaChatbotDescription"
                              rows="5" placeholder='Type...'
                              value={editContentData.chat_bbl_desc}
                              onChange={(e) => handleFormInput(e)} />
                            {stateForErr.chat_bbl_descErr && <span>{stateForErr.chat_bbl_descErr}</span>}
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="field">
                            <label for="selectTone">Tone</label>
                            <AsyncSelect
                              classNames={{
                                menu: () => "react-select-menu",
                                menuList: () => "react-select-menu-lists",
                                option: () => "react-select-menu-option",
                              }}
                              placeholder="Select Tone"
                              isClearable={false}
                              isSearchable={true}
                              defaultValue={botSettingsProps?.tone?.find(option => option.value == editContentData.tone)}
                              value={botSettingsProps?.tone?.find(option => option.value == editContentData.tone)}
                              options={botSettingsProps?.tone}
                              onChange={(obj) => handleFormInput(obj?.id ?? "", "select", "tone")}
                              styles={customStyles} // Apply custom styles
                            />
                            {/* <select class="form-select" value={editContentData.tone} name="tone" onChange={(e) => handleFormInput(e)}>
                              {botSettingsProps?.tone?.length > 0 && botSettingsProps?.tone.map((tone, ind) => (
                                <option value={tone.pid}>{tone.pname}</option>
                              ))}
                            </select> */}
                            {stateForErr.toneErr && <span>{stateForErr.toneErr}</span>}
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="field">
                            <label for="selectLanguage">Language</label>
                            <AsyncSelect
                              classNames={{
                                menu: () => "react-select-menu",
                                menuList: () => "react-select-menu-lists",
                                option: () => "react-select-menu-option",
                              }}
                              placeholder="Select Language"
                              isClearable={false}
                              isSearchable={true}
                              defaultValue={botSettingsProps?.language?.find(option => option.value == editContentData.language)}
                              value={botSettingsProps?.language?.find(option => option.value == editContentData.language)}
                              options={botSettingsProps?.language}
                              onChange={(obj) => handleFormInput(obj?.id ?? "", "select", "language")}
                              styles={customStyles} // Apply custom styles
                            />
                            {/* <select class="form-select" name="language" onChange={(e) => handleFormInput(e)}>
                              {botSettingsProps?.language?.length > 0 && botSettingsProps?.language.map((language, ind) => (
                                <option value={language.pid}>{language.pname}</option>
                              ))}
                            </select> */}
                            {stateForErr.languageErr && <span>{stateForErr.languageErr}</span>}
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="field">
                            <label for="selectFontHeader">Font Size</label>
                            <AsyncSelect
                              classNames={{
                                menu: () => "react-select-menu",
                                menuList: () => "react-select-menu-lists",
                                option: () => "react-select-menu-option",
                              }}
                              placeholder="Select Font Header"
                              isClearable={false}
                              isSearchable={true}
                              defaultValue={botSettingsProps?.fontHeader?.find(option => option.value == editContentData.fontHeader)}
                              value={botSettingsProps?.fontHeader?.find(option => option.value == editContentData.fontHeader)}
                              options={botSettingsProps?.fontHeader}
                              onChange={(obj) => handleFormInput(obj?.id ?? "", "select", "fontHeader")}
                              styles={customStyles} // Apply custom styles
                            />
                            {/* <select class="form-select" name="fontHeader" onChange={(e) => handleFormInput(e)}>
                              {botSettingsProps?.fontHeader?.length > 0 && botSettingsProps?.fontHeader.map((fontHeader, ind) => (
                                <option value={fontHeader.pid}>{fontHeader.pname}</option>
                              ))}
                            </select> */}
                            {stateForErr.fontHeaderErr && <span>{stateForErr.fontHeaderErr}</span>}
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="field">
                            <label for="selectFontBody">Font Family</label>
                            <AsyncSelect
                              classNames={{
                                menu: () => "react-select-menu",
                                menuList: () => "react-select-menu-lists",
                                option: () => "react-select-menu-option",
                              }}
                              placeholder="Select Font Body"
                              isClearable={false}
                              isSearchable={true}
                              defaultValue={botSettingsProps?.fontBody?.find(option => option.value == editContentData.fontBody)}
                              value={botSettingsProps?.fontBody?.find(option => option.value == editContentData.fontBody)}
                              options={botSettingsProps?.fontBody}
                              onChange={(obj) => handleFormInput(obj?.id ?? "", "select", "fontBody")}
                              styles={customStyles} // Apply custom styles
                            />
                            {/* <select class="form-select" name="fontBody" onChange={(e) => handleFormInput(e)}>
                              {botSettingsProps?.fontBody?.length > 0 && botSettingsProps?.fontBody.map((fontBody, ind) => (
                                <option value={fontBody.pid}>{fontBody.pname}</option>
                              ))}
                            </select> */}
                            {stateForErr.fontBodyErr && <span>{stateForErr.fontBodyErr}</span>}
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="field">
                            <label for="selectFontBody">Response Length</label>
                            <AsyncSelect
                              classNames={{
                                menu: () => "react-select-menu",
                                menuList: () => "react-select-menu-lists",
                                option: () => "react-select-menu-option",
                              }}
                              placeholder="Select Response Length"
                              isClearable={false}
                              isSearchable={true}
                              defaultValue={botSettingsProps?.responseLength?.find(option => option.value == editContentData.responseLength)}
                              value={botSettingsProps?.responseLength?.find(option => option.value == editContentData.responseLength)}
                              options={botSettingsProps?.responseLength}
                              onChange={(obj) => handleFormInput(obj?.id ?? "", "select", "responseLength")}
                              styles={customStyles} // Apply custom styles
                            />
                            {/* <select class="form-select" name="responseLength" onChange={(e) => handleFormInput(e)}>
                              {botSettingsProps?.responseLength?.length > 0 && botSettingsProps?.responseLength.map((responseLength, ind) => (
                                <option value={responseLength.pid}>{responseLength.pname}</option>
                              ))}
                            </select> */}
                            {stateForErr.responseLengthErr && <span>{stateForErr.responseLengthErr}</span>}
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="field">
                            <label for="selectFontBody">Adjust Bot positon</label>
                            <select className="form-select adjustBotPositon"
                              onChange={(e) => handleSetPosition(e.target.value)}>
                              <option selected={isCustomPos == null} value="0">Custom</option>
                              <option selected={isCustomPos == 0} value="top-left">Top Left</option>
                              <option selected={isCustomPos == 1} value="top-right">Top Right</option>
                              <option selected={isCustomPos == 2} value="bottom-left">Bottom Left</option>
                              <option selected={isCustomPos == 3} value="bottom-right">Bottom Right</option>
                            </select>
                          </div>
                        </div>

                        <div class="col-md-6 none">
                          <div class="field">
                            <label for="textareaChatbotDescription">Adjust chatbot Height and width</label>
                            <label onClick={handleopenBotTestModal}>Height</label>
                            <input
                              readOnly
                              class="form-control"
                              value={`${editContentData.height} px`}
                              onClick={() => handleopenBotTestModal()} />
                            <label onClick={handleopenBotTestModal}>Width</label>
                            <input
                              readOnly
                              class="form-control"
                              value={`${editContentData.width} px`}
                              onClick={() => handleopenBotTestModal()} />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="field">
                            <label for="">Adjust chatbot Height and width</label>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="adjust-chatbot-height readable">
                                  <label onClick={handleopenBotTestModal}>Height</label>
                                  <input
                                    readOnly
                                    class="form-control"
                                    value={`${editContentData.height} px`}
                                    onClick={() => handleopenBotTestModal()} />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="adjust-chatbot-width readable">
                                  <label onClick={handleopenBotTestModal}>Width</label>
                                  <input
                                    readOnly
                                    class="form-control"
                                    value={`${editContentData.width} px`}
                                    onClick={() => handleopenBotTestModal()} />
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>

                        <div class="col-12 mb-4">
                          <div class="d-flex align-items-center gap-3 text-14">
                            <span>Upload widget</span>
                            <label class="toggle-switch">
                              <input type="checkbox" name="widgetCheck" checked={editContentData.widgetCheck == 1 || false} onChange={(e) => handleFormInput(e, "check")} />
                              <span class="toggleSlider"></span>
                            </label>
                            <spam class="text-grey">Upload Chatbot Avatar</spam>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="field">
                            <div class={!editContentData.widgetCheck ? "file-upload-container" : "file-upload-container disabled"}>
                              {!editContentData.filesWidget && <div class="image-preview" id="image-preview">
                                <span><img src="/images/icons/upload-img-icon.svg" alt="upload icon" /></span>
                              </div>}
                              <div className="uploadWidget">
                                <label class="file-upload-label" for="file-upload">{editContentData.widgetCheck ? "Chat Bubble Icon" : "Chat Bubble Icon *"}</label>
                                <input
                                  type="file"
                                  id="file-upload"
                                  class="file-upload-input"
                                  accept="image/*"
                                  onClick={e => (e.target.value = null)}
                                  onChange={(e) => handleFormInput(e, "file")} />
                                {!!editContentData.filesWidget && <img
                                  // alt={editContentData.template_name}
                                  src={editContentData.filesWidget ? typeof (editContentData.filesWidget) == "object" ? URL.createObjectURL(editContentData.filesWidget) : editContentData.filesWidget : ''}
                                  sx={{ width: 56, height: 56 }}
                                />}
                              </div>
                            </div>
                            {stateForErr.filesWidgetErr && <span>{stateForErr.filesWidgetErr}</span>}
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="field">
                            <div class={editContentData.widgetCheck ? "file-upload-container" : "file-upload-container disabled"}>
                              {!editContentData.filesAvatar && <div class="image-preview" id="image-preview">
                                <span><img src="/images/icons/upload-img-icon.svg" alt="upload icon" /></span>
                              </div>}
                              <div className="uploadWidget">
                                <label class="file-upload-label" for="file-upload">{editContentData.widgetCheck ? "Upload Chatbot Avatar *" : "Upload Chatbot Avatar"}</label>
                                <input type="file"
                                  id="file-upload"
                                  class="file-upload-input"
                                  accept="image/*"
                                  onChange={(e) => handleFormInput(e, "file")}
                                  onClick={e => (e.target.value = null)} />
                                {!!editContentData.filesAvatar && <img
                                  // alt={editContentData.template_name}
                                  src={editContentData.filesAvatar ? typeof (editContentData.filesAvatar) == "object" ? URL.createObjectURL(editContentData.filesAvatar) : editContentData.filesAvatar : ''}
                                  sx={{ width: 56, height: 56 }} />}
                              </div>
                            </div>
                            {stateForErr.filesAvatarErr && <span>{stateForErr.filesAvatarErr}</span>}
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="field">
                            <label for="txtDomainLink">Domain Link *</label>
                            <input type="text" value={editContentData.domain_link} class="form-control" id="txtDomainLink" name="domain_link" placeholder="https/domailname/page_name..." onChange={(e) => handleFormInput(e)} />
                            {stateForErr.domain_linkErr && <span>{stateForErr.domain_linkErr}</span>}
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="field">
                            <div class="group-form d-flex align-items-center gap-3">
                              {!!editContentData.short_code
                                ? <label for="txtDomainLink">Short Code</label>
                                : <>
                                  <a href="javascript:void(0)" class="cta-green" onClick={() => handleGenerateShortCode()}>
                                    {shortCodeLoader && <i class="fa-solid fa-spinner fa-spin"></i>}
                                    GENERATE SHORT CODE *
                                  </a>
                                </>}

                              <div class="with-icon">
                                <input type="text" disabled value={!!editContentData.short_code ? `${process.env.REACT_APP_SHORT_CODE_BUILD_PATH}?id=${editContentData.short_code}` : ""} class="form-control" id="txtDomainLink" name="short_code" placeholder="coolbiz/aichatbot/chatbot1/798956..." />
                                {!!editContentData.short_code && <a href="javascript:void(0)" onClick={copyToClipboard} ><i class="fa-light fa-clone"></i></a>}
                                {isCopied && (
                                  <span style={{ marginLeft: '10px', color: 'green' }}>
                                    Link copied successfully!
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="error">{stateForErr.short_codeErr && <span>{stateForErr.short_codeErr}</span>}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

              </div>}

          </div>
        </div>
      </div>

      {!dataLoader &&
        (
          openBotTestModal
            ? <ResizableDiv
              setOpenBotTestModal={setOpenBotTestModal}
              resizableDivPos={resizableDivPos}
              editContentData={editContentData}
              setEditContentData={setEditContentData} />
            : <Tooltip title="Drag to reposition">
              <div className="bottom-chatbot_message2"
                id="prev_desc_box"
                style={{
                  position: 'fixed',
                  top: `${position.y * window.innerHeight / 730}px`,
                  left: `${position.x * window.innerWidth / 1536}px`,
                  width: '50px',
                  height: '50px',
                  backgroundColor: 'white',
                  cursor: 'pointer',
                  userSelect: 'none',
                }}
                ref={dragRef}
                onMouseDown={handleMouseDown}>
                {editContentData.chat_bbl_desc && showPreviewDesc && !!editContentData.isDescAllow &&
                  <div
                    className="messageBox"
                    id="chat_desc_box"
                    style={{
                      position: 'fixed',
                      top: `${descBoxPos.y * window.innerHeight / 730}px`,
                      left: `${descBoxPos.x * window.innerWidth / 1536}px`,
                      width: `${240 * window.innerWidth / 1536}px`,
                      height: 'fit-content',
                      minHeight: '55px'
                    }}>
                    <a href="javascript:void(0)" className="closeBox" onClick={() => setShowPreviewDesc(false)}>X</a>
                    <p>{editContentData.chat_bbl_desc || "your bot description here"}</p>
                  </div>}
                <figure>
                  <img
                    src={previewImage ? typeof (previewImage) == "object" ? URL.createObjectURL(previewImage) : previewImage : "/images/images-100.png"}
                    alt="images-100" />
                </figure>
              </div>
            </Tooltip>)}
    </div>
  )
}

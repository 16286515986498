import React from 'react';

function Error() {

    return (
        <section className="body-wrap">
            <div className="banner-home text-center">
                <div className="container">
                    <h1>Upps! 404 ERROR</h1>
                </div>
            </div>
        </section>
    )
}

export default Error

import React, { useEffect, useState } from 'react';
import { LineChart, Line, Area, AreaChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { BOT_LIST_WITH_STATUS } from '../../utils/APIEndPoints';
import { CallAPI } from '../../utils/apiActions';
const sampleData=[
    { month: "Jan", uv: 0 },
    { month: "Feb", uv: 0 },
    { month: "Mar", uv: 0 },
    { month: "Apr", uv: 0 },
    { month: "May", uv: 0 },
    { month: "Jun", uv: 0 },
    { month: "Jul", uv: 0 },
    { month: "Aug", uv: 0 },
    { month: "Sep", uv: 0 },
    { month: "Oct", uv: 0 },
    { month: "Nov", uv: 0 },
    { month: "Dec", uv: 0 },
  ]

const GraphStatistics = ({ ...props }) => {
    const [botList, setBotList] = useState(null)

    console.log("props.messageGraph---", props.messageGraph)

    useEffect(() => {
        getAllBotList()
      }, []);

    const getAllBotList = async () => {
        let response = await CallAPI("BOT", "GET", BOT_LIST_WITH_STATUS)
        setBotList(response?.res.data?.data?.data)
      };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active) {
            return (
                <div className="abc" style={{
                    backgroundColor: '#000',
                    border: '1px solid #ccc',
                    padding: '10px',
                    borderRadius: '5px',
                    boxShadow: '0px 0px 10px rgba(0,0,0,0.1)'
                }}>
                    <p className="label">Imcoming Message</p>
                    {/* <p className="intro">{getIntroOfPage(label)}</p> */}
                    <p className="desc"><img src="images/icons/message-icon-w.svg" alt="" /> {payload[0]?.value}</p>
                </div>
            );
        }

        return null;
    };

    return (
        <div className="dashboard-chart-grid">
            <header className="d-md-flex align-items-center justify-content-between">
                <h6>Incoming Message</h6>

                <div className="dropdown_select">
                    <select className="form-select with-arrow" aria-label="Default select example" onChange={(e) => props.setSelectedBot(e.target.value)}>
                        <option selected="" value={0}>All</option>
                        {botList?.length > 0 && botList.map((bot, index) => (
                            <option className={bot?.is_deleted == 1 ? "delect-text" : bot?.status != 1 ? "inactive-text" : ""}
                                value={bot.bot_id} >
                                {bot?.bot_name} {bot?.is_deleted == 1 ? "(Deleted)" : bot?.status != 1 ? "(Inactive)" : ""}
                            </option>
                        ))}
                    </select>
                </div>
            </header>

            <ResponsiveContainer width="100%" height={400}>
                <AreaChart data={!props.messageGraph?.length?sampleData:props.messageGraph}>
                    <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip content={<CustomTooltip />} />
                    <Area type="monotone" dataKey="uv" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
                </AreaChart>
            </ResponsiveContainer>

        </div>
    )
}

export default GraphStatistics;
import { CircularProgress } from "@mui/material";
import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

function convertPercentageToInt(percentageString) {
  return Math.round(parseFloat(percentageString.replace("%", "")));
}
const UserEngagement = ({ data, isLoading = false }) => {
  return (
    <div className="chart-block user-engagement-chart dark-bg block-300">
      <h3 className="mb-0">User Engagement</h3>
      <p className="mb-0">Year</p>
      { data?.count ? (
        <>
          <h5>{data?.count}</h5>
          <p>{`${convertPercentageToInt(data?.engagement_count_change).toFixed(0)} %`} {data?.engagement_count_change.includes("-")?"decrease":"increase"} from last year</p>
          <div style={styles.gaugeContainer}>
            <CircularProgressbar
              value={convertPercentageToInt(data?.engagement_count_change).toFixed(0)}
              text={`${convertPercentageToInt(data?.engagement_count_change).toFixed(0)} %`}
              circleRatio={.5}
              styles={buildStyles({
                pathColor: "#823cc1",
                textColor: "#fff",
                trailColor: "#666875",
                rotation: 1.75,
                textSize:'16px'
              })}
            />
          </div>
        </>
      ) : (
        <p>No user engagement for this year range. </p>
      )}
    </div>
  );
};

const styles = {
  engagementValue: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#8e44ad",
  },
  gaugeContainer: {
    width: 150,
    height: 150,
    margin: "auto",
  },
};

export default UserEngagement;

import "./App.css";
import "./assets/css/coolbiz_dashboard.css";
import { AuthProvider } from "./contexts/JWTAuthContext";
import { AdminProvider } from "./contexts/AdminContext";
import { DasboardProvider } from "./contexts/DashboardContext";
import routes, { renderRoutes } from "./routes";
import ScrollToTop from "./utils/ScrollToTop";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import React, { useEffect } from "react";
import ScrollToHash from "./components/ScrollToHash";
import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "./firebase/firebaseConfig";
import { showToast } from "./helper/toastHelper";
import MessageNotification from "./components/MessageNotification";
import "react-toastify/dist/ReactToastify.css";
const App = () => {
  function waitForServiceWorker() {
    return new Promise((resolve, reject) => {
      if (navigator.serviceWorker.controller) {
        console.log("Service worker is already available");
        resolve();
      } else {
        navigator.serviceWorker.oncontrollerchange = () => {
          console.log("Service worker errir");
          resolve();
        };
      }
    });
  }
  async function requestNotificationPermission() {
    console.log("Current Notification.permission:", Notification.permission);

    if (Notification.permission === 'default') {
        const permission = await Notification.requestPermission();
        console.log("Permission after request:", permission);

        if (permission === 'granted') {
            console.log("Notification permission granted.");
            await waitForServiceWorker();
            await getMessagingToken();
        } else {
            console.error("Notification permission denied.");
        }
    } else if (Notification.permission === 'granted') {
        console.log("Notification permission already granted.");
        await waitForServiceWorker();
        await getMessagingToken();
    } else if (Notification.permission === 'denied') {
        console.error("Permission was blocked. Please enable notifications in your browser settings.");
    }
}
  async function getMessagingToken() {
      const token = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
      });
      console.log("Token generated : ", token);
      localStorage.setItem("FcmToken",token)
  }

  useEffect(() => {
    requestNotificationPermission();
    onMessage(messaging, (payload) => {
      toast(<MessageNotification notification={payload.notification} />,{theme:"dark"});
    });
  }, []);
  return (
    <React.Fragment>
      <ScrollToHash />
      <ToastContainer />
      <DasboardProvider>
        <AuthProvider>
          <AdminProvider>
            <ScrollToTop />
            {renderRoutes(routes)}
          </AdminProvider>
        </AuthProvider>
      </DasboardProvider>
    </React.Fragment>
  );
};

export default App;

import React, { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Dropdown from "../../UI/dropdown";
import ToggleSwitch from "../../UI/toggle-switch";
import FileUpload from "../../UI/file-upload";
import { cmsTypeOptions, defaultValuesForCms, LABEL } from "../../../utils/constant";
import CopyableLink from "../../UI/CopyableLink";
import { cmsSchema } from "../../../utils/validationSchema";
import useCmsManagement from "../../../hooks/useCmsManagement";
import { showToast } from "../../../helper/toastHelper";
import { tabs } from "../Cms";

const createFormData = (data) => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    formData.append(key, value);
  });
  return formData;
};

const showSubtitles = ['5', '6', '7'];
const showVideos = ['3', '4'];

export default function CreateOrUpdateCms() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { createCms, getCMSById, updateCms, isLoading } = useCmsManagement();
  const fileUploadRef=useRef()
  const {
    handleSubmit,
    control,
    register,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(cmsSchema),
    defaultValues: defaultValuesForCms,
  });

  const { cmsType } = watch()

  useEffect(() => {
    const fetchCMSData = async () => {
      if (id) {
        try {
          const editCmsContent = await getCMSById(id);
          console.log("edirdata",editCmsContent)
          const { cmsType, description, fileLink, subTitle, title } = editCmsContent;
          const updatedValues = {
            cmsType: cmsType?.toString(), // Ensure it's a string
            title: title || "",
            description: description || "",
            subTitle: subTitle || "",
            files: fileLink || null,
          };

          // Reset the form with updated values
          reset(updatedValues);
         
        } catch (error) {
          console.error("Failed to fetch CMS data:", error);
        }
      }
    };

    fetchCMSData();
  }, [id]);

  useEffect(() => {
    if(cmsType&&!id){
      reset({...defaultValuesForCms, cmsType});
    }
  }, [cmsType]);

  const afterSuccess = (msg) => {
    navigate("/admin/cms");
    if(id){
      return showToast(`${tabs.filter((item)=>item.id==cmsType)[0].label} successfully updated!`, LABEL.TOAST_TYPE.SUCCESS);
    }
    else{
      return showToast(`${tabs.filter((item)=>item.id==cmsType)[0].label} successfully created!`, LABEL.TOAST_TYPE.SUCCESS);
    }
    
  };

  const onSubmit = (data) => {
    if (id) {
      if (!(data.files instanceof File)) {
        const { files, ...rest } = data;
        return updateCms(rest, afterSuccess, id)
      }

      const payloadResponse = createFormData(data)
      console.log(payloadResponse);
      updateCms(payloadResponse, afterSuccess, id, 'file')

    } else {

      if (!(data.files instanceof File)) {
        const { files, ...rest } = data;
        return createCms(rest, afterSuccess)
      }

      const payloadResponse = createFormData(data)
      console.log(payloadResponse);
      createCms(payloadResponse, afterSuccess, 'file')
    }
  };

  return (
    <div className="mychatbot-grid">
      <div className="tab-content-grid">
        <div className="tab-content active">
          <div className="chatbot-history-grid">
            <header className="d-md-flex align-items-center justify-content-between">
              <h3>{id ? LABEL.CMS.EDIT_HEADER : LABEL.CMS.HEADER}</h3>

              {/* <div className="group-form d-md-flex">
                <a href="javascript:void(0)" className="cta-green">
                  {LABEL.CMS.SAVE_AND_CONTINUE}
                </a>
              </div> */}
            </header>

            <div className="chatSettings-grid">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div class="row">
                  <div class="col-md-12 col-lg-12">
                    <div class="row">

                      <div className="col-md-12">
                        <Controller
                          name="cmsType"
                          control={control}
                          render={({ field }) => (
                            <Dropdown
                              label="Select Content Page"
                              error={errors.cmsType}
                              options={cmsTypeOptions}
                              disabled={!!id}
                              {...field}
                            />
                          )}
                        />
                      </div>

                      <div class={showSubtitles.includes(cmsType) ? "col-md-6" : "col-md-12"}>
                        <div class="field">
                          <label for="textareaChatbotDescription">
                            {LABEL.CMS.TITLE}<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            {...register("title")}
                            className={`form-control ${errors.title ? "is-invalid" : ""
                              }`}
                            placeholder="Enter CMS Title"
                          />
                          {errors.title && (
                            <span className="invalid-feedback">
                              {errors.title.message}
                            </span>
                          )}
                        </div>
                      </div>

                      {showSubtitles.includes(cmsType) ? <div class="col-md-6">
                        <div class="field">
                          <label for="textareaChatbotDescription">
                            {LABEL.CMS.SUBTITLE}
                          </label>
                          <input
                            {...register("subTitle")}
                            className={`form-control ${errors.subTitle ? "is-invalid" : ""
                              }`}
                            placeholder="Enter CMS Subtitle"
                          />
                          {errors.subTitle && (
                            <span className="invalid-feedback">
                              {errors.subTitle.message}
                            </span>
                          )}
                        </div>
                      </div> : null}



                      <div class="col-md-12">
                        <div class="field">
                          <label for="textareaChatbotDescription">
                            {LABEL.CMS.DESCRIPTION}<span style={{ color: "red" }}>*</span>
                          </label>
                          <textarea
                            {...register("description")}
                            className={`form-control ${errors.description ? "is-invalid" : ""
                              }`}
                            placeholder="Enter Description"
                          />
                          {errors.description && (
                            <span className="invalid-feedback">
                              {errors.description.message}
                            </span>
                          )}
                        </div>
                      </div>

                      {showVideos.includes(cmsType) ? <div class="col-md-6">
                        <div class="field">
                          {/* Image Upload */}
                          <div className="file-upload-container">
                            <Controller
                              name="files"
                              control={control}
                              render={({ field }) => (
                                <FileUpload
                                  accept={cmsType === '3' ? "video/*" : null}
                                  label={cmsType === '3' ? LABEL.CMS.UPLOAD_VIDEO : LABEL.CMS.CHAT_BUBBLE_ICON}
                                  ref={fileUploadRef}
                                  {...field}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div> : null}

                      <div class="col-md-12">
                        <div class="field">
                          <div class="group-form d-flex align-items-center gap-3">
                            {/* Submit Button */}
                            <button type="submit" className="cta-green" disabled={!!isLoading}>
                            {!!isLoading && <i class="fa-solid fa-spinner fa-spin"></i>}
                              {LABEL.CMS.SAVE_AND_CONTINUE}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

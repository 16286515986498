import { useEffect, useRef, useState } from "react";
import { BotTemplate1, BotTemplate2 } from "./TemplateCollection.jsx.jsx";
import validationUtility from "../../../utils/Validation.js";
import { BotAppearance1, BotAppearance2 } from "../LiveBots/LiveBotAppearance.jsx";
import PhoneNumber from "awesome-phonenumber"
import useChatBot from "../../../hooks/useChatBot.js";

export default function Templates({ ...props }) {
    const { globalSocket, messageList, receivedQuestionType, responseDelay, updateMsgList, updateDelay, handleSetChatConsent } = useChatBot();
    const firstNode = props?.elementFlow?.find(element => element.id == localStorage.getItem("chatSource"))
    const [testChatFlow, setTestChatFlow] = useState(props?.type == "appearance" ? [] : [{ type: firstNode?.type, question: firstNode?.question, options: firstNode?.options?.length > 0 ? firstNode?.options : undefined }])
    // const [testChatFlow, setTestChatFlow] = useState([
    //     {
    //         "type": "text-question",
    //         "question": "Welcome",
    //         "answer": "Hi"
    //     },
    //     {
    //         "type": "phone",
    //         "question": "Please provide your phone number",
    //         "answer": "+1 912 304 8890"
    //     },
    //     {
    //         "type": "single-choice",
    //         "question": "Please select one from the given options",
    //         "options": [
    //             {
    //                 "value": "3#a",
    //                 "label": "option 1"
    //             },
    //             {
    //                 "value": "3#b",
    //                 "label": "option 2"
    //             }
    //         ],
    //         "answer": "option 1"
    //     },
    //     {
    //         "type": "phone",
    //         "question": "Please provide your phone number",
    //         "answer": "+1 912 304 8890"
    //     },
    //     {
    //         "type": "message",
    //         "question": "Welcome to CoolBiz. I am here to help you.",
    //         "answer": ""
    //     }
    // ])
    const [latestQuestion, setLatestQuestion] = useState(props?.type == "appearance" ? {} : { ...firstNode, index: 0 })
    const [inputValue, setInputValue] = useState("")
    const [questionDelay, setQuestionDelay] = useState(false)
    const [showEffect, setShowEffect] = useState(true)
    const chatContainerRef = useRef(null);

    console.log("testChatFlow test----", testChatFlow, props.selectedTemplate, props?.type, props?.openBotTestModal)

    useEffect(() => {
        if(props?.chatHistory?.length > 0)
        {
            updateMsgList(props.chatHistory)
        }
        else
        {
            localStorage.removeItem("browser_unique_id")
        }
    }, [props?.chatHistory]);

    useEffect(() => {
        if (!localStorage.getItem("browser_unique_id") || props?.connectionType == "platform") {
            let laMsgList = [...messageList]
            if (!props?.consent?.trustedDevice && !props?.consent?.consent && !checkConsentExists("consentType", "trustedDevice")) {
                laMsgList.push({
                    "type": "single-choice",
                    "options": [
                        {
                            "label": "Yes",
                            "value": "yes"
                        },
                        {
                            "label": "No",
                            "value": "no"
                        }
                    ],
                    "consentType": "trustedDevice",
                    "directionType": "receive",
                    "question": "Welcome! Trust This Device for 30 Days?",
                    "datetime": new Date()
                })
            }
            else if (!!props?.consent?.trustedDevice && !props?.consent?.consent && !checkConsentExists("consentType", "chatHistory")) {
                laMsgList.push({
                    "type": "single-choice",
                    "options": [
                        {
                            "label": "Yes",
                            "value": "yes"
                        },
                        {
                            "label": "No",
                            "value": "no"
                        }
                    ],
                    "consentType": "chatHistory",
                    "directionType": "receive",
                    "question": "Welcome! Would you like to save your chat history for future reference? Type YES to set up secure access, or type NO to continue without saving. Note: If YES, your chat history will be saved for 30 days. If NO, your chat history will be automatically purged when the chat ends.",
                    "datetime": new Date()
                })
            }
            updateMsgList([...laMsgList])
        }
    }, [props?.consent?.trustedDevice]);

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [latestQuestion, questionDelay, messageList]);

    useEffect(() => {
        if (!responseDelay) {
            const input = document.getElementById('input-control-id');
            input?.focus();
        }
    }, [responseDelay]);

    useEffect(() => {
        props.colorSettingsArray?.map((settings, index) => {
            const elements = document.querySelectorAll(`.${settings.elementClassName}`);
            elements.forEach((el) => {
                el.style.setProperty(settings.props, settings.color, 'important');
            });
        })
    }, [showEffect, questionDelay, messageList]);

    useEffect(() => {
        if (latestQuestion?.type == "message") {
            handleMessageFire()
        }
    }, [latestQuestion]);

    const checkConsentExists = (key, value) => {
        return messageList.some((item) => item[key] === value);  // Check if 'name' key matches the searchKey
    };

    const handleSetInputvalue = (e, type) => {
        if (type == "multi-choice") {
            let multiChoice = !!inputValue ? [...inputValue] : []
            if (multiChoice.indexOf(e) == -1) {
                multiChoice.push(e)
            }
            else {
                multiChoice.splice(multiChoice.indexOf(e), 1);
            }
            setInputValue(multiChoice)
        }
        else if (type == "phone") {
            setInputValue(e)
        }
        else {
            setInputValue(!!(e.target.value.trim()) ? e.target.value : '')
        }
    }

    const handleMessageFire = async () => {
        await delay(500);
        handleSetUserReply()
    }

    const handleSetUserReply = async (value = null, type = null) => {
        let laTestChatFlow = [...testChatFlow]
        let ansFlag = true
        if (!!laTestChatFlow[(laTestChatFlow.length - 1)].answer) {

        }
        else {
            laTestChatFlow[(laTestChatFlow.length - 1)].answer = type == "single-choice" ? value.label : typeof (inputValue) == "string" ? inputValue : inputValue.join(", ")
        }
        setTestChatFlow(laTestChatFlow)
        setInputValue("")

        setQuestionDelay(true)
        await delay(500);
        setQuestionDelay(false)

        if (latestQuestion?.type == "email" && !validationUtility.email(inputValue)) {
            ansFlag = false
            laTestChatFlow.push({ question: "Err! The email is not valid." })
        }

        if (latestQuestion?.type == "phone" && !new PhoneNumber(inputValue).isValid()) {
            ansFlag = false
            laTestChatFlow.push({ question: "Err! The phone is not valid." })
        }

        if (ansFlag && !!latestQuestion) {
            let nextNodeFind
            if (type == "single-choice") {
                nextNodeFind = props.flowDirection.find((element, ind) => element.source == value.value)?.target
            }
            else {
                nextNodeFind = props.flowDirection.find((element, ind) => element.source == latestQuestion?.id)?.target
            }
            let nextNode = props?.elementFlow.find(element => element.id == nextNodeFind)
            if (!!nextNode) {
                console.log("nextNodeFind---", nextNodeFind)
                setLatestQuestion({ ...nextNode })
                laTestChatFlow.push({ type: nextNode.type, question: nextNode.question, options: nextNode?.options?.length > 0 ? nextNode?.options : undefined })
            }
            else {
                setLatestQuestion(null)
            }
        }
        setShowEffect(!showEffect)
        setTestChatFlow(laTestChatFlow)
    }

    function delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    const handleReply = async (option, type = "", consent) => {
        if (!!consent) {
            handleChatbotConsent(consent, option?.value)
        }
        let laMsgList = [...messageList]
        let msgListArr = [...messageList]
        const lastReceivedElement = msgListArr.slice().reverse().find(item => item.directionType === "receive");
        laMsgList[messageList.length - 1].isAnswered = true
        let message = {}
        if (type == "single-choice") {
            message.id = option?.value || ''
            message.user_message = option.label
        }
        else {
            message.id = lastReceivedElement?.id || ''
            if (type == "multi-choice") {
                message.user_message = inputValue.join(',')
            }
            else {
                message.user_message = inputValue
            }
        }
        laMsgList.push({ ...message, directionType: "send", datetime: new Date().toUTCString() })
        setInputValue("")
        updateMsgList(laMsgList)
        setShowEffect(!showEffect)
        globalSocket && globalSocket.send(JSON.stringify({ "message": message, datetime: new Date().toUTCString() }))
        if (!consent || consent == "chatHistory")
            updateDelay(true)
    }

    const handleKeypress = (e, type) => {
        if (e.key === "Enter") {
            if (props?.type == "test-bot") {
                handleSetUserReply(e, type);
            }
            else {
                handleReply(e, type);
            }
        }
    }

    const handleChatbotConsent = (type, value) => {
        props.setConsent({ ...props.consent, [type]: value })
        handleSetChatConsent(type, value)
    }

    console.log("messageList for format---", messageList)
    return (
        props?.type == "appearance"
            ?
            !!props?.selectedTemplate
                ?
                <div className="dark-bg2 box-38">
                    {props.selectedTemplate == "template#1"
                        ? <BotTemplate1 type={props?.type} botIcon={props?.botIcon} />
                        : <BotTemplate2 type={props?.type} botIcon={props?.botIcon} />}
                </div>
                :
                <>
                    <div className="dark-bg2 box-38" onClick={() => props.setSelectedTemplate("template#1")}>
                        <BotTemplate1 type={props?.type} />
                    </div>
                    <div className="dark-bg2 box-38" onClick={() => props.setSelectedTemplate("template#2")}>
                        <BotTemplate2 type={props?.type} />
                    </div>
                </>
            :
            props?.type == "test-bot"
                ? props.selectedTemplate == "template#1"
                    ? <BotTemplate1
                        chatContainerRef={chatContainerRef}
                        type={props?.type}
                        testChatFlow={testChatFlow}
                        handleSetInputvalue={handleSetInputvalue}
                        handleSetUserReply={handleSetUserReply}
                        inputValue={inputValue}
                        questionDelay={questionDelay}
                        questionType={latestQuestion?.type}
                        setOpenBotTestModal={props?.setOpenBotTestModal}
                        botIcon={props?.botIcon}
                        handleKeypress={handleKeypress} />
                    : <BotTemplate2
                        chatContainerRef={chatContainerRef}
                        type={props?.type}
                        testChatFlow={testChatFlow}
                        handleSetInputvalue={handleSetInputvalue}
                        handleSetUserReply={handleSetUserReply}
                        inputValue={inputValue}
                        questionDelay={questionDelay}
                        questionType={latestQuestion?.type}
                        setOpenBotTestModal={props?.setOpenBotTestModal}
                        botIcon={props?.botIcon}
                        handleKeypress={handleKeypress} />
                : props.selectedTemplate == "template#1"
                    ? <BotAppearance1
                        consentParams={props.consent}
                        type={receivedQuestionType}
                        chatContainerRef={chatContainerRef}
                        messageList={messageList}
                        handleSetInputvalue={handleSetInputvalue}
                        handleReply={handleReply}
                        inputValue={inputValue}
                        questionDelay={questionDelay}
                        questionType={latestQuestion?.type}
                        setOpenBotTestModal={props?.setOpenBotTestModal}
                        botIcon={props?.botIcon}
                        botBubbleIcon={props?.botBubbleIcon}
                        handleKeypress={handleKeypress}
                        responseDelay={responseDelay}
                        openBotStyle={props?.openBotStyle}
                        fontStyle={props.fontStyle}
                        fontSize={props.fontSize}
                        handleChatbotConsent={handleChatbotConsent} />
                    : <BotAppearance2
                        consentParams={props.consent}
                        type={receivedQuestionType}
                        chatContainerRef={chatContainerRef}
                        messageList={messageList}
                        handleSetInputvalue={handleSetInputvalue}
                        handleReply={handleReply}
                        inputValue={inputValue}
                        questionDelay={questionDelay}
                        questionType={latestQuestion?.type}
                        setOpenBotTestModal={props?.setOpenBotTestModal}
                        botIcon={props?.botIcon}
                        botBubbleIcon={props?.botBubbleIcon}
                        handleKeypress={handleKeypress}
                        responseDelay={responseDelay}
                        openBotStyle={props?.openBotStyle}
                        fontStyle={props.fontStyle}
                        fontSize={props.fontSize}
                        handleChatbotConsent={handleChatbotConsent} />
    )
}

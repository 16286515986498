import React, { useCallback, useEffect } from 'react';
import useDashboard from '../hooks/useDashboard';

const Features = () => {
  const { isLoading, getCmsByCmsType, allCMS } = useDashboard();

  useEffect(() => {
    fetchAllCmsList(4);
  }, []);

  const fetchAllCmsList = useCallback((type) => {
    getCmsByCmsType(type);
  }, [getCmsByCmsType]);

  return (
    <section className="body-wrap">

      <div className="features-grid">
        <div className="container">
          <div className="banner-home_text with-h1-line text-center">
            <h1>Chatbot Features</h1>
          </div>

          <div className="features-grid_list">
            <ul>
              {
                allCMS?.cmsData?.map((feature) => (<li key={feature.id}>
                  <figure>
                    <img src={feature.fileLink || "images/features-icons/automated-management-icon.svg"} alt="icon" />
                  </figure>
                  <aside>
                    <h4>{feature.title || ""}</h4>
                    <p>{feature.description || ""}</p>
                  </aside>
                </li>))
              }

              {/*
               <li>
                <figure>
                  <img src="/images/features-icons/automated-management-icon.svg" alt="icon" />
                </figure>
                <aside>
                  <h4>Automated GDPR/CCPA/HIPAA Compliance Management</h4>
                  <p>The chatbot detects when compliance is required, gathers user consent, and
                    records opt-in status for regulatory purposes,
                    either in your CRM contact tags or the dashboard, ensuring compliance with
                    CCPA/GDPR. All data streams are secured with
                    the highest levels of encryption, protecting data privacy and ensuring
                    end-to-end compliance across platforms.</p>
                </aside>
              </li>
               <li>
                <figure>
                  <img src="/images/features-icons/file-upload-icon.svg" alt="icon" />
                </figure>
                <aside>
                  <h4>File and Image Upload for Analysis</h4>
                  <p>The chatbot allows users to upload images or files, such as bills or forms, for
                    automated analysis. This feature is
                    aluable in healthcare, finance, and legal services, where document processing
                    assistance is needed. Subscribers can
                    train the chatbot to recognize data points and guide users to the next steps,
                    improving real-time assistance whether
                    or not a CRM is used.</p>
                </aside>
              </li>
              <li>
                <figure>
                  <img src="/images/features-icons/chatbot-interaction-icon.svg" alt="icon" />
                </figure>
                <aside>
                  <h4>No Barriers to Chatbot Interaction</h4>
                  <p>While the chatbot maintains constant compliance, users can engage with the
                    chatbot immediately without inputting
                    personal information upfront, such as name, email, and phone number. This
                    reduces friction and boosts engagement rates.
                    This open interaction allows potential customers or clients to explore your
                    services freely, making the chatbot an
                    approachable 24/7 representative, whether used for sales, support, or other
                    inquiries.</p>
                </aside>
              </li>
              <li>
                <figure>
                  <img src="/images/features-icons/memory-chats-icon.svg" alt="icon" />
                </figure>
                <aside>
                  <h4>Persistent Memory for Chats</h4>
                  <p>The chatbot retains chat history (persistent memory) for future reference,
                    allowing users to recall past interactions and provide a more personalized
                    experience. Users have full control to delete chat history for privacy. However,
                    the opt-in status remains intact for subscriber compliance purposes. Persistent
                    memory is key for industries requiring ongoing customer support or where
                    previous interactions are important for service continuity.</p>
                </aside>
              </li>
              <li>
                <figure>
                  <img src="/images/features-icons/ai-modals-icon.svg" alt="icon" />
                </figure>
                <aside>
                  <h4>Customizable Proprietary AI Models</h4>
                  <p>Tailor the chatbot to meet your business's specific needs, whether for sales,
                    service, or support. Advanced models are available for specialized industries
                    such as finance or healthcare, providing enhanced customization and compliance.
                    These industry-specific models allow you to deliver tailored experiences,
                    ensuring that your chatbot aligns with complex regulatory and service
                    requirements.</p>
                </aside>
              </li>
              <li>
                <figure>
                  <img src="/images/features-icons/chat-history-icon.svg" alt="icon" />
                </figure>
                <aside>
                  <h4>Downloadable Chat History</h4>
                  <p>Easily download chat histories in PDF format to save or print for training,
                    behavior refinement, or record-keeping purposes. This feature is handy for
                    businesses looking to improve customer interactions, refine chatbot responses,
                    or provide documentation for compliance, internal training, or auditing
                    purposes.</p>
                </aside>
              </li>
              <li>
                <figure>
                  <img src="/images/features-icons/language-support-icon.svg" alt="icon" />
                </figure>
                <aside>
                  <h4>Multilingual Language Support</h4>
                  <p>The chatbot can seamlessly switch between multiple languages, allowing users to
                    use their preferred language. Whether serving global clients or multilingual
                    communities, the chatbot ensures smooth and inclusive communication, enhancing
                    user satisfaction. This feature is essential for businesses operating
                    internationally or serving diverse audiences.</p>
                </aside>
              </li>
              <li>
                <figure>
                  <img src="/images/features-icons/calendar-link-icon.svg" alt="icon" />
                </figure>
                <aside>
                  <h4>Up to 20 Calendar Link Options</h4>
                  <p>The chatbot supports up to 20 calendar links, enabling seamless appointment
                    scheduling across platforms like the Go High Level CRM, Google Calendar, and
                    Office 365. Designed for businesses with complex scheduling needs, this feature
                    simplifies setup and offers 24/7 automated scheduling, ideal for healthcare,
                    finance, multi-provider offices, and booking consultations, reducing no-shows
                    and improving operational efficiency.</p>
                </aside>
              </li>
              <li>
                <figure>
                  <img src="/images/features-icons/privacy-controls-icon.svg" alt="icon" />
                </figure>
                <aside>
                  <h4>User Privacy Controls</h4>
                  <p>Users have complete control over their chat history, with options to delete or
                    retain interactions for future use or log out at the end of their session. Users
                    can also choose to log out and delete their chat history. This supports
                    GDPR/CCPA compliance and ensures transparency in data handling. Even after chat
                    deletion, the chatbot retains opt-in status unless the user opts out via
                    channels like email or SMS.</p>
                </aside>
              </li>
              <li>
                <figure>
                  <img src="/images/features-icons/chat-widget-icon.svg" alt="icon" />
                </figure>
                <aside>
                  <h4>Customizable Chat Widget</h4>
                  <p>Subscribers can fully customize the chatbot widget, adjusting its design,
                    interaction style, avatar, and placement to match their brand. They have full
                    control over colors and fonts, using tools like a color wheel and eyedropper for
                    precise customization. This flexibility ensures a professional, consistent look
                    across devices and pages, with a responsive design that avoids conflicts with
                    other website elements.</p>
                </aside>
              </li>
              <li>
                <figure>
                  <img src="/images/features-icons/flexible-branding-icon.svg" alt="icon" />
                </figure>
                <aside>
                  <h4>Flexible Branding Control for Agencies</h4>
                  <p>Agencies can use their branding or customize sub-accounts. This feature enables
                    tiered pricing strategies based on branding options. Seamless integration with
                    CRM systems like Go High Level streamlines onboarding, allowing sub-accounts to
                    sign up, brand, and auto-populate their chatbots, maximizing agency control and
                    flexibility.</p>
                </aside>
              </li>
              <li>
                <figure>
                  <img src="/images/features-icons/interaction-analytics-icon.svg" alt="icon" />
                </figure>
                <aside>
                  <h4>Advanced Client and User Interaction Analytics</h4>
                  <p>Through advanced analytics, gain insights into user engagement, session duration,
                    and behavior patterns. This data can be used to improve chatbot performance,
                    refine customer interaction strategies, and optimize sales and support
                    workflows. For agencies, these insights are invaluable in demonstrating the
                    value of chatbot services to clients and improving overall ROI.</p>
                </aside>
              </li>
              <li>
                <figure>
                  <img src="/images/features-icons/engagement-analytics-icon.svg" alt="icon" />
                </figure>
                <aside>
                  <h4>5 Sites for Engagement Analytics</h4>
                  <p>Subscribers can track user engagement across up to 5 different websites,
                    providing detailed insights into how users interact with chatbots on various
                    platforms. This feature helps businesses optimize chatbot performance based on
                    user behavior from multiple online touchpoints.</p>
                </aside>
              </li>
              <li>
                <figure>
                  <img src="/images/features-icons/client-rebilling-icon.svg" alt="icon" />
                </figure>
                <aside>
                  <h4>Automated Client Rebilling through Stripe</h4>
                  <p>Agencies can integrate Stripe with their CRM to automate client billing for
                    chatbot services. This feature supports custom payment tiers and recurring
                    billing, allowing payments to be collected automatically. Stripe’s secure
                    processing ensures compliance and helps agencies manage cash flow efficiently.
                  </p>
                </aside>
              </li>
              <li>
                <figure>
                  <img src="/images/features-icons/dashboards-management-icon.svg" alt="icon" />
                </figure>
                <aside>
                  <h4>20 Dashboards for Comprehensive Management</h4>
                  <p>Subscribers have access to up to 20 customizable dashboards, allowing them to
                    manage various aspects of their chatbot, such as performance metrics, user
                    engagement, and chatbot behavior, all in one place. This feature is ideal for
                    businesses with multiple departments or service lines needing separate insights
                    and configurations.</p>
                </aside>
              </li>
              <li>
                <figure>
                  <img src="/images/features-icons/unlimited-chatbots-icon.svg" alt="icon" />
                </figure>
                <aside>
                  <h4>Unlimited AI Chatbots</h4>
                  <p>Subscribers can deploy an unlimited number of AI chatbots across their sites,
                    enabling them to cater to different audiences or functions (sales, support, or
                    general business) without limitations. This flexibility ensures scalability for
                    businesses, no matter their size or number of use cases.</p>
                </aside>
              </li>
              <li>
                <figure>
                  <img src="/images/features-icons/unlimited-integrations-icon.svg" alt="icon" />
                </figure>
                <aside>
                  <h4>Unlimited Integrations</h4>
                  <p>The chatbot seamlessly integrates with the Go High Level CRM platform while
                    offering powerful integrations with essential tools like Google Calendar and
                    Office 365. These integrations are particularly beneficial for businesses not
                    using a CRM, providing robust scheduling and workflow capabilities. The number
                    of integrations is unlimited, enabling businesses to create a comprehensive
                    workflow tailored to their needs, regardless of whether they use a CRM.</p>
                </aside>
              </li>
              <li>
                <figure>
                  <img src="/images/features-icons/chatbot-sessions-icon.svg" alt="icon" />
                </figure>
                <aside>
                  <h4>Unlimited Chatbot Sessions</h4>
                  <p>There are no limitations on the number of chatbot interactions or sessions,
                    ensuring that the chatbot can handle unlimited conversations with users 24/7.
                    This feature supports businesses that rely on continuous customer engagement
                    without worrying about usage caps.</p>
                </aside>
              </li>
              <li>
                <figure>
                  <img src="/images/features-icons/level-control-icon.svg" alt="icon" />
                </figure>
                <aside>
                  <h4>Agency-Level Control (For Both CRM & Non-CRM Users)</h4>
                  <p>Agencies can apply their branding to the chatbot or let sub-account users fully
                    customize it. This flexibility works seamlessly for CRM users such as Go High
                    Level and non-CRM users. Agencies also control pricing, offering a lower-priced,
                    agency-branded option or a higher-tier service for sub-account branding
                    customization, allowing them to tailor their offerings to different client
                    needs.</p>
                </aside>
              </li>
              <li>
                <figure>
                  <img src="/images/features-icons/account-branding-icon.svg" alt="icon" />
                </figure>
                <aside>
                  <h4>Sub-Account Branding (For Both CRM & Non-CRM Users)</h4>
                  <p>Sub-account users can customize their chatbot branding by default, including
                    logos and colors, regardless of whether they use a CRM. Automated setup tools,
                    like those in Go High Level or independent platforms, make onboarding easy by
                    instantly applying custom branding. This ensures a smooth, personalized
                    experience for all users across platforms.</p>
                </aside>
              </li> */}
            </ul>
          </div>
        </div>
      </div>


    </section>
  )
}

export default Features;
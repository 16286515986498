import React, { useEffect, useLayoutEffect, useState } from 'react'
import { CallAPI } from '../../utils/apiActions';
import { BOT_CHAT_HISTORY, BOT_EMBED } from '../../utils/APIEndPoints';
import Templates from '../my-chatboat/Templates/Templates';
import styled from 'styled-components';
import axios from 'axios';
import { ChatBotProvider } from '../../contexts/ChatBotContext';
import ChatSocketInitialization from '../ChatSocketInitialization';
import useChatBot from '../../hooks/useChatBot';

const BottomChatbotMessage = styled.div`
  position: fixed;
  z-index: 9;
  display: flex;
  align-items: center;
  border: 2px solid #ffffff;
  border-radius: 50%;
  width: '50px',
  height: '50px',
  backgroundColor: 'white',
`;

const Figure = styled.figure`
  width: 44px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
`;

const Img = styled.img`
  width: 44px;
  height: 44px;
  object-fit: cover;
`;

const MessageBox = styled.div`
  position: fixed;
  right: 0;
  bottom: 54px;
  min-height: 55px;
  fit-content;
  font-size: 14px;
  background: #fff;
  color: #000;
  padding: 24px 12px 12px;
  border-radius: 10px;
`;

const MessageBoxText = styled.p`
  margin-bottom: 0;
  line-height: 1.6;
`;

const CloseBox = styled.div`
  background-color: #ccc;
  width: 22px;
  height: 22px;
  font-weight: 500;
  border-radius: 50px;
  display: flex;
  align-items: center;
  font-size: 12px;
  justify-content: center;
  position: absolute;
  right: 5px;
  top: 5px;

  &:hover {
    background-color: #000000;
    color: #fff;
  }
`;

export default function LiveChat({ ...props }) {

  // const searchParams = new URLSearchParams(window.location.search);
  // const [idParam,setIdParam] = searchParams.get('id');
  const { updateMsgList } = useChatBot()
  const [chatHistory, setChatHistory] = useState([])
  const [consent, setConsent] = useState({ trustedDevice: '', chatHistory: '' })
  const [shortCode, setShortCode] = useState('786076108040')
  const [colorSettingsArray, setColorSettingsArray] = useState([
    { key: "header_bg", lable: "Header Background", color: "#355266", elementClassName: "header-back-color", props: "background-color" },
    { key: "page_bg", lable: "Page Background", color: "#ffffff", elementClassName: "page-back-color", props: "background-color" },
    { key: "bot_tb_color", lable: "Bot Text Box Color", color: "#ffffff", elementClassName: "bot-color-box", props: "background-color" },
    { key: "bot_txt_color", lable: "Bot Text Color", color: "#000000", elementClassName: "bot-text-color", props: "color" },
    { key: "sender_tb_color", lable: "Sender Text box Color", color: "#ffffff", elementClassName: "sender-color-box", props: "background-color" },
    { key: "sender_txt_color", lable: "Sender Text Color", color: "#000000", elementClassName: "sender-text-color", props: "color" },
    { key: "link_color", lable: "Link Color", color: "#3498db", elementClassName: "link-color", props: "color" },
    { key: "btn_txt_color", lable: "Icon Color", color: "#212529bf", elementClassName: "button-text-color", props: "color" },
    { key: "option_tb_color", lable: "Option Button Color", color: "#3498db", elementClassName: "option-color", props: "background" },
    { key: "option_txt_color", lable: "Option Text Color", color: "#ffffff", elementClassName: "option-color", props: "color" },
    { key: "footer_bg", lable: "Footer Background", color: "#ffffff", elementClassName: "footer-back-color", props: "background-color" }
  ]);

  const [openBotTestModal, setOpenBotTestModal] = useState(false)
  const [botSettings, setBotSettings] = useState(null)
  const [botStyle, setBotStyle] = useState({
    width: 360,
    height: 425,
    original_x: 1536,
    original_y: 730,
    desc_box_x: 0,
    desc_box_y: 0,
    chat_box_x: 1110,
    chat_box_y: 300,
    viewport_x: 0,
    viewport_y: 0
  })
  const [descDiv, setDescDiv] = useState(true)
  const [domainURL, setDomainURL] = useState("https://bot.coolbiz.info/")
  const [domainPath, setDomainPath] = useState("/bot-settings?tab=3")
  // const [descBoxPos, setDescBoxPos] = useState({ x: 1400, y: 650 });
  // const [resizableDivPos, setResizableDivPos] = useState({ x: 1400, y: 650 });

  // useLayoutEffect(() => {
  //   getConsentContent()
  // }, []);

  const getIdFromScript = (shortCode = 786076108040) => {
    if (!!shortCode) {
      setShortCode(shortCode)
      getBotStyle(shortCode)
    }
    else {
      const scripts = document.getElementsByTagName('script');
      let scriptSrc = '';

      // Find the script tag that includes your React app (i.e., main.js)
      for (let i = 0; i < scripts.length; i++) {
        if (scripts[i].src.includes('chatbot.')) {  // Match your React script file name
          scriptSrc = scripts[i].src;
          break;
        }
      }

      // If the script URL is found, extract the 'id' query parameter
      if (scriptSrc) {
        const urlParams = new URLSearchParams(new URL(scriptSrc).search);
        setShortCode(urlParams.get('id'))
        getBotStyle(urlParams.get('id'))
      }
      else {
        getBotStyle("786076108040")
      }
    }
  };

  const getChatHistory = async () => {
    const response = await CallAPI(
      "CHAT",
      "GET",
      `${BOT_CHAT_HISTORY}/?browser_unique_id=${localStorage.getItem("browser_unique_id")}`
    );
    let lamessageList = []
    console.log("response chat history---", response)
    response?.res?.data?.map((chat, index) => {
      chat.chat_details?.map((thread, ind) => {
        let botResponse = {
          question: thread.bot.question,
          datetime: thread.bot.datetime,
          type: thread.bot.type,
          options: thread.bot.options || null,
          directionType: "receive",
          isAnswered: true
        }
        lamessageList.push(botResponse)
        if (thread.user.replay) {
          let userResponse = {
            user_message: thread.user.replay,
            directionType: "send",
            datetime: thread.user.datetime
          }
          lamessageList.push(userResponse)
        }
      })
    })
    setChatHistory(lamessageList)
  };

  useEffect(() => {
    colorSettingsArray?.map((settings, index) => {
      const elements = document.querySelectorAll(`.${settings.elementClassName}`);
      elements.forEach((el) => {
        el.style.setProperty(settings.props, settings.color, 'important');
      });
    })
  }, [colorSettingsArray, openBotTestModal, consent]);

  useEffect(() => {
    if (props?.connectionType == "outside") {
      if (!!localStorage.getItem("browser_unique_id")) {
        setConsent({ trustedDevice: true, chatHistory: true })
        getChatHistory()
      }
      if (typeof window !== 'undefined' && props?.connectionType == "outside") {
        setDomainURL(window.location.href); // Set the host (e.g., "localhost")
        setDomainPath(window.location.pathname); // Set the path (e.g., "/home")
      }
      getIdFromScript(props.shortCode || null)
    }
    else if (props?.connectionType == "platform") {
      if (!!props.shortCode && props.botCompletedStep == 4) {
        getIdFromScript(props.shortCode || null)
      }
      else {
        responsiveStyleAdoption(botStyle)
        setColorSettingsArray(props.colorSettingsArray)
        setBotSettings({ bot_image: props.botBubbleIcon, template_name: props.selectedTemplate })
      }
      setOpenBotTestModal(props.openBotModal)
    }
    else {
      alert("nither")
    }
  }, [props?.connectionType]);

  

  const getConsentContent = async () => {
    const response = await CallAPI("CHAT", "GET", `translated-output/?shortcodes=786076108040`)
    // if (response.status) {
    //   let contentData = response?.res?.data?.data[0]
    //   console.log("contentData---", contentData)
    // }
  };

  const getBotStyle = async (short_code) => {
    let styleArr = [...colorSettingsArray]
    axios.defaults.headers['domain'] = window.location.href;
    axios.defaults.headers['shortcode'] = short_code;
    const response = await CallAPI("BOT", "GET", BOT_EMBED)
    delete axios.defaults.headers['domain'];
    delete axios.defaults.headers['shortcode'];
    if (response.status) {
      let contentData = response?.res?.data?.data[0]
      console.log("contentData---", contentData)
      responsiveStyleAdoption(contentData)
      // let x_positions = {
      //   width: contentData.width,
      //   desc_box_x: contentData.desc_box_x,
      //   chat_box_x: contentData.chat_box_x,
      //   viewport_x: contentData.viewport_x,
      // }
      // let y_positions = {
      //   height: contentData.height,
      //   desc_box_y: contentData.desc_box_y,
      //   chat_box_y: contentData.chat_box_y,
      //   viewport_y: contentData.viewport_y
      // }
      // let responsiveStyle = { ...botStyle }
      // for (let [key, value] of Object.entries(x_positions)) {
      //   responsiveStyle[key] = parseInt(value) * parseInt(window.innerWidth) / parseInt(contentData.original_x)
      // }
      // for (let [key, value] of Object.entries(y_positions)) {
      //   responsiveStyle[key] = parseInt(value) * parseInt(window.innerHeight) / parseInt(contentData.original_y)
      // }
      // setBotStyle({ ...responsiveStyle })
      for (let [key, value] of Object.entries(contentData?.styles)) {
        var foundIndex = styleArr.findIndex(x => x.key == key);
        if (foundIndex != -1) styleArr[foundIndex].color = value;
      }
      setColorSettingsArray(styleArr)
      setBotSettings(contentData)
    }
  };

  const responsiveStyleAdoption = (contentData) => {
    let x_positions = {
      width: contentData.width,
      desc_box_x: contentData.desc_box_x,
      chat_box_x: contentData.chat_box_x,
      viewport_x: contentData.viewport_x,
    }
    let y_positions = {
      height: contentData.height,
      desc_box_y: contentData.desc_box_y,
      chat_box_y: contentData.chat_box_y,
      viewport_y: contentData.viewport_y
    }
    let responsiveStyle = { ...botStyle }
    for (let [key, value] of Object.entries(x_positions)) {
      responsiveStyle[key] = parseInt(value) * parseInt(window.innerWidth) / parseInt(contentData.original_x)
    }
    for (let [key, value] of Object.entries(y_positions)) {
      responsiveStyle[key] = parseInt(value) * parseInt(window.innerHeight) / parseInt(contentData.original_y)
    }
    setBotStyle({ ...responsiveStyle })
    console.log("response apprance----", y_positions, x_positions, window.innerWidth, window.innerHeight, contentData.original_x, contentData.original_y, responsiveStyle)
  }
  
  return (
    <ChatBotProvider>
      {
        <>
          {openBotTestModal && botSettings
            ?
            <>
              {(!!consent.trustedDevice && !!consent.chatHistory) &&
                <ChatSocketInitialization
                  shortCode={shortCode}
                  domainURL={domainURL}
                  domainPath={domainPath}
                  bot_id={props.bot_id}
                  connectionType={props?.connectionType}
                  botCompletedStep={props.botCompletedStep}
                />}
              <Templates
                setConsent={setConsent}
                consent={consent}
                chatHistory={chatHistory}
                type={"live-bot"}
                selectedTemplate={botSettings?.template_name}
                setOpenBotTestModal={props?.connectionType == "platform" ? setOpenBotTestModal && props.setOpenBotModal : setOpenBotTestModal}
                botBubbleIcon={botSettings?.bot_image || "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"}
                colorSettingsArray={colorSettingsArray}
                openBotStyle={{
                  top: `${botStyle.chat_box_y}px`,
                  left: `${botStyle.chat_box_x}px`,
                  height: `${botStyle.height}px`,
                  width: `${botStyle.width}px`
                }}
                fontStyle={botSettings.fontBody}
                fontSize={botSettings?.fontHeader?.toLowerCase()}
                connectionType={props?.connectionType}
              />
            </>
            : botSettings &&
            <BottomChatbotMessage
              style={{
                // top: `${botStyle.viewport_y * window.innerHeight / 730}px`,
                // left: `${botStyle.viewport_x * window.innerWidth / 1536}px`,
                top: `${botStyle.viewport_y}px`,
                left: `${botStyle.viewport_x}px`
              }}>
              {descDiv && botSettings?.chat_bbl_desc && !!botSettings?.isDescAllow &&
                <MessageBox
                  style={{
                    position: 'fixed',
                    top: `${botStyle.desc_box_y}px`,
                    left: `${botStyle.desc_box_x}px`,
                    width: `${240 * window.innerWidth / botSettings.original_x}px`,
                    height: 'fit-content',
                    minHeight: '55px'
                  }}>
                  <CloseBox href="javascript:void(0)" onClick={() => setDescDiv(false)}>X</CloseBox>
                  <MessageBoxText>{botSettings?.chat_bbl_desc || "your bot description here"}</MessageBoxText>
                </MessageBox>}
              <Figure onClick={() => setOpenBotTestModal(true)}>
                <Img
                  src={botSettings?.slugs ? botSettings?.slugs : "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"}
                  alt="images-100" /></Figure>
            </BottomChatbotMessage>}
        </>
      }
    </ChatBotProvider>
  )
}

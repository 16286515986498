import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { LABEL, paginationObj, user_type } from '../../utils/constant';
import { useNavigate } from 'react-router-dom';
import { showToast } from '../../helper/toastHelper';
import { CallAPI } from '../../utils/apiActions';
import { BOT_DELETE, BOT_LIST_WITH_STATUS } from '../../utils/APIEndPoints';
import moment from 'moment';
import useAuth from '../../hooks/useAuth';
import { Pagination } from '@mui/material';

const BotListStatistics = ({ ...props }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const botSettingsPath = user_type[user?.userType] == "super_admin" ? '/admin/bot-settings' : '/bot-settings';

  const handleDeleteBot = async (botId) => {
    Swal.fire({
      title: "Are you sure you want to delete this bot?",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          let res = await CallAPI("BOT", "DELETE", `${BOT_DELETE}/${botId}`)
          if (res.status) {
            showToast(res.msg, LABEL.TOAST_TYPE.SUCCESS);
            props.getAllBotList()
          }
          else {
            showToast(res.msg, LABEL.TOAST_TYPE.ERROR);
          }
        } catch (err) {
          showToast(err.msg, LABEL.TOAST_TYPE.ERROR);
        }
      }
    });
  }

  return (
    <div className="all-chatboat">
      <header className="d-md-flex align-items-center justify-content-between">
        <h3>All Chatbots</h3>

        <a href="javascript:void(0)" className="cta-green" onClick={() => navigate(`${botSettingsPath}?tab=1`)}>
          <i className="fa-solid fa-sparkles" aria-hidden="true"></i>
          Create New Chatbot
        </a>
      </header>
      <div className="table-responsive">
        <table className="table align-middle">
          <thead>
            <tr>
              <th className="text-left">Chatbot name</th>
              <th className="text-center">Create date</th>
              <th className="text-center">Status</th>
              <th className="text-center">Chatbot Description</th>
              <th className="text-center action">Action</th>
            </tr>
          </thead>
          <tbody>
            {props.botList?.length > 0 && props.botList.map((bot, index) => (
              <tr>
                <td className="text-left">{bot?.bot_name}</td>
                <td className="text-center">{moment(bot?.createdAt).format('DD-MM-YYYY HH:mm:ss')}</td>
                <td className="text-center">{bot?.is_deleted == 1 ? "Deleted" : bot?.status == 1 ? "Active" : "Inactive"}</td>
                <td className="text-center"><span className="short-text">{bot?.chat_bbl_desc}</span></td>
                <td className="text-center action">
                  {bot?.is_deleted != 1 && <a href="javascript:void(0)"
                    onClick={() => navigate(`${botSettingsPath}?tab=1&id=${bot.bot_id}&temp=${bot.template_id}&botname=${bot.bot_name}`)}>
                    <img src="/images/icons/edit-icon.svg"
                      alt="" /></a>}
                  {bot?.is_deleted != 1 && <a href="javascript:void(0)"
                    onClick={() => handleDeleteBot(bot.bot_id)}><img src="/images/icons/delete-icon.svg"
                      alt="" /></a>}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="pagination_wrap">
          <div className="pagination-info">
            Showing {(props.paginationData.currentPage - 1) * paginationObj.item_per_page + 1} to{" "}
            {Math.min(
              props.paginationData.currentPage * paginationObj.item_per_page,
              props.paginationData.totalRecords
            )}{" "}
            of {props.paginationData.totalRecords} entries
          </div>
          <div className="pagination-table">
            <Pagination
              count={props.paginationData.totalPages}
              page={props.paginationData.currentPage}
              onChange={props.handlePaginationChange}
              color="secondary"
              shape="rounded"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BotListStatistics;
/* eslint-disable radix */
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../../hooks/useAuth';
import Swal from 'sweetalert2';

const AuthGuard = ({ children }) => {
  const { isAuthenticated, user } = useAuth();
  // console.log('isAuthenticated', isAuthenticated, user)
  const location = useLocation();
  const navigate = useNavigate();

  if (location.pathname != "/bot-settings") {
    localStorage.removeItem("chatSource")
    localStorage.removeItem("botStyle")
    localStorage.removeItem("botWorkFlowEdge")
    localStorage.removeItem("botWorkFlowNode")
    localStorage.removeItem("chatElement")
    localStorage.removeItem("chatNode")
  }

  if (!isAuthenticated && user === null) {
    return <Navigate to="/" />;
  }

  // if (user && user.userType === 1) {
  //   return <Navigate to="/admin/dashboard" />;
  // }

  // if (user && user.userType === 1) {
  //   return <Navigate to="/agency/dashboard" />;
  // }

  // if (user && user.userType === 1) {
  //   return <Navigate to="/affiliate/dashboard" />;
  // }

  return (
    <>
      {children}
    </>
  );
};

AuthGuard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthGuard;
